import dayjs from "dayjs";
import { PTRA_EMAIL_ENTITY } from "./fields";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.extend(timezone);

export const RiskAlertEmailManagerColumns = [
  {
    Header: "Email",
    accessor: PTRA_EMAIL_ENTITY.recipientEmail,
    width: 150,
    id: PTRA_EMAIL_ENTITY.recipientEmail,
  },
  {
    Header: "Created By",
    accessor: PTRA_EMAIL_ENTITY.creator,
    width: 100,
    id: PTRA_EMAIL_ENTITY.creator,
  },
  {
    Header: "Last Update",
    accessor: row =>
      row[PTRA_EMAIL_ENTITY.lastUpdate]
        ? dayjs(row[PTRA_EMAIL_ENTITY.lastUpdate]).format("MM/DD/YYYY HH:mm")
        : "N/A",
    width: 100,
    id: PTRA_EMAIL_ENTITY.lastUpdate,
  },
];
