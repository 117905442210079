import { Tag } from "@nef/core";
import dayjs from "dayjs";
import { POR, PORModel } from "components/pvRejects/constant";
import { PVR_DATE_TIME_FORMAT } from "../client/columns";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { TagCellWrapper } from "components/standardTable/tagCellWrapper";
import { currentStatusColorMap, TagColor } from "components/settings/pvr/audit/columns";
dayjs.extend(utc);
dayjs.extend(timezone);

export type Row = {
  symbol: string;
  requestId: string;
  requestStatus: string;
  lastUpdate: string;
};

export const columns = [
  { Header: "Symbol", id: "symbol", accessor: "symbol", width: 100 },
  { Header: "MPID", id: "mpid", accessor: "mpid", width: 100 },
  { Header: "Price", id: "price", accessor: "price", width: 100 },
  { Header: "Quantity", id: "quantity", accessor: "quantity", width: 100 },
  { Header: "Reject Reason", id: "rejectReason", accessor: "rejectReason", width: 200 },
];

export enum PORModel2 {
  SYMBOL = "symbol",
  MPID = "mpid",
  REQUEST_STATUS = "requestStatus",
  REQUESTOR = "requestor",
  REQUEST_ID = "requestId",
  LAST_UPDATE_TIME = "lastUpdateTime",
}

export const pvSupervisorColumns2 = [
  { Header: "Symbol", id: "symbol", accessor: "symbol", width: 60 },
  { Header: "MPID", id: "mpid", accessor: "mpid", width: 60 },
  {
    Header: "Request Status",
    id: "status",
    accessor: "status",
    width: 110,
    Cell: ({ row }: { row: { original: POR } }) => {
      const color =
        currentStatusColorMap[row.original[PORModel.REQUEST_STATUS]] ?? TagColor.WARNING;
      return (
        <TagCellWrapper>
          <Tag color={color}>{row.original[PORModel.REQUEST_STATUS]}</Tag>
        </TagCellWrapper>
      );
    },
  },
  { Header: "Requestor", id: "requestorEmail", accessor: "requestorEmail", width: 150 },
  { Header: "Request ID", id: "id", accessor: "id", width: 80 },
  {
    Header: "Actioned By",
    id: PORModel.ACTIONER_EMAIL,
    accessor: PORModel.ACTIONER_EMAIL,
    width: 150,
  },
  {
    Header: "Last Update Time",
    id: "lastUpdateDatetime",
    accessor: (row: POR) => {
      const currentTimeZone = dayjs().tz("America/New_York").format("Z");
      return dayjs(`${row[PORModel.LAST_UDPATE]}${currentTimeZone}`).format(PVR_DATE_TIME_FORMAT);
    },
    width: 130,
  },
];
