import { Tag } from "@nef/core";
import { POR, PORModel, PvRejectModel } from "components/pvRejects/constant";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { currentStatusColorMap } from "components/settings/pvr/audit/columns";
import { TagCellWrapper } from "components/standardTable/tagCellWrapper";
dayjs.extend(utc);
dayjs.extend(timezone);

export const PVR_DATE_FORMAT = "YYYY-MM-DD";
export const PVR_TIME_FORMAT = "HH:mm:ss";
export const PVR_DATE_TIME_FORMAT = `${PVR_DATE_FORMAT} ${PVR_TIME_FORMAT}`;

export const pvLiteColumns = [
  { Header: "MPID", id: PORModel.MPID, accessor: PORModel.MPID, width: 75 },
  {
    Header: "Symbol",
    id: PvRejectModel.SYMBOL,
    accessor: PvRejectModel.SYMBOL,
    width: 90,
  },
  {
    Header: "Request Status",
    id: PORModel.REQUEST_STATUS,
    accessor: PORModel.REQUEST_STATUS,
    width: 115,
    Cell: ({ row }: { row: { original: POR } }) => {
      const color = currentStatusColorMap[row.original[PORModel.REQUEST_STATUS]] ?? null;
      if (color === null) {
        return <></>;
      }
      return (
        <TagCellWrapper>
          <Tag color={color}>{row.original[PORModel.REQUEST_STATUS]}</Tag>
        </TagCellWrapper>
      );
    },
  },
  {
    Header: "Requestor",
    id: PORModel.REQUESTOR_EMAIL,
    accessor: PORModel.REQUESTOR_EMAIL,
    width: 150,
  },
  { Header: "Request ID", id: PORModel.ID, accessor: PORModel.ID, width: 80 },
  {
    Header: "Last Update",
    id: PORModel.LAST_UDPATE,
    accessor: (row: POR) => {
      if (row[PORModel.LAST_UDPATE]) {
        return dayjs(row[PORModel.LAST_UDPATE]).format(PVR_DATE_TIME_FORMAT);
      }
      return "N/A";
    },
    width: 160,
  },
];
