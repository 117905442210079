import { PorWindowTimer } from "components/pvRejects/porWindowTimer";
import { StandardHeader, StandardTable } from "components/standardTable";
import { useCallback } from "react";
import styled from "styled-components";
import { StandardTables } from "wksConstants";
import { PorTableButtons } from "./por.table.buttons";

const LeftTableWrapper = styled.div`
  grid-column: span 1;
`;

export const PorTable = ({ toggle, title }: { toggle?: () => void | undefined; title: string }) => {
  const TheHeader = useCallback(() => {
    return <StandardHeader title={title} />;
  }, [title]);

  const TheOtherHeader = useCallback(() => {
    return <PorWindowTimer marginTopPx={7} />;
  }, []);

  return (
    <LeftTableWrapper>
      <StandardTable
        header={TheHeader}
        table={StandardTables.PV_SUPERVISOR_MONITOR2}
        enableLayoutExport={true}
        isSingleSelect={false}
        isColumnsVirtualized={false}
        isFilterable={true}
        subHeader={undefined}
        hideRowCount={false}
        hideSelectedCount={false}
        hideQueryDate={true}
        headerMenu={<PorTableButtons />}
        exportCallback={undefined}
        exportFileName={undefined}
        additionalRowClick={undefined}
        isEditableTableEnabled={undefined}
        style={undefined}
        isRowSelectEnabled={undefined}
        Header={TheOtherHeader}
      />
    </LeftTableWrapper>
  );
};
