import { useCallback, useEffect, useState } from "react";
import { TableButtonAction, TableButtonLabel } from "wksConstantsTS";
import { TableButtons } from "components/standardTable";
import { ViewActions } from "viewConstants";
import { useUserContext } from "components/user";
import { EQRC_RULE_STATUS } from "../constants";
import { Header } from "@nef/core";

export const EQRCTableButtons = ({
  table,
  onClickNew,
  onClickEdit,
  onClickDelete,
  onClickCopy,
  onClickNewAlert,
  onClickAudit,
  onClickRefresh,
  isAuditLoading,
  activeOrConfigured,
  disableEdit = false,
  showActiveConfiguredWarning = false,
}) => {
  const [userData] = useUserContext();

  const handleEdit = useCallback(() => {
    if (typeof onClickEdit === "function") {
      onClickEdit();
    }
  }, [onClickEdit]);

  const handleNew = useCallback(() => {
    if (typeof onClickNew === "function") {
      onClickNew();
    }
  }, [onClickNew]);

  const handleDelete = useCallback(() => {
    if (typeof onClickDelete === "function") {
      onClickDelete();
    }
  }, [onClickDelete]);

  const handleCopy = useCallback(() => {
    if (typeof onClickCopy === "function") {
      onClickCopy();
    }
  }, [onClickCopy]);

  const handleNewAlert = useCallback(() => {
    if (typeof onClickNewAlert === "function") {
      onClickNewAlert();
    }
  }, [onClickNewAlert]);

  const handleAudit = useCallback(() => {
    if (typeof onClickAudit === "function") {
      onClickAudit();
    }
  }, [onClickAudit]);

  const handleRefresh = useCallback(() => {
    if (typeof onClickRefresh === "function") {
      onClickRefresh();
    }
  }, [onClickRefresh]);

  const getTableButtons = useCallback(() => {
    const isActive = activeOrConfigured === EQRC_RULE_STATUS.Active;
    const buttons = [];

    if (userData.allowed.actions[ViewActions.EQRC_RULES_WRITE]) {
      if (isActive) {
        buttons.push({
          icon: "edit",
          text: TableButtonLabel.EDIT,
          actionId: TableButtonAction.EQRC_EDIT,
          confirm: false,
          requireSelect: true,
          allowMultiSelect: true,
          onClick: handleEdit,
          isDisabled: disableEdit,
          disabledMessage: disableEdit
            ? "All selected rows are rules without a Next Day rule, and therefore cannot be edited"
            : "Please select a row",
        });
      } else {
        buttons.push({
          icon: "plus",
          text: TableButtonLabel.EQRC_NEW,
          actionId: TableButtonAction.EQRC_NEW,
          confirm: false,
          requireSelect: false,
          allowMultiSelect: true,
          onClick: handleNew,
        });
        buttons.push({
          icon: "copy",
          text: TableButtonLabel.COPY,
          actionId: TableButtonAction.EQRC_COPY,
          confirm: false,
          requireSelect: true,
          allowMultiSelect: false,
          onClick: handleCopy,
        });
        buttons.push({
          icon: "edit",
          text: TableButtonLabel.EDIT,
          actionId: TableButtonAction.EQRC_EDIT,
          confirm: false,
          requireSelect: true,
          allowMultiSelect: true,
          onClick: handleEdit,
        });
        // Enable after API supports
        // buttons.push({
        //   icon: "ban",
        //   text: TableButtonLabel.DISABLE,
        //   actionId: TableButtonAction.EQRC_DISABLE,
        //   confirm: true,
        //   requireSelect: true,
        //   allowMultiSelect: true,
        //   onClick: () => alert("unsupported action"),
        // });
        buttons.push({
          icon: "minus",
          text: TableButtonLabel.DELETE,
          actionId: TableButtonAction.EQRC_DELETE,
          confirm: true,
          allowConfirm: true,
          canConfirm: true,
          requireSelect: true,
          allowMultiSelect: false,
          onClick: handleDelete,
          header: () => (
            <>
              <Header size={3}>Delete 1 Rule</Header>
              {showActiveConfiguredWarning && (
                <p>
                  This rule has a matching Active rule. If you delete this rule, the Active rule
                  will not be editable. If you wish to make updates to the Active rule, please do so
                  before deleting this rule
                </p>
              )}
            </>
          ),
        });
      }
    }
    if (isActive) {
      buttons.push({
        icon: "history",
        text: TableButtonLabel.AUDIT,
        actionId: TableButtonAction.EQRC_AUDIT,
        confirm: true,
        allowConfirm: true,
        canConfirm: true,
        onClick: handleAudit,
        loading: isAuditLoading,
      });
    }
    if (userData.allowed.actions[ViewActions.EQRC_RULES_WRITE]) {
      buttons.push({
        icon: "bell",
        text: TableButtonLabel.EQRC_ALERT_NEW,
        actionId: TableButtonAction.EQRC_ALERT_NEW,
        confirm: false,
        requireSelect: true,
        allowMultiSelect: false,
        onClick: handleNewAlert,
      });
    }
    buttons.push({
      icon: "redo",
      text: TableButtonLabel.REFRESH,
      actionId: TableButtonAction.EQRC_REFRESH,
      confirm: false,
      allowConfirm: false,
      canConfirm: false,
      requireSelect: false,
      allowMultiSelect: false,
      onClick: handleRefresh,
    });
    return buttons;
  }, [
    activeOrConfigured,
    userData.allowed.actions,
    handleNewAlert,
    handleRefresh,
    handleEdit,
    disableEdit,
    handleNew,
    handleCopy,
    handleDelete,
    showActiveConfiguredWarning,
    handleAudit,
    isAuditLoading,
  ]);

  const [tableButtons, setTableButtons] = useState(getTableButtons());
  useEffect(() => setTableButtons(getTableButtons()), [getTableButtons]);

  return <TableButtons table={table} buttons={tableButtons} />;
};
