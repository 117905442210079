import React, { useCallback, useMemo } from "react";
import { TableButton } from "components/standardTable";
import { getIsButtonDisabled } from "./util";
import { Messages } from "wksConstants";
import { TableButtonAction, TableButtonLabel } from "wksConstantsTS";
import { getNumSelectedRows, useStandardTableContext } from "../standardTableContext";
import { TradesHeader } from "./header.trades";
import { useIsConfirmTableHotButtons } from "./";
import { useFormDispatch } from "components/form";
import { Forms } from "components/fields";

export const AcceptButton = ({ table, form }) => {
  const formDispatch = useFormDispatch();

  const handleCloseMenu = useCallback(() => {
    formDispatch({
      type: "RESET_FORM",
      payload: { form: Forms.ST_ACCEPT },
    });
  }, [formDispatch]);

  const [standardTableData] = useStandardTableContext();
  const acceptButtonConfig = {
    icon: "check",
    text: TableButtonLabel.ACCEPT,
    actionId: TableButtonAction.ACCEPT,
    canConfirm: true,
    allowConfirm: useIsConfirmTableHotButtons(),
    requireSelect: true,
    allowMultiSelect: true,
    allowMenuOverride: false,
    disabledMessage: Messages.MULTI_ROW,
    header: TradesHeader,
    onCloseConfirm: handleCloseMenu,
  };

  const numSelected = useMemo(() => {
    return getNumSelectedRows(standardTableData[table]);
  }, [table, standardTableData]);

  return (
    <TableButton
      table={table}
      {...acceptButtonConfig}
      disabled={getIsButtonDisabled(acceptButtonConfig, numSelected)}
      key="tableButton_accept"
    />
  );
};
