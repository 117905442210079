import { BarChart, ColumnChart, PieChart } from "@nef/charts";
import { Box, CSSGrid, NotificationHub } from "@nef/core";
import { Forms } from "components/fields/fieldConstants";
import dayjs from "dayjs";
import React, { memo, useMemo, useCallback, useEffect, useRef } from "react";
import { useSettingsContext } from "components/settings";
import { useStandardTableContext } from "components/standardTable";
import { StandardTables } from "wksConstants";
import { actionCallbacks, useStandardTableDispatch } from "../standardTable";
import { useViewModelContext } from "../context";
import { useFormDispatch } from "../form";
import { ChartWrapper } from "./";
import { useStatsContext } from "./context";
import { network } from "./network";
import { useUserContext } from "../user";
import { formatNumberToString } from "../../utils/js.utils";
import { USER_MPIDS_ATTRIBUTE } from "components/user/mpidContext";

const Charts = () => {
  const standardTableDispatch = useStandardTableDispatch();
  const [standardTableData] = useStandardTableContext();
  const [statsData, statsDispatch] = useStatsContext();
  const [settings] = useSettingsContext();
  const formDispatch = useFormDispatch();
  const [, dispatch] = useViewModelContext();
  const xhr = useRef(null);
  const [userData] = useUserContext();

  const maxTableSize = useMemo(() => {
    return standardTableData[StandardTables.ST_MIDDLE].maxTableSize;
  }, [standardTableData]);

  const successCallback = useCallback(
    jsonData => {
      const tableData = jsonData.slice(0, maxTableSize);
      const truncated = jsonData.slice(maxTableSize, jsonData.length);
      actionCallbacks.genericScan(
        formDispatch,
        maxTableSize,
        standardTableDispatch,
        tableData,
        truncated,
        StandardTables.ST_MIDDLE,
        false,
        userData.mpidAttributes[USER_MPIDS_ATTRIBUTE.WORKX_REALTIME_STATISTICS_MPIDS]
      );
      const dispatchActions = [
        {
          type: "DISABLE_FORM",
          payload: { form: Forms.ST_MODIFY },
        },
        {
          type: "DISABLE_FORM",
          payload: { form: Forms.ST_COPY },
        },
        {
          type: "DISABLE_FORM",
          payload: { form: Forms.ST_REPAIR },
        },
      ];
      dispatch(dispatchActions);

      const formActions = [
        {
          type: "RESET_FORM",
          payload: { form: Forms.ST_MODIFY, entitlements: userData.entitlements },
        },
        {
          type: "RESET_FORM",
          payload: { form: Forms.ST_COPY, entitlements: userData.entitlements },
        },
        {
          type: "RESET_FORM",
          payload: { form: Forms.ST_REPAIR, entitlements: userData.entitlements },
        },
      ];
      formDispatch(formActions);
    },
    [
      maxTableSize,
      formDispatch,
      standardTableDispatch,
      userData.mpidAttributes,
      userData.entitlements,
      dispatch,
    ]
  );

  const errorCallback = useCallback(() => {
    NotificationHub.send("danger", "Your scanned trades did not load", {
      subtitle: "Please try again later",
    });

    standardTableDispatch({
      type: "SET_NOT_LOADING",
      payload: { table: Forms.ST_MIDDLE.key },
    });
  }, []);

  const didSomeOneAsk = standardTableData[Forms.ST_MIDDLE.key].someOneAskedThatWeRefresh;
  const table = StandardTables.ST_MIDDLE;
  useEffect(() => {
    if (
      xhr.current ||
      !statsData.requestPayload.chart ||
      !statsData.requestPayload.trf.length ||
      !statsData.requestPayload.mpids.length
    ) {
      standardTableDispatch({ type: "SET_DATA_EMPTY", payload: { table } });
      return;
    }

    if (table === StandardTables.ST_MIDDLE) {
      const dispatchs = [
        {
          type: "SET_LOADING",
          payload: { table: table, isLoading: true },
        },
        {
          type: "SET_SOMEONE_ASKED_THAT_WE_REFRESH",
          payload: { ask: false, table: table },
        },
      ];

      standardTableDispatch(dispatchs);
      xhr.current = true;
      network()
        .rowsByQuery({
          chart: statsData.requestPayload.chart,
          name: statsData.requestPayload.name,
          successCallback,
          errorCallback,
          context: {
            mpidvals: statsData.requestPayload.mpids,
            trfVals: statsData.requestPayload.trf,
            incContraGUP: settings.incContraGUP,
            incContraMPID: settings.incContraMPID,
            incExecGUP: settings.incExecGUP,
            incExecMPID: settings.incExecMPID,
            fromUpdateDate: `${dayjs().format("YYYY-MM-DD")}T00:00:00.000-05:00`,
            toUpdateDate: `${dayjs().format("YYYY-MM-DD")}T23:59:59.998-05:00`,
          },
        })
        .then(() => {
          xhr.current = null;
        });

      statsDispatch({
        type: "CHANGE_TITLE",
        payload: {
          name: statsData.requestPayload.name,
          chart: statsData.requestPayload.chart,
        },
      });
    }
  }, [
    didSomeOneAsk,
    errorCallback,
    successCallback,
    table,
    standardTableDispatch,
    statsData.requestPayload,
    settings,
    statsDispatch,
  ]); //deps intentional - don't update when data changes - wait for a user event maybe

  const handleClick = useCallback(
    chart => (e, data) => {
      statsDispatch({ type: "SET_CLICKED_CHART_SLICE", payload: { name: data.name, chart } });
    },
    [statsDispatch]
  );

  return (
    <Box>
      <CSSGrid cols={"20% 20% 30% calc(30% - 2.55rem)"} rows={1} gap=".85rem">
        <LateTrades handleClick={handleClick("Late Trades")} />
        <Dissemination handleClick={handleClick("Dissemination")} />
        <TradeAccuracy handleClick={handleClick("Trade Accuracy")} />
        <Statuses handleClick={handleClick("Statuses")} />
      </CSSGrid>
    </Box>
  );
};
export default Charts;

const getDisplayData = (data, percentFlag = true) => {
  let total = data.reduce((acc, curr) => acc + curr.value, 0);
  const displayData = data.map(d => {
    const percent = percentFlag ? ` (${((total ? d.value / total : 0) * 100).toFixed(2)}%)` : "";

    return {
      name: `${d.label}${percent}`,
      value: d.value,
      displayValue: formatNumberToString(d.value),
    };
  });
  return [displayData, total];
};

const shouldShowChart = data => {
  const total = data.reduce((acc, cur) => cur.value + acc, 0) > 0;
  return !!data.length && total;
};

export const LateTrades = memo(({ handleClick, legend, isRight }) => {
  const [statsData] = useStatsContext();
  const [data, total] = getDisplayData(statsData.lateTrades);

  return (
    <ChartWrapper
      header="Late Trades 20 Min Rule"
      subtitle={`Total Trades: ${total || ""}`}
      isRight={isRight}
    >
      {shouldShowChart(data) && (
        <PieChart
          ratio={null}
          data={data}
          onClick={handleClick}
          legend={legend === false ? legend : statsData.legends}
          logScale={true}
        />
      )}
    </ChartWrapper>
  );
});

export const Dissemination = memo(({ handleClick, legend, isRight }) => {
  const [statsData] = useStatsContext();
  const [data, total] = getDisplayData(statsData.dissemination);

  const derivedLegend = legend === false ? legend : statsData.legends;
  const subtitle = `Total Trades: ${total || ""}`;
  const shouldShow = shouldShowChart(data);
  return (
    <ChartWrapper
      header="Dissemination"
      subtitle={subtitle}
      shouldShow={shouldShow}
      isRight={isRight}
    >
      {shouldShowChart(data) && (
        <PieChart
          labels={false}
          ratio={null}
          data={data}
          donut={false}
          onClick={handleClick}
          legend={derivedLegend}
          logScale={true}
        />
      )}
    </ChartWrapper>
  );
});

const ticks = data => {
  let largestValue = 0;
  data.forEach(i => {
    if (i.value > largestValue) {
      largestValue = i.value;
    }
  });

  largestValue = Math.ceil(largestValue);

  const begin = 0;
  const end = Math.log10(largestValue);
  const logMiddle = (begin + end) / 2;
  const middle = Math.floor(10 ** logMiddle);

  const ticks = [1, middle];
  if (largestValue !== middle) {
    ticks.push(largestValue);
  }

  return ticks;
};
export const TradeAccuracy = memo(({ handleClick, legend, isRight }) => {
  const [statsData] = useStatsContext();
  const [data] = getDisplayData(statsData.tradeAccuracy, false);

  return (
    <ChartWrapper header="Trade Accuracy" isRight={isRight}>
      {shouldShowChart(data) && (
        <BarChart
          ratio={null}
          xGridLines={false}
          yGridLines={false}
          data={data}
          onClick={handleClick}
          legend={legend === false ? legend : statsData.legends}
          xAxis={legend === false ? legend : statsData.legends}
          logScale={true}
          yAxisFormatting={yAxis =>
            yAxis
              .ticks(6)
              .tickValues(ticks(data))
              .tickFormat(d => Number.prototype.toLocaleString.call(d))
          }
        />
      )}
    </ChartWrapper>
  );
});

export const Statuses = memo(({ handleClick, legend, isRight }) => {
  const [statsData] = useStatsContext();
  const [data] = getDisplayData(statsData.statuses, false);

  return (
    <ChartWrapper header="Statuses" isRight={isRight}>
      {shouldShowChart(data) && (
        <ColumnChart
          ratio={null}
          data={data}
          legend={false}
          onClick={handleClick}
          logScale={true}
          xAxisFormatting={xAxis =>
            xAxis
              .ticks(4)
              .tickValues(ticks(data))
              .tickFormat(d => Number.prototype.toLocaleString.call(d))
          }
        />
      )}
    </ChartWrapper>
  );
});
