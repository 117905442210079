import { NotificationHub } from "@nef/core";
import { ApiResponseNames, FieldNames } from "components/fields";
import { Forms } from "components/fields/fieldConstants";
import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router";
import { TableButton } from "components/standardTable";
import { useUserContext } from "components/user";
import { hasValidMPIDFromReportingParty, getFieldValue, isReversal } from "utils/js.utils";
import { ViewForm, ViewForms } from "viewConstants";
import { Messages } from "wksConstants";
import { TableButtonAction, TableButtonLabel } from "wksConstantsTS";
import { getIsButtonDisabled } from "./util";
import { useViewModelContext, useViewModelDispatch } from "../../context";
import { useFormDispatch } from "../../form";
import { getClearingMpidFromClearingNum, NON_CLEARING_NUM } from "../../refData";
import { useRefDataContext } from "../../refData/context";
import { KeycloakRoles } from "../../user";
import {
  convertTableToForm,
  getNumSelectedRows,
  getSelectedRow,
  useStandardTableContext,
  useStandardTableDispatch,
} from "../standardTableContext";
import { TradesHeader } from "./header.trades";
import { USER_MPIDS_ATTRIBUTE } from "components/user/mpidContext";

const repairButtonConfig = {
  requireSelect: true,
  allowMultiSelect: false,
  icon: "tools",
  text: TableButtonLabel.REPAIR,
  actionId: TableButtonAction.REPAIR,
  disabledMessage: Messages.ONE_ROW,
  header: TradesHeader,
};

export const RepairButton = ({ table, form }) => {
  const [user] = useUserContext();
  const [refData] = useRefDataContext();
  const standardTableDispatch = useStandardTableDispatch();
  const [viewData] = useViewModelContext();
  const formDispatch = useFormDispatch();
  const viewDispatch = useViewModelDispatch();
  const history = useHistory();
  const [standardTableData] = useStandardTableContext();

  const [row, numSelected] = useMemo(() => {
    return [getSelectedRow(standardTableData[table]), getNumSelectedRows(standardTableData[table])];
  }, [table, standardTableData]);

  const handleRepair = useCallback(async () => {
    const validMPID = hasValidMPIDFromReportingParty(
      getFieldValue(row?.reportingParty),
      row,
      user.mpidAttributes[USER_MPIDS_ATTRIBUTE.UI_TRADE_REPORTING_ACTION_MPIDS]
    );

    let validClearer = false;
    if (user?.entitlements[KeycloakRoles.CLEARER] && refData?.firm) {
      const reporter = row?.reportingParty?.value;
      let clearingNum = NON_CLEARING_NUM;
      if (reporter === "M") {
        clearingNum = row[ApiResponseNames.executingClearingNum];
      } else {
        clearingNum = row[ApiResponseNames.contraClearingNum];
      }
      const clearingMpid = getClearingMpidFromClearingNum(refData.firm, clearingNum);
      validClearer =
        user.mpidAttributes[USER_MPIDS_ATTRIBUTE.UI_TRADE_REPORTING_ACTION_MPIDS].includes(
          clearingMpid
        );
    }
    if (row.tradeStatus.value !== "-" /* Rejected */ || (!validMPID && !validClearer)) {
      NotificationHub.send("warning", "This trade was ignored due to invalid state", {
        subtitle: `Control#: ${row.controlNum ? row.controlNum : "N/A"}`,
      });

      standardTableDispatch({
        type: "DESELECT_ALL_ROWS",
        payload: { table },
      });
      return;
    }

    const converted = convertTableToForm(row, user);
    Object.assign(converted, { objVer: row.objver, workstationId: row.workstationID, id: row.id });

    if (isReversal(row[ApiResponseNames.reversalFlag])) {
      converted[FieldNames.isReversal] = true;
      converted["isReversalTrade"] = true;
    }

    formDispatch([
      {
        type: "RESET_FORM",
        payload: { form: Forms.ST_COPY, entitlements: user.entitlements },
      },
      {
        type: "RESET_FORM",
        payload: { form: Forms.ST_MODIFY, entitlements: user.entitlements },
      },
      {
        type: "SET_FORM_VALUES_AND_PREMODIFIED",
        payload: {
          form: ViewForms[viewData.view][ViewForm.REPAIR],
          fields: converted,
        },
      },
      {
        type: "UPDATE_CLEARING_PRICE",
        payload: {
          form: ViewForms[viewData.view][ViewForm.REPAIR],
        },
      },
    ]);

    viewDispatch([
      {
        type: "DISABLE_FORM",
        payload: { form: Forms.ST_MODIFY },
      },
      {
        type: "CHANGE_FORM",
        payload: { form: ViewForm.REPAIR, history },
      },
      {
        type: "ENABLE_FORM",
        payload: { form },
      },
    ]);

    standardTableDispatch({
      type: "SET_CLICKED_ROW",
      payload: { row, table: table },
    });
  }, [
    row,
    user.mpidAttributes,
    user.entitlements,
    refData.firm,
    formDispatch,
    viewData.view,
    viewDispatch,
    history,
    form,
    standardTableDispatch,
    table,
  ]);

  return (
    <TableButton
      table={table}
      {...repairButtonConfig}
      disabled={getIsButtonDisabled(repairButtonConfig, numSelected)}
      onClick={handleRepair}
      key="tableButton_repair"
    />
  );
};
