import React, { useMemo } from "react";
import { TableButton } from "components/standardTable";
import { Messages } from "wksConstants";
import { TableButtonAction, TableButtonLabel } from "wksConstantsTS";
import { getIsButtonDisabled } from "./util";
import { getNumSelectedRows, useStandardTableContext } from "../standardTableContext";
import { TradesHeader } from "./header.trades";
import { useIsConfirmTableHotButtons } from "./";

export const BreakButton = ({ table }) => {
  const [standardTableData] = useStandardTableContext();
  const breakButtonConfig = {
    icon: "heart-broken",
    text: TableButtonLabel.BREAK,
    actionId: TableButtonAction.BREAK,
    requireSelect: true,
    allowMultiSelect: true,
    disabledMessage: Messages.MULTI_ROW,
    canConfirm: true,
    allowConfirm: useIsConfirmTableHotButtons(),
    header: TradesHeader,
  };
  const numSelected = useMemo(() => {
    return getNumSelectedRows(standardTableData[table]);
  }, [table, standardTableData]);

  return (
    <TableButton
      table={table}
      {...breakButtonConfig}
      disabled={getIsButtonDisabled(breakButtonConfig, numSelected)}
      key="tableButton_break"
    />
  );
};
