import { FlipCard } from "components/flipCard";
import {
  REJECT_CACHE_FILTER,
  REJECT_CACHE_FILTER_BY,
  RejectCacheMpidCritia,
  RejectCacheSymbolCriteria,
  useRejectCacheDispatch,
} from "components/pvRejects/cache/rejectCache";
import { useRejectCountState } from "components/pvRejects/cache/rejectCountCache";
import {
  ArrayButton,
  ArraySection,
  DataArrayCount,
  DATE_FILTER,
  PV_REJECT_LABELS,
  PV_REJECT_MESSAGE,
  REJ_PRICE_OO_OVERRIDE_RANGE,
  REJ_PRICE_OO_RANGE,
  sortRejectArrayData,
} from "components/pvRejects/constant";
import { useStandardTableDispatch } from "components/standardTable";
import { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { StandardTables } from "wksConstants";
import { PorOrPvr, usePORCacheContext } from "../context";
import { Back } from "./pvr.back";
import { Front } from "./pvr.front";
import { PieChartData } from "components/pvRejects/client/constant";
import { Loader } from "@nef/core";

const LoaderWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoRejectsFiller = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoRejectsFillerJsx = <NoRejectsFiller>No Rejects Found</NoRejectsFiller>;

type RejectCountMap = { [key: string]: { [rejMsg: string]: number } };

export const PVRs = ({ setTitle }: { setTitle: (title: string) => void }) => {
  const [isFlipped, setIsFlipped] = useState(true);
  const toggle = useCallback(() => {
    setIsFlipped(!isFlipped);
  }, [isFlipped]);
  const { state } = usePORCacheContext();
  const rejectCountState = useRejectCountState();
  const rejectCacheDispatch = useRejectCacheDispatch();
  const tableDispatch = useStandardTableDispatch();

  const handleSetFilter = useCallback(
    ({
        type,
        key,
        reject,
      }: {
        type: REJECT_CACHE_FILTER_BY.MPID | REJECT_CACHE_FILTER_BY.SYMBOL;
        key: string;
        reject: string;
      }) =>
      () => {
        const criteria: RejectCacheMpidCritia | RejectCacheSymbolCriteria = {
          rejectText: reject,
          date: DATE_FILTER.ALL,
        } as RejectCacheMpidCritia | RejectCacheSymbolCriteria;
        switch (type) {
          case REJECT_CACHE_FILTER_BY.MPID:
            (criteria as RejectCacheMpidCritia)["mpids"] = [key];
            break;
          case REJECT_CACHE_FILTER_BY.SYMBOL:
            (criteria as RejectCacheSymbolCriteria)["symbols"] = [key];
            break;
        }
        tableDispatch({
          type: "DESELECT_ALL_ROWS",
          payload: { table: StandardTables.PV_SUPERVISOR_MONITOR2 },
        });
        rejectCacheDispatch({
          type: "START_POLLING",
          payload: {
            filter: {
              filterBy: type,
              criteria,
            } as REJECT_CACHE_FILTER,
          },
        });
      },
    [rejectCacheDispatch, tableDispatch]
  );

  const { mpidArrayCounts, symbolArrayCounts } = useMemo(() => {
    const { mpidArrayCounts } = Object.entries(rejectCountState.finraCounts.mpidCounts).reduce(
      (acc, [mpid, count]) => {
        const subRows = [];
        if (count.priceCount > 0) {
          subRows.push({
            label: PV_REJECT_LABELS[REJ_PRICE_OO_RANGE],
            value: (
              <ArrayButton
                onClick={handleSetFilter({
                  type: REJECT_CACHE_FILTER_BY.MPID,
                  key: mpid,
                  reject: REJ_PRICE_OO_RANGE,
                })}
              >
                {count.priceCount}
              </ArrayButton>
            ),
            count: count.priceCount,
          });
        }
        if (count.priceOverrideCount > 0) {
          subRows.push({
            label: PV_REJECT_LABELS[REJ_PRICE_OO_OVERRIDE_RANGE],
            value: (
              <ArrayButton
                onClick={handleSetFilter({
                  type: REJECT_CACHE_FILTER_BY.MPID,
                  key: mpid,
                  reject: REJ_PRICE_OO_OVERRIDE_RANGE,
                })}
              >
                {count.priceOverrideCount}
              </ArrayButton>
            ),
            count: count.priceOverrideCount,
          });
        }
        acc.mpidArrayCounts.push({
          label: mpid,
          value: count.priceCount + count.priceOverrideCount,
          subRows,
        });
        acc.pieChartData[0].value += count.priceCount;
        acc.pieChartData[1].value += count.priceOverrideCount;
        acc.totalRejectCount += count.priceCount + count.priceOverrideCount;
        return acc;
      },
      {
        mpidArrayCounts: [],
        pieChartData: [
          { name: REJ_PRICE_OO_RANGE, value: 0 },
          { name: REJ_PRICE_OO_OVERRIDE_RANGE, value: 0 },
        ],
        totalRejectCount: 0,
      } as {
        mpidArrayCounts: DataArrayCount[];
        pieChartData: PieChartData;
        totalRejectCount: number;
      }
    );

    const { symbolArrayCounts } = Object.entries(rejectCountState.finraCounts.symbolCounts).reduce(
      (acc, [symbol, count]) => {
        const subRows = [];
        if (count.priceCount > 0) {
          subRows.push({
            label: PV_REJECT_LABELS[REJ_PRICE_OO_RANGE],
            value: (
              <ArrayButton
                onClick={handleSetFilter({
                  type: REJECT_CACHE_FILTER_BY.SYMBOL,
                  key: symbol,
                  reject: REJ_PRICE_OO_RANGE,
                })}
              >
                {count.priceCount}
              </ArrayButton>
            ),
            count: count.priceCount,
          });
        }
        if (count.priceOverrideCount > 0) {
          subRows.push({
            label: PV_REJECT_LABELS[REJ_PRICE_OO_OVERRIDE_RANGE],
            value: (
              <ArrayButton
                onClick={handleSetFilter({
                  type: REJECT_CACHE_FILTER_BY.SYMBOL,
                  key: symbol,
                  reject: REJ_PRICE_OO_OVERRIDE_RANGE,
                })}
              >
                {count.priceOverrideCount}
              </ArrayButton>
            ),
            count: count.priceOverrideCount,
          });
        }
        acc.symbolArrayCounts.push({
          label: symbol,
          value: count.priceCount + count.priceOverrideCount,
          subRows,
        });
        acc.pieChartData[0].value += count.priceCount;
        acc.pieChartData[1].value += count.priceOverrideCount;
        acc.totalRejectCount += count.priceCount + count.priceOverrideCount;
        return acc;
      },
      {
        symbolArrayCounts: [],
        pieChartData: [
          { name: REJ_PRICE_OO_RANGE, value: 0 },
          { name: REJ_PRICE_OO_OVERRIDE_RANGE, value: 0 },
        ],
        totalRejectCount: 0,
      } as {
        symbolArrayCounts: DataArrayCount[];
        pieChartData: PieChartData;
        totalRejectCount: number;
      }
    );
    return { mpidArrayCounts, symbolArrayCounts };
  }, [handleSetFilter, rejectCountState.finraCounts]);

  const { mpidChartData, symbolChartData, totalRejectCount } = useMemo(() => {
    const { mpidData, totalRejectCount } = Object.entries(
      rejectCountState.finraCounts.mpidCounts
    ).reduce(
      (acc, [mpid, count]) => {
        acc.mpidData[mpid] = {
          [REJ_PRICE_OO_RANGE]: count.priceCount,
          [REJ_PRICE_OO_OVERRIDE_RANGE]: count.priceOverrideCount,
        };
        acc.totalRejectCount += count.priceCount + count.priceOverrideCount;
        return acc;
      },
      { mpidData: {}, totalRejectCount: 0 } as {
        mpidData: RejectCountMap;
        totalRejectCount: number;
      }
    );
    const mpidArrData = Object.entries(mpidData).map(([key, val]) => {
      return { mpid: key, rejects: val };
    });
    mpidArrData.sort(sortRejectArrayData);
    const mpidChartData = [];
    for (let i = 0; i < 5 && i < mpidArrData.length; i++) {
      mpidChartData.push({
        name: mpidArrData[i].mpid,
        values: Object.entries(mpidArrData[i].rejects).map(([rejMsg, count]) => ({
          name: PV_REJECT_LABELS[rejMsg as PV_REJECT_MESSAGE],
          value: count,
        })),
      });
    }
    const countOfAllOtherMpids = mpidArrData.slice(5, mpidArrData.length).reduce((acc, curr) => {
      Object.entries(curr.rejects).forEach(([rejMsg, count]) => {
        if (!acc[rejMsg]) {
          acc[rejMsg] = 0;
        }
        acc[rejMsg] += count;
      });
      return acc;
    }, {} as { [rejMsg: string]: number });
    const mpidVals = Object.values(countOfAllOtherMpids);
    if (mpidVals.length > 0 && mpidVals.reduce((acc, curr) => acc + curr) > 0) {
      mpidChartData.push({
        name: "Other",
        values: Object.entries(countOfAllOtherMpids).map(([rejMsg, count]) => ({
          name: PV_REJECT_LABELS[rejMsg as PV_REJECT_MESSAGE],
          value: count,
        })),
      });
    }

    const symbolData = Object.entries(rejectCountState.finraCounts.symbolCounts).reduce(
      (acc, [symbol, count]) => {
        acc[symbol] = {
          [REJ_PRICE_OO_RANGE]: count.priceCount,
          [REJ_PRICE_OO_OVERRIDE_RANGE]: count.priceOverrideCount,
        };
        return acc;
      },
      {} as RejectCountMap
    );
    const symbolArrData = Object.entries(symbolData).map(([key, val]) => {
      return { symbol: key, rejects: val };
    });
    symbolArrData.sort(sortRejectArrayData);
    const symbolChartData = [];
    for (let i = 0; i < 5 && i < symbolArrData.length; i++) {
      symbolChartData.push({
        name: symbolArrData[i].symbol,
        values: Object.entries(symbolArrData[i].rejects).map(([rejMsg, count]) => ({
          name: PV_REJECT_LABELS[rejMsg as PV_REJECT_MESSAGE],
          value: count,
        })),
      });
    }
    const countOfAllOtherSymbols = symbolArrData
      .slice(5, symbolArrData.length)
      .reduce((acc, curr) => {
        Object.entries(curr.rejects).forEach(([rejMsg, count]) => {
          if (!acc[rejMsg]) {
            acc[rejMsg] = 0;
          }
          acc[rejMsg] += count;
        });
        return acc;
      }, {} as { [rejMsg: string]: number });
    const symbolVals = Object.values(countOfAllOtherSymbols);
    if (symbolVals.length > 0 && symbolVals.reduce((acc, curr) => acc + curr) > 0) {
      symbolChartData.push({
        name: "Other",
        values: Object.entries(countOfAllOtherSymbols).map(([rejMsg, count]) => ({
          name: PV_REJECT_LABELS[rejMsg as PV_REJECT_MESSAGE],
          value: count,
        })),
      });
    }
    return { mpidChartData, symbolChartData, totalRejectCount };
  }, [rejectCountState.finraCounts]);

  return (
    <ArraySection>
      <LoaderWrapper>
        <Loader isLoading={rejectCountState.isLoading}>
          {totalRejectCount === 0 ? NoRejectsFillerJsx : <></>}
          <FlipCard
            front={
              <Front
                toggle={toggle}
                isMpidOrSymbol={state[PorOrPvr.PVR].mpidOrSymbol}
                mpidArrayCounts={mpidArrayCounts}
                symbolArrayCounts={symbolArrayCounts}
                totalRejectCount={totalRejectCount}
              />
            }
            back={
              <Back
                toggle={toggle}
                isAllOrPending={state[PorOrPvr.PVR].allOrPending}
                isMpidOrSymbol={state[PorOrPvr.PVR].mpidOrSymbol}
                mpidChartData={mpidChartData}
                symbolChartData={symbolChartData}
                totalRejectCount={totalRejectCount}
              />
            }
            isFlipped={isFlipped}
          />
        </Loader>
      </LoaderWrapper>
    </ArraySection>
  );
};
