import { Button, Modal, ModalBody, ModalFooter, ModalHeader, NotificationHub } from "@nef/core";
import { AlertEditValues, LIMIT_SIDE_BREACH_VALUES } from "components/fields/fieldConstants";
import { useFormContext, useFormDispatch } from "components/form";
import { useRefDataContext } from "components/refData";
import {
  formValidationCheck,
  useStandardTableContext,
  useStandardTableDispatch,
} from "components/standardTable";
import { useUserContext } from "components/user";
import { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { formatUrl } from "utils/js.utils";
import { PTRA_EMAIL_ENTITY } from ".";
import { getHeaders } from "../../../keycloak";
import { doFetchWrapper } from "../../../network";
import { PTRA_SET_REQUESTING_TYPE, usePtraConfigDispatch } from "./configContext";
import { PTRA_CONFIGURATION_ENTITY, PTRA_VALIDATION_RESULT } from "./fields";

const StyledModalBody = styled(ModalBody)`
  height: 90vh;
  overflow: overlay;
`;

const MISSING_FIELDS_MESSAGE = "Missing required fields to create alert configuration(s).";
export const PtraCreateModal = ({
  form,
  table,
  RiskAlertForm,
  ValidationTable,
  isVisible,
  onClose,
  isAgu,
}) => {
  const tableDispatch = useStandardTableDispatch();
  const [tableData] = useStandardTableContext();
  const [formData] = useFormContext();
  const [stepNumber, setStepNumber] = useState(0);
  const formDispatch = useFormDispatch();
  const [userData] = useUserContext();
  const [refData] = useRefDataContext();
  const [hasError, setHasError] = useState(true);
  const configDispatch = usePtraConfigDispatch();

  const handleCloseNew = useCallback(() => {
    setStepNumber(0);
    if (typeof onClose === "function") {
      onClose();
    }
  }, [onClose]);

  const createManyCallback = useCallback(
    data => {
      NotificationHub.send("success", `Created ${data?.length || 0} alert configuration(s)`);
      configDispatch({
        type: PTRA_SET_REQUESTING_TYPE[isAgu],
        payload: true,
      });
    },
    [configDispatch, isAgu]
  );

  const createManyError = useCallback(() => {
    NotificationHub.send("danger", `Failed to create alert configuration(s)`);
  }, []);

  const createConfigs = useMemo(() => {
    const alertData = formData[form.key].fields;
    if (Array.isArray(alertData[PTRA_CONFIGURATION_ENTITY.correspondentMPID])) {
      const recipients =
        alertData[PTRA_CONFIGURATION_ENTITY.emailId]?.map(emailOpt => emailOpt?.value) || [];
      const addWorkXUser =
        alertData[PTRA_CONFIGURATION_ENTITY.includeWorkXUserRecipient] ||
        alertData[PTRA_CONFIGURATION_ENTITY.isAlertLogActive] ||
        alertData[PTRA_CONFIGURATION_ENTITY.isAlertPopupActive] ||
        alertData[PTRA_CONFIGURATION_ENTITY.isAlertSoundActive];
      if (addWorkXUser) {
        recipients.unshift(-1);
      }
      const alertConfigs = [];
      alertData[PTRA_CONFIGURATION_ENTITY.correspondentMPID].forEach(mpid => {
        const mpidJson = JSON.parse(mpid.value);
        const clearingFirmNum = mpidJson.clearingNum;
        const clearingFirmMPID = refData.clearingNumToMpidMap[mpidJson.clearingNum];
        const correspondentMPID = mpidJson.correspondentMPID;
        // create config for each mpid + email combination
        const limitBreachSide =
          alertData[PTRA_CONFIGURATION_ENTITY.limitBreachSide]?.value === AlertEditValues.NO
            ? LIMIT_SIDE_BREACH_VALUES.Own
            : null;
        recipients.forEach((emailId, index) => {
          alertConfigs.push({
            [PTRA_CONFIGURATION_ENTITY.isAgu]: isAgu,
            [PTRA_CONFIGURATION_ENTITY.clearingNum]: clearingFirmNum,
            [PTRA_CONFIGURATION_ENTITY.clearingMPID]: clearingFirmMPID,
            [PTRA_CONFIGURATION_ENTITY.correspondentMPID]: correspondentMPID,
            [PTRA_CONFIGURATION_ENTITY.emailId]: emailId,
            [PTRA_CONFIGURATION_ENTITY.limitBreachSide]: limitBreachSide,
            [PTRA_CONFIGURATION_ENTITY.isAlertLogActive]:
              index === 0 && addWorkXUser && alertData[PTRA_CONFIGURATION_ENTITY.isAlertLogActive],
            [PTRA_CONFIGURATION_ENTITY.isAlertPopupActive]:
              index === 0 &&
              addWorkXUser &&
              alertData[PTRA_CONFIGURATION_ENTITY.isAlertPopupActive],
            [PTRA_CONFIGURATION_ENTITY.isAlertSoundActive]:
              index === 0 &&
              addWorkXUser &&
              alertData[PTRA_CONFIGURATION_ENTITY.isAlertSoundActive],
            [PTRA_CONFIGURATION_ENTITY.isEmailRealTimeActive]:
              // (
              (index === 0 &&
                addWorkXUser &&
                alertData[PTRA_CONFIGURATION_ENTITY.includeWorkXUserRecipient]) ||
              !addWorkXUser ||
              index > 0,
            // ) && alertData[PTRA_CONFIGURATION_ENTITY.isEmailRealTimeActive],
            [PTRA_CONFIGURATION_ENTITY.isEmailEndOfDayActive]:
              ((index === 0 &&
                addWorkXUser &&
                alertData[PTRA_CONFIGURATION_ENTITY.includeWorkXUserRecipient]) ||
                !addWorkXUser ||
                index > 0) &&
              alertData[PTRA_CONFIGURATION_ENTITY.isEmailEndOfDayActive],
            [PTRA_CONFIGURATION_ENTITY.includeGiveups]:
              alertData[PTRA_CONFIGURATION_ENTITY.includeGiveups],
            [PTRA_CONFIGURATION_ENTITY.isNextDayChangeActive]:
              alertData[PTRA_CONFIGURATION_ENTITY.isNextDayChangeActive],
            [PTRA_CONFIGURATION_ENTITY.isIntraDayChangeActive]:
              alertData[PTRA_CONFIGURATION_ENTITY.isIntraDayChangeActive],
            [PTRA_CONFIGURATION_ENTITY.isAggregateAlertActive]:
              alertData[PTRA_CONFIGURATION_ENTITY.isAggregateAlertActive],
            [PTRA_CONFIGURATION_ENTITY.isAggregateHoldActive]:
              alertData[PTRA_CONFIGURATION_ENTITY.isAggregateHoldActive],
            [PTRA_CONFIGURATION_ENTITY.isAggregateKillActive]:
              alertData[PTRA_CONFIGURATION_ENTITY.isAggregateKillActive],
            [PTRA_CONFIGURATION_ENTITY.isPerTradeAlertActive]:
              alertData[PTRA_CONFIGURATION_ENTITY.isPerTradeAlertActive],
            [PTRA_CONFIGURATION_ENTITY.isPerTradeHoldActive]:
              alertData[PTRA_CONFIGURATION_ENTITY.isPerTradeHoldActive],
            [PTRA_CONFIGURATION_ENTITY.isMaxTradeAlertActive]:
              alertData[PTRA_CONFIGURATION_ENTITY.isMaxTradeAlertActive],
            [PTRA_CONFIGURATION_ENTITY.isHeldTradeActive]:
              alertData[PTRA_CONFIGURATION_ENTITY.isHeldTradeActive],
            [PTRA_CONFIGURATION_ENTITY.isKillLimitRejectActive]:
              alertData[PTRA_CONFIGURATION_ENTITY.isKillLimitRejectActive],
            [PTRA_CONFIGURATION_ENTITY.isAggSettingActive]:
              alertData[PTRA_CONFIGURATION_ENTITY.isAggSettingActive],
            [PTRA_CONFIGURATION_ENTITY.isPerTradeSettingActive]:
              alertData[PTRA_CONFIGURATION_ENTITY.isPerTradeSettingActive],
            [PTRA_CONFIGURATION_ENTITY.isMaxTradeSettingActive]:
              alertData[PTRA_CONFIGURATION_ENTITY.isMaxTradeSettingActive],
            [PTRA_CONFIGURATION_ENTITY.isDefaultActionSettingActive]:
              alertData[PTRA_CONFIGURATION_ENTITY.isDefaultActionSettingActive],
          });
        });
      });
      return alertConfigs;
    } else {
      return [];
    }
  }, [form.key, formData, isAgu, refData.clearingNumToMpidMap]);

  const handleSaveNew = useCallback(() => {
    if (createConfigs.length > 0) {
      doFetchWrapper(
        formatUrl(process.env.REACT_APP_URL_PTRA_SUB_API, "alert/configuration/createMany"),
        {
          method: "post",
          headers: getHeaders(),
          body: JSON.stringify(createConfigs),
        },
        createManyCallback,
        createManyError
      );
      handleCloseNew();
    } else {
      NotificationHub.send("warning", MISSING_FIELDS_MESSAGE);
    }
  }, [createConfigs, createManyCallback, createManyError, handleCloseNew]);

  const handleBack = useCallback(() => {
    setHasError(true);
    setStepNumber(0);
  }, []);

  const handleValidateCreate = useCallback(() => {
    if (formValidationCheck({ formData, formDispatch, form, userData })) {
      const success = data => {
        const {
          [PTRA_VALIDATION_RESULT.entities]: entities,
          [PTRA_VALIDATION_RESULT.hasError]: hasError,
        } = data;
        setHasError(hasError);
        entities.forEach(config => {
          const identifier = `${config[PTRA_CONFIGURATION_ENTITY.email]?.[PTRA_EMAIL_ENTITY.id]}|${
            config[PTRA_CONFIGURATION_ENTITY.clearingNum]
          }|${config[PTRA_CONFIGURATION_ENTITY.correspondentMPID]}`;
          // errors
          if (data[PTRA_VALIDATION_RESULT.errors]?.[identifier]) {
            config[PTRA_VALIDATION_RESULT.errors] = data[PTRA_VALIDATION_RESULT.errors][identifier];
          } else {
            delete config[PTRA_VALIDATION_RESULT.errors];
          }
          // warnings
          if (data[PTRA_VALIDATION_RESULT.warnings]?.[identifier]) {
            config[PTRA_VALIDATION_RESULT.warnings] =
              data[PTRA_VALIDATION_RESULT.warnings][identifier];
          } else {
            delete config[PTRA_VALIDATION_RESULT.warnings];
          }
        });
        tableDispatch([
          {
            type: "SET_TABLE_DATA",
            payload: {
              table,
              data: entities,
            },
          },
          { type: "SET_NOT_LOADING", payload: { table } },
        ]);
      };
      const error = errors => {
        NotificationHub.send("danger", "An error occurred while validating.");
      };

      if (createConfigs.length > 0) {
        doFetchWrapper(
          formatUrl(process.env.REACT_APP_URL_PTRA_SUB_API, "alert/configuration/validateCreate"),
          {
            method: "post",
            headers: getHeaders(),
            body: JSON.stringify(createConfigs),
          },
          success,
          error
        );
        tableDispatch({
          type: "SET_LOADING",
          payload: { table },
        });
        setStepNumber(1);
      } else {
        NotificationHub.send("warning", MISSING_FIELDS_MESSAGE);
      }
    }
  }, [createConfigs, form, formData, formDispatch, table, tableDispatch, userData]);

  return (
    <Modal
      isOpen={isVisible}
      closeOnOutsideClick={true}
      toggle={handleCloseNew}
      size={stepNumber === 0 ? "md" : "flex"}
    >
      <ModalHeader toggle={handleCloseNew} title="Create Alert for Post - Trade Risk" />
      <StyledModalBody>
        {stepNumber === 0 ? <RiskAlertForm /> : <ValidationTable table={table} />}
      </StyledModalBody>
      <ModalFooter>
        {stepNumber === 0 ? (
          <>
            <Button outline={true} onClick={handleCloseNew}>
              Close
            </Button>
            <Button onClick={handleValidateCreate}>Next</Button>
          </>
        ) : (
          <>
            <Button outline={true} onClick={handleBack}>
              Back
            </Button>
            <Button onClick={handleSaveNew} disabled={hasError || tableData[table].isLoading}>
              Save
            </Button>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};
