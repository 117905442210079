import { Tag } from "@nef/core";
import { PVR_DATE_TIME_FORMAT } from "components/pvRejects/client/columns";
import { POR, PORAction, PORModel, PORStatus } from "components/pvRejects/constant";
import { CellCopy } from "components/standardTable/cellCopy";
import { TagCellWrapper } from "components/standardTable/tagCellWrapper";
import dayjs from "dayjs";
import { EST_TIMEZONE } from "utils/dateUtils";

export enum TagColor {
  PRIMARY = "primary",
  SUCCESS = "success",
  DANGER = "danger",
  WARNING = "warning",
}

export const currentStatusColorMap: { [key in PORStatus]: TagColor } = {
  [PORStatus.PENDING]: TagColor.PRIMARY,
  [PORStatus.APPROVED]: TagColor.SUCCESS,
  [PORStatus.DENIED]: TagColor.DANGER,
  [PORStatus.EXPIRED]: TagColor.WARNING,
};

const actionStatusColorMap: { [key in PORAction]: TagColor } = {
  [PORAction.APPROVE]: TagColor.SUCCESS,
  [PORAction.DENY]: TagColor.DANGER,
};

export const porAuditColumns = [
  {
    Header: "Request ID",
    id: PORModel.ID,
    accessor: PORModel.ID,
    Cell: ({ row }: { row: { original: POR } }) => {
      if (row.original[PORModel.ID]) {
        const id = `copy-${row.original[PORModel.ID]}`;
        return <CellCopy id={id} value={row.original[PORModel.ID]} />;
      } else {
        return "";
      }
    },
    width: 100,
  },
  { Header: "Symbol", id: PORModel.SYMBOL, accessor: PORModel.SYMBOL, width: 60 },
  { Header: "MPID", id: PORModel.MPID, accessor: PORModel.MPID, width: 60 },
  {
    Header: "Current Status",
    id: PORModel.REQUEST_STATUS,
    accessor: PORModel.REQUEST_STATUS,
    width: 110,
    type: "tag",
    Cell: ({ row }: { row: { original: POR } }) => {
      const color =
        currentStatusColorMap[row.original[PORModel.REQUEST_STATUS]] ?? TagColor.WARNING;
      return (
        <TagCellWrapper>
          <Tag color={color}>{row.original[PORModel.REQUEST_STATUS]}</Tag>
        </TagCellWrapper>
      );
    },
  },
  {
    Header: "Action Status",
    id: PORModel.ACTION_TYPE,
    accessor: PORModel.ACTION_TYPE,
    width: 110,
    Cell: ({ row }: { row: { original: POR } }) => {
      const color = actionStatusColorMap[row.original[PORModel.ACTION_TYPE]] ?? TagColor.WARNING;
      return (
        <TagCellWrapper>
          <Tag color={color}>
            {row.original[PORModel.ACTION_TYPE] ? row.original[PORModel.ACTION_TYPE] : "No Action"}
          </Tag>
        </TagCellWrapper>
      );
    },
  },
  {
    Header: "Request Time",
    id: PORModel.PENDING_TIME,
    accessor: (row: POR) => {
      return dayjs(`${row[PORModel.PENDING_TIME]}${EST_TIMEZONE}`).format(PVR_DATE_TIME_FORMAT);
    },
    width: 130,
  },
  {
    Header: "Action Time",
    id: PORModel.ACTIONED_TIME,
    accessor: (row: POR) => {
      if (row[PORModel.ACTIONED_TIME] !== null) {
        return dayjs(`${row[PORModel.ACTIONED_TIME]}${EST_TIMEZONE}`).format(PVR_DATE_TIME_FORMAT);
      }
      return "No Action";
    },
    width: 130,
  },
  {
    Header: "Expired Time",
    id: PORModel.EXPIRED_TIME,
    accessor: (row: POR) => {
      if (row[PORModel.EXPIRED_TIME] !== null) {
        return dayjs(`${row[PORModel.EXPIRED_TIME]}${EST_TIMEZONE}`).format(PVR_DATE_TIME_FORMAT);
      }
      return "Not Expired";
    },
    width: 130,
  },
  {
    Header: "Expired From",
    id: PORModel.EXPIRED_FROM,
    accessor: PORModel.EXPIRED_FROM,
    width: 130,
  },
  {
    Header: "Requestor",
    id: PORModel.REQUESTOR_EMAIL,
    accessor: PORModel.REQUESTOR_EMAIL,
    width: 150,
  },
  {
    Header: "Actioned By",
    id: PORModel.ACTIONER_EMAIL,
    accessor: PORModel.ACTIONER_EMAIL,
    width: 150,
  },
  {
    Header: "Last Update Time",
    id: "lastUpdateDatetime",
    accessor: (row: POR) => {
      return dayjs(`${row[PORModel.LAST_UDPATE]}${EST_TIMEZONE}`).format(PVR_DATE_TIME_FORMAT);
    },
    width: 130,
  },
];
