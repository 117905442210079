import dayjs from "dayjs";
import { ApiResponseNames, OptionConstants, FieldNames } from "components/fields/fieldConstants";
import { TR_SIDE } from "components/fields/constants";

export function isFunction(functionToCheck) {
  return typeof functionToCheck === "function";
}

export function isPlainObject(obj) {
  return Object.prototype.toString.call(obj) === "[object Object]";
}

export const execOrReturn = (checkMe, params) => {
  if (!checkMe) {
    return null;
  }

  if (isFunction(checkMe)) {
    return checkMe(params || {});
  } else {
    return checkMe;
  }
};

export const getQueryStringParams = query => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query).split("&").reduce((params, param) => {
        let [key, value] = param.split("=");
        params[key] = value ? decodeURIComponent(value.replace(/\+/g, " ")) : "";
        return params;
      }, {})
    : {};
};

export const safeParseJSON = json => {
  try {
    return JSON.parse(json || "{}");
  } catch (e) {
    console.error(e);
  }
};

export const formatNumberToCurrencyString = (num, notANumberReturn = "") => {
  if (typeof num !== "number" || num === 0 || isNaN(num)) {
    return notANumberReturn;
  }

  return num.toLocaleString("en-us", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 6,
    style: "currency",
    currency: "USD",
  });
};

export const formatNumberToString = num => {
  if (typeof num !== "number") {
    return "";
  }

  return num.toLocaleString("en-us", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 6,
  });
};

export const padTimeArrayValue = (value, numberOfTrailingDigits) => {
  if (Array.isArray(value) && value.length === 4 && value.join("").length > 0) {
    const paddedValue = value.reduce((acc, curr, idx) => {
      if (idx === 3) {
        acc.push(curr.padEnd(numberOfTrailingDigits, "0"));
      } else {
        acc.push(curr.padStart(2, "0"));
      }
      return acc;
    }, []);
    return paddedValue;
  } else {
    return ["", "", "", ""];
  }
};

export const multiplyFloats = (first, second) => {
  if (typeof first === "number" && typeof second === "number") {
    const splitFirst = first.toString().split(".");
    const numFirstDecimal = splitFirst[1]?.length || 0;

    const splitSecond = second.toString().split(".");
    const numSecondDecimal = splitSecond[1]?.length || 0;

    first = parseInt(splitFirst[0] + splitFirst[1]);
    second = parseInt(splitSecond[0] + splitSecond[1]);

    const num = first * second;
    const numStr = num.toString();
    return parseFloat(
      numStr.substr(0, numStr.length - (numFirstDecimal + numSecondDecimal)) +
        "." +
        numStr.substr(numStr.length - (numFirstDecimal + numSecondDecimal), numStr.length)
    );
  }
  return 0;
};

export const getSideLabel = side => {
  switch (side) {
    case TR_SIDE.BUY:
      return "Buy";
    case TR_SIDE.SELL:
      return "Sell";
    case TR_SIDE.SELL_SHORT:
      return "Sell Short";
    case TR_SIDE.SHORT_SELL_EXEMPT:
      return "Short Sell Exempt";
    case TR_SIDE.CROSS:
      return "Cross";
    case TR_SIDE.CROSS_SELL_SHORT:
      return "Cross Sell Short";
    case TR_SIDE.CROSS_SELL_SHORT_EXEMPT:
      return "Cross Sell Short Exempt";
    case TR_SIDE.BUY_CUSTOMER_SOLD_SHORT:
      return "Buy, Customer Sold Short";
    case TR_SIDE.BUY_CUSTOMER_SOLD_SHORT_EXEMPT:
      return "Buy, Customer Sold Short Exempt";
    case TR_SIDE.UNKNOWN:
      return "";
    default:
      return side;
  }
};

export const getFieldValue = field => {
  if (field !== null && typeof field === "object") {
    return field.value;
  }
  return field;
};

export const getFieldLabel = field => {
  if (field !== null && typeof field === "object") {
    return field.label;
  }
  return field;
};

export const hasValidMPIDFromReportingParty = (reportingParty, inboundData, userMPIDs = []) => {
  const mpid =
    reportingParty === "M"
      ? getFieldValue(inboundData[ApiResponseNames.executingMPID])
      : getFieldValue(inboundData[ApiResponseNames.contraMPID]);
  return userMPIDs.includes(mpid);
};

const REVERSAL_FLAGS = ["K", "R", "Reversal K", "Reversal R"];

export const isReversal = reversalFlag => {
  return REVERSAL_FLAGS.includes(reversalFlag);
};

export const formatUrl = (base, endpoint) => {
  return `${base?.replace(/\/+$/, "")}/${endpoint?.replace(/^\/+/, "")}`;
};

export const getFileNameDate = date => {
  const theDate = date ? date : new dayjs();
  return `_${theDate.format("YYYY-MM-DD_HHmmss")}`;
};

export const getIsClearingOnlyEligible = (reportTypeVals, isReportAllowed) => {
  if (!Array.isArray(reportTypeVals)) {
    reportTypeVals = [reportTypeVals];
  }
  let hasClear = false;
  let isValidReport = true;
  reportTypeVals.forEach(opt => {
    const value = getFieldValue(opt);
    if (value === OptionConstants[FieldNames.clearReportRiskVals].clear) {
      hasClear = true;
    }
    if (!isReportAllowed && value === OptionConstants[FieldNames.clearReportRiskVals].report) {
      isValidReport = false;
    }
  });
  return hasClear && isValidReport;
};

export const addPrefixToField = (prefix, field) => `${prefix}${field}`;

export const getCookie = name => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

export const abbreviateNumber = value => {
  try {
    return Intl.NumberFormat("en-US", {
      notation: "compact",
      maximumFractionDigits: value < 1 ? 2 : 1,
    }).format(value);
  } catch (e) {
    console.error(e);
    return "N/A";
  }
};
