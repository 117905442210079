import { NotificationHub } from "@nef/core";
import { SubtitleList } from "components/notification";

export enum Status {
  NO_STATUS = "primary",
  ERROR = "danger",
  SUCCESS = "success",
  WARN = "warning",
}

export enum WorkXResponseDTO {
  BulkResubmitResponseDTO = "BulkResubmitResponseDTO",
}

export type WorkXResponse<BodyT = any> = {
  body?: BodyT;
  bodyName?: string;
  errorCode?: string;
  errorMessages?: string[];
};

export enum NotificationColor {
  ERROR = "danger",
  SUCCESS = "success",
  WARN = "warning",
  PRIMARY = "primary",
}

export const sendWorkXResponseNotification = ({
  response,
  color,
  title,
}: {
  response: WorkXResponse;
  color: NotificationColor;
  title: string;
}) => {
  NotificationHub.send(color, title, {
    subtitle:
      response.errorMessages !== undefined && response.errorMessages.length > 0 ? (
        <SubtitleList messages={response.errorMessages} />
      ) : undefined,
  });
};

export enum TableButtonAction {
  ACCEPT = "Accept",
  ALLOW = "Allow",
  BREAK = "Break",
  CANCEL = "Cancel",
  CLOSE = "Close",
  COPY = "Copy",
  DECLINE = "Decline",
  LAYOUT = "Layout",
  INHIBIT = "Inhibit",
  MODIFY = "Modify",
  MATCH = "Match",
  RECAP = "Recap",
  REPAIR = "Repair",
  REVERSE = "Reverse",
  EDIT_LIMITS = "Edit Limits",
  EXPORT = "Export",
  EXPORT_LIMITS = "Export Limits",
  RISK_ALERT_COPY = "RiskAlertCopy",
  RISK_ALERT_DELETE = "RiskAlertDelete",
  RISK_ALERT_EDIT = "RiskAlertEdit",
  RISK_ALERT_NEW = "RiskAlertNew",
  EQRC_AUDIT = "EQRCAudit",
  EQRC_COPY = "EQRCCopy",
  EQRC_DELETE = "EQRCDelete",
  EQRC_EDIT = "EQRCEdit",
  EQRC_NEW = "EQRCNew",
  EQRC_ENABLE = "Enable",
  EQRC_DISABLE = "Disable",
  EQRC_ALERT_NEW = "EQRCAlertNew",
  EQRC_HISTORY = "EQRCHistory",
  EQRC_REFRESH = "EQRCRefresh",
  RISK_ALERT_DISABLE = "Disable",
  RISK_ALERT_ENABLE = "Enable",
  RASH_CANCEL = "RASH_CANCEL",
  RASH_MASS_CANCEL = "RASH_CANCEL",
  KEYCLOAK_REFRESH = "KeycloakRefresh",
  PVR_RESUBMIT = "PVR_Resubmit",
  PVR_CLOSE = "PVR_CLOSE",
  POR = "POR",
  PVR_EMAIL_NEW = "PVR_EMAIL_NEW",
  PVR_EMAIL_EDIT = "PVR_EMAIL_EDIT",
  PVR_EMAIL_DELETE = "PVR_EMAIL_DELETE",
  PVR_DENY = "DENY",
  PVR_APPROVE = "APPROVE",
  BANNER_ANNOUNCEMENT_DELETE = "BANNER_ANNOUNCEMENT_DELETE",
  BANNER_ANNOUNCEMENT_EDIT = "BANNER_ANNOUNCEMENT_EDIT",
  BANNER_ANNOUNCEMENT_NEW = "BANNER_ANNOUNCEMENT_NEW",
  BANNER_ANNOUNCEMENT_PREVIEW = "BANNER_ANNOUNCEMENT_PREVIEW",
}

export enum TableButtonLabel {
  ACCEPT = "Accept",
  ALLOW = "Allow",
  AUDIT = "Audit",
  BREAK = "Break",
  CANCEL = "Cancel",
  CLOSE = "Close",
  COPY = "Copy",
  DECLINE = "Decline",
  DELETE = "Delete",
  EDIT = "Edit",
  HISTORY = "History",
  LAYOUT = "Layout",
  INHIBIT = "Inhibit",
  MATCH = "Match",
  MODIFY = "Modify",
  NEW = "NEW",
  RECAP = "Recap",
  REPAIR = "Repair",
  REVERSE = "Reverse",
  EDIT_LIMITS = "Edit Limits",
  EXPORT = "Export",
  EXPORT_LIMITS = "Export Limits",
  EQRC_NEW = "New Rule",
  EQRC_ALERT_NEW = "New Alert",
  DISABLE = "Disable",
  ENABLE = "Enable",
  RASH_MASS_CANCEL = "Mass Cancel",
  REFRESH = "Refresh",
  RESUBMIT = "Resubmit",
  POR = "POR",
  DENY = "Deny",
  APPROVE = "Approve",
  PREVIEW = "Preview",
}
