import React, { useCallback, useEffect, useState } from "react";
import { TableButtons } from "components/standardTable";
import { StandardTables } from "wksConstants";
import { TableButtonAction, TableButtonLabel } from "wksConstantsTS";
import { useUserContext } from "components/user";
import { ViewActions } from "viewConstants";

export const EQRCAlertConfigTableButtons = ({
  onClickNew,
  onClickDelete,
  onClickEdit,
  onClickEnable,
  onClickDisable,
  onClickHistory,
  isHistoryLoading,
}) => {
  const [userData] = useUserContext();

  const handleNew = useCallback(() => {
    if (typeof onClickNew === "function") {
      onClickNew();
    }
  }, [onClickNew]);

  const handleDelete = useCallback(() => {
    if (typeof onClickDelete === "function") {
      onClickDelete();
    }
  }, [onClickDelete]);

  const handleDisable = useCallback(() => {
    if (typeof onClickDisable === "function") {
      onClickDisable();
    }
  }, [onClickDisable]);

  const handleEnable = useCallback(() => {
    if (typeof onClickEnable === "function") {
      onClickEnable();
    }
  }, [onClickEnable]);

  const handleEdit = useCallback(() => {
    if (typeof onClickEdit === "function") {
      onClickEdit();
    }
  }, [onClickEdit]);

  const handleHistory = useCallback(() => {
    if (typeof onClickHistory === "function") {
      onClickHistory();
    }
  }, [onClickHistory]);

  const getTableButtons = useCallback(() => {
    const buttons = [
      {
        icon: "history",
        text: TableButtonLabel.HISTORY,
        actionId: TableButtonAction.EQRC_HISTORY,
        confirm: true,
        allowConfirm: true,
        canConfirm: true,
        onClick: handleHistory,
        loading: isHistoryLoading,
      },
    ];
    if (userData.allowed.actions[ViewActions.EQRC_RULES_WRITE]) {
      buttons.push({
        icon: "minus",
        text: TableButtonLabel.DELETE,
        actionId: TableButtonAction.EQRC_DELETE,
        allowConfirm: true,
        canConfirm: true,
        requireSelect: true,
        allowMultiSelect: false,
        onClick: handleDelete,
      });
      buttons.push({
        icon: "plus",
        text: TableButtonLabel.NEW,
        actionId: TableButtonAction.EQRC_NEW_ALERT,
        confirm: false,
        requireSelect: false,
        allowMultiSelect: true,
        onClick: handleNew,
      });
      buttons.push({
        icon: "edit",
        text: TableButtonLabel.EDIT,
        actionId: TableButtonAction.EQRC_EDIT,
        confirm: false,
        requireSelect: true,
        allowMultiSelect: false,
        onClick: handleEdit,
      });
      buttons.push({
        icon: "ban",
        text: TableButtonLabel.DISABLE,
        actionId: TableButtonAction.EQRC_DISABLE,
        confirm: true,
        requireSelect: true,
        allowMultiSelect: true,
        onClick: handleDisable,
      });
      buttons.push({
        icon: "check",
        text: TableButtonLabel.ENABLE,
        actionId: TableButtonAction.EQRC_ENABLE,
        confirm: true,
        requireSelect: true,
        allowMultiSelect: true,
        onClick: handleEnable,
      });
    }
    return buttons;
  }, [
    handleHistory,
    isHistoryLoading,
    userData.allowed.actions,
    handleDelete,
    handleNew,
    handleEdit,
    handleDisable,
    handleEnable,
  ]);

  const [tableButtons, setTableButtons] = useState(getTableButtons());

  useEffect(() => setTableButtons(getTableButtons()), [getTableButtons]);

  return <TableButtons table={StandardTables.EQRC_ALERT_CONFIG} buttons={tableButtons} />;
};
