import { NotificationHub } from "@nef/core";
import { getColor } from "@nef/style-utils";
import { LoadingIcon } from "components/loadingIcon";
import { getHeaders } from "keycloak";
import { doFetchWrapper } from "network";
import { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { formatUrl } from "utils/js.utils";
import { useAlertCacheDispatch, useAlertCacheState } from "./alertCache";
import { PVR_ALERT_MODEL, PorWindowEvent, getIdentifierFromAlert } from "./constants";
import { POR_STATUS, PVR_ALERT_TYPE } from "components/settings/pvr/constants";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.extend(timezone);

const currentTimeZone = dayjs().tz("America/New_York").format("Z");
const AlertBodyContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 1fr;
  grid-gap: 0.5rem;
  color: ${getColor("gray", 500)};
  padding: 3px 0px;
`;

const fontSize = "0.875rem";

const AlertDataList = styled.ul`
  grid-column: 1 / 2;
  align-self: end;
  font-size: ${fontSize};
  line-height: ${fontSize};
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-gap: 0.25rem;
  position: relative;
`;

export const SharedBtn = `
padding: 0px;
position: relative;
background: none;
border: none;
cursor: pointer;
font-size: ${fontSize};
line-height: ${fontSize};
`;

const ListItem = styled.li`
  white-space: nowrap;
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ReadBtn = styled.button<{
  $alignSelf: string;
  $justifySelf: string;
  $isRead: boolean;
  $isLoading: boolean;
}>`
  ${props => `
    ${SharedBtn}
    grid-column: 2 / 3;
    align-self: ${props.$alignSelf};
    justify-self: ${props.$justifySelf};
    color: ${
      props.$isRead || props.$isLoading
        ? getColor("gray", 500)(props)
        : getColor("primary", 500)(props)
    };
    text-align: end;
    display: flex;
    align-items: center;
    justify-content: end;
    cursor: ${props.$isLoading ? "progress" : "pointer"};

    ${
      !props.$isLoading
        ? `
      &:hover {
        text-decoration: underline;
        color: ${props.$isRead ? getColor("gray", 400)(props) : getColor("primary", 400)(props)}
      }`
        : ""
    }
`}
`;

export const ReadText = styled.span`
  margin-left: 0.25rem;
`;

type PvrAlertBodyProps = {
  alert: PVR_ALERT_MODEL;
  includeMarkAsRead: boolean;
};

export const PvrAlertBody: React.FC<PvrAlertBodyProps> = ({ alert, includeMarkAsRead }) => {
  const pvrAlertData = useAlertCacheState();
  const pvrAlertDispatch = useAlertCacheDispatch();
  const [isMarkAsReadLoading, setMarkAsReadLoading] = useState(false);

  const hasPendingRequest = useMemo(() => {
    return isMarkAsReadLoading || pvrAlertData.isMarkAllLoading;
  }, [pvrAlertData.isMarkAllLoading, isMarkAsReadLoading]);

  const isRead = useMemo(() => {
    return !!pvrAlertData.readMap[getIdentifierFromAlert(alert)];
  }, [alert, pvrAlertData.readMap]);

  const handleMarkAsRead = useCallback(() => {
    setMarkAsReadLoading(true);
    const cb = (json: any) => {
      pvrAlertDispatch({
        type: "SET_READ",
        payload: json,
      });
      setMarkAsReadLoading(false);
    };
    const errorCb = () => {
      NotificationHub.send("danger", "Error marking alert as read");
      setMarkAsReadLoading(false);
    };
    doFetchWrapper(
      formatUrl(process.env.REACT_APP_URL_USER_WS, "savepvralertsread"),
      {
        method: "post",
        headers: getHeaders(),
        body: JSON.stringify([getIdentifierFromAlert(alert)]),
      },
      cb,
      errorCb
    );
  }, [alert, pvrAlertDispatch]);

  const handleMarkAsUnread = useCallback(() => {
    setMarkAsReadLoading(true);
    const cb = () => {
      pvrAlertDispatch({
        type: "SET_UNREAD",
        payload: [alert],
      });
      setMarkAsReadLoading(false);
    };
    const errorCb = () => {
      NotificationHub.send("danger", "Error marking alert as unread");
      setMarkAsReadLoading(false);
    };
    doFetchWrapper(
      formatUrl(process.env.REACT_APP_URL_USER_WS, "deleteunreadpvralerts"),
      {
        method: "post",
        headers: getHeaders(),
        body: JSON.stringify([getIdentifierFromAlert(alert)]),
      },
      cb,
      errorCb
    );
  }, [alert, pvrAlertDispatch]);

  const MarkAsRead = useMemo(() => {
    if (includeMarkAsRead) {
      let onClick = undefined;
      if (!hasPendingRequest) {
        if (isRead) {
          onClick = handleMarkAsUnread;
        } else {
          onClick = handleMarkAsRead;
        }
      }
      return (
        <ReadBtn
          $alignSelf="end"
          $justifySelf="end"
          $isRead={isRead}
          onClick={onClick}
          $isLoading={hasPendingRequest}
        >
          <LoadingIcon isLoading={hasPendingRequest} />
          <ReadText>{isRead ? "Mark as Unread" : "Mark as Read"}</ReadText>
        </ReadBtn>
      );
    } else {
      return undefined;
    }
  }, [includeMarkAsRead, isRead, handleMarkAsUnread, handleMarkAsRead, hasPendingRequest]);

  switch (alert.alertType) {
    case PVR_ALERT_TYPE.POR_STATUS:
      switch (alert.status) {
        case POR_STATUS.PENDING:
          return (
            <AlertBodyContainer>
              <AlertDataList>
                <li>A parameter override request has been sent to FINRA for review</li>
                <li>MPID: {alert.mpid}</li>
                <li>Symbol: {alert.symbol}</li>
              </AlertDataList>
              {MarkAsRead}
            </AlertBodyContainer>
          );
        case POR_STATUS.APPROVED:
          return (
            <AlertBodyContainer>
              <AlertDataList>
                <li>A parameter override request has been approved by FINRA</li>
                <li>MPID: {alert.mpid}</li>
                <li>Symbol: {alert.symbol}</li>
              </AlertDataList>
              {MarkAsRead}
            </AlertBodyContainer>
          );
        case POR_STATUS.DENIED:
          return (
            <AlertBodyContainer>
              <AlertDataList>
                <li>A parameter override request has been denied by FINRA</li>
                <li>MPID: {alert.mpid}</li>
                <li>Symbol: {alert.symbol}</li>
              </AlertDataList>
              {MarkAsRead}
            </AlertBodyContainer>
          );
        case POR_STATUS.EXPIRED:
          return (
            <AlertBodyContainer>
              <AlertDataList>
                <li>A parameter override request has expired</li>
                <li>MPID: {alert.mpid}</li>
                <li>Symbol: {alert.symbol}</li>
              </AlertDataList>
              {MarkAsRead}
            </AlertBodyContainer>
          );
        default:
          return (
            <AlertBodyContainer>
              Invalid Status Type
              {MarkAsRead}
            </AlertBodyContainer>
          );
      }
    case PVR_ALERT_TYPE.PVR_RESUBMIT:
      return (
        <AlertBodyContainer>
          <AlertDataList>
            <li>Status: Done</li>
            <li>MPID: {alert.mpid}</li>
            <ListItem>Symbol(s): {alert.symbols.join(", ")}</ListItem>
            <li>Requestor: {alert.requestor}</li>
          </AlertDataList>
          {MarkAsRead}
        </AlertBodyContainer>
      );
    case PVR_ALERT_TYPE.POR_WINDOW:
      return (
        <AlertBodyContainer>
          <AlertDataList>
            <li>
              A parameter override request window has{" "}
              {alert.event === PorWindowEvent.OPEN ? "opened" : "closed"}
            </li>
            <li>Open: {dayjs(`${alert.window.startTime}${currentTimeZone}`).format("hh:mm A")}</li>
            <li>Close: {dayjs(`${alert.window.endTime}${currentTimeZone}`).format("hh:mm A")}</li>
          </AlertDataList>
          {MarkAsRead}
        </AlertBodyContainer>
      );
    default:
      return (
        <AlertBodyContainer>
          Invalid Alert Type
          {MarkAsRead}
        </AlertBodyContainer>
      );
  }
};
