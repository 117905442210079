import { StandardTable } from "components/standardTable/standardTable";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { StandardTables } from "wksConstants";
import { TableButtonAction, TableButtonLabel } from "wksConstantsTS";
import styled from "styled-components";
import {
  CloseButton,
  getSelectedRows,
  RecapButton,
  StandardHeader,
  useStandardTableContext,
  useStandardTableDispatch,
} from "components/standardTable";
import {
  getSaveAllErrorSubtitle,
  getSaveAllFailedMessage,
  getSaveAllSuccessMessage,
  PorProcessManyDto,
  PorProcessManyStatus,
  PorServiceErrorResponse,
  PvReject,
  PvRejectModel,
  REJ_PRICE_OO_OVERRIDE_RANGE,
  ServiceIndicatorWrapper,
} from "../constant";
import { LimoLVCIndicator } from "components/limitMonitor";
import { REJECT_CACHE_FILTER_BY, useRejectCacheState } from "../cache/rejectCache";
import {
  NotificationColor,
  sendWorkXResponseNotification,
  WorkXResponse,
  WorkXResponseDTO,
} from "wksConstantsTS";
import { Header, NotificationHub } from "@nef/core";
import { doFetchWrapper } from "network";
import { formatUrl } from "utils/js.utils";
import { getHeaders } from "keycloak";
import { FieldNames, Forms } from "components/fields";
import { useFormContext, useFormDispatch } from "components/form";
import { BulkResubmitResponseDTO, PVR_ALL_VAL } from "./constant";
import { useBulkJobDispatch } from "components/topBar/bulkJob/context";
import { useUserContext } from "components/user";
import { useMPIDOptionsContext } from "components/user/mpidContext";
import { PorButtonHeader } from "./porButtonHeader";
import { ViewActions } from "viewConstants";
import TableButtons from "components/standardTable/tableButtons";
import { PageLoader } from "components/content/pageLoader";

const RejectTableWrapper = styled.div`
  grid-column: span 4;
`;

enum ResubmitMessageSuffix {
  PRICE_OVERRIDE = "Price Override",
  PRICE_VALIDATION_OFF = "Price Validation Off",
}
const getResubmitSuccessMessage = (numProcessing: number, messageSuffix: string) => {
  return `Processing ${numProcessing} reject(s) with ${messageSuffix}.`;
};

const getResubmitFailedMessage = (numProcessing: number, messageSuffix: string) => {
  return `${numProcessing} reject(s) failed to process with ${messageSuffix}.`;
};

const getResubmitErrorMessage = (messageSuffix: string) => {
  return `Error resubmitting rejects with ${ResubmitMessageSuffix.PRICE_VALIDATION_OFF}`;
};

export const PvClientRejectTable: React.FC = () => {
  const rejectCacheState = useRejectCacheState();
  const [tableData] = useStandardTableContext();
  const tableDispatch = useStandardTableDispatch();
  const [formData] = useFormContext();
  const bulkJobDispatch = useBulkJobDispatch();
  const [isResubmitting, setIsResubmitting] = useState(false);
  const formDispatch = useFormDispatch();
  const [userData] = useUserContext();
  const [mpidState] = useMPIDOptionsContext();

  useEffect(() => {
    const actions = [];
    if (rejectCacheState.isLoading) {
      actions.push({
        type: "DESELECT_ALL_ROWS",
        payload: { table: StandardTables.PV_REJECTS },
      });
    }
    actions.push({
      type: "SET_TABLE_DATA_KEEP_SELECTED",
      payload: {
        table: StandardTables.PV_REJECTS,
        data: Object.values(rejectCacheState.data),
        selectByColumn: PvRejectModel.ID,
      },
    });
    actions.push({
      type: "SET_IS_LOADING",
      payload: { table: StandardTables.PV_REJECTS, isLoading: rejectCacheState.isLoading },
    });
    tableDispatch(actions);
  }, [rejectCacheState.data, rejectCacheState.isLoading, tableDispatch]);

  const createPORs = useCallback(() => {
    if (rejectCacheState.filter?.criteria.rejectText !== REJ_PRICE_OO_OVERRIDE_RANGE) {
      NotificationHub.send("danger", "POR action is not eligible for Price Out Of Range rejects");
    } else {
      const rows: PvReject[] = getSelectedRows(tableData[StandardTables.PV_REJECTS]);
      const porData: any[] = [];
      const porKeys = new Set();
      rows.forEach(row => {
        const key = `${row[PvRejectModel.ENTRY_MPID]}|${row[PvRejectModel.SYMBOL]}`;
        if (!porKeys.has(key)) {
          porKeys.add(key);
          porData.push({ symbol: row[PvRejectModel.SYMBOL], mpid: row[PvRejectModel.ENTRY_MPID] });
        }
      });

      const createPorSuccess = (data: WorkXResponse<PorProcessManyDto>) => {
        switch (data.body?.status) {
          case PorProcessManyStatus.SUCCESS: {
            NotificationHub.send("success", getSaveAllSuccessMessage(data.body.successful.length));
            tableDispatch({
              type: "ADD_TABLE_DATA_FIRST",
              payload: { table: StandardTables.PV_REQUESTS, data: data.body.successful },
            });
            break;
          }
          case PorProcessManyStatus.FAILED: {
            NotificationHub.send("danger", getSaveAllFailedMessage(data.body.failed.length), {
              subtitle: getSaveAllErrorSubtitle(data.errorMessages),
            });
            break;
          }
          case PorProcessManyStatus.PARTIAL: {
            NotificationHub.send(
              "warning",
              `${getSaveAllSuccessMessage(data.body.successful.length)} ${getSaveAllFailedMessage(
                data.body.failed.length
              )}`,
              {
                subtitle: getSaveAllErrorSubtitle(data.errorMessages),
              }
            );
            tableDispatch({
              type: "ADD_TABLE_DATA_FIRST",
              payload: { table: StandardTables.PV_REQUESTS, data: data.body.successful },
            });
            break;
          }
          default: {
            NotificationHub.send("danger", "Response for creating POR(s) is not recognized");
            break;
          }
        }
      };

      const createPorError = (err: PorServiceErrorResponse) => {
        NotificationHub.send("danger", "Error creating POR(s)", { subtitle: err.message });
      };

      doFetchWrapper(
        formatUrl(process.env.REACT_APP_URL_PVR_POR_SERVICE, "por/save-all"),
        {
          method: "post",
          headers: getHeaders(),
          body: JSON.stringify(porData),
        },
        createPorSuccess,
        createPorError
      );
    }
  }, [tableData, tableDispatch, rejectCacheState.filter]);

  const lateTradeModifier = useMemo(() => {
    return formData[Forms.PVR_RESUBMIT.key].fields[FieldNames.lateTradeModifier]?.value;
  }, [formData]);

  const resubmitPriceRange = useCallback(() => {
    const rows: PvReject[] = getSelectedRows(tableData[StandardTables.PV_REJECTS]);
    const rejectIds = rows.map(row => row[PvRejectModel.ID]);
    const resubmitSuccess = (response: WorkXResponse) => {
      const body: BulkResubmitResponseDTO = response.body;
      setIsResubmitting(false);
      if (body.numProcessing > 0) {
        if (body.numWorkxRejects > 0) {
          sendWorkXResponseNotification({
            response,
            color: NotificationColor.WARN,
            title: `${getResubmitSuccessMessage(
              body.numProcessing,
              ResubmitMessageSuffix.PRICE_OVERRIDE
            )} ${getResubmitFailedMessage(
              body.numWorkxRejects,
              ResubmitMessageSuffix.PRICE_OVERRIDE
            )}`,
          });
        } else {
          sendWorkXResponseNotification({
            response,
            color: NotificationColor.SUCCESS,
            title: `${getResubmitSuccessMessage(
              body.numProcessing,
              ResubmitMessageSuffix.PRICE_OVERRIDE
            )}`,
          });
        }
        bulkJobDispatch({ type: "ADD_JOB", payload: body.data });
      } else {
        sendWorkXResponseNotification({
          response,
          color: NotificationColor.ERROR,
          title: `${getResubmitFailedMessage(
            body.numWorkxRejects,
            ResubmitMessageSuffix.PRICE_OVERRIDE
          )}`,
        });
      }
    };

    const resubmitError = (response: WorkXResponse) => {
      setIsResubmitting(false);
      if (response.bodyName === WorkXResponseDTO.BulkResubmitResponseDTO) {
        const body = response.body as BulkResubmitResponseDTO;
        sendWorkXResponseNotification({
          response,
          color: NotificationColor.ERROR,
          title: `${getResubmitFailedMessage(
            body.numWorkxRejects,
            ResubmitMessageSuffix.PRICE_OVERRIDE
          )}`,
        });
      } else {
        NotificationHub.send(
          "danger",
          getResubmitErrorMessage(ResubmitMessageSuffix.PRICE_OVERRIDE),
          {
            subtitle:
              response?.errorMessages?.length !== undefined && response.errorMessages.length > 0
                ? response?.errorMessages?.[0]
                : undefined,
          }
        );
      }
    };
    setIsResubmitting(true);
    doFetchWrapper(
      formatUrl(process.env.REACT_APP_URL_PVR_ACT_WS, "bulk/resubmit/priceOverride"),
      {
        method: "post",
        headers: getHeaders(),
        body: JSON.stringify({ rejectIds, lateTradeModifier }),
      },
      resubmitSuccess,
      resubmitError
    );
    tableDispatch({
      type: "DESELECT_ALL_ROWS",
      payload: { table: StandardTables.PV_REJECTS },
    });
  }, [bulkJobDispatch, lateTradeModifier, tableData, tableDispatch]);

  const resubmitOverrideRange = useCallback(() => {
    const rows: PvReject[] = getSelectedRows(tableData[StandardTables.PV_REJECTS]);
    const rejectIds = rows.map(row => row[PvRejectModel.ID]);
    const resubmitSuccess = (response: WorkXResponse) => {
      const body: BulkResubmitResponseDTO = response.body;
      setIsResubmitting(false);
      if (body.numProcessing > 0) {
        if (body.numWorkxRejects > 0) {
          sendWorkXResponseNotification({
            response,
            color: NotificationColor.WARN,
            title: `${getResubmitSuccessMessage(
              body.numProcessing,
              ResubmitMessageSuffix.PRICE_VALIDATION_OFF
            )} ${getResubmitFailedMessage(
              body.numWorkxRejects,
              ResubmitMessageSuffix.PRICE_VALIDATION_OFF
            )}`,
          });
        } else {
          sendWorkXResponseNotification({
            response,
            color: NotificationColor.SUCCESS,
            title: `${getResubmitSuccessMessage(
              body.numProcessing,
              ResubmitMessageSuffix.PRICE_VALIDATION_OFF
            )}`,
          });
        }
        bulkJobDispatch({ type: "ADD_JOB", payload: body.data });
      } else {
        sendWorkXResponseNotification({
          response,
          color: NotificationColor.ERROR,
          title: `${getResubmitFailedMessage(
            body.numWorkxRejects,
            ResubmitMessageSuffix.PRICE_VALIDATION_OFF
          )}`,
        });
      }
    };

    const resubmitError = (response: WorkXResponse) => {
      setIsResubmitting(false);
      if (response.bodyName === WorkXResponseDTO.BulkResubmitResponseDTO) {
        const body = response.body as BulkResubmitResponseDTO;
        sendWorkXResponseNotification({
          response,
          color: NotificationColor.ERROR,
          title: `${getResubmitFailedMessage(
            body.numWorkxRejects,
            ResubmitMessageSuffix.PRICE_VALIDATION_OFF
          )}`,
        });
      } else {
        NotificationHub.send(
          "danger",
          getResubmitErrorMessage(ResubmitMessageSuffix.PRICE_VALIDATION_OFF),
          {
            subtitle:
              response?.errorMessages?.length !== undefined && response.errorMessages.length > 0
                ? response?.errorMessages?.[0]
                : undefined,
          }
        );
      }
    };
    setIsResubmitting(true);
    doFetchWrapper(
      formatUrl(process.env.REACT_APP_URL_PVR_ACT_WS, "bulk/resubmit/priceValidationOff"),
      {
        method: "post",
        headers: getHeaders(),
        body: JSON.stringify({ rejectIds, lateTradeModifier }),
      },
      resubmitSuccess,
      resubmitError
    );
    tableDispatch({
      type: "DESELECT_ALL_ROWS",
      payload: { table: StandardTables.PV_REJECTS },
    });
  }, [bulkJobDispatch, tableData, tableDispatch, lateTradeModifier]);

  const { uniqueSelectedPorRejects, numRejectsSelected } = useMemo(() => {
    const symbolSet = new Set<string>();
    const selected = getSelectedRows(tableData[StandardTables.PV_REJECTS]) as PvReject[];
    const uniqueSelectedPorRejects = selected.reduce((acc, curr) => {
      if (curr) {
        if (!symbolSet.has(curr.symbol)) {
          acc++;
          symbolSet.add(curr.symbol);
        }
      } else {
        console.warn("Invalid row found in selected rows: " + curr);
      }
      return acc;
    }, 0);
    return { uniqueSelectedPorRejects, numRejectsSelected: selected.length };
  }, [tableData]);

  const ResubmitButttonConfirm = useCallback(() => {
    return (
      <Header size={3}>Are you sure you want to Resubmit {numRejectsSelected} Reject(s)?</Header>
    );
  }, [numRejectsSelected]);

  const PorButttonConfirm = useCallback(() => {
    return <PorButtonHeader count={uniqueSelectedPorRejects} />;
  }, [uniqueSelectedPorRejects]);

  const handleCloseMenu = useCallback(() => {
    formDispatch({
      type: "RESET_FORM",
      payload: { form: Forms.PVR_RESUBMIT },
    });
  }, [formDispatch]);

  const RejectButtons = useMemo(() => {
    const tableButtons = [];
    if (userData.allowed.actions[ViewActions.PVR_CLIENT_MONITOR_RESUBMIT]) {
      tableButtons.push({
        icon: "arrow-up",
        text: TableButtonLabel.RESUBMIT,
        actionId: TableButtonAction.PVR_RESUBMIT,
        canConfirm: true,
        allowConfirm: true,
        requireSelect: true,
        allowMultiSelect: true,
        header: ResubmitButttonConfirm,
        onClick:
          rejectCacheState.filter?.criteria.rejectText === REJ_PRICE_OO_OVERRIDE_RANGE
            ? resubmitOverrideRange
            : resubmitPriceRange,
        isDisabled: isResubmitting,
        loading: isResubmitting,
        onCloseConfirm: handleCloseMenu,
      });
    }
    if (userData.allowed.actions[ViewActions.PVR_CLIENT_MONITOR_POR]) {
      tableButtons.push({
        icon: "lock-open",
        text: TableButtonLabel.POR,
        actionId: TableButtonAction.POR,
        canConfirm: true,
        header: PorButttonConfirm,
        allowConfirm: true,
        requireSelect: true,
        allowMultiSelect: true,
        onClick: createPORs,
        confirmText: "YES",
      });
    }
    const buttons =
      tableButtons.length > 0
        ? [<TableButtons table={StandardTables.PV_REJECTS} buttons={tableButtons} />]
        : [];
    if (userData.allowed.actions[ViewActions.TRADE_REPORTING]) {
      buttons.push(<CloseButton table={StandardTables.PV_REJECTS} />);
    }
    buttons.push(<RecapButton table={StandardTables.PV_REJECTS} />);
    return buttons;
  }, [
    userData.allowed.actions,
    ResubmitButttonConfirm,
    rejectCacheState.filter?.criteria.rejectText,
    resubmitOverrideRange,
    resubmitPriceRange,
    isResubmitting,
    handleCloseMenu,
    PorButttonConfirm,
    createPORs,
  ]);

  const RejectTableHeader = useCallback(() => {
    let title = "Price Rejects";
    if (rejectCacheState.filter?.criteria?.rejectText === REJ_PRICE_OO_OVERRIDE_RANGE) {
      title = "Price Override Rejects";
    }
    if (rejectCacheState.filter?.filterBy === REJECT_CACHE_FILTER_BY.MPID_SYMBOL) {
      const symbols = Object.values(rejectCacheState.filter?.criteria?.mpidToSymbols)?.[0];
      if (symbols) {
        if (symbols.length === 1) {
          if (symbols[0] !== PVR_ALL_VAL) {
            title += ` for ${symbols[0]}`;
          }
        } else {
          title += ` for ${symbols.length} Symbols`;
        }
      }
    }

    return (
      <StandardHeader
        title={
          <ServiceIndicatorWrapper>
            {title}
            <LimoLVCIndicator
              prefix={undefined}
              id={"rej-cache-indicator"}
              status={rejectCacheState.status}
            />
          </ServiceIndicatorWrapper>
        }
      />
    );
  }, [rejectCacheState.filter, rejectCacheState.status]);

  if (!mpidState.userMPIDOptions.isReady) {
    return <PageLoader />;
  }

  return (
    <RejectTableWrapper>
      <StandardTable
        header={RejectTableHeader}
        table={StandardTables.PV_REJECTS}
        enableLayoutExport={true}
        isSingleSelect={false}
        isColumnsVirtualized={false}
        isFilterable={true}
        subHeader={undefined}
        hideRowCount={false}
        hideSelectedCount={false}
        hideQueryDate={undefined}
        headerMenu={RejectButtons}
        exportCallback={undefined}
        exportFileName={undefined}
        additionalRowClick={undefined}
        isEditableTableEnabled={undefined}
        style={undefined}
        isRowSelectEnabled={undefined}
        Header={undefined}
      />
    </RejectTableWrapper>
  );
};
