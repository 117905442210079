import { NotificationHub } from "@nef/core";
import { useLayoutDispatch } from "components/context";
import { LimoLVCIndicator } from "components/limitMonitor";
import { Middle } from "components/middle";
import { PorServiceErrorResponse, ServiceIndicatorWrapper } from "components/pvRejects/constant";
import { StandardHeader } from "components/standardTable";
import { StandardTable } from "components/standardTable/standardTable";
import {
  getSelectedRows,
  useStandardTableContext,
  useStandardTableDispatch,
} from "components/standardTable/standardTableContext";
import TableButtons from "components/standardTable/tableButtons";
import { useUserContext } from "components/user";
import { USER_MPIDS_ATTRIBUTE } from "components/user/mpidContext";
import { getHeaders } from "keycloak";
import { doFetchWrapper } from "network";
import React, { EffectCallback, useCallback, useEffect, useMemo } from "react";
import styled from "styled-components";
import { formatUrl } from "utils/js.utils";
import { StandardTables } from "wksConstants";
import { TableButtonAction, TableButtonLabel } from "wksConstantsTS";
import {
  DATE_FILTER,
  getSaveAllErrorSubtitle,
  getSaveAllFailedMessage,
  getSaveAllSuccessMessage,
  POR,
  PORModel,
  PorProcessManyDto,
  PorProcessManyStatus,
  PORStatus,
} from "components/pvRejects/constant";
import { PorButtonHeader } from "../client/porButtonHeader";
import {
  REJECT_COUNT_FILTER_BY,
  useRejectCountDispatch,
  useRejectCountState,
} from "../cache/rejectCountCache";
import { POR_CACHE_FILTER_BY, usePorCacheDispatch, usePorCacheState } from "../cache/porCache";
import { PorWindowTimer } from "../porWindowTimer";
import { ViewActions } from "viewConstants";
import { WorkXResponse } from "wksConstantsTS";

const TimerWrapper = styled.div`
  margin-bottom: 0.5rem;
`;

const tableHeight = { height: "calc(100% - 20px)" };

const PorStatusValue = {
  [PORStatus.EXPIRED]: 1,
  [PORStatus.DENIED]: 2,
  [PORStatus.PENDING]: 3,
  [PORStatus.APPROVED]: 4,
};

export const PvRejectLite: React.FC = () => {
  const tableDispatch = useStandardTableDispatch();
  const porCacheState = usePorCacheState();
  const porCacheDispatch = usePorCacheDispatch();
  const rejectCountState = useRejectCountState();
  const rejectCountDispatch = useRejectCountDispatch();
  const [userData] = useUserContext();
  const layoutDispatch = useLayoutDispatch();
  const [tableData] = useStandardTableContext();

  const tradeReportingMpids: string[] = useMemo(() => {
    return userData.mpidAttributes[USER_MPIDS_ATTRIBUTE.WORKX_TRADE_REPORTING_MPIDS] || [];
  }, [userData]);

  useEffect((): ReturnType<EffectCallback> => {
    layoutDispatch({
      type: "SET_HIDE_LEFT",
      payload: true,
    });
    return () => {
      layoutDispatch({
        type: "SET_HIDE_LEFT",
        payload: false,
      });
    };
  }, [layoutDispatch]);

  useEffect(() => {
    if (Array.isArray(tradeReportingMpids) && tradeReportingMpids.length > 0) {
      porCacheDispatch({
        type: "START_POLLING",
        payload: {
          filterBy: POR_CACHE_FILTER_BY.MPID,
          keys: tradeReportingMpids,
        },
      });
      rejectCountDispatch({
        type: "START_POLLING",
        payload: {
          filter: {
            filterBy: REJECT_COUNT_FILTER_BY.LITE_COUNTS,
            criteria: tradeReportingMpids,
            date: DATE_FILTER.ALL,
          },
        },
      });
    } else {
      porCacheDispatch({
        type: "RESET_CACHE",
      });
      rejectCountDispatch({
        type: "RESET_CACHE",
      });
    }
    return () => {
      porCacheDispatch({
        type: "RESET_CACHE",
      });
      rejectCountDispatch({
        type: "RESET_CACHE",
      });
      tableDispatch({
        type: "DESELECT_ALL_ROWS",
        payload: { table: StandardTables.PV_REJECTS_LITE },
      });
    };
  }, [tableDispatch, porCacheDispatch, rejectCountDispatch, tradeReportingMpids]);

  useEffect(
    () => () =>
      porCacheDispatch({
        type: "STOP_POLLING",
      }),
    [porCacheDispatch]
  );

  useEffect(() => {
    const porMap = Object.values(porCacheState.data).reduce((acc, curr) => {
      if (!curr[PORModel.SUBSCRIBED]) {
        if (acc[curr[PORModel.MPID]] === undefined) {
          acc[curr[PORModel.MPID]] = {
            [curr[PORModel.SYMBOL]]: curr,
          };
        } else if (
          acc[curr[PORModel.MPID]][curr[PORModel.SYMBOL]] === undefined ||
          PorStatusValue[curr[PORModel.REQUEST_STATUS]] >
            PorStatusValue[acc[curr[PORModel.MPID]][curr[PORModel.SYMBOL]][PORModel.REQUEST_STATUS]]
        ) {
          acc[curr[PORModel.MPID]][curr[PORModel.SYMBOL]] = curr;
        }
      }
      return acc;
    }, {} as { [mpid: string]: { [symbol: string]: POR } });
    const tableData = Object.entries(rejectCountState.counts).reduce(
      (acc, [mpid, symbolCounts]) => {
        Object.entries(symbolCounts).forEach(([symbol, count]) => {
          acc.push({
            [PORModel.MPID]: mpid,
            [PORModel.SYMBOL]: symbol,
            [PORModel.REQUEST_STATUS]: porMap[mpid]?.[symbol]?.[PORModel.REQUEST_STATUS],
            [PORModel.REQUESTOR_EMAIL]: porMap[mpid]?.[symbol]?.[PORModel.REQUESTOR_EMAIL],
            [PORModel.ID]: porMap[mpid]?.[symbol]?.[PORModel.ID],
            [PORModel.LAST_UDPATE]: porMap[mpid]?.[symbol]?.[PORModel.LAST_UDPATE],
          });
        });
        return acc;
      },
      [] as {
        [PORModel.MPID]: string;
        [PORModel.SYMBOL]: string;
        [PORModel.REQUEST_STATUS]: string;
        [PORModel.REQUESTOR_EMAIL]: string;
        [PORModel.ID]: string;
        [PORModel.LAST_UDPATE]: string;
      }[]
    );
    tableDispatch([
      {
        type: "SET_TABLE_DATA",
        payload: {
          table: StandardTables.PV_REJECTS_LITE,
          data: tableData,
        },
      },
      {
        type: "SET_IS_LOADING",
        payload: { table: StandardTables.PV_REJECTS_LITE, isLoading: porCacheState.isLoading },
      },
    ]);
  }, [
    porCacheState.data,
    porCacheState.isLoading,
    rejectCountState.data,
    rejectCountState.counts,
    tableDispatch,
  ]);

  const PorButttonConfirm = useCallback(() => {
    const selected = getSelectedRows(tableData[StandardTables.PV_REJECTS_LITE]) as POR[];
    const uniqueSelectedPors = selected.reduce((acc, curr) => {
      acc.add(curr[PORModel.SYMBOL]);
      return acc;
    }, new Set<string>());

    return <PorButtonHeader count={uniqueSelectedPors.size} />;
  }, [tableData]);

  const createPORs = useCallback(() => {
    const selected = getSelectedRows(tableData[StandardTables.PV_REJECTS_LITE]) as POR[];
    const porData: { symbol: string; mpid: string }[] = selected.map(por => ({
      symbol: por[PORModel.SYMBOL],
      mpid: por[PORModel.MPID],
    }));

    const createPorSuccess = (data: WorkXResponse<PorProcessManyDto>) => {
      switch (data.body?.status) {
        case PorProcessManyStatus.SUCCESS: {
          NotificationHub.send("success", getSaveAllSuccessMessage(data.body.successful.length));
          tableDispatch({
            type: "ADD_TABLE_DATA_FIRST",
            payload: { table: StandardTables.PV_REQUESTS, data: data.body.successful },
          });
          break;
        }
        case PorProcessManyStatus.FAILED: {
          NotificationHub.send("danger", getSaveAllFailedMessage(data.body.failed.length), {
            subtitle: getSaveAllErrorSubtitle(data.errorMessages),
          });
          break;
        }
        case PorProcessManyStatus.PARTIAL: {
          NotificationHub.send(
            "warning",
            `${getSaveAllSuccessMessage(data.body.successful.length)} ${getSaveAllFailedMessage(
              data.body.failed.length
            )}`,
            {
              subtitle: getSaveAllErrorSubtitle(data.errorMessages),
            }
          );
          tableDispatch({
            type: "ADD_TABLE_DATA_FIRST",
            payload: { table: StandardTables.PV_REQUESTS, data: data.body.successful },
          });
          break;
        }
        default: {
          NotificationHub.send("danger", "Response for creating POR(s) is not recognized");
          break;
        }
      }
    };

    const createPorError = (err: PorServiceErrorResponse) => {
      NotificationHub.send("danger", "Error submitting POR(s)", { subtitle: err.message });
    };

    doFetchWrapper(
      formatUrl(process.env.REACT_APP_URL_PVR_POR_SERVICE, "por/save-all"),
      {
        method: "post",
        headers: getHeaders(),
        body: JSON.stringify(porData),
      },
      createPorSuccess,
      createPorError
    );
  }, [tableData, tableDispatch]);

  const LiteButtons = useMemo(() => {
    if (userData.allowed.actions[ViewActions.PVR_CLIENT_MONITOR_POR]) {
      const tableButtons = [
        {
          icon: "lock-open",
          text: TableButtonLabel.POR,
          actionId: TableButtonAction.POR,
          canConfirm: true,
          header: PorButttonConfirm,
          allowConfirm: true,
          requireSelect: true,
          allowMultiSelect: true,
          onClick: createPORs,
          confirmText: "YES",
        },
      ];
      return [<TableButtons table={StandardTables.PV_REJECTS_LITE} buttons={tableButtons} />];
    }
    return undefined;
  }, [PorButttonConfirm, createPORs, userData.allowed.actions]);

  const RejectLiteHeader = useCallback(
    () => (
      <StandardHeader
        title={
          <ServiceIndicatorWrapper>
            Price Override Rejects By Symbol
            <LimoLVCIndicator
              prefix={"Reject"}
              id={"reject-indicator"}
              status={rejectCountState.status}
            />
            <LimoLVCIndicator prefix={"POR"} id={"por-indicator"} status={porCacheState.status} />
          </ServiceIndicatorWrapper>
        }
      />
    ),
    [porCacheState.status, rejectCountState.status]
  );

  return (
    <Middle>
      <TimerWrapper>
        <PorWindowTimer marginTopPx={0} />
      </TimerWrapper>
      <StandardTable
        header={RejectLiteHeader}
        table={StandardTables.PV_REJECTS_LITE}
        enableLayoutExport={true}
        isSingleSelect={false}
        isColumnsVirtualized={false}
        isFilterable={true}
        subHeader={undefined}
        hideRowCount={false}
        hideSelectedCount={false}
        hideQueryDate={undefined}
        headerMenu={LiteButtons}
        exportCallback={undefined}
        exportFileName={undefined}
        additionalRowClick={undefined}
        isEditableTableEnabled={undefined}
        style={tableHeight}
        isRowSelectEnabled={undefined}
        Header={undefined}
      />
    </Middle>
  );
};
