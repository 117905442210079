import { Header, NotificationHub } from "@nef/core";
import { FORM_KEY, Forms } from "components/fields";
import { useFormContext, useFormDispatch } from "components/form";
import {
  FinraOffsetKeyPrefix,
  getOffsetKeyForMpidAndSymbol,
  useRejectCacheState,
} from "components/pvRejects/cache/rejectCache";
import {
  getSaveAllErrorSubtitle,
  POR,
  PorProcessManyDto,
  PorProcessManyStatus,
} from "components/pvRejects/constant";
import { POR_BYPASS_REJECT_AUDIT_FIELD } from "components/settings/pvr/fields";
import {
  getColumnHeadersAndAccessors,
  getSelectedRows,
  useStandardTableContext,
  useStandardTableDispatch,
} from "components/standardTable";
import TableButtons from "components/standardTable/tableButtons";
import { convertToCSV, createAndDownloadTemplate } from "components/standardTable/utils";
import { useUserContext } from "components/user";
import { getHeaders } from "keycloak";
import { doFetchWrapper } from "network";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { formatUrl } from "utils/js.utils";
import { ViewActions } from "viewConstants";
import { StandardTables } from "wksConstants";
import { TableButtonAction, TableButtonLabel } from "wksConstantsTS";
import { WorkXResponse } from "wksConstantsTS";

type PORAction = TableButtonAction.PVR_APPROVE | TableButtonAction.PVR_DENY;
type PORActionLabel = TableButtonLabel.APPROVE | TableButtonLabel.DENY;

enum StatusChangeLabel {
  APPROVED = "Approved",
  DENIED = "Denied",
}

const StatusChangeLabelMap = {
  [TableButtonAction.PVR_APPROVE]: StatusChangeLabel.APPROVED,
  [TableButtonAction.PVR_DENY]: StatusChangeLabel.DENIED,
} as const;

const changeStatusSuccessMessage = (
  newStatus: StatusChangeLabel,
  response: WorkXResponse<PorProcessManyDto>
) => `Status has been updated to ${newStatus} for ${response.body?.successful?.length} POR(s).`;

const changeStatusFailedMessage = (newStatus: string, response: WorkXResponse<PorProcessManyDto>) =>
  `Failed to update status to ${newStatus} for ${response.body?.failed?.length} POR(s).`;

const ActionToForm = {
  [TableButtonAction.PVR_APPROVE]: FORM_KEY.POR_APPROVE,
  [TableButtonAction.PVR_DENY]: FORM_KEY.POR_DENY,
};

export const PorTableButtons = () => {
  const [tableData] = useStandardTableContext();
  const tableDispatch = useStandardTableDispatch();
  const [userData] = useUserContext();
  const rejectData = useRejectCacheState();
  const formDispatch = useFormDispatch();
  const [formData] = useFormContext();
  const isRejectDataLoading = useRef(true);
  const [isActionLoading, setActionLoading] = useState(false);

  const rows: POR[] = useMemo(() => {
    return getSelectedRows(tableData[StandardTables.PV_SUPERVISOR_MONITOR2]);
  }, [tableData]);

  const exportRejectsToFile = useCallback(
    (action: string) => {
      const { accessors, headers } = getColumnHeadersAndAccessors(
        StandardTables.PV_SUPERVISOR_MONITOR,
        tableData,
        true
      );

      createAndDownloadTemplate(
        `${action}_ACTION_REJECTS.csv`,
        convertToCSV(headers, tableData[StandardTables.PV_SUPERVISOR_MONITOR].allTheData, accessors)
      ).then(() => console.info(`Rejects have been exported for ${action}`));
    },
    [tableData]
  );

  const handleSendPorAction = useCallback(
    (status: PORAction) => {
      const bypassAuditTrailCheck =
        formData[ActionToForm[status]].fields[POR_BYPASS_REJECT_AUDIT_FIELD] ?? false;
      const body = rows.map(row => ({
        id: row.id,
        statusChangeEvent: status,
        bypassAuditTrailCheck,
        endIndexOutOfRange:
          rejectData.offsets[
            getOffsetKeyForMpidAndSymbol(row.mpid, row.symbol, FinraOffsetKeyPrefix.RANGE)
          ] ?? 0,
        endIndexOutOfOverrideRange:
          rejectData.offsets[
            getOffsetKeyForMpidAndSymbol(row.mpid, row.symbol, FinraOffsetKeyPrefix.OVERRIDE)
          ] ?? 0,
      }));

      const actionPorSuccess = (response: WorkXResponse<PorProcessManyDto>) => {
        tableDispatch({
          type: "DESELECT_ALL_ROWS",
          payload: { table: StandardTables.PV_SUPERVISOR_MONITOR2 },
        });
        switch (response.body?.status) {
          case PorProcessManyStatus.SUCCESS:
            NotificationHub.send(
              "success",
              changeStatusSuccessMessage(StatusChangeLabelMap[status], response)
            );
            break;
          case PorProcessManyStatus.PARTIAL:
            NotificationHub.send(
              "warning",
              `${changeStatusSuccessMessage(
                StatusChangeLabelMap[status],
                response
              )} ${changeStatusFailedMessage(StatusChangeLabelMap[status], response)}`,
              { subtitle: getSaveAllErrorSubtitle(response.errorMessages) }
            );
            break;
          case PorProcessManyStatus.FAILED:
            NotificationHub.send(
              "danger",
              changeStatusFailedMessage(StatusChangeLabelMap[status], response),
              {
                subtitle: getSaveAllErrorSubtitle(response.errorMessages),
              }
            );
            break;
          default:
            break;
        }
      };

      const actionPorError = () => {
        NotificationHub.send("danger", `Error updating POR status to ${status}`);
      };

      if (bypassAuditTrailCheck) {
        exportRejectsToFile(status);
      }

      doFetchWrapper(
        formatUrl(process.env.REACT_APP_URL_PVR_POR_SERVICE, "por/changeStatuses"),
        {
          method: "put",
          headers: getHeaders(),
          body: JSON.stringify(body),
        },
        actionPorSuccess,
        actionPorError
      );
    },
    [exportRejectsToFile, formData, rejectData.offsets, rows, tableDispatch]
  );
  const handleSendPorActionRef = useRef(handleSendPorAction);

  useEffect(() => {
    handleSendPorActionRef.current = handleSendPorAction;
    isRejectDataLoading.current = rejectData.isLoading;
  }, [handleSendPorAction, rejectData.isLoading]);

  const handlePorAction = useCallback(
    (status: PORAction) => () => {
      try {
        setActionLoading(true);
        if (isRejectDataLoading.current) {
          setTimeout(() => {
            handlePorAction(status)();
          }, 500);
        } else {
          handleSendPorActionRef.current(status);
        }
      } finally {
        setActionLoading(false);
      }
    },
    []
  );

  const ActionHeader = useCallback(
    (action: PORActionLabel) => () => {
      return (
        <Header size={3}>
          Are you sure you want to {action} {rows.length} POR(s)?
        </Header>
      );
    },
    [rows]
  );

  const resetFormOnClose = useCallback(
    (form: FORM_KEY) => () => {
      formDispatch({
        type: "RESET_FORM",
        payload: { form: Forms[form] },
      });
    },
    [formDispatch]
  );

  const tableButtons = useMemo(() => {
    if (userData.allowed.actions[ViewActions.FINRA_PRO]) {
      return [
        {
          icon: "check",
          text: TableButtonLabel.APPROVE,
          actionId: TableButtonAction.PVR_APPROVE,
          requireSelect: true,
          allowMultiSelect: true,
          onClick: handlePorAction(TableButtonAction.PVR_APPROVE),
          allowConfirm: true,
          canConfirm: true,
          loading: isActionLoading,
          header: ActionHeader(TableButtonLabel.APPROVE),
          onCloseConfirm: resetFormOnClose(FORM_KEY.POR_APPROVE),
        },
        {
          icon: "times",
          text: TableButtonLabel.DENY,
          actionId: TableButtonAction.PVR_DENY,
          requireSelect: true,
          allowMultiSelect: true,
          onClick: handlePorAction(TableButtonAction.PVR_DENY),
          allowConfirm: true,
          canConfirm: true,
          loading: isActionLoading,
          header: ActionHeader(TableButtonLabel.DENY),
          onCloseConfirm: resetFormOnClose(FORM_KEY.POR_DENY),
        },
      ];
    }
    return [];
  }, [ActionHeader, handlePorAction, isActionLoading, resetFormOnClose, userData.allowed.actions]);

  return <TableButtons table={StandardTables.PV_SUPERVISOR_MONITOR2} buttons={tableButtons} />;
};
