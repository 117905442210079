import { NotificationHub } from "@nef/core";
import { Forms } from "components/fields";
import { useFormDispatch } from "components/form";
import {
  getSelectedRows,
  TableButtons,
  useStandardTableContext,
  useStandardTableDispatch,
} from "components/standardTable";
import { getHeaders } from "keycloak";
import { doFetchWrapper } from "network";
import React, { useCallback, useMemo, useState } from "react";
import { formatUrl } from "utils/js.utils";
import { StandardTables } from "wksConstants";
import { TableButtonAction, TableButtonLabel } from "wksConstantsTS";
import { Banner, BANNER_FORM } from "./constant";
import { useBannerDispatch } from "./context";
import { BannerModal } from "./modal";

export const BannerTableButtons: React.FC = () => {
  const [tableData] = useStandardTableContext();
  const tableDispatch = useStandardTableDispatch();
  const formDispatch = useFormDispatch();
  const bannerDispatch = useBannerDispatch();
  const [{ isModalVisible, selectedBanner }, setBannerModal] = useState<{
    isModalVisible: boolean;
    selectedBanner: Banner | null;
  }>({
    isModalVisible: false,
    selectedBanner: null,
  });

  const handleClickNew = useCallback(() => {
    setBannerModal({ isModalVisible: true, selectedBanner: null });
  }, []);

  const handleClickEdit = useCallback(() => {
    const selected = getSelectedRows(tableData[StandardTables.SETTINGS_BANNER_MANAGER]);
    setBannerModal({
      isModalVisible: true,
      selectedBanner: selected[0] as Banner,
    });
  }, [tableData]);

  const handleClickDelete = useCallback(() => {
    const selected = getSelectedRows(tableData[StandardTables.SETTINGS_BANNER_MANAGER]);
    const onSuccess = () => {
      NotificationHub.send("success", "Deleted banner(s)");
      tableDispatch({
        type: "DESELECT_ALL_ROWS",
        payload: { table: StandardTables.SETTINGS_BANNER_MANAGER },
      });
      bannerDispatch({
        type: "START_REFRESH",
      });
    };
    const onError = () => {
      NotificationHub.send("danger", "Error deleting banner(s)");
    };
    doFetchWrapper(
      formatUrl(process.env.REACT_APP_URL_USER_WS, "banner"),
      {
        method: "delete",
        headers: getHeaders(),
        body: JSON.stringify(selected.map(row => row[BANNER_FORM.ID])),
      },
      onSuccess,
      onError
    );
  }, [bannerDispatch, tableData, tableDispatch]);

  const handleClickPreview = useCallback(() => {
    const selected = getSelectedRows(tableData[StandardTables.SETTINGS_BANNER_MANAGER]);
    bannerDispatch({
      type: "PREVIEW_BANNERS",
      payload: selected as Banner[],
    });
  }, [tableData, bannerDispatch]);

  const handleCloseModal = useCallback(() => {
    formDispatch({ type: "RESET_FORM", payload: { form: Forms.SETTINGS_BANNER } });
    setBannerModal({ isModalVisible: false, selectedBanner: null });
  }, [formDispatch]);

  const tableButtons = useMemo(() => {
    return [
      {
        icon: "eye",
        text: TableButtonLabel.PREVIEW,
        actionId: TableButtonAction.BANNER_ANNOUNCEMENT_PREVIEW,
        requireSelect: true,
        allowMultiSelect: true,
        onClick: handleClickPreview,
      },
      {
        icon: "minus",
        text: TableButtonLabel.DELETE,
        actionId: TableButtonAction.BANNER_ANNOUNCEMENT_DELETE,
        allowConfirm: true,
        canConfirm: true,
        requireSelect: true,
        allowMultiSelect: true,
        onClick: handleClickDelete,
      },
      {
        icon: "edit",
        text: TableButtonLabel.EDIT,
        actionId: TableButtonAction.BANNER_ANNOUNCEMENT_EDIT,
        requireSelect: true,
        allowMultiSelect: false,
        onClick: handleClickEdit,
      },
      {
        icon: "plus",
        text: TableButtonLabel.NEW,
        actionId: TableButtonAction.BANNER_ANNOUNCEMENT_NEW,
        requireSelect: false,
        allowMultiSelect: true,
        onClick: handleClickNew,
      },
    ];
  }, [handleClickDelete, handleClickEdit, handleClickNew, handleClickPreview]);

  return (
    <>
      <TableButtons table={StandardTables.SETTINGS_BANNER_MANAGER} buttons={tableButtons} />
      <BannerModal
        isVisible={isModalVisible}
        selectedBanner={selectedBanner}
        onClose={handleCloseModal}
      />
    </>
  );
};
