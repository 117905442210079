import { NotificationHub, Button } from "@nef/core";
import { useViewModelContext } from "components/context";
import { Forms } from "components/fields/fieldConstants";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useStandardTableContext } from "components/standardTable";
import { useUserContext } from "components/user";
import { ViewTable } from "viewConstants";
import { LeftView, ReportScanMiddle } from ".";
import { Views } from "../../viewConstants";
import { StandardTables } from "../../wksConstants";
import { network } from "./network";
import { USER_MPIDS_ATTRIBUTE } from "components/user/mpidContext";

const ReportScan = () => {
  const [standardTableData, dispatch] = useStandardTableContext();
  const [user] = useUserContext();
  const [viewData] = useViewModelContext();
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const table = useMemo(() => {
    return ViewTable[viewData.view];
  }, [viewData.view]);
  const successCallback = useCallback(
    jsonData => {
      const dispatchs = [
        {
          type: "SET_TABLE_DATA",
          payload: { table: Forms.TR_REPORT.key, data: jsonData },
        },
        {
          type: "SET_NOT_LOADING",
          payload: { table: table },
        },
        { type: "SET_LAST_QUERY_DATE", payload: { table: StandardTables.TR_REPORT } },
      ];
      dispatch(dispatchs);
    },
    [dispatch, table]
  );

  const errorCallback = useCallback(() => {
    const dispatchs = [
      {
        type: "SET_NOT_LOADING",
        payload: { table: table },
      },
    ];

    dispatch(dispatchs);

    NotificationHub.send("danger", "Your reported trades did not load", {
      subtitle: "Please try again later",
    });
  }, [dispatch, table]);

  const didSomeOneAsk = standardTableData[Forms.TR_REPORT.key].someOneAskedThatWeRefresh;

  useEffect(() => {
    if (didSomeOneAsk) {
      setShouldRefresh(true);
    }
  }, [didSomeOneAsk]);

  useEffect(() => {
    if (table === StandardTables.TR_REPORT && user.username && user.allowed.views[Views.REPORT]) {
      setShouldRefresh(true);
    }
  }, [table, user.allowed.views, user.username]);

  useEffect(() => {
    if (shouldRefresh) {
      if (table === StandardTables.TR_REPORT && user.username && user.allowed.views[Views.REPORT]) {
        const dispatchs = [
          {
            type: "SET_LOADING",
            payload: { table: table, isLoading: true },
          },
          {
            type: "SET_SOMEONE_ASKED_THAT_WE_REFRESH",
            payload: { ask: false, table: table },
          },
        ];

        dispatch(dispatchs);
        network(
          user.mpidAttributes[USER_MPIDS_ATTRIBUTE.WORKX_TRADE_REPORTING_MPIDS]
        ).firstReportRender(
          {
            trfVals: ["1", "2"],
            mpidvals: user.mpidAttributes[USER_MPIDS_ATTRIBUTE.WORKX_TRADE_REPORTING_MPIDS],
            portUser: user.username,
            fromUpdateDate: `${dayjs().format("YYYY-MM-DD")}T00:00:00.000-05:00`,
            toUpdateDate: `${dayjs().format("YYYY-MM-DD")}T23:59:59.998-05:00`,
          },
          successCallback,
          errorCallback
        );
      }

      setShouldRefresh(false);
    }
  }, [
    table,
    dispatch,
    errorCallback,
    successCallback,
    user.username,
    shouldRefresh,
    user.allowed.views,
    user.mpidAttributes,
  ]);

  const toggles = [];
  if ([Forms.TR_REPORT, Forms.TR_SCAN, Forms.TR_REJECTS].includes(viewData.form)) {
    if (user.allowed.views[Views.REPORT]) {
      toggles.push(
        <Button value={Views.REPORT} color="primary" outline size="sm">
          Report
        </Button>
      );
    }

    toggles.push(
      <Button value={Views.SCAN} color="primary" outline size="sm">
        Scan
      </Button>
    );
    toggles.push(
      <Button value={Views.REJECTS} color="primary" outline size="sm">
        Rejects
      </Button>
    );
  }
  return (
    <>
      {viewData.form && <LeftView view={viewData.view} form={viewData.form} toggles={toggles} />}
      <ReportScanMiddle />
    </>
  );
};
export default ReportScan;
