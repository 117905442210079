import { getHeaders } from "keycloak";
import { doFetchWrapper } from "network";
import { convertDataToAPI, convertSampleAPIData } from "components/standardTable";
import { formatUrl } from "../../utils/js.utils";

export const network = () => ({
  uploadFile: async (formData, callback, errorCallback) => {
    await doFetchWrapper(
      formatUrl(process.env.REACT_APP_URL_ACT_WS, "uploader/upload"),
      {
        method: "post",
        mode: "cors",
        headers: (function () {
          const headers = getHeaders();
          delete headers["Content-Type"];
          return headers;
        })(),
        body: formData,
      },

      callback,
      json => errorCallback(json /*response.status*/)
    );
  },
  getUploads: async (callback, errorCallback) => {
    await doFetchWrapper(
      formatUrl(process.env.REACT_APP_URL_ACT_WS, "uploader/getuploads"),
      {
        method: "post",
        mode: "cors",
        headers: getHeaders(),
      },
      callback,
      errorCallback
    );
  },

  getDetails: async (uploadId, callback, errorCallback) => {
    await doFetchWrapper(
      formatUrl(process.env.REACT_APP_URL_ACT_WS, "uploader/records"),
      {
        method: "post",
        mode: "cors",
        headers: getHeaders(),
        body: JSON.stringify(uploadId),
      },

      callback,
      errorCallback
    );
  },

  getDocsList: async (callback, errorCallback) => {
    await doFetchWrapper(
      formatUrl(process.env.REACT_APP_URL_ACT_WS, "uploader/getdocslist"),
      {
        method: "get",
        mode: "cors",
        headers: getHeaders(),
      },

      json => {
        const data = convertSampleAPIData([json]);
        callback(data[0]);
      },
      errorCallback
    );
  },

  getRejectedTrade: async (uploadDetail, callback, errorCallback) => {
    const { uploadDetailId } = uploadDetail;
    const wksId = uploadDetail.key || uploadDetail.workstationId || uploadDetail.workstationID;
    await doFetchWrapper(
      formatUrl(
        process.env.REACT_APP_URL_ACT_WS,
        `uploader/getrejectedtrade?workstationId=${encodeURIComponent(wksId)}`
      ),
      {
        method: "post",
        mode: "cors",
        headers: getHeaders(),
      },

      json => {
        const data = convertSampleAPIData([json]);
        callback(data[0], uploadDetailId);
      },
      e => {
        errorCallback(e, wksId);
      }
    );
  },

  doRepair: async (r, uploadDetailId, callback, errorCallback) => {
    const id = r[0].id;
    await doFetchWrapper(
      formatUrl(process.env.REACT_APP_URL_ACT_WS, "uploader/repairTrade"),
      {
        method: "post",
        mode: "cors",
        headers: getHeaders(),
        body: JSON.stringify({ ...convertDataToAPI(r)[0], uploadDetailId }),
      },

      json => {
        callback(json, id);
      },
      json => {
        errorCallback(json, id);
      }
    );
  },

  getAllRejectsForExoprt: async (filename, uploadId, callback, errorCallback) => {
    await doFetchWrapper(
      formatUrl(
        process.env.REACT_APP_URL_ACT_WS,
        `uploader/getallrejectsforexport?uploadId=${uploadId}`
      ),
      {
        method: "post",
        mode: "cors",
        headers: getHeaders(),
        body: JSON.stringify(uploadId),
      },

      json => {
        callback(filename, json);
      },
      errorCallback
    );
  },
});
