import {
  DatePicker,
  FormDropdown,
  FormField,
  FormRadioCheckboxButton,
  FormRadioCheckboxGroup,
  FormSelect,
  FormTimeField,
} from "@nef/core";
import { Forms, MATCH_FIELD_PREFIX, ScanDateType } from "components/fields/fieldConstants";
import WorkXNumber from "components/fields/workXNumber";
import WorkXText from "components/fields/workXText";
import _ from "lodash";
import {
  execOrReturn,
  addPrefixToField,
  formatNumberToString,
  formatNumberToCurrencyString,
} from "utils/js.utils";
import { KeycloakRoles } from "../user";
import {
  ExportLimitTypes,
  FieldNames,
  PriceTradeDigit,
  SelectOptions,
  OptionConstants,
  clearReportRiskOptions,
  symbolOrCUSIP,
  SettlementValues,
  SettlementOptions,
} from "./fieldConstants";
import { comparators } from "./fields.comparators";
import {
  EqrcFields,
  EQRC_RULE_STATUS,
  EQRC_SHARES_LOCATED_OPTIONS,
  EQRC_SHARES_LOCATED_VALUES,
  OPPOSITE_ROW,
} from "components/settings/eqrc/constants";
import {
  PTRA_FIELD_PREFIX,
  PTRA_CONFIGURATION_ENTITY,
  PTRA_ALERT_TYPE,
  PTR_ALERT_TYPE_LABEL,
  PTRA_AGU_ENTITY,
} from "../settings/ptra/fields";
import { PTRA_EMAIL_FIELD_PREFIX, PTRA_EMAIL_ENTITY } from "../settings/ptra/email/fields";
import { EQRC_FIELD_PREFIX } from "../settings/eqrc/constants";
import WorkXSelectPersistCreatable from "components/fields/workXSelectPersistCreatable/workXSelectPersistCreatable";
import { ALL_FORMS, AUGMENT_ON_CHANGE, CONTROLLED_INPUT_TYPES } from "./constants";
import { DAY_TYPES } from "components/settings";
import { RASH_FIELD_PREFIX, RASH_ORDER } from "components/orderEntry/constants";
import { PV_REJECT_FIELD_PREFIX } from "components/pvRejects/constant";
import { PV_REJECT_TOP } from "components/pvRejects/client/constant";
import { WorkXDropdown2 } from ".";
import {
  POR_BYPASS_REJECT_AUDIT_FIELD,
  PVR_ALERT_TYPE,
  PVR_ALERT_TYPE_LABEL,
  PVR_CONFIGURATION_ENTITY,
  PVR_FIELD_PREFIX,
} from "components/settings/pvr/fields";
import { PVR_EMAIL_ENTITY, PVR_EMAIL_FIELD_PREFIX } from "components/settings/pvr";
import { BANNER_FIELD_PREFIX, BANNER_FORM } from "components/settings/banner/constant";
import { POR_AUDIT_FIELD_PREFIX } from "components/settings/pvr/audit/constant";

const NOT_APPLICABLE = "N/A";

const priceValidationConfirmMessage = ({ formState, currentForm }) => {
  const formValues = formState[currentForm.key].fields;
  return formValues[FieldNames.priceTradeDigit] === PriceTradeDigit.CONTRACT
    ? `Contract Amount entered: ${formatNumberToCurrencyString(
        parseFloat(formValues[FieldNames.contract])
      )} - Bypassing Price Validation (Price Override)`
    : `Price entered: ${formatNumberToCurrencyString(
        parseFloat(formValues[FieldNames.price]),
        NOT_APPLICABLE
      )} - Bypassing Price Validation (Price Override)`;
};

const width114 = { width: "114px" };
const width100 = { width: "100%" };
const fullWidthCheckboxStyle = { ...width100, marginBottom: "1rem" };
const inactiveHelpText = limitType => `${limitType} Limit Inactive`;
const inverseCharacterCaseHelpText = "Hold shift for lower case";
const ptraMpidHelpText = "An alert is created for each MPID and user/email combination";

const inverseSymbolForms = [
  Forms.TR_REPORT.key,
  Forms.TR_SCAN.key,
  Forms.TR_REJECTS.key,
  Forms.QUERY.key,
  Forms.TR_REPAIR.key,
  Forms.UPLOAD_REPAIR.key,
  Forms.TR_MODIFY.key,
  Forms.TR_COPY.key,
  Forms.ST_MODIFY.key,
  Forms.ST_REPAIR.key,
  Forms.ST_COPY.key,
  Forms.RD_CUSIP.key,
];

const feeAugmentOnChange = {
  [Forms.TR_REPORT.key]: AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,
  [Forms.TR_REPAIR.key]: AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,
  [Forms.TR_COPY.key]: AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,
  [Forms.TR_MODIFY.key]: AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,
  [Forms.TR_MATCH.key]: AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,

  [Forms.UPLOAD_REPAIR.key]: AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,

  [Forms.ST_REPAIR.key]: AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,
  [Forms.ST_COPY.key]: AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,
  [Forms.ST_MODIFY.key]: AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,
  [Forms.ST_MATCH.key]: AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,
};

const priceAndFeeValidationOnForms = {
  [Forms.TR_REPORT.key]: true,
  [Forms.TR_REPAIR.key]: true,
  [Forms.TR_COPY.key]: true,
  [Forms.TR_MODIFY.key]: true,
  [Forms.UPLOAD_REPAIR.key]: true,
  [Forms.TR_MATCH.key]: true,
  [Forms.ST_MATCH.key]: true,
  [Forms.ST_REPAIR.key]: true,
  [Forms.ST_COPY.key]: true,
  [Forms.ST_MODIFY.key]: true,
};

const EDIT_ALERT_PLACEHOLDER = "No change will be made without selection";
const EDIT_ALERT_PLACEHOLDER_FOR_CHANGES_MADE =
  "All items in this list will be deleted if it is empty";
const EDIT_ALERT_PLACEHOLDER_FOR_LISTS = "This list will be emptied if no items are present";

const PTRA_WORKX_USER_FIELD_HELPTEXT = "Only applied to logged in WorkX user configuration(s)";
const PTRA_SEND_ALERT_TO_USER_EMAIL_LABEL = "Send Alert to WorkX User's Email";
const PTRA_POPUPS_LABEL = "Pop-ups";
const EQRC_PORT_AND_EXCHANGE_ERROR_TEXT = "Port and Group ID cannot be set together";

const MAX_DECIMAL = 900719925474.0991;
export const JAVA_8_BIT_INT = 32767;

const getEqrcRangeAmt = num => {
  const fieldNameMap = {
    1: EqrcFields.rangeOneAmt,
    2: EqrcFields.rangeTwoAmt,
    3: EqrcFields.rangeThreeAmt,
    4: EqrcFields.rangeFourAmt,
    5: EqrcFields.rangeFiveAmt,
    6: EqrcFields.rangeSixAmt,
  };
  return ({ form }) => {
    return {
      Type: FormField,
      TypeName: "FormField",
      props: {
        label: `Range ${num} Amount`,
        placeholder: form === Forms.EQRC_EDIT_FAT_FINGER ? EDIT_ALERT_PLACEHOLDER : undefined,
        name: fieldNameMap[num],
        id: addPrefixToField(EQRC_FIELD_PREFIX, fieldNameMap[num]),
        fieldType: CONTROLLED_INPUT_TYPES.FOUR_DECIMAL_PLACES,
        style: { gridColumn: "span 5" },
        allowNegative: false,
        allowDecimal: true,
        maxLength: 9,
        validation: {
          required: {
            onForms: {
              [Forms.EQRC_FAT_FINGER.key]: true,
              [Forms.EQRC_EDIT_FAT_FINGER.key]: false,
            },
          },
          greaterThan: {
            onForms: {
              [Forms.EQRC_FAT_FINGER.key]: true,
              [Forms.EQRC_EDIT_FAT_FINGER.key]: true,
            },
            orEqualToValue: true,
            valueToCheck: 0,
          },
          lessThan: {
            onForms: {
              [Forms.EQRC_FAT_FINGER.key]: true,
              [Forms.EQRC_EDIT_FAT_FINGER.key]: true,
            },
            orEqualToValue: true,
            valueToCheck: 199999.99,
          },
        },
      },
    };
  };
};

const getEqrcRangePct = num => {
  const fieldNameMap = {
    1: EqrcFields.rangeOnePct,
    2: EqrcFields.rangeTwoPct,
    3: EqrcFields.rangeThreePct,
    4: EqrcFields.rangeFourPct,
    5: EqrcFields.rangeFivePct,
    6: EqrcFields.rangeSixPct,
  };
  return ({ form }) => ({
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: `Range ${num} %`,
      placeholder: form === Forms.EQRC_EDIT_FAT_FINGER ? EDIT_ALERT_PLACEHOLDER : undefined,
      name: fieldNameMap[num],
      id: addPrefixToField(EQRC_FIELD_PREFIX, fieldNameMap[num]),
      style: { gridColumn: "span 5" },
      allowNegative: false,
      fieldType: "numeric",
      allowDecimal: false,
      maxLength: 3,
      validation: {
        required: {
          onForms: {
            [Forms.EQRC_FAT_FINGER.key]: true,
            [Forms.EQRC_EDIT_FAT_FINGER.key]: false,
          },
        },
        greaterThan: {
          onForms: {
            [Forms.EQRC_FAT_FINGER.key]: true,
            [Forms.EQRC_EDIT_FAT_FINGER.key]: true,
          },
          orEqualToValue: true,
          valueToCheck: 0,
        },
        lessThan: {
          onForms: {
            [Forms.EQRC_FAT_FINGER.key]: true,
            [Forms.EQRC_EDIT_FAT_FINGER.key]: true,
          },
          orEqualToValue: true,
          valueToCheck: 500,
        },
      },
    },
  });
};

const PTRA_FIELD_ID = {
  [PTRA_CONFIGURATION_ENTITY.isAggregateAlertActive]: addPrefixToField(
    PTRA_FIELD_PREFIX,
    PTRA_CONFIGURATION_ENTITY.isAggregateAlertActive
  ),
  [PTRA_CONFIGURATION_ENTITY.isAggregateHoldActive]: addPrefixToField(
    PTRA_FIELD_PREFIX,
    PTRA_CONFIGURATION_ENTITY.isAggregateHoldActive
  ),
  [PTRA_CONFIGURATION_ENTITY.isAggregateKillActive]: addPrefixToField(
    PTRA_FIELD_PREFIX,
    PTRA_CONFIGURATION_ENTITY.isAggregateKillActive
  ),
  [PTRA_CONFIGURATION_ENTITY.isPerTradeAlertActive]: addPrefixToField(
    PTRA_FIELD_PREFIX,
    PTRA_CONFIGURATION_ENTITY.isPerTradeAlertActive
  ),
  [PTRA_CONFIGURATION_ENTITY.isPerTradeHoldActive]: addPrefixToField(
    PTRA_FIELD_PREFIX,
    PTRA_CONFIGURATION_ENTITY.isPerTradeHoldActive
  ),
  [PTRA_CONFIGURATION_ENTITY.isMaxTradeAlertActive]: addPrefixToField(
    PTRA_FIELD_PREFIX,
    PTRA_CONFIGURATION_ENTITY.isMaxTradeAlertActive
  ),
  [PTRA_CONFIGURATION_ENTITY.isKillLimitRejectActive]: addPrefixToField(
    PTRA_FIELD_PREFIX,
    PTRA_CONFIGURATION_ENTITY.isKillLimitRejectActive
  ),
  [PTRA_CONFIGURATION_ENTITY.isHeldTradeActive]: addPrefixToField(
    PTRA_FIELD_PREFIX,
    PTRA_CONFIGURATION_ENTITY.isHeldTradeActive
  ),
  [PTRA_CONFIGURATION_ENTITY.isIntraDayChangeActive]: addPrefixToField(
    PTRA_FIELD_PREFIX,
    PTRA_CONFIGURATION_ENTITY.isIntraDayChangeActive
  ),
  [PTRA_CONFIGURATION_ENTITY.isNextDayChangeActive]: addPrefixToField(
    PTRA_FIELD_PREFIX,
    PTRA_CONFIGURATION_ENTITY.isNextDayChangeActive
  ),
  [PTRA_CONFIGURATION_ENTITY.isEmailRealTimeActive]: addPrefixToField(
    PTRA_FIELD_PREFIX,
    PTRA_CONFIGURATION_ENTITY.isEmailRealTimeActive
  ),
  [PTRA_CONFIGURATION_ENTITY.isEmailEndOfDayActive]: addPrefixToField(
    PTRA_FIELD_PREFIX,
    PTRA_CONFIGURATION_ENTITY.isEmailEndOfDayActive
  ),
  [PTRA_CONFIGURATION_ENTITY.isAlertLogActive]: addPrefixToField(
    PTRA_FIELD_PREFIX,
    PTRA_CONFIGURATION_ENTITY.isAlertLogActive
  ),
  [PTRA_CONFIGURATION_ENTITY.isAlertPopupActive]: addPrefixToField(
    PTRA_FIELD_PREFIX,
    PTRA_CONFIGURATION_ENTITY.isAlertPopupActive
  ),
  [PTRA_CONFIGURATION_ENTITY.isAlertSoundActive]: addPrefixToField(
    PTRA_FIELD_PREFIX,
    PTRA_CONFIGURATION_ENTITY.isAlertSoundActive
  ),
  [PTRA_CONFIGURATION_ENTITY.emailId]: addPrefixToField(
    PTRA_FIELD_PREFIX,
    PTRA_CONFIGURATION_ENTITY.emailId
  ),
  [PTRA_CONFIGURATION_ENTITY.includeWorkXUserRecipient]: addPrefixToField(
    PTRA_FIELD_PREFIX,
    PTRA_CONFIGURATION_ENTITY.includeWorkXUserRecipient
  ),
  [PTRA_CONFIGURATION_ENTITY.isAggSettingActive]: addPrefixToField(
    PTRA_FIELD_PREFIX,
    PTRA_CONFIGURATION_ENTITY.isAggSettingActive
  ),
  [PTRA_CONFIGURATION_ENTITY.isPerTradeSettingActive]: addPrefixToField(
    PTRA_FIELD_PREFIX,
    PTRA_CONFIGURATION_ENTITY.isPerTradeSettingActive
  ),
  [PTRA_CONFIGURATION_ENTITY.isMaxTradeSettingActive]: addPrefixToField(
    PTRA_FIELD_PREFIX,
    PTRA_CONFIGURATION_ENTITY.isMaxTradeSettingActive
  ),
  [PTRA_CONFIGURATION_ENTITY.isDefaultActionSettingActive]: addPrefixToField(
    PTRA_FIELD_PREFIX,
    PTRA_CONFIGURATION_ENTITY.isDefaultActionSettingActive
  ),
};

export const PVR_FIELD_ID = {
  [PVR_CONFIGURATION_ENTITY.isAllMpid]: addPrefixToField(
    PVR_FIELD_PREFIX,
    PVR_CONFIGURATION_ENTITY.isAllMpid
  ),
  [PVR_CONFIGURATION_ENTITY.mpid]: addPrefixToField(
    PVR_FIELD_PREFIX,
    PVR_CONFIGURATION_ENTITY.mpid
  ),
  [PVR_CONFIGURATION_ENTITY.porStatusAlertActive]: addPrefixToField(
    PVR_FIELD_PREFIX,
    PVR_CONFIGURATION_ENTITY.porStatusAlertActive
  ),
  [PVR_CONFIGURATION_ENTITY.pvWindowAlertActive]: addPrefixToField(
    PVR_FIELD_PREFIX,
    PVR_CONFIGURATION_ENTITY.pvWindowAlertActive
  ),
  [PVR_CONFIGURATION_ENTITY.pvResubmitAlertActive]: addPrefixToField(
    PVR_FIELD_PREFIX,
    PVR_CONFIGURATION_ENTITY.pvResubmitAlertActive
  ),
  [PVR_CONFIGURATION_ENTITY.popupActive]: addPrefixToField(
    PVR_FIELD_PREFIX,
    PVR_CONFIGURATION_ENTITY.popupActive
  ),
  [PVR_CONFIGURATION_ENTITY.logActive]: addPrefixToField(
    PVR_FIELD_PREFIX,
    PVR_CONFIGURATION_ENTITY.logActive
  ),
  [PVR_CONFIGURATION_ENTITY.emailActive]: addPrefixToField(
    PVR_FIELD_PREFIX,
    PVR_CONFIGURATION_ENTITY.emailActive
  ),
  [PVR_CONFIGURATION_ENTITY.includeWorkXUserRecipient]: addPrefixToField(
    PVR_FIELD_PREFIX,
    PVR_CONFIGURATION_ENTITY.includeWorkXUserRecipient
  ),
  [PVR_CONFIGURATION_ENTITY.emailId]: addPrefixToField(
    PVR_FIELD_PREFIX,
    PVR_CONFIGURATION_ENTITY.emailId
  ),
};

const ptraConfigOnForms = { [Forms.PTRA_CONFIG.key]: true, [Forms.PTRA_AGU_CONFIG.key]: true };
const allPtraAlertOptionFieldIds = [
  PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isAggregateAlertActive],
  PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isAggregateHoldActive],
  PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isAggregateKillActive],
  PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isPerTradeAlertActive],
  PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isPerTradeHoldActive],
  PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isMaxTradeAlertActive],
  PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isIntraDayChangeActive],
  PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isNextDayChangeActive],
  PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isKillLimitRejectActive],
  PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isHeldTradeActive],
  PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isAggSettingActive],
  PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isPerTradeSettingActive],
  PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isMaxTradeSettingActive],
  PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isDefaultActionSettingActive],
];

const allPtraDeliveryOptionFieldIds = [
  PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isEmailRealTimeActive],
  PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isEmailEndOfDayActive],
  PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isAlertLogActive],
  PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isAlertPopupActive],
  PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isAlertSoundActive],
  PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.emailId],
  PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.includeWorkXUserRecipient],
];

const pvrConfigOnForms = { [Forms.PVR_CONFIG.key]: true };
const allPvrAlertOptionFieldIds = [
  PVR_FIELD_ID[PVR_CONFIGURATION_ENTITY.porStatusAlertActive],
  PVR_FIELD_ID[PVR_CONFIGURATION_ENTITY.pvWindowAlertActive],
  PVR_FIELD_ID[PVR_CONFIGURATION_ENTITY.pvResubmitAlertActive],
];

const allPvrDeliveryOptionFieldIds = [
  // PVR_FIELD_ID[PVR_CONFIGURATION_ENTITY.emailActive],
  PVR_FIELD_ID[PVR_CONFIGURATION_ENTITY.popupActive],
  PVR_FIELD_ID[PVR_CONFIGURATION_ENTITY.logActive],
  PVR_FIELD_ID[PVR_CONFIGURATION_ENTITY.emailId],
  PVR_FIELD_ID[PVR_CONFIGURATION_ENTITY.includeWorkXUserRecipient],
];

const getAllFieldValuesEmptyValidateOther = (currId, allIds) => {
  return allIds.filter(id => id !== currId);
};
const getAllFieldValuesEmptyValidation = (currId, allIds, onForms) => {
  return allIds.reduce(
    (acc, curr) => {
      if (curr !== currId) {
        acc.requiredIfAllFieldValues.otherFields.push({
          field: curr,
          value: comparators.falseNullUndefinedOrEmpty,
        });
      }
      return acc;
    },
    { requiredIfAllFieldValues: { otherFields: [], onForms } }
  );
};

export const getMpid =
  ({ style, name, id, augmentOnChange } = {}) =>
  props => {
    let type = { Type: FormSelect, TypeName: "FormSelect" };
    const selectAllForms = [
      Forms.TR_SCAN.key,
      Forms.TR_REJECTS.key,
      Forms.QUERY.key,
      Forms.CV_TOP.key,
      Forms.PV_MONITOR_MEMBER_TOP.key,
      Forms.PTRA_CONFIG.key,
      Forms.RIGHT_LIMIT.key,
      Forms.SETTINGS_PTR_LIMIT_EXPORT.key,
      Forms.PVR_CONFIG.key,
      Forms.POR_AUDIT.key,
    ];

    const ignoreCountForms = [
      Forms.CV_TOP.key,
      Forms.PV_MONITOR_MEMBER_TOP.key,
      Forms.RIGHT_LIMIT.key,
      Forms.PTRA_CONFIG.key,
      Forms.PVR_CONFIG.key,
      Forms.RIGHT_LIMIT.key,
      Forms.SETTINGS_PTR_LIMIT_EXPORT.key,
    ];

    if (
      selectAllForms.includes(props?.form?.key) &&
      typeof props?.selectAllMpidCount === "number"
    ) {
      let mpidOptions = SelectOptions[FieldNames.mpid];
      if (typeof mpidOptions === "function") {
        mpidOptions = mpidOptions(props?.form.key);
      }
      if (props?.selectAllMpidCount <= mpidOptions?.length) {
        type = { Type: FormDropdown, TypeName: "FormDropdown" };
      }
    }
    if (ignoreCountForms.includes(props.form.key)) {
      type = { Type: FormDropdown, TypeName: "FormDropdown" };
    }

    let helpText;
    switch (props.form) {
      case Forms.PTRA_CONFIG:
        helpText = ptraMpidHelpText;
        break;
      default:
        break;
    }

    return {
      ...type,
      props: {
        isMulti: [
          Forms.TR_SCAN.key,
          Forms.TR_REJECTS.key,
          Forms.QUERY.key,
          Forms.ST_MIDDLE.key,
          Forms.RIGHT_LIMIT.key,
          Forms.PTRA_CONFIG.key,
          Forms.PVR_CONFIG.key,
          Forms.PV_MONITOR_MEMBER_TOP.key,
        ],
        label: [Forms.POR_AUDIT.key].includes(props?.form?.key) ? "MPID" : "My MPID",
        showLabel: ![
          Forms.ST_MIDDLE.key,
          Forms.CV_TOP.key,
          Forms.PV_MONITOR_MEMBER_TOP.key,
          Forms.RIGHT_LIMIT.key,
        ].includes(props?.form?.key),
        placeholder: [
          Forms.ST_MIDDLE.key,
          Forms.CV_TOP.key,
          Forms.PV_MONITOR_MEMBER_TOP.key,
          Forms.RIGHT_LIMIT.key,
        ].includes(props?.form?.key)
          ? "My MPID"
          : undefined,
        id,
        name: name ? name : FieldNames.mpid,
        inputId: id ? id : "mpid",
        options: SelectOptions[FieldNames.mpid],
        style: style ? style : width100,
        required: true,
        helpText,
        validation: {
          required: {
            onForms: {
              [Forms.TR_REPORT.key]: true,
              [Forms.TR_SCAN.key]: false,
              [Forms.TR_REJECTS.key]: true,
              [Forms.TR_REPAIR.key]: true,
              [Forms.TR_COPY.key]: true,
              [Forms.TR_MODIFY.key]: true,
              [Forms.UPLOAD_REPAIR.key]: true,
              [Forms.TR_MATCH.key]: true,
              [Forms.ST_MATCH.key]: true,
              [Forms.ST_MODIFY.key]: true,
              [Forms.ST_REPAIR.key]: true,
              [Forms.ST_COPY.key]: true,
              [Forms.QUERY.key]: true,
              [Forms.PTRA_CONFIG.key]: true,
              // [Forms.PVR_CONFIG.key]: true,
              [Forms.RD_AGU.key]: true,
              [Forms.PTRA_AGU_CONFIG.key]: true,
              [Forms.EQRC_FAT_FINGER.key]: true,
              [Forms.EQRC_RESTRICTED_STOCK_LIST.key]: true,
              [Forms.POR_AUDIT.key]: false,
            },
          },
          requiredIfAnyFieldValue: {
            otherFields: [
              {
                field: FieldNames.giveUpMPID,
                value: comparators.notInMPIDList,
              },
              {
                field: FieldNames.counterGiveUpMPID,
                value: comparators.notInMPIDList,
              },
            ],
            onForms: { [Forms.TR_SCAN.key]: true },
          },
          requiredIfAllFieldValues: {
            otherFields: [
              {
                field: PVR_FIELD_ID[PVR_CONFIGURATION_ENTITY.isAllMpid],
                value: v => {
                  return true !== v;
                },
              },
            ],
            onForms: { [Forms.PVR_CONFIG.key]: true },
          },
          allowedValue: {
            onForms: {
              [Forms.TR_REPORT.key]: true,
              [Forms.TR_SCAN.key]: true,
              [Forms.TR_REJECTS.key]: true,
              [Forms.TR_REPAIR.key]: true,
              [Forms.TR_COPY.key]: true,
              [Forms.TR_MODIFY.key]: true,
              [Forms.TR_MATCH.key]: true,
              [Forms.ST_MATCH.key]: true,
              [Forms.UPLOAD_REPAIR.key]: true,
              [Forms.ST_MODIFY.key]: true,
              [Forms.ST_REPAIR.key]: true,
              [Forms.ST_COPY.key]: true,
              [Forms.QUERY.key]: true,
              [Forms.RD_AGU.key]: true,
            },
            valueList: SelectOptions[FieldNames.mpid](props?.form?.key),
            instant: true,
          },
        },
        disabledWhen: [
          {
            field: PVR_CONFIGURATION_ENTITY.isAllMpid,
            value: true,
            onForms: [Forms.PVR_CONFIG],
          },
        ],
        augmentOnChange,
      },
    };
  };

export const final = {
  [FieldNames.mpid]: getMpid(),
  [FieldNames.scanGiveupWithMyMpid]: {
    Type: FormRadioCheckboxButton,
    TypeName: "FormRadioCheckboxButton",
    props: {
      label: "My MPID with Give Up MPIDs",
      name: FieldNames.scanGiveupWithMyMpid,
      id: FieldNames.scanGiveupWithMyMpid,
      style: width100,
      Type: "checkbox",
      getTooltip: ({ value }) => {
        switch (value) {
          case true:
            return "MyMPID as Executing, Counter Party or Giveup Party";
          case false:
          default:
            return "MyMPID as Executing or Counter Party";
        }
      },
      tooltipPlacement: "right-end",
    },
  },
  [FieldNames.scanAllPartiesWithMyMPID]: {
    Type: FormRadioCheckboxButton,
    TypeName: "FormRadioCheckboxButton",
    props: {
      label: "My MPID as Any Party",
      name: FieldNames.scanAllPartiesWithMyMPID,
      id: FieldNames.scanAllPartiesWithMyMPID,
      style: width100,
      Type: "checkbox",
    },
  },
  [FieldNames.trf]: props => ({
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      label: "TRF",
      name: FieldNames.trf,
      isMulti: [Forms.ST_MIDDLE.key, Forms.RIGHT_LIMIT.key],
      options: SelectOptions[FieldNames.trf],
      style: width100,
      showLabel: ![Forms.ST_MIDDLE.key, Forms.CV_TOP.key, Forms.RIGHT_LIMIT.key].includes(
        props?.form?.key
      ),
      placeholder: [Forms.ST_MIDDLE.key, Forms.CV_TOP.key, Forms.RIGHT_LIMIT.key].includes(
        props?.form?.key
      )
        ? "TRF"
        : undefined,
      required: true,
      validation: {
        required: {
          onForms: {
            [Forms.TR_REPORT.key]: true,
            [Forms.TR_REPAIR.key]: true,
            [Forms.UPLOAD_REPAIR.key]: true,
            [Forms.TR_MODIFY.key]: true,
            [Forms.TR_COPY.key]: true,
            [Forms.TR_MATCH.key]: true,
            [Forms.ST_MATCH.key]: true,
            [Forms.ST_MODIFY.key]: true,
            [Forms.ST_REPAIR.key]: true,
            [Forms.ST_COPY.key]: true,
            [Forms.QUERY.key]: true,
          },
        },
      },
    },
  }),
  [FieldNames.reportingParty]: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      label: "Role",

      name: FieldNames.reportingParty,
      options: SelectOptions[FieldNames.reportingParty],
      style: width114,
      required: true,
      validation: {
        required: {
          onForms: {
            [Forms.TR_REPORT.key]: true,
            [Forms.TR_REPAIR.key]: true,
            [Forms.UPLOAD_REPAIR.key]: true,
            [Forms.TR_MODIFY.key]: true,
            [Forms.TR_COPY.key]: true,
            [Forms.ST_MODIFY.key]: true,
            [Forms.TR_MATCH.key]: true,
            [Forms.ST_MATCH.key]: true,
            [Forms.ST_REPAIR.key]: true,
            [Forms.ST_COPY.key]: true,
          },
        },
      },
    },
  },
  [FieldNames.clearReportRiskVals]: props => ({
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      id: FieldNames.clearReportRiskVals,
      name: FieldNames.clearReportRiskVals,
      label: "Report Type",
      isMulti: true,
      // this needs to be a function to get updated correctly
      options: SelectOptions[FieldNames.clearReportRiskVals],
      style: width100,
      required: true,
      augmentOnChange: {
        [Forms.TR_REPORT.key]: AUGMENT_ON_CHANGE.SET_RISK_REPORT_TYPE,
        [Forms.TR_MODIFY.key]: AUGMENT_ON_CHANGE.SET_RISK_REPORT_TYPE,
        [Forms.TR_REPAIR.key]: AUGMENT_ON_CHANGE.SET_RISK_REPORT_TYPE,
        [Forms.TR_COPY.key]: AUGMENT_ON_CHANGE.SET_RISK_REPORT_TYPE,
        [Forms.UPLOAD_REPAIR.key]: AUGMENT_ON_CHANGE.SET_RISK_REPORT_TYPE,
        [Forms.ST_MODIFY.key]: AUGMENT_ON_CHANGE.SET_RISK_REPORT_TYPE,
        [Forms.ST_REPAIR.key]: AUGMENT_ON_CHANGE.SET_RISK_REPORT_TYPE,
        [Forms.ST_COPY.key]: AUGMENT_ON_CHANGE.SET_RISK_REPORT_TYPE,
        [Forms.TR_MATCH.key]: AUGMENT_ON_CHANGE.SET_RISK_REPORT_TYPE,
        [Forms.ST_MATCH.key]: AUGMENT_ON_CHANGE.SET_RISK_REPORT_TYPE,
      },
      validation: {
        allowedValue: ({ form, field, entitlements }) => {
          if (entitlements?.[KeycloakRoles.TRADE_REPORTING_CLEARING_ONLY]) {
            return {
              onForms: {
                [Forms.TR_REPAIR.key]: true,
                [Forms.TR_COPY.key]: true,
                [Forms.ST_REPAIR.key]: true,
                [Forms.ST_COPY.key]: true,
              },

              valueList: [
                clearReportRiskOptions[OptionConstants[FieldNames.clearReportRiskVals].clear],
                clearReportRiskOptions[OptionConstants[FieldNames.clearReportRiskVals].risk],
              ],
              instant: true,
            };
          } else {
            return undefined;
          }
        },

        requiredValues: ({ form, field, entitlements }) => {
          if (entitlements?.[KeycloakRoles.TRADE_REPORTING_CLEARING_ONLY]) {
            return {
              onForms: {
                [Forms.TR_REPORT.key]: true,
                [Forms.TR_REPAIR.key]: true,
                [Forms.TR_COPY.key]: true,
                [Forms.TR_MATCH.key]: true,
                [Forms.ST_MATCH.key]: true,
                [Forms.ST_REPAIR.key]: true,
                [Forms.ST_COPY.key]: true,
              },
              requiredOptions: [
                clearReportRiskOptions[OptionConstants[FieldNames.clearReportRiskVals].clear],
              ],
              instant: true,
            };
          } else {
            return undefined;
          }
        },
      },
    },
  }),
  [FieldNames.agreement]: props => ({
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      isMulti: [Forms.RD_AGU.key],
      label: "Agreements",
      name: FieldNames.agreement,
      options: SelectOptions[FieldNames.agreement],
      style: props?.form === Forms.RD_AGU ? width100 : width114,
      augmentOnChange: {
        [Forms.TR_REPORT.key]: AUGMENT_ON_CHANGE.EMPTY_GIVE_UPS,
        [Forms.TR_REPAIR.key]: AUGMENT_ON_CHANGE.EMPTY_GIVE_UPS,
        [Forms.UPLOAD_REPAIR.key]: AUGMENT_ON_CHANGE.EMPTY_GIVE_UPS,
      },
      validation: {
        required: {
          onForms: { [Forms.RD_AGU.key]: true },
        },
      },
    },
  }),
  [FieldNames.counterMPID]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Counterparty MPID",
      name: FieldNames.counterMPID,
      maxLength: 4,
      style: form => {
        switch (form) {
          case Forms.TR_REJECTS:
            return width100;
          default:
            return width114;
        }
      },
      fieldType: [
        { type: CONTROLLED_INPUT_TYPES.ALPHA, forms: ALL_FORMS },
        { type: CONTROLLED_INPUT_TYPES.INVERSE_CHAR_CASE, forms: ALL_FORMS },
      ],
      helpText: inverseCharacterCaseHelpText,
      required: true,
      alpha: true,
      validation: {
        required: {
          onForms: {
            [Forms.TR_REPORT.key]: false,
            [Forms.TR_SCAN.key]: false,
            [Forms.TR_REPAIR.key]: false,
            [Forms.UPLOAD_REPAIR.key]: false,
            [Forms.TR_MODIFY.key]: false,
            [Forms.TR_COPY.key]: false,
            [Forms.TR_MATCH.key]: true,
            [Forms.ST_MATCH.key]: true,
            [Forms.ST_MODIFY.key]: false,
            [Forms.ST_REPAIR.key]: false,
            [Forms.ST_COPY.key]: false,
          },
        },
        alpha: true,
      },
    },
  },
  [FieldNames.correspondentMPID]: props => {
    switch (props?.form) {
      case Forms.PTR_INTRADAY_LIMITS:
      case Forms.SETTINGS_LIMO: {
        return {
          Type: FormSelect,
          TypeName: "FormSelect",
          props: {
            name: FieldNames.correspondentMPID,
            label: "Correspondent MPID",
            options: SelectOptions[FieldNames.correspondentMPID],
            validation: {
              required: {
                onForms: {
                  [Forms.PTR_INTRADAY_LIMITS.key]: true,
                  [Forms.SETTINGS_LIMO.key]: true,
                },
              },
            },
          },
        };
      }
      case Forms.PTR_AUDIT_TRAIL: {
        return {
          Type: FormDropdown,
          TypeName: "FormDropdown",
          props: {
            name: FieldNames.correspondentMPID,
            label: "Correspondent MPID(s)",
            isMulti: true,
            hasSelectAll: true,
            options: SelectOptions[FieldNames.correspondentMPID],
            disabledWhen: [
              {
                field: FieldNames.clearingFirmMPID,
                value: undefined,
              },
            ],
            validation: {
              required: {
                onForms: {
                  [Forms.PTR_AUDIT_TRAIL.key]: true,
                },
              },
            },
          },
        };
      }
      default: {
        return {
          Type: FormField,
          TypeName: "FormField",
          props: {
            label: "Correspondent",
            name: FieldNames.correspondentMPID,
            maxLength: 4,
            style: width114,
            Type: "alpha",
            fieldType: CONTROLLED_INPUT_TYPES.ALPHA,
            validation: {},
          },
        };
      }
    }
  },
  [FieldNames.side]: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      label: "Side",
      name: FieldNames.side,
      options: SelectOptions[FieldNames.side],
      style: width100,
      required: true,
      validation: {
        required: {
          onForms: {
            [Forms.TR_REPORT.key]: true,
            [Forms.TR_REPAIR.key]: true,
            [Forms.UPLOAD_REPAIR.key]: true,
            [Forms.TR_MODIFY.key]: true,
            [Forms.TR_COPY.key]: true,
            [Forms.TR_MATCH.key]: true,
            [Forms.ST_MATCH.key]: true,
            [Forms.ST_MODIFY.key]: true,
            [Forms.ST_REPAIR.key]: true,
            [Forms.ST_COPY.key]: true,
          },
        },
      },
    },
  },
  [FieldNames.symbol]: props => ({
    Type: FormField,
    TypeName: "FormField",
    props: {
      name: FieldNames.symbol,
      placeholder: props.form === Forms.RD_CUSIP ? "Symbol" : undefined,
      label: "Symbol",
      showLabel: props.form === Forms.RD_CUSIP ? false : true,
      style: width100,
      required: true,
      fieldType: [
        { type: CONTROLLED_INPUT_TYPES.SYMBOL, forms: ALL_FORMS },
        { type: CONTROLLED_INPUT_TYPES.INVERSE_CHAR_CASE, forms: inverseSymbolForms },
      ],
      inverseCharacterCase: inverseSymbolForms,
      helpText: inverseSymbolForms.reduce((acc, curr) => {
        acc[curr] = inverseCharacterCaseHelpText;
        return acc;
      }, {}),
      validation: {
        requiredIfAnyFieldValue: {
          onForms: { [Forms.RD_CUSIP.key]: true },
          otherFields: [
            {
              field: FieldNames.symbolOrCUSIP,
              value: symbolOrCUSIP.SYMBOL,
            },
          ],
        },
        required: {
          onForms: {
            [Forms.TR_REPORT.key]: true,
            [Forms.TR_REPAIR.key]: true,
            [Forms.UPLOAD_REPAIR.key]: true,
            [Forms.TR_MODIFY.key]: true,
            [Forms.TR_COPY.key]: true,
            [Forms.TR_MATCH.key]: true,
            [Forms.ST_MATCH.key]: true,
            [Forms.ST_MODIFY.key]: true,
            [Forms.ST_REPAIR.key]: true,
            [Forms.ST_COPY.key]: true,
          },
        },
      },
      validateOther: [FieldNames.symbolOrCUSIP],

      visible:
        props.form === Forms.RD_CUSIP
          ? {
              field: FieldNames.symbolOrCUSIP,
              value: symbolOrCUSIP.SYMBOL,
            }
          : undefined,
    },
  }),
  [FieldNames.quantity]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      name: FieldNames.quantity,
      label: "Quantity",

      maxLength: 8,
      style: width100,
      fieldType: CONTROLLED_INPUT_TYPES.NUMERIC,
      required: true,
      confirmKey: FieldNames.quantity,
      confirmWhenValues: ({ formState, currentForm }) => {
        if (
          formState[Forms.SETTINGS_TR.key].fields[FieldNames.confirmReportQuantity] &&
          parseInt(
            formState[Forms.SETTINGS_TR.key].fields[FieldNames.confirmReportQuantityNumber]
          ) < parseInt(formState[currentForm.key].fields[FieldNames.quantity])
        ) {
          return true;
        }
        return false;
      },
      confirmMessage: ({ formState, currentForm }) => {
        return `Are you sure you want to submit a trade with quantity > ${formatNumberToString(
          parseInt(formState[Forms.SETTINGS_TR.key].fields[FieldNames.confirmReportQuantityNumber])
        )}?`;
      },
      augmentOnChange: {
        [Forms.TR_REPORT.key]: [AUGMENT_ON_CHANGE.SET_CONFIRM_MSG],
        [Forms.TR_MODIFY.key]: [AUGMENT_ON_CHANGE.SET_CONFIRM_MSG],
        [Forms.TR_REPAIR.key]: [AUGMENT_ON_CHANGE.SET_CONFIRM_MSG],
        [Forms.TR_COPY.key]: [AUGMENT_ON_CHANGE.SET_CONFIRM_MSG],
        [Forms.UPLOAD_REPAIR.key]: [AUGMENT_ON_CHANGE.SET_CONFIRM_MSG],
        [Forms.ST_MODIFY.key]: [AUGMENT_ON_CHANGE.SET_CONFIRM_MSG],
        [Forms.ST_REPAIR.key]: [AUGMENT_ON_CHANGE.SET_CONFIRM_MSG],
        [Forms.ST_COPY.key]: [AUGMENT_ON_CHANGE.SET_CONFIRM_MSG],
        [Forms.TR_MATCH.key]: [AUGMENT_ON_CHANGE.SET_CONFIRM_MSG],
        [Forms.ST_MATCH.key]: [AUGMENT_ON_CHANGE.SET_CONFIRM_MSG],
      },
      validation: {
        required: {
          onForms: {
            [Forms.TR_REPORT.key]: true,
            [Forms.TR_SCAN.key]: false,
            [Forms.TR_REPAIR.key]: true,
            [Forms.UPLOAD_REPAIR.key]: true,
            [Forms.TR_MODIFY.key]: true,
            [Forms.TR_COPY.key]: true,
            [Forms.TR_MATCH.key]: true,
            [Forms.ST_MATCH.key]: true,
            [Forms.ST_MODIFY.key]: true,
            [Forms.ST_REPAIR.key]: true,
            [Forms.ST_COPY.key]: true,
          },
        },
      },
    },
  },
  [FieldNames.price]: props => {
    const field = {
      Type: FormField,
      TypeName: "FormField",
      props: {
        name: FieldNames.price,
        id: FieldNames.price,
        label: "Price",
        style: width100,
        fieldType: CONTROLLED_INPUT_TYPES.PRICE,
        required: true,
        confirmKey: FieldNames.priceOverride,
        confirmWhenValues: { [FieldNames.priceOverride]: true },
        confirmMessage: priceValidationConfirmMessage,
        augmentOnChange: {
          [Forms.TR_REPORT.key]: [
            AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,
            AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
          ],
          [Forms.TR_MODIFY.key]: [
            AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,
            AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
          ],
          [Forms.TR_REPAIR.key]: [
            AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,
            AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
          ],
          [Forms.TR_COPY.key]: [
            AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,
            AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
          ],
          [Forms.UPLOAD_REPAIR.key]: [
            AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,
            AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
          ],
          [Forms.ST_MODIFY.key]: [
            AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,
            AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
          ],
          [Forms.ST_REPAIR.key]: [
            AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,
            AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
          ],
          [Forms.ST_COPY.key]: [
            AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,
            AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
          ],
          [Forms.TR_MATCH.key]: [
            AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,
            AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
          ],
          [Forms.ST_MATCH.key]: [
            AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,
            AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
          ],
        },
        validateOther: [FieldNames.clearingPrice],
        validation: {
          requiredIfAnyFieldValue: {
            otherFields: [
              {
                field: FieldNames.priceTradeDigit,
                value: PriceTradeDigit.PER_SHARE,
              },
            ],
            onForms: priceAndFeeValidationOnForms,
          },
          greaterThan: {
            onForms: priceAndFeeValidationOnForms,
            orEqualToField: false,
            valueToCheck: 0,
          },
        },
      },
    };
    switch (props?.form) {
      case Forms.TR_REPORT:
      case Forms.TR_MODIFY:
      case Forms.TR_REPAIR:
      case Forms.TR_COPY:
      case Forms.UPLOAD_REPAIR:
      case Forms.TR_MATCH:
      case Forms.ST_MATCH:
      case Forms.ST_MODIFY:
      case Forms.ST_REPAIR:
      case Forms.ST_COPY: {
        const fieldWithVisible = { ...field };
        fieldWithVisible.props.visible = {
          field: FieldNames.priceTradeDigit,
          value: PriceTradeDigit.PER_SHARE,
        };
        return fieldWithVisible;
      }
      default: {
        return field;
      }
    }
  },
  [FieldNames.priceTradeDigit]: {
    Type: FormRadioCheckboxGroup,
    TypeName: "FormRadioCheckboxGroup",
    props: {
      name: FieldNames.priceTradeDigit,
      style: width100,
      Type: "radio",
      inline: true,
      options: [
        {
          label: "Price",
          value: PriceTradeDigit.PER_SHARE,
        },
        {
          label: "Contract",
          value: PriceTradeDigit.CONTRACT,
        },
      ],
      validateOther: [FieldNames.contract, FieldNames.price],
      augmentOnChange: {
        [Forms.TR_REPORT.key]: [
          AUGMENT_ON_CHANGE.EMPTY_PRICE_CONTRACT_AND_FEE,
          AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,
          AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
        ],
        [Forms.TR_MODIFY.key]: [
          AUGMENT_ON_CHANGE.EMPTY_PRICE_CONTRACT_AND_FEE,
          AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,
          AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
        ],
        [Forms.TR_REPAIR.key]: [
          AUGMENT_ON_CHANGE.EMPTY_PRICE_CONTRACT_AND_FEE,
          AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,
          AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
        ],
        [Forms.TR_COPY.key]: [
          AUGMENT_ON_CHANGE.EMPTY_PRICE_CONTRACT_AND_FEE,
          AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,
          AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
        ],
        [Forms.UPLOAD_REPAIR.key]: [
          AUGMENT_ON_CHANGE.EMPTY_PRICE_CONTRACT_AND_FEE,
          AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,
          AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
        ],
        [Forms.ST_MODIFY.key]: [
          AUGMENT_ON_CHANGE.EMPTY_PRICE_CONTRACT_AND_FEE,
          AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,
          AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
        ],
        [Forms.ST_REPAIR.key]: [
          AUGMENT_ON_CHANGE.EMPTY_PRICE_CONTRACT_AND_FEE,
          AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,
          AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
        ],
        [Forms.ST_COPY.key]: [
          AUGMENT_ON_CHANGE.EMPTY_PRICE_CONTRACT_AND_FEE,
          AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,
          AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
        ],
        [Forms.TR_MATCH.key]: [
          AUGMENT_ON_CHANGE.EMPTY_PRICE_CONTRACT_AND_FEE,
          AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,
          AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
        ],
        [Forms.ST_MATCH.key]: [
          AUGMENT_ON_CHANGE.EMPTY_PRICE_CONTRACT_AND_FEE,
          AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,
          AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
        ],
      },
      confirmKey: FieldNames.priceOverride,
      confirmWhenValues: { [FieldNames.priceOverride]: true },
      confirmMessage: priceValidationConfirmMessage,
    },
  },
  [FieldNames.contract]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      name: FieldNames.contract,
      id: FieldNames.contract,
      label: "Contract Amount",
      style: width100,
      fieldType: CONTROLLED_INPUT_TYPES.CONTRACT,
      visible: {
        field: FieldNames.priceTradeDigit,
        value: PriceTradeDigit.CONTRACT,
      },
      confirmKey: FieldNames.priceOverride,
      confirmWhenValues: { [FieldNames.priceOverride]: true },
      confirmMessage: priceValidationConfirmMessage,
      validation: {
        requiredIfAnyFieldValue: {
          otherFields: [
            {
              field: FieldNames.priceTradeDigit,
              value: PriceTradeDigit.CONTRACT,
            },
          ],
          onForms: priceAndFeeValidationOnForms,
        },
        greaterThan: {
          onForms: priceAndFeeValidationOnForms,
          orEqualToField: false,
          valueToCheck: 0,
        },
      },
      augmentOnChange: {
        [Forms.TR_REPORT.key]: [
          AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,
          AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
        ],
        [Forms.TR_MODIFY.key]: [
          AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,
          AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
        ],
        [Forms.TR_REPAIR.key]: [
          AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,
          AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
        ],
        [Forms.TR_COPY.key]: [
          AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,
          AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
        ],
        [Forms.UPLOAD_REPAIR.key]: [
          AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,
          AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
        ],
        [Forms.ST_MODIFY.key]: [
          AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,
          AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
        ],
        [Forms.ST_REPAIR.key]: [
          AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,
          AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
        ],
        [Forms.ST_COPY.key]: [
          AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,
          AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
        ],
        [Forms.TR_MATCH.key]: [
          AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,
          AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
        ],
        [Forms.ST_MATCH.key]: [
          AUGMENT_ON_CHANGE.UPDATE_CLEARING_PRICE,
          AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
        ],
      },
    },
  },
  [FieldNames.priceOverride]: ({ form = Forms.TR_REPORT }) => {
    switch (form.key) {
      case Forms.TR_SCAN.key: {
        return {
          Type: FormSelect,
          TypeName: "FormSelect",
          props: {
            name: FieldNames.priceOverride,
            label: "Price Override",

            style: width100,
            options: SelectOptions[FieldNames.priceOverride],
          },
        };
      }
      default:
        return {
          Type: FormRadioCheckboxButton,
          TypeName: "FormRadioCheckboxButton",
          props: {
            label: "Price Override",
            name: FieldNames.priceOverride,
            style: fullWidthCheckboxStyle,
            Type: "checkbox",
            augmentOnChange: {
              [Forms.TR_REPORT.key]: AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
              [Forms.TR_MODIFY.key]: AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
              [Forms.TR_REPAIR.key]: AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
              [Forms.TR_COPY.key]: AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
              [Forms.UPLOAD_REPAIR.key]: AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
              [Forms.ST_MODIFY.key]: AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
              [Forms.ST_REPAIR.key]: AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
              [Forms.ST_COPY.key]: AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
              [Forms.TR_MATCH.key]: AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
              [Forms.ST_MATCH.key]: AUGMENT_ON_CHANGE.SET_CONFIRM_MSG,
            },
            confirmKey: FieldNames.priceOverride,
            confirmWhenValues: { [FieldNames.priceOverride]: true },
            confirmMessage: priceValidationConfirmMessage,
          },
        };
    }
  },
  [FieldNames.actDate]: ({ form } /*form = Forms.TR_REPORT*/) => {
    if (form === undefined) {
      form = Forms.TR_REPORT;
    }
    switch (form) {
      default: {
        return {
          Type: DatePicker,
          TypeName: "DatePicker",
          props: {
            name: FieldNames.actDate,
            id: FieldNames.actDate,
            inputId: FieldNames.actDate,
            label: "Trade Date",
            style: width100,
            required: true,
            validation: {
              required: {
                onForms: {
                  [Forms.TR_REPORT.key]: true,
                  [Forms.TR_REPAIR.key]: true,
                  [Forms.UPLOAD_REPAIR.key]: true,
                  [Forms.TR_MODIFY.key]: true,
                  [Forms.TR_COPY.key]: true,
                  [Forms.TR_MATCH.key]: true,
                  [Forms.ST_MATCH.key]: true,
                  [Forms.ST_MODIFY.key]: true,
                  [Forms.ST_REPAIR.key]: true,
                  [Forms.ST_COPY.key]: true,
                },
              },
            },
          },
        };
      }
    }
  },
  [FieldNames.actTime]: {
    Type: FormTimeField,
    TypeName: "FormTimeField",
    props: {
      name: FieldNames.actTime,
      label: "Trade Time",
      maxLength: {
        [Forms.TR_REPORT.key]: [2, 2, 2, 9],
        [Forms.TR_SCAN.key]: [2, 2, 2, 3],
        [Forms.TR_REPAIR.key]: [2, 2, 2, 9],
        [Forms.UPLOAD_REPAIR.key]: [2, 2, 2, 9],
        [Forms.TR_MODIFY.key]: [2, 2, 2, 9],
        [Forms.TR_COPY.key]: [2, 2, 2, 9],
        [Forms.TR_MATCH.key]: [2, 2, 2, 9],
        [Forms.ST_MATCH.key]: [2, 2, 2, 9],
        [Forms.ST_MODIFY.key]: [2, 2, 2, 9],
        [Forms.ST_REPAIR.key]: [2, 2, 2, 9],
        [Forms.ST_COPY.key]: [2, 2, 2, 9],
        [Forms.QUERY.key]: [2, 2, 2, 3],
      },
      style: width100,
      required: true,
      validation: {
        required: {
          onForms: {
            [Forms.TR_REPORT.key]: true,
            [Forms.TR_REPAIR.key]: true,
            [Forms.UPLOAD_REPAIR.key]: true,
            [Forms.TR_MODIFY.key]: true,
            [Forms.TR_COPY.key]: true,
            [Forms.TR_MATCH.key]: true,
            [Forms.ST_MATCH.key]: true,
            [Forms.ST_MODIFY.key]: true,
            [Forms.ST_REPAIR.key]: true,
            [Forms.ST_COPY.key]: true,
          },
        },
      },
    },
  },
  [FieldNames.timeRange]: {
    Type: "FormTimeRangeField",
    TypeName: "FormTimeRangeField",
    props: {
      name: FieldNames.timeRange,
      label: "Trade Time Range",
      maxLength: {
        [Forms.TR_REPORT.key]: [2, 2, 2, 9],
        [Forms.TR_SCAN.key]: [2, 2, 2, 3],
        [Forms.TR_REPAIR.key]: [2, 2, 2, 9],
        [Forms.UPLOAD_REPAIR.key]: [2, 2, 2, 9],
        [Forms.TR_MODIFY.key]: [2, 2, 2, 9],
        [Forms.TR_COPY.key]: [2, 2, 2, 9],
        [Forms.TR_MATCH.key]: [2, 2, 2, 9],
        [Forms.ST_MATCH.key]: [2, 2, 2, 9],
        [Forms.ST_MODIFY.key]: [2, 2, 2, 9],
        [Forms.ST_REPAIR.key]: [2, 2, 2, 9],
        [Forms.ST_COPY.key]: [2, 2, 2, 9],
        [Forms.QUERY.key]: [2, 2, 2, 3],
      },
      validation: {
        validTimeRange: {
          onForms: {
            [Forms.QUERY.key]: true,
          },
        },
      },

      style: width114,
    },
  },
  [FieldNames.settlement]: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      label: "Settlement",

      name: FieldNames.settlement,
      style: { width: "155px" },
      required: true,
      options: SelectOptions[FieldNames.settlement],
      validation: {
        required: {
          onForms: {
            [Forms.TR_REPORT.key]: true,
            [Forms.TR_REPAIR.key]: true,
            [Forms.UPLOAD_REPAIR.key]: true,
            [Forms.TR_MODIFY.key]: true,
            [Forms.TR_COPY.key]: true,
            [Forms.TR_MATCH.key]: true,
            [Forms.ST_MATCH.key]: true,
            [Forms.ST_MODIFY.key]: true,
            [Forms.ST_REPAIR.key]: true,
            [Forms.ST_COPY.key]: true,
          },
        },
      },
      validateOther: [FieldNames.sellerDays],
      augmentOnChange: {
        [Forms.TR_REPORT.key]: AUGMENT_ON_CHANGE.EMPTY_SELLER_DAYS,
        [Forms.TR_SCAN.key]: AUGMENT_ON_CHANGE.EMPTY_SELLER_DAYS,
        [Forms.TR_REPAIR.key]: AUGMENT_ON_CHANGE.EMPTY_SELLER_DAYS,
        [Forms.UPLOAD_REPAIR.key]: AUGMENT_ON_CHANGE.EMPTY_SELLER_DAYS,
        [Forms.TR_MODIFY.key]: AUGMENT_ON_CHANGE.EMPTY_SELLER_DAYS,
        [Forms.TR_COPY.key]: AUGMENT_ON_CHANGE.EMPTY_SELLER_DAYS,
        [Forms.ST_MODIFY.key]: AUGMENT_ON_CHANGE.EMPTY_SELLER_DAYS,
        [Forms.ST_REPAIR.key]: AUGMENT_ON_CHANGE.EMPTY_SELLER_DAYS,
        [Forms.ST_COPY.key]: AUGMENT_ON_CHANGE.EMPTY_SELLER_DAYS,
        [Forms.TR_MATCH.key]: AUGMENT_ON_CHANGE.EMPTY_SELLER_DAYS,
        [Forms.ST_MATCH.key]: AUGMENT_ON_CHANGE.EMPTY_SELLER_DAYS,
      },
    },
  },
  [FieldNames.sellerDays]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      name: FieldNames.sellerDays,
      label: "Option",
      maxLength: 2,
      style: { width: "70px" },
      fieldType: CONTROLLED_INPUT_TYPES.NUMERIC,
      visible: {
        field: FieldNames.settlement,
        value: SettlementValues.Other,
      },
      validation: {
        requiredIfAnyFieldValue: {
          otherFields: [
            {
              field: FieldNames.settlement,
              value: v => {
                const valueToCheck = Array.isArray(v) ? v?.[0]?.value : v;
                return valueToCheck === SettlementOptions[SettlementValues.Other];
              },
            },
          ],
          onForms: {
            [Forms.TR_REPORT.key]: true,
            [Forms.TR_SCAN.key]: false,
            [Forms.TR_REPAIR.key]: true,
            [Forms.UPLOAD_REPAIR.key]: true,
            [Forms.TR_MODIFY.key]: true,
            [Forms.TR_COPY.key]: true,
            [Forms.TR_MATCH.key]: true,
            [Forms.ST_MATCH.key]: true,
            [Forms.ST_MODIFY.key]: true,
            [Forms.ST_REPAIR.key]: true,
            [Forms.ST_COPY.key]: true,
          },
        },
      },
    },
  },
  [FieldNames.counterCapacity]: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      name: FieldNames.counterCapacity,
      label: "Counterparty Capacity",

      options: SelectOptions[FieldNames.counterCapacity],
      style: width114,
    },
  },
  [FieldNames.counterGiveUpMPID]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      name: FieldNames.counterGiveUpMPID,
      label: "Counterparty Give Up",
      maxLength: 4,
      fieldType: [
        { type: CONTROLLED_INPUT_TYPES.ALPHA, forms: ALL_FORMS },
        { type: CONTROLLED_INPUT_TYPES.INVERSE_CHAR_CASE, forms: ALL_FORMS },
      ],
      helpText: inverseCharacterCaseHelpText,
      style: width114,
      validation: {
        requiredIfAnyFieldValue: {
          otherFields: [
            {
              field: FieldNames.mpid,
              value: comparators.emptyArrayorString,
            },
            {
              field: FieldNames.giveUpMPID,
              value: comparators.emptyArrayorString,
            },
          ],
          onForms: { [Forms.TR_SCAN.key]: true },
        },
        inValueList: {
          otherFields: [
            {
              field: FieldNames.mpid,
              value: comparators.emptyArrayorString,
            },
            {
              field: FieldNames.giveUpMPID,
              value: comparators.notInMPIDList,
            },
          ],
          comparator: comparators.inMPIDList,
          onForms: { [Forms.TR_SCAN.key]: true },
        },
      },
    },
  },
  [FieldNames.counterBranchSeqNum]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      name: FieldNames.counterBranchSeqNum,
      label: "Counterparty Branch Sq #",

      maxLength: 20,
      style: width114,
    },
  },
  [FieldNames.counterClearingNum]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      name: FieldNames.counterClearingNum,
      label: "Counterparty Clearing #",

      fieldType: CONTROLLED_INPUT_TYPES.NUMERIC,
      style: width114,
      validation: {},
    },
  },
  [FieldNames.capacity]: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      name: FieldNames.capacity,
      label: "Capacity",

      options: SelectOptions[FieldNames.capacity],
      style: width114,
      required: true,
      validation: {
        required: {
          onForms: {
            [Forms.TR_REPORT.key]: true,
            [Forms.TR_SCAN.key]: false,
            [Forms.ST_ACCEPT.key]: true,
            [Forms.TR_REPAIR.key]: true,
            [Forms.UPLOAD_REPAIR.key]: true,
            [Forms.TR_MODIFY.key]: true,
            [Forms.TR_COPY.key]: true,
            [Forms.TR_MATCH.key]: true,
            [Forms.ST_MATCH.key]: true,
            [Forms.ST_MODIFY.key]: true,
            [Forms.ST_REPAIR.key]: true,
            [Forms.ST_COPY.key]: true,
          },
        },
      },
    },
  },
  [FieldNames.giveUpMPID]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      name: FieldNames.giveUpMPID,
      label: "Give Up",
      maxLength: 4,
      fieldType: [
        { type: CONTROLLED_INPUT_TYPES.ALPHA, forms: ALL_FORMS },
        { type: CONTROLLED_INPUT_TYPES.INVERSE_CHAR_CASE, forms: ALL_FORMS },
      ],
      helpText: inverseCharacterCaseHelpText,
      style: width114,
      validation: {
        requiredIfAnyFieldValue: {
          otherFields: [
            {
              field: FieldNames.counterGiveUpMPID,
              value: comparators.emptyArrayorString,
            },
            {
              field: FieldNames.mpid,
              value: comparators.emptyArrayorString,
            },
          ],
          onForms: { [Forms.TR_SCAN.key]: true },
        },
        inValueList: {
          otherFields: [
            {
              field: FieldNames.counterGiveUpMPID,
              value: comparators.notInMPIDList,
            },
            {
              field: FieldNames.mpid,
              value: comparators.emptyArrayorString,
            },
          ],
          comparator: comparators.inMPIDList,
          onForms: { [Forms.TR_SCAN.key]: true },
        },
      },
    },
  },
  [FieldNames.branchSeqNum]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      name: FieldNames.branchSeqNum,
      label: "Branch Seq #",

      maxLength: 20,
      style: width114,
      validation: {},
    },
  },
  [FieldNames.clearingNum]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      name: FieldNames.clearingNum,
      label: "Clearing #",

      maxLength: 10,
      fieldType: CONTROLLED_INPUT_TYPES.NUMERIC,
      style: width114,
      validation: {},
    },
  },
  [FieldNames.modifier2]: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      label: form =>
        [
          Forms.TR_REPORT,
          Forms.TR_COPY,
          Forms.TR_REPAIR,
          Forms.TR_MODIFY,
          Forms.ST_MODIFY,
          Forms.ST_REPAIR,
          Forms.TR_MATCH,
          Forms.ST_MATCH,
          Forms.ST_COPY,
          Forms.UPLOAD_REPAIR,
        ].includes(form)
          ? "Mod2"
          : "Out Mod2",
      name: FieldNames.modifier2,
      options: form => {
        if (
          [
            Forms.TR_REPORT,
            Forms.TR_COPY,
            Forms.TR_REPAIR,
            Forms.TR_MODIFY,
            Forms.TR_MATCH,
            Forms.ST_MATCH,
            Forms.ST_MODIFY,
            Forms.ST_REPAIR,
            Forms.ST_COPY,
            Forms.UPLOAD_REPAIR,
          ].includes(form)
        ) {
          return SelectOptions[FieldNames.modifier2];
        } else {
          return SelectOptions[FieldNames.modifier2Input];
        }
      },
      style: width114,
    },
  },
  [FieldNames.modifier2Input]: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      label: "Inp Mod2",

      name: FieldNames.modifier2Input,
      options: SelectOptions[FieldNames.modifier2],
      style: width114,
    },
  },
  [FieldNames.modifier2Time]: {
    Type: FormTimeField,
    TypeName: "FormTimeField",
    props: {
      label: "Time Mod2",

      name: FieldNames.modifier2Time,
      maxLength: {
        [Forms.TR_REPORT.key]: [2, 2, 2, 9],
        [Forms.TR_SCAN.key]: [2, 2, 2, 3],
        [Forms.TR_REPAIR.key]: [2, 2, 2, 9],
        [Forms.UPLOAD_REPAIR.key]: [2, 2, 2, 9],
        [Forms.TR_MODIFY.key]: [2, 2, 2, 9],
        [Forms.TR_COPY.key]: [2, 2, 2, 9],
        [Forms.TR_MATCH.key]: [2, 2, 2, 9],
        [Forms.ST_MATCH.key]: [2, 2, 2, 9],
        [Forms.ST_MODIFY.key]: [2, 2, 2, 9],
        [Forms.ST_REPAIR.key]: [2, 2, 2, 9],
        [Forms.ST_COPY.key]: [2, 2, 2, 9],
      },
      style: width100,
      validation: {
        /*time: true timeFormat: "hh:mm:ss:mmm"*/
      },
    },
  },
  [FieldNames.modifier3]: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      label: form =>
        [
          Forms.TR_REPORT,
          Forms.TR_COPY,
          Forms.TR_REPAIR,
          Forms.TR_MODIFY,
          Forms.TR_MATCH,
          Forms.ST_MATCH,
          Forms.ST_MODIFY,
          Forms.ST_REPAIR,
          Forms.ST_COPY,
          Forms.UPLOAD_REPAIR,
        ].includes(form)
          ? "Mod3"
          : "Out Mod3",
      name: FieldNames.modifier3,
      style: width114,
      options: form => {
        if (
          [
            Forms.TR_REPORT,
            Forms.TR_COPY,
            Forms.TR_REPAIR,
            Forms.TR_MODIFY,
            Forms.TR_MATCH,
            Forms.ST_MATCH,
            Forms.ST_MODIFY,
            Forms.ST_REPAIR,
            Forms.ST_COPY,
            Forms.UPLOAD_REPAIR,
          ].includes(form)
        ) {
          return SelectOptions[FieldNames.modifier3];
        } else {
          return SelectOptions[FieldNames.modifier3Input];
        }
      },
    },
  },
  [FieldNames.modifier3Input]: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      label: "Inp Mod3",
      name: FieldNames.modifier3Input,
      style: width114,
      options: SelectOptions[FieldNames.modifier3],
    },
  },
  [FieldNames.modifier4]: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      label: form =>
        [
          Forms.TR_REPORT,
          Forms.TR_COPY,
          Forms.TR_REPAIR,
          Forms.TR_MODIFY,
          Forms.TR_MATCH,
          Forms.ST_MATCH,
          Forms.ST_MODIFY,
          Forms.ST_REPAIR,
          Forms.ST_COPY,
          Forms.UPLOAD_REPAIR,
        ].includes(form)
          ? "Mod4"
          : "Out Mod4",
      name: FieldNames.modifier4,
      style: width114,
      options: form => {
        if (
          [
            Forms.TR_REPORT,
            Forms.TR_COPY,
            Forms.TR_REPAIR,
            Forms.TR_MODIFY,
            Forms.TR_MATCH,
            Forms.ST_MATCH,
            Forms.ST_MODIFY,
            Forms.ST_REPAIR,
            Forms.ST_COPY,
            Forms.UPLOAD_REPAIR,
          ].includes(form)
        ) {
          return SelectOptions[FieldNames.modifier4];
        } else {
          return SelectOptions[FieldNames.modifier4Input];
        }
      },
    },
  },
  [FieldNames.modifier4Input]: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      label: "Inp Mod4",

      name: FieldNames.modifier4Input,
      style: width114,
      options: SelectOptions[FieldNames.modifier4],
    },
  },
  [FieldNames.modifier4Time]: {
    Type: FormTimeField,
    TypeName: "FormTimeField",
    props: {
      label: "Time Mod4",

      name: FieldNames.modifier4Time,
      maxLength: {
        [Forms.TR_REPORT.key]: [2, 2, 2, 9],
        [Forms.TR_SCAN.key]: [2, 2, 2, 3],
        [Forms.TR_REPAIR.key]: [2, 2, 2, 9],
        [Forms.UPLOAD_REPAIR.key]: [2, 2, 2, 9],
        [Forms.TR_MODIFY.key]: [2, 2, 2, 9],
        [Forms.TR_COPY.key]: [2, 2, 2, 9],
        [Forms.TR_MATCH.key]: [2, 2, 2, 9],
        [Forms.ST_MATCH.key]: [2, 2, 2, 9],
        [Forms.ST_MODIFY.key]: [2, 2, 2, 9],
        [Forms.ST_REPAIR.key]: [2, 2, 2, 9],
        [Forms.ST_COPY.key]: [2, 2, 2, 9],
      },
      style: width100,
      validation: {
        /*(time: true timeFormat: "hh:mm:ss:mmm"*/
      },
    },
  },
  [FieldNames.tradeThrough]: ({ form } /*form = Forms.TR_REPORT*/) => {
    if (form === undefined) {
      form = Forms.TR_REPORT;
    }
    switch (form.key) {
      case Forms.TR_SCAN.key: {
        return {
          Type: FormSelect,
          TypeName: "FormSelect",
          props: {
            name: FieldNames.tradeThrough,
            label: "Trade Through",

            style: width114,
            options: SelectOptions[FieldNames.tradeThroughExempt],
          },
        };
      }
      default:
        return {
          Type: FormRadioCheckboxButton,
          TypeName: "FormRadioCheckboxButton",
          props: {
            label: "Trade Through",

            name: FieldNames.tradeThrough,
            style: width100,
            Type: "checkbox",
          },
        };
    }
  },

  [FieldNames.relatedMarketFlag]: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      label: "Related Market",

      name: FieldNames.relatedMarketFlag,
      options: SelectOptions[FieldNames.relatedMarketFlag],
      style: width114,
    },
  },
  [FieldNames.intendedMarketFlag]: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      label: "Intended Market",

      name: FieldNames.intendedMarketFlag,
      options: SelectOptions[FieldNames.intendedMarketFlag],
      style: width114,
    },
  },
  [FieldNames.stepInOut]: ({ form }) => {
    let isMulti = false;
    let options = SelectOptions[FieldNames.stepInOut];
    if (form === Forms.TR_SCAN) {
      isMulti = true;
      options = SelectOptions[FieldNames.stepInOutVals];
    }
    return {
      Type: FormSelect,
      TypeName: "FormSelect",
      props: {
        label: "Step In/Out",
        isMulti,
        name: FieldNames.stepInOut,
        options,
        style: width100,
        validation: {
          requiredIfAllFieldValues: {
            otherFields: [
              {
                field: addPrefixToField(MATCH_FIELD_PREFIX, FieldNames.stepInOut),
                value: value => !comparators.falseNullOrUndefined(value),
              },
            ],
            onForms: {
              [Forms.TR_MATCH.key]: true,
              [Forms.ST_MATCH.key]: true,
            },
          },
        },
      },
    };
  },
  [addPrefixToField(MATCH_FIELD_PREFIX, FieldNames.stepInOut)]: {
    props: {
      name: addPrefixToField(MATCH_FIELD_PREFIX, FieldNames.stepInOut),
    },
  },
  [FieldNames.rejectTexts]: {
    Type: FormDropdown,
    TypeName: "FormDropdown",
    props: {
      label: "Reject Reason",
      name: FieldNames.rejectTexts,
      isMulti: true,
      style: width100,
      options: SelectOptions[FieldNames.rejectTexts],
    },
  },
  [FieldNames.fee]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Fee",
      id: FieldNames.fee,
      name: FieldNames.fee,
      style: width114,
      fieldType: CONTROLLED_INPUT_TYPES.FEE_PRICE,
      augmentOnChange: feeAugmentOnChange,
      visible: {
        field: FieldNames.priceTradeDigit,
        value: PriceTradeDigit.PER_SHARE,
      },
      validateOther: [FieldNames.clearingPrice],
      validation: {
        greaterThan: {
          onForms: priceAndFeeValidationOnForms,
          valueToCheck: -1000000,
        },
        lessThan: {
          onForms: priceAndFeeValidationOnForms,
          valueToCheck: 1000000,
        },
        mustPerformMathCheck: {
          onForms: priceAndFeeValidationOnForms,
          mathPredicate: value => parseFloat(value) !== 0,
          messageFragment: "a value other than 0",
        },
      },
    },
  },
  [FieldNames.contractFee]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Fee",
      id: FieldNames.contractFee,
      name: FieldNames.fee,
      style: width114,
      fieldType: CONTROLLED_INPUT_TYPES.FEE_CONTRACT,
      augmentOnChange: feeAugmentOnChange,
      visible: {
        field: FieldNames.priceTradeDigit,
        value: PriceTradeDigit.CONTRACT,
      },
      validateOther: [FieldNames.clearingPrice],
      validation: {
        greaterThan: {
          onForms: priceAndFeeValidationOnForms,
          valueToCheck: -1000000000,
        },
        lessThan: {
          onForms: priceAndFeeValidationOnForms,
          valueToCheck: 1000000000,
        },
        mustPerformMathCheck: {
          onForms: priceAndFeeValidationOnForms,
          mathPredicate: value => parseFloat(value) !== 0,
          messageFragment: "a value other than 0",
        },
      },
    },
  },
  [FieldNames.clearingPrice]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Clearing Price",
      name: FieldNames.clearingPrice,
      style: width114,
      fieldType: CONTROLLED_INPUT_TYPES.DECIMAL,
      maxLength: 13,
      typeName: "price",
      disabled: priceAndFeeValidationOnForms,
      validation: {
        greaterThan: {
          onForms: priceAndFeeValidationOnForms,
          orEqualToField: false,
          valueToCheck: 0,
        },
      },
    },
  },
  [FieldNames.special]: ({ form } /*form = Forms.TR_REPORT*/) => {
    if (form === undefined) {
      form = Forms.TR_REPORT;
    }
    switch (form.key) {
      case Forms.TR_SCAN.key: {
        return {
          Type: FormSelect,
          TypeName: "FormSelect",
          props: {
            name: FieldNames.special,
            label: "Special",

            style: width100,
            options: SelectOptions[FieldNames.special],
          },
        };
      }
      default:
        return {
          Type: FormRadioCheckboxButton,
          TypeName: "FormRadioCheckboxButton",
          props: {
            name: FieldNames.special,
            label: "Special",
            Type: "checkbox",
            style: fullWidthCheckboxStyle,
          },
        };
    }
  },
  [FieldNames.specialInstructions]: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      label: "Special Instructions",

      name: FieldNames.specialInstructions,
      style: width100,
      options: SelectOptions[FieldNames.specialInstructions],
      validation: {},
    },
  },
  [FieldNames.memo]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Additional Instructions",

      name: FieldNames.memo,
      maxLength: 10,
      style: width100,
    },
  },
  [FieldNames.tradeReferenceNum]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Trade Ref #",

      name: FieldNames.tradeReferenceNum,
      maxLength: 6,
      style: width100,
      validation: {},
    },
  },
  [FieldNames.scanDateType]: {
    Type: FormRadioCheckboxGroup,
    TypeName: "FormRadioCheckboxGroup",
    props: {
      name: FieldNames.scanDateType,
      style: width100,
      Type: "radio",
      inline: true,
      options: [
        {
          label: "Trade Date",
          value: ScanDateType.TRADE_DATE,
        },
        {
          label: "Report Date",
          value: ScanDateType.REPORT_DATE,
        },
      ],
      validateOther: [FieldNames.contract, FieldNames.price],
      augmentOnChange: {
        [Forms.TR_SCAN.key]: [AUGMENT_ON_CHANGE.EMPTY_DATE_TIME_RANGES],
      },
    },
  },
  [FieldNames.dateRange]: props => ({
    Type: DatePicker,
    TypeName: "DatePicker",
    props: {
      label: [Forms.PTR_AUDIT_TRAIL, Forms.POR_AUDIT].includes(props.form)
        ? "Date Range"
        : "Trade Date Range",
      rangeType: "range",
      name: FieldNames.dateRange,
      id: FieldNames.dateRange,
      style: width100,
      visible: {
        field: FieldNames.scanDateType,
        value: ScanDateType.TRADE_DATE,
        onForms: { [Forms.TR_SCAN.key]: true },
      },
      validation: {
        required: { onForms: { [Forms.QUERY.key]: true, [Forms.POR_AUDIT.key]: true } },
      },
    },
  }),
  [FieldNames.tradeTimeRange]: {
    Type: "FormTimeRangeField",
    TypeName: "FormTimeRangeField",
    props: {
      name: FieldNames.tradeTimeRange,
      label: "Trade Time Range",
      maxLength: {
        [Forms.TR_SCAN.key]: [2, 2, 2, 3],
        [Forms.TR_REJECTS.key]: [2, 2, 2, 3],
      },
      style: width114,
      visible: {
        field: FieldNames.scanDateType,
        value: ScanDateType.TRADE_DATE,
        onForms: { [Forms.TR_SCAN.key]: true },
      },
      disabledWhen: [
        {
          field: FieldNames.dateRange,
          value: null,
        },
        {
          field: FieldNames.dateRange,
          value: undefined,
        },
      ],
    },
  },
  [FieldNames.reportDateRange]: {
    Type: DatePicker,
    TypeName: "DatePicker",
    props: {
      label: "Report Date Range",
      rangeType: "range",
      name: FieldNames.reportDateRange,
      id: FieldNames.reportDateRange,
      style: width100,
      visible: {
        field: FieldNames.scanDateType,
        value: ScanDateType.REPORT_DATE,
        onForms: { [Forms.TR_SCAN.key]: true },
      },
    },
  },
  [FieldNames.reportTimeRange]: {
    Type: "FormTimeRangeField",
    TypeName: "FormTimeRangeField",
    props: {
      name: FieldNames.reportTimeRange,
      label: "Report Time Range",
      maxLength: {
        [Forms.TR_SCAN.key]: [2, 2, 2, 3],
        [Forms.TR_REJECTS.key]: [2, 2, 2, 3],
      },
      style: width114,
      visible: {
        field: FieldNames.scanDateType,
        value: ScanDateType.REPORT_DATE,
        onForms: { [Forms.TR_SCAN.key]: true },
      },
      disabledWhen: [
        {
          field: FieldNames.reportDateRange,
          value: null,
        },
        {
          field: FieldNames.reportDateRange,
          value: undefined,
        },
      ],
    },
  },
  [FieldNames.reportDateYear]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      name: FieldNames.reportDateYear,
      label: "Report Date Year",
      fieldType: CONTROLLED_INPUT_TYPES.NUMERIC,
      maxLength: 4,
      style: width100,
      placeholder: "YYYY",
    },
  },
  [FieldNames.controlNum]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      name: FieldNames.controlNum,
      label: "Control #",

      style: width100,
    },
  },
  [FieldNames.originalControlNum]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      name: FieldNames.originalControlNum,
      label: "Original Control #",

      style: width100,
    },
  },
  [FieldNames.originalControlDate]: {
    Type: DatePicker,
    TypeName: "DatePicker",
    props: {
      name: FieldNames.originalControlDate,
      id: FieldNames.originalControlDate,
      label: "Original Control Date",

      style: width100,
    },
  },
  [FieldNames.referenceReportingVenue]: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      label: "Reference Reporting Facility",

      name: FieldNames.referenceReportingVenue,
      style: width100,
      isMulti: false,
      options: SelectOptions[FieldNames.referenceReportingVenue],
    },
  },
  [FieldNames.lateTradeModifier]: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      label: "Would you like to add a Late Modifier?",
      name: FieldNames.lateTradeModifier,
      style: width100,
      isMulti: false,
      options: SelectOptions[FieldNames.lateTradeModifier],
    },
  },
  [FieldNames.tradeStatusVals]: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      label: "Trade Status",

      name: FieldNames.tradeStatusVals,
      style: width100,
      isMulti: true,
      options: SelectOptions[FieldNames.tradeStatusVals],
    },
  },
  tradeStatusQueryTool: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      label: "Trade Status",

      name: "tradeStatusQueryTool",
      style: width100,
      isMulti: true,
      options: SelectOptions["tradeStatusQueryTool"],
    },
  },
  [FieldNames.portUser]: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      label: "Port / User",

      name: FieldNames.portUser,
      style: width114,
      options: function () {
        return SelectOptions[FieldNames.portUser]();
      },
      validation: {},
    },
  },
  [FieldNames.asOf]: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      label: "As-Of",

      name: FieldNames.asOf,
      style: width100,
      options: SelectOptions[FieldNames.asOf],
      validation: {},
      isMulti: true,
    },
  },
  asOfFlag: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      label: "As-Of Flag",

      name: "asOfFlag",
      style: width100,
      options: SelectOptions[FieldNames.asOf],
      validation: {},
      isMulti: false,
    },
  },
  [FieldNames.contraShortSaleInd]: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      label: "Short Sale Indicator",

      name: FieldNames.contraShortSaleInd,
      style: width114,
      options: SelectOptions[FieldNames.contraShortSaleInd],
      validation: {
        required: { onForms: { [Forms.ST_ACCEPT.key]: false } },
      },
    },
  },
  incExecMPID: {
    Type: FormRadioCheckboxButton,
    TypeName: "FormRadioCheckboxButton",
    props: {
      label: "Executing MPID",

      name: "incExecMPID",
      style: {},
      Type: "checkbox",
    },
  },
  incExecGUP: {
    Type: FormRadioCheckboxButton,
    TypeName: "FormRadioCheckboxButton",
    props: {
      label: "Executing Give Up",

      name: "incExecGUP",
      style: {},
      Type: "checkbox",
    },
  },
  incContraMPID: {
    Type: FormRadioCheckboxButton,
    TypeName: "FormRadioCheckboxButton",
    props: {
      label: "Contra MPID",

      name: "incContraMPID",

      style: {},
      Type: "checkbox",
    },
  },
  incContraGUP: {
    Type: FormRadioCheckboxButton,
    TypeName: "FormRadioCheckboxButton",
    props: {
      label: "Contra Give Up",

      name: "incContraGUP",
      style: {},
      Type: "checkbox",
    },
  },
  [FieldNames.stickyFieldsSubmit]: {
    Type: FormRadioCheckboxButton,
    TypeName: "FormRadioCheckboxButton",
    props: {
      label: "Report fields are sticky when submitting a report",
      name: FieldNames.stickyFieldsSubmit,

      style: {},
      Type: "checkbox",
    },
  },
  [FieldNames.stickyFieldsSwitch]: {
    Type: FormRadioCheckboxButton,
    TypeName: "FormRadioCheckboxButton",
    props: {
      label: "Report / Scan / Rejects fields are sticky when changing modes",
      name: FieldNames.stickyFieldsSwitch,

      style: {},
      Type: "checkbox",
    },
  },
  [FieldNames.confirmTableHotButtons]: {
    Type: FormRadioCheckboxButton,
    TypeName: "FormRadioCheckboxButton",
    props: {
      label: "Trade Action Warning",
      name: FieldNames.confirmTableHotButtons,
      style: {},
      Type: "checkbox",
    },
  },
  [FieldNames.confirmReportQuantity]: {
    Type: FormRadioCheckboxButton,
    TypeName: "FormRadioCheckboxButton",
    props: {
      label: "Large Trade Size",
      name: FieldNames.confirmReportQuantity,
      style: {},
      Type: "checkbox",
    },
  },
  [FieldNames.confirmReportQuantityNumber]: {
    Type: WorkXNumber,
    TypeName: "WorkXNumber",
    props: {
      name: FieldNames.confirmReportQuantityNumber,
      label: "Large Trade Size Quantity",
      allowNegative: false,
      style: { maxWidth: "200px" },
      disabledWhen: [
        {
          field: FieldNames.confirmReportQuantity,
          value: false,
        },
        {
          field: FieldNames.confirmReportQuantity,
          value: undefined,
        },
      ],
    },
  },
  [FieldNames.isReversal]: {
    Type: FormRadioCheckboxButton,
    TypeName: "FormRadioCheckboxButton",
    props: {
      label: "Reversal",
      name: FieldNames.isReversal,
      inputId: FieldNames.isReversal,
      style: { marginBottom: "1rem" },
      Type: "checkbox",
      disabled: {
        [Forms.TR_REPAIR.key]: true,
      },
      validateOther: [
        FieldNames.reversalOriginalControlNum,
        FieldNames.reversalOriginalControlDate,
      ],
      augmentOnChange: {
        [Forms.TR_REPORT.key]: AUGMENT_ON_CHANGE.EMPTY_REVERSAL_FIELDS,
        [Forms.TR_COPY.key]: AUGMENT_ON_CHANGE.EMPTY_REVERSAL_FIELDS,
        [Forms.TR_REPAIR.key]: AUGMENT_ON_CHANGE.EMPTY_REVERSAL_FIELDS,
      },
    },
  },
  [FieldNames.reversalOriginalControlNum]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      name: FieldNames.reversalOriginalControlNum,
      label: "Original Control #",
      style: width100,
      visible: {
        field: FieldNames.isReversal,
        value: true,
      },
      maxLength: 10,
      validation: {
        requiredIfAnyFieldValue: {
          otherFields: [
            {
              field: FieldNames.isReversal,
              value: true,
            },
          ],
          onForms: {
            [Forms.TR_REPORT.key]: true,
            [Forms.TR_COPY.key]: true,
            [Forms.TR_REPAIR.key]: true,
          },
        },
      },
    },
  },
  [FieldNames.reversalOriginalControlDate]: {
    Type: DatePicker,
    TypeName: "DatePicker",
    props: {
      name: FieldNames.reversalOriginalControlDate,
      id: FieldNames.reversalOriginalControlDate,
      label: "Original Control Date",
      style: width100,
      visible: {
        field: FieldNames.isReversal,
        value: true,
      },
      validation: {
        requiredIfAnyFieldValue: {
          otherFields: [
            {
              field: FieldNames.isReversal,
              value: true,
            },
          ],
          onForms: {
            [Forms.TR_REPORT.key]: true,
            [Forms.TR_COPY.key]: true,
            [Forms.TR_REPAIR.key]: true,
          },
        },
      },
    },
  },
  [FieldNames.reversalReferenceReportingVenue]: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      label: "Reference Reporting Facility",
      name: FieldNames.reversalReferenceReportingVenue,
      style: width100,
      isMulti: false,
      options: SelectOptions[FieldNames.referenceReportingVenue],
      visible: {
        field: FieldNames.isReversal,
        value: true,
      },
    },
  },
  [FieldNames.clearingFirmMPID]: props => {
    return {
      Type: FormSelect,
      TypeName: "FormSelect",
      props: {
        isMulti: false,
        label: "Clearing MPID", // My MPID
        name: FieldNames.clearingFirmMPID,
        inputId: FieldNames.clearingFirmMPID,
        options: SelectOptions[FieldNames.clearingFirmMPID],
        validation: {
          required: {
            onForms: {
              [Forms.SETTINGS_LIMO.key]: true,
              [Forms.PTR_AUDIT_TRAIL.key]: true,
            },
          },
        },
        augmentOnChange: {
          [Forms.SETTINGS_LIMO.key]: AUGMENT_ON_CHANGE.EMPTY_CORRESPONDENT_MPID,
          [Forms.PTR_AUDIT_TRAIL.key]: AUGMENT_ON_CHANGE.EMPTY_CORRESPONDENT_MPID,
        },
      },
    };
  },
  [FieldNames.clearingFirmNum]: {}, // this can be derived from ref data using clearirng / correspondent mpids
  [FieldNames.dataSources]: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      forceUpperCase: false,
      isMulti: true,
      label: "Data Sources",
      name: FieldNames.dataSources,
      inputId: "dataSources",
      options: SelectOptions[FieldNames.dataSources],
      validation: {
        required: {
          onForms: {
            [Forms.SETTINGS_LIMO.key]: true,
          },
        },
      },
    },
  },
  [FieldNames.isT2EntryAllowed]: {
    Type: FormRadioCheckboxButton,
    TypeName: "FormRadioCheckboxButton",
    props: {
      label: "Submit >= T+2",
      name: FieldNames.isT2EntryAllowed,
      style: width100,
      Type: "checkbox",
    },
  },
  [FieldNames.updateTrfLimits]: {
    Type: FormRadioCheckboxButton,
    TypeName: "FormRadioCheckboxButton",
    props: {
      label: "Send Risk Limit Updates to TRF (DO NOT MODIFY)",
      name: FieldNames.updateTrfLimits,
      style: width100,
      Type: "checkbox",
    },
  },
  [FieldNames.useNetTrading]: {
    Type: FormRadioCheckboxGroup,
    TypeName: "FormRadioCheckboxGroup",
    props: {
      id: FieldNames.useNetTrading,
      name: FieldNames.useNetTrading,
      style: width100,
      Type: "radio",
      inline: true,
      options: [
        {
          label: "Aggregate BUY / Sell Limit",
          value: "false",
        },
        {
          label: "Aggregate Net Limit",
          value: "true",
        },
      ],
    },
  },
  [FieldNames.isMaxTradeActive]: {
    Type: FormRadioCheckboxButton,
    TypeName: "FormRadioCheckboxButton",
    props: {
      name: FieldNames.isMaxTradeActive,
      Type: "checkbox",
      label: "Max Trade",
      style: { gridColumn: "1 / 3" },
    },
  },
  [FieldNames.maxTradeLimit]: {
    Type: WorkXNumber,
    TypeName: "WorkXNumber",
    props: {
      name: FieldNames.maxTradeLimit,
      label: "Max Trade Limit",
      style: width100,
      allowNegative: false,
      prefix: "$",
      maxValue: 999999999999,
      helpText: formValues =>
        formValues[FieldNames.isMaxTradeActive] ? undefined : inactiveHelpText("Max Trade"),
      validation: {
        required: {
          onForms: {
            [Forms.PTR_INTRADAY_LIMITS.key]: true,
            [Forms.SETTINGS_LIMO.key]: true,
          },
        },
      },
    },
  },
  [FieldNames.isPerTradeActive]: {
    Type: FormRadioCheckboxButton,
    TypeName: "FormRadioCheckboxButton",
    props: {
      name: FieldNames.isPerTradeActive,
      Type: "checkbox",
      label: "Per Trade BUY / SELL Limit",
      style: { gridColumn: "1 / 3" },
    },
  },
  [FieldNames.perTradeBuyLimit]: {
    Type: WorkXNumber,
    TypeName: "WorkXNumber",
    props: {
      name: FieldNames.perTradeBuyLimit,
      label: "BUY Limit",
      style: width100,
      allowNegative: false,
      prefix: "$",
      maxValue: 999999999999,
      helpText: formValues =>
        formValues[FieldNames.isPerTradeActive] ? undefined : inactiveHelpText("Per Trade"),
      validation: {
        required: {
          onForms: {
            [Forms.PTR_INTRADAY_LIMITS.key]: true,
            [Forms.SETTINGS_LIMO.key]: true,
          },
        },
      },
    },
  },
  [FieldNames.perTradeBuyAction]: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      isMulti: false,
      label: "Per Trade Default Action",
      name: FieldNames.perTradeBuyAction,
      id: FieldNames.perTradeBuyAction,
      inputId: FieldNames.perTradeBuyAction,
      options: SelectOptions[FieldNames.perTradeBuyAction],
      // style: width100,
      validation: {
        required: {
          onForms: {
            [Forms.PTR_INTRADAY_LIMITS.key]: true,
            [Forms.SETTINGS_LIMO.key]: true,
          },
        },
      },
    },
  },
  [FieldNames.perTradeSellLimit]: {
    Type: WorkXNumber,
    TypeName: "WorkXNumber",
    props: {
      name: FieldNames.perTradeSellLimit,
      label: "SELL Limit",
      style: width100,

      allowNegative: false,
      prefix: "$",
      maxValue: 999999999999,
      helpText: formValues =>
        formValues[FieldNames.isPerTradeActive] ? undefined : inactiveHelpText("Per Trade"),
      validation: {
        required: {
          onForms: {
            [Forms.SETTINGS_LIMO.key]: true,
          },
        },
      },
    },
  },
  [FieldNames.perTradeSellAction]: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      forceUpperCase: false,
      isMulti: false,
      label: "Per Trade Sell Action",
      name: FieldNames.perTradeSellAction,
      inputId: "perTradeSellAction",
      options: SelectOptions[FieldNames.perTradeSellAction],
      style: width100,
      validation: {
        required: {
          onForms: {
            [Forms.SETTINGS_LIMO.key]: true,
          },
        },
      },
    },
  },
  [FieldNames.isAlertActive]: {
    Type: FormRadioCheckboxButton,
    TypeName: "FormRadioCheckboxButton",
    props: {
      label: "Alert",
      name: FieldNames.isAlertActive,
      Type: "checkbox",
      style: { gridColumn: "1 / 3" },
      validateOther: [
        FieldNames.netAlertLimit,
        FieldNames.buyAlertLimit,
        FieldNames.sellAlertLimit,
      ],
    },
  },
  [FieldNames.buyAlertLimit]: {
    Type: WorkXNumber,
    TypeName: "WorkXNumber",
    props: {
      name: FieldNames.buyAlertLimit,
      label: "BUY Alert Limit",
      allowNegative: false,
      style: width100,
      prefix: "$",
      maxValue: 999999999999,
      helpText: formValues =>
        formValues[FieldNames.isAlertActive] ? undefined : inactiveHelpText("Alert"),
      validation: {
        lessThan: {
          onForms: {
            [Forms.SETTINGS_LIMO.key]: true,
            [Forms.PTR_INTRADAY_LIMITS.key]: true,
          },
          fields: [FieldNames.buyHoldLimit, FieldNames.buyRejectLimit],
          orEqualToField: true,
        },
        required: {
          onForms: {
            [Forms.SETTINGS_LIMO.key]: true,
            [Forms.PTR_INTRADAY_LIMITS.key]: true,
          },
        },
      },
      validateOther: [FieldNames.buyHoldLimit, FieldNames.buyRejectLimit],
    },
  },
  [FieldNames.sellAlertLimit]: {
    Type: WorkXNumber,
    TypeName: "WorkXNumber",
    props: {
      name: FieldNames.sellAlertLimit,
      label: "SELL Alert Limit",
      allowNegative: false,
      style: width100,
      prefix: "$",
      maxValue: 999999999999,
      helpText: formValues =>
        formValues[FieldNames.isAlertActive] ? undefined : inactiveHelpText("Alert"),
      validation: {
        lessThan: {
          onForms: {
            [Forms.SETTINGS_LIMO.key]: true,
            [Forms.PTR_INTRADAY_LIMITS.key]: true,
          },
          fields: [FieldNames.sellHoldLimit, FieldNames.sellRejectLimit],
          orEqualToField: true,
        },
        required: {
          onForms: {
            [Forms.SETTINGS_LIMO.key]: true,
            [Forms.PTR_INTRADAY_LIMITS.key]: true,
          },
        },
      },
      validateOther: [FieldNames.sellHoldLimit, FieldNames.sellRejectLimit],
    },
  },
  [FieldNames.netAlertLimit]: {
    Type: WorkXNumber,
    TypeName: "WorkXNumber",
    props: {
      name: FieldNames.netAlertLimit,
      label: "Net Alert Limit",
      style: width100,
      allowNegative: true,
      prefix: "$",
      maxValue: 999999999999,
      helpText: formValues =>
        formValues[FieldNames.isAlertActive] ? undefined : inactiveHelpText("Alert"),
      validation: {
        lessThan: {
          onForms: {
            [Forms.SETTINGS_LIMO.key]: true,
            [Forms.PTR_INTRADAY_LIMITS.key]: true,
          },
          fields: [FieldNames.netHoldLimit, FieldNames.netRejectLimit],
          orEqualToField: true,
        },
        required: {
          onForms: {
            [Forms.SETTINGS_LIMO.key]: true,
            [Forms.PTR_INTRADAY_LIMITS.key]: true,
          },
        },
      },
      validateOther: [FieldNames.netHoldLimit, FieldNames.netRejectLimit],
    },
  },
  [FieldNames.isHoldActive]: {
    Type: FormRadioCheckboxButton,
    TypeName: "FormRadioCheckboxButton",
    props: {
      label: "Hold",
      name: FieldNames.isHoldActive,
      Type: "checkbox",
      style: { gridColumn: "1 / 3" },
      validateOther: [FieldNames.netHoldLimit, FieldNames.buyHoldLimit, FieldNames.sellHoldLimit],
    },
  },
  [FieldNames.buyHoldLimit]: {
    Type: WorkXNumber,
    TypeName: "WorkXNumber",
    props: {
      name: FieldNames.buyHoldLimit,
      label: "BUY Hold Limit",
      style: width100,
      allowNegative: false,
      prefix: "$",
      maxValue: 999999999999,
      helpText: formValues =>
        formValues[FieldNames.isHoldActive] ? undefined : inactiveHelpText("Hold"),
      validation: {
        greaterThan: {
          onForms: {
            [Forms.SETTINGS_LIMO.key]: true,
            [Forms.PTR_INTRADAY_LIMITS.key]: true,
          },
          fields: [FieldNames.buyAlertLimit],
          orEqualToField: true,
        },
        lessThan: {
          onForms: {
            [Forms.SETTINGS_LIMO.key]: true,
            [Forms.PTR_INTRADAY_LIMITS.key]: true,
          },
          fields: [FieldNames.buyRejectLimit],
          orEqualToField: true,
        },
        required: {
          onForms: {
            [Forms.SETTINGS_LIMO.key]: true,
            [Forms.PTR_INTRADAY_LIMITS.key]: true,
          },
        },
      },
      validateOther: [FieldNames.buyRejectLimit, FieldNames.buyAlertLimit],
    },
  },
  [FieldNames.buyDefaultAction]: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      forceUpperCase: false,
      isMulti: false,
      label: "Buy Default Action",
      name: FieldNames.buyDefaultAction,
      inputId: "buyDefaultAction",
      options: SelectOptions[FieldNames.buyDefaultAction],
      style: width100,
      validation: {
        required: {
          onForms: {
            [Forms.SETTINGS_LIMO.key]: false,
          },
        },
      },
    },
  },
  [FieldNames.sellHoldLimit]: {
    Type: WorkXNumber,
    TypeName: "WorkXNumber",
    props: {
      name: FieldNames.sellHoldLimit,
      label: "SELL Hold Limit",
      style: width100,
      allowNegative: false,
      prefix: "$",
      maxValue: 999999999999,
      helpText: formValues =>
        formValues[FieldNames.isHoldActive] ? undefined : inactiveHelpText("Hold"),
      validation: {
        greaterThan: {
          onForms: {
            [Forms.SETTINGS_LIMO.key]: true,
            [Forms.PTR_INTRADAY_LIMITS.key]: true,
          },
          fields: [FieldNames.sellAlertLimit],
          orEqualToField: true,
        },
        lessThan: {
          onForms: {
            [Forms.SETTINGS_LIMO.key]: true,
            [Forms.PTR_INTRADAY_LIMITS.key]: true,
          },
          fields: [FieldNames.sellRejectLimit],
          orEqualToField: true,
        },
        required: {
          onForms: {
            [Forms.SETTINGS_LIMO.key]: true,
            [Forms.PTR_INTRADAY_LIMITS.key]: true,
          },
        },
      },
      validateOther: [FieldNames.sellAlertLimit, FieldNames.sellRejectLimit],
    },
  },
  [FieldNames.holdDefaultAction]: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      isMulti: false,
      label: "Hold Default Action",
      name: FieldNames.holdDefaultAction,
      id: FieldNames.holdDefaultAction,
      inputId: FieldNames.holdDefaultAction,
      options: SelectOptions[FieldNames.holdDefaultAction],
      // style: width100,
      validation: {
        required: {
          onForms: {
            [Forms.PTR_INTRADAY_LIMITS.key]: true,
            [Forms.SETTINGS_LIMO.key]: true,
          },
        },
      },
    },
  },
  [FieldNames.netHoldLimit]: {
    Type: WorkXNumber,
    TypeName: "WorkXNumber",
    props: {
      name: FieldNames.netHoldLimit,
      label: "Net Hold Limit",
      style: width100,
      allowNegative: true,
      prefix: "$",
      maxValue: 999999999999,
      helpText: formValues =>
        formValues[FieldNames.isHoldActive] ? undefined : inactiveHelpText("Hold"),
      validation: {
        greaterThan: {
          onForms: {
            [Forms.SETTINGS_LIMO.key]: true,
            [Forms.PTR_INTRADAY_LIMITS.key]: true,
          },
          fields: [FieldNames.netAlertLimit],
          orEqualToField: true,
        },
        lessThan: {
          onForms: {
            [Forms.SETTINGS_LIMO.key]: true,
            [Forms.PTR_INTRADAY_LIMITS.key]: true,
          },
          fields: [FieldNames.netRejectLimit],
          orEqualToField: true,
        },
        required: {
          onForms: {
            [Forms.SETTINGS_LIMO.key]: true,
            [Forms.PTR_INTRADAY_LIMITS.key]: true,
          },
        },
      },
      validateOther: [FieldNames.netAlertLimit, FieldNames.netRejectLimit],
    },
  },
  [FieldNames.netDefaultAction]: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      forceUpperCase: false,
      isMulti: false,
      label: "Net Default Action",
      name: FieldNames.netDefaultAction,
      inputId: "netDefaultAction",
      options: SelectOptions[FieldNames.netDefaultAction],
      style: width100,
      validation: {
        required: {
          onForms: {
            [Forms.SETTINGS_LIMO.key]: false,
          },
        },
      },
    },
  },
  [FieldNames.isRejectActive]: {
    Type: FormRadioCheckboxButton,
    TypeName: "FormRadioCheckboxButton",
    props: {
      label: "Kill",
      name: FieldNames.isRejectActive,
      Type: "checkbox",
      style: { gridColumn: "1 / 3" },
      validateOther: [
        FieldNames.netRejectLimit,
        FieldNames.buyRejectLimit,
        FieldNames.sellRejectLimit,
      ],
    },
  },
  [FieldNames.buyRejectLimit]: {
    Type: WorkXNumber,
    TypeName: "WorkXNumber",
    props: {
      name: FieldNames.buyRejectLimit,
      label: "BUY Kill Limit",
      style: width100,
      allowNegative: false,
      prefix: "$",
      maxValue: 999999999999,
      helpText: formValues =>
        formValues[FieldNames.isRejectActive] ? undefined : inactiveHelpText("Kill"),
      validation: {
        greaterThan: {
          onForms: {
            [Forms.SETTINGS_LIMO.key]: true,
            [Forms.PTR_INTRADAY_LIMITS.key]: true,
          },
          fields: [FieldNames.buyAlertLimit, FieldNames.buyHoldLimit],
          orEqualToField: true,
        },
        required: {
          onForms: {
            [Forms.SETTINGS_LIMO.key]: true,
            [Forms.PTR_INTRADAY_LIMITS.key]: true,
          },
        },
      },
      validateOther: [FieldNames.buyAlertLimit, FieldNames.buyHoldLimit],
    },
  },
  [FieldNames.sellRejectLimit]: {
    Type: WorkXNumber,
    TypeName: "WorkXNumber",
    props: {
      name: FieldNames.sellRejectLimit,
      label: "SELL Kill Limit",
      style: width100,
      allowNegative: false,
      prefix: "$",
      maxValue: 999999999999,
      helpText: formValues =>
        formValues[FieldNames.isRejectActive] ? undefined : inactiveHelpText("Kill"),
      validation: {
        greaterThan: {
          onForms: {
            [Forms.SETTINGS_LIMO.key]: true,
            [Forms.PTR_INTRADAY_LIMITS.key]: true,
          },
          fields: [FieldNames.sellAlertLimit, FieldNames.sellHoldLimit],
          orEqualToField: true,
        },
        required: {
          onForms: {
            [Forms.SETTINGS_LIMO.key]: true,
            [Forms.PTR_INTRADAY_LIMITS.key]: true,
          },
        },
      },
      validateOther: [FieldNames.sellAlertLimit, FieldNames.sellHoldLimit],
    },
  },
  [FieldNames.netRejectLimit]: {
    Type: WorkXNumber,
    TypeName: "WorkXNumber",
    props: {
      name: FieldNames.netRejectLimit,
      label: "Net Kill Limit",
      style: width100,
      allowNegative: true,
      prefix: "$",
      maxValue: 999999999999,
      helpText: formValues =>
        formValues[FieldNames.isRejectActive] ? undefined : inactiveHelpText("Kill"),
      validation: {
        greaterThan: {
          onForms: {
            [Forms.SETTINGS_LIMO.key]: true,
            [Forms.PTR_INTRADAY_LIMITS.key]: true,
          },
          fields: [FieldNames.netAlertLimit, FieldNames.netHoldLimit],
          orEqualToField: true,
        },
        required: {
          onForms: {
            [Forms.SETTINGS_LIMO.key]: true,
            [Forms.PTR_INTRADAY_LIMITS.key]: true,
          },
        },
      },
      validateOther: [FieldNames.netAlertLimit, FieldNames.netHoldLimit],
    },
  },
  [FieldNames.isPostTradeRiskServiceActive]: {
    Type: FormRadioCheckboxButton,
    TypeName: "FormRadioCheckboxButton",
    props: {
      name: FieldNames.isPostTradeRiskServiceActive,
      label: "Post - Trade Risk Service",
      Type: "checkbox",
      disabled: {
        [Forms.SETTINGS_LIMO.key]: true,
      },
    },
  },
  [FieldNames.trfLimits]: {
    Type: FormRadioCheckboxButton,
    TypeName: "FormRadioCheckboxButton",
    props: {
      name: FieldNames.trfLimits,
      label: "Send Risk Limit Updates to TRF (DO NOT MODIFY)",
      Type: "checkbox",
    },
  },
  [FieldNames.submissionDate]: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      forceUpperCase: false,
      isMulti: false,
      label: "Submission Date",
      name: FieldNames.submissionDate,
      inputId: "submissionDate",
      options: SelectOptions[FieldNames.submissionDate],
      style: width100,
    },
  },
  [FieldNames.exportLimitType]: {
    Type: FormRadioCheckboxGroup,
    TypeName: "FormRadioCheckboxGroup",
    props: {
      inline: false,
      Type: "radio",
      name: FieldNames.exportLimitType,
      options: [
        {
          label: "Intraday Limits",
          value: ExportLimitTypes.INTRADAY,
        },
        {
          label: "Next Day Limits",
          value: ExportLimitTypes.NEXTDAY,
        },
      ],
    },
  },
  [FieldNames.scanReportedByMyMpid]: {
    Type: FormRadioCheckboxButton,
    TypeName: "FormRadioCheckboxButton",
    props: {
      label: "Reported By My MPID",
      name: FieldNames.scanReportedByMyMpid,
      id: FieldNames.scanReportedByMyMpid,
      style: fullWidthCheckboxStyle,
      Type: "checkbox",
    },
  },
  [addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.correspondentMPID)]: getMpid({
    name: PTRA_CONFIGURATION_ENTITY.correspondentMPID,
    id: addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.correspondentMPID),
  }),
  [addPrefixToField(PTRA_FIELD_PREFIX, PTRA_AGU_ENTITY.executingMPID)]: getMpid({
    name: PTRA_AGU_ENTITY.executingMPID,
    id: addPrefixToField(PTRA_FIELD_PREFIX, PTRA_AGU_ENTITY.executingMPID),
    augmentOnChange: {
      [Forms.PTRA_AGU_CONFIG.key]: AUGMENT_ON_CHANGE.EMPTY_PARTICIPANT_MPID,
    },
  }),
  [addPrefixToField(PTRA_FIELD_PREFIX, PTRA_AGU_ENTITY.participantMPID)]: props => ({
    Type: WorkXDropdown2,
    TypeName: "WorkXDropdown2",
    props: {
      name: PTRA_CONFIGURATION_ENTITY.correspondentMPID,
      id: addPrefixToField(PTRA_FIELD_PREFIX, PTRA_AGU_ENTITY.participantMPID),
      label: "Participant MPIDs",
      helpText: ptraMpidHelpText,
      optionsNew: true,
      validation: {
        required: {
          onForms: {
            [Forms.PTRA_AGU_CONFIG.key]: true,
          },
        },
      },
    },
  }),
  [PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isNextDayChangeActive]]: ({ form }) => {
    const label = "Next day limit change";
    const name = PTRA_CONFIGURATION_ENTITY.isNextDayChangeActive;
    const id = PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isNextDayChangeActive];
    const style = width100;
    return [Forms.PTRA_EDIT, Forms.PTRA_AGU_EDIT].includes(form)
      ? {
          Type: FormSelect,
          TypeName: "FormSelect",
          props: {
            label,
            name,
            id,
            options: SelectOptions.alertEditOptions,
            style,
            placeholder: EDIT_ALERT_PLACEHOLDER,
          },
        }
      : {
          Type: FormRadioCheckboxButton,
          TypeName: "FormRadioCheckboxButton",
          props: {
            label,
            name,
            id,
            style,
            Type: "checkbox",
            validateOther: getAllFieldValuesEmptyValidateOther(id, allPtraAlertOptionFieldIds),
            validation: getAllFieldValuesEmptyValidation(
              id,
              allPtraAlertOptionFieldIds,
              ptraConfigOnForms
            ),
          },
        };
  },
  [PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isIntraDayChangeActive]]: ({ form }) => {
    const label = "Intraday limit change";
    const name = PTRA_CONFIGURATION_ENTITY.isIntraDayChangeActive;
    const id = PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isIntraDayChangeActive];
    const style = width100;
    return [Forms.PTRA_EDIT, Forms.PTRA_AGU_EDIT].includes(form)
      ? {
          Type: FormSelect,
          TypeName: "FormSelect",
          props: {
            label,
            name,
            id,
            options: SelectOptions.alertEditOptions,
            style,
            placeholder: EDIT_ALERT_PLACEHOLDER,
          },
        }
      : {
          Type: FormRadioCheckboxButton,
          TypeName: "FormRadioCheckboxButton",
          props: {
            label,
            name,
            id,
            style,
            Type: "checkbox",
            validateOther: getAllFieldValuesEmptyValidateOther(id, allPtraAlertOptionFieldIds),
            validation: getAllFieldValuesEmptyValidation(
              id,
              allPtraAlertOptionFieldIds,
              ptraConfigOnForms
            ),
          },
        };
  },
  [addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.settingChangeAlertFrequency)]: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      isMulti: true,
      label: "Alert Frequency (Setting Change)",
      name: PTRA_CONFIGURATION_ENTITY.settingChangeAlertFrequency,
      id: addPrefixToField(
        PTRA_FIELD_PREFIX,
        PTRA_CONFIGURATION_ENTITY.settingChangeAlertFrequency
      ),
      inputId: "alertFrequency",
      options: SelectOptions.alertFrequency,
      disabled: {
        [Forms.PTRA_CONFIG.key]: true,
        [Forms.PTRA_AGU_CONFIG.key]: true,
      },
      // helpText: "How often setting change alerts are sent out",
      style: width100,
    },
  },
  [addPrefixToField(PTRA_FIELD_PREFIX, FieldNames.action)]: () => {
    const id = addPrefixToField(PTRA_FIELD_PREFIX, FieldNames.action);
    return {
      Type: FormSelect,
      TypeName: "FormSelect",
      props: {
        isMulti: false,
        label: "Action",
        name: FieldNames.action,
        id,
        placeholder: "<All>",
        inputId: id,
        options: SelectOptions[id],
        disabled: {
          [Forms.PTRA_CONFIG.key]: true,
        },
        style: width100,
      },
    };
  },
  [addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.breachAlertFrequency)]: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      isMulti: true,
      label: "Alert Frequency (Breach)",
      name: PTRA_CONFIGURATION_ENTITY.breachAlertFrequency,
      id: addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.breachAlertFrequency),
      inputId: "alertFrequency",
      options: SelectOptions.alertFrequency,
      disabled: {
        [Forms.PTRA_CONFIG.key]: true,
        [Forms.PTRA_AGU_CONFIG.key]: true,
      },
      // helpText: "How often breach alerts are sent out",
      style: width100,
    },
  },
  [addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.limitBreachSide)]: ({ form }) => ({
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      label: "Include Counter Party Limit Breach Alerts",
      name: PTRA_CONFIGURATION_ENTITY.limitBreachSide,
      id: addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.limitBreachSide),
      options: SelectOptions.alertEditOptions,
      style: width100,
      helpText: "Applies to FINRA/Nasdaq TRF Alerts",
      placeholder: [Forms.PTRA_EDIT, Forms.PTRA_AGU_EDIT].includes(form)
        ? EDIT_ALERT_PLACEHOLDER
        : "<Yes>",
    },
  }),
  [addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.includeGiveups)]: ({ form }) => {
    const label = "Include Giveups";
    const name = PTRA_CONFIGURATION_ENTITY.includeGiveups;
    const id = addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.includeGiveups);
    const style = width100;
    return [Forms.PTRA_EDIT, Forms.PTRA_AGU_EDIT].includes(form)
      ? {
          Type: FormSelect,
          TypeName: "FormSelect",
          props: {
            label,
            name,
            id,
            options: SelectOptions.alertEditOptions,
            style,
            placeholder: EDIT_ALERT_PLACEHOLDER,
          },
        }
      : {
          Type: FormRadioCheckboxButton,
          TypeName: "FormRadioCheckboxButton",
          props: {
            label,
            name,
            id,
            style,
            Type: "checkbox",
          },
        };
  },
  [PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isAggregateAlertActive]]: ({ form }) => {
    const label = PTR_ALERT_TYPE_LABEL[PTRA_ALERT_TYPE.AGG_ALERT];
    const name = PTRA_CONFIGURATION_ENTITY.isAggregateAlertActive;
    const id = PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isAggregateAlertActive];
    const style = width100;
    return [Forms.PTRA_EDIT, Forms.PTRA_AGU_EDIT].includes(form)
      ? {
          Type: FormSelect,
          TypeName: "FormSelect",
          props: {
            label,
            name,
            id,
            options: SelectOptions.alertEditOptions,
            style,
            placeholder: EDIT_ALERT_PLACEHOLDER,
          },
        }
      : {
          Type: FormRadioCheckboxButton,
          TypeName: "FormRadioCheckboxButton",
          props: {
            label,
            name,
            id,
            style,
            Type: "checkbox",
            validateOther: getAllFieldValuesEmptyValidateOther(id, allPtraAlertOptionFieldIds),
            validation: getAllFieldValuesEmptyValidation(
              id,
              allPtraAlertOptionFieldIds,
              ptraConfigOnForms
            ),
          },
        };
  },
  [PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isAggregateHoldActive]]: ({ form }) => {
    const label = PTR_ALERT_TYPE_LABEL[PTRA_ALERT_TYPE.AGG_HOLD];
    const name = PTRA_CONFIGURATION_ENTITY.isAggregateHoldActive;
    const style = width100;
    const id = PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isAggregateHoldActive];
    return [Forms.PTRA_EDIT, Forms.PTRA_AGU_EDIT].includes(form)
      ? {
          Type: FormSelect,
          TypeName: "FormSelect",
          props: {
            label,
            name,
            id,
            options: SelectOptions.alertEditOptions,
            style,
            placeholder: EDIT_ALERT_PLACEHOLDER,
          },
        }
      : {
          Type: FormRadioCheckboxButton,
          TypeName: "FormRadioCheckboxButton",
          props: {
            label,
            name,
            style,
            id,
            Type: "checkbox",
            validateOther: getAllFieldValuesEmptyValidateOther(id, allPtraAlertOptionFieldIds),
            validation: getAllFieldValuesEmptyValidation(
              id,
              allPtraAlertOptionFieldIds,
              ptraConfigOnForms
            ),
          },
        };
  },
  [PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isAggregateKillActive]]: ({ form }) => {
    const label = PTR_ALERT_TYPE_LABEL[PTRA_ALERT_TYPE.AGG_KILL];
    const name = PTRA_CONFIGURATION_ENTITY.isAggregateKillActive;
    const style = width100;
    const id = PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isAggregateKillActive];
    return [Forms.PTRA_EDIT, Forms.PTRA_AGU_EDIT].includes(form)
      ? {
          Type: FormSelect,
          TypeName: "FormSelect",
          props: {
            label,
            name,
            id,
            options: SelectOptions.alertEditOptions,
            style,
            placeholder: EDIT_ALERT_PLACEHOLDER,
          },
        }
      : {
          Type: FormRadioCheckboxButton,
          TypeName: "FormRadioCheckboxButton",
          props: {
            label,
            name,
            style,
            id,
            Type: "checkbox",
            validateOther: getAllFieldValuesEmptyValidateOther(id, allPtraAlertOptionFieldIds),
            validation: getAllFieldValuesEmptyValidation(
              id,
              allPtraAlertOptionFieldIds,
              ptraConfigOnForms
            ),
          },
        };
  },
  [PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isPerTradeAlertActive]]: ({ form }) => {
    const label = PTR_ALERT_TYPE_LABEL[PTRA_ALERT_TYPE.PER_TRADE_ALERT];
    const name = PTRA_CONFIGURATION_ENTITY.isPerTradeAlertActive;
    const style = width100;
    const id = PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isPerTradeAlertActive];
    return [Forms.PTRA_EDIT, Forms.PTRA_AGU_EDIT].includes(form)
      ? {
          Type: FormSelect,
          TypeName: "FormSelect",
          props: {
            label,
            name,
            id,
            options: SelectOptions.alertEditOptions,
            style,
            placeholder: EDIT_ALERT_PLACEHOLDER,
          },
        }
      : {
          Type: FormRadioCheckboxButton,
          TypeName: "FormRadioCheckboxButton",
          props: {
            label,
            name,
            id,
            style,
            Type: "checkbox",
            validateOther: getAllFieldValuesEmptyValidateOther(id, allPtraAlertOptionFieldIds),
            validation: getAllFieldValuesEmptyValidation(
              id,
              allPtraAlertOptionFieldIds,
              ptraConfigOnForms
            ),
          },
        };
  },
  [PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isPerTradeHoldActive]]: ({ form }) => {
    const label = PTR_ALERT_TYPE_LABEL[PTRA_ALERT_TYPE.PER_TRADE_HOLD];
    const name = PTRA_CONFIGURATION_ENTITY.isPerTradeHoldActive;
    const style = width100;
    const id = PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isPerTradeHoldActive];
    return form === Forms.PTRA_EDIT
      ? {
          Type: FormSelect,
          TypeName: "FormSelect",
          props: {
            label,
            name,
            id,
            options: SelectOptions.alertEditOptions,
            style,
            placeholder: EDIT_ALERT_PLACEHOLDER,
          },
        }
      : {
          Type: FormRadioCheckboxButton,
          TypeName: "FormRadioCheckboxButton",
          props: {
            label,
            name,
            id,
            style,
            Type: "checkbox",
            validateOther: getAllFieldValuesEmptyValidateOther(id, allPtraAlertOptionFieldIds),
            validation: getAllFieldValuesEmptyValidation(
              id,
              allPtraAlertOptionFieldIds,
              ptraConfigOnForms
            ),
          },
        };
  },
  [PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isMaxTradeAlertActive]]: ({ form }) => {
    const label = PTR_ALERT_TYPE_LABEL[PTRA_ALERT_TYPE.MAX_TRADE];
    const name = PTRA_CONFIGURATION_ENTITY.isMaxTradeAlertActive;
    const style = width100;
    const id = PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isMaxTradeAlertActive];
    return [Forms.PTRA_EDIT, Forms.PTRA_AGU_EDIT].includes(form)
      ? {
          Type: FormSelect,
          TypeName: "FormSelect",
          props: {
            label,
            name,
            id,
            options: SelectOptions.alertEditOptions,
            style,
            placeholder: EDIT_ALERT_PLACEHOLDER,
          },
        }
      : {
          Type: FormRadioCheckboxButton,
          TypeName: "FormRadioCheckboxButton",
          props: {
            label,
            name,
            id,
            style,
            Type: "checkbox",
            validateOther: getAllFieldValuesEmptyValidateOther(id, allPtraAlertOptionFieldIds),
            validation: getAllFieldValuesEmptyValidation(
              id,
              allPtraAlertOptionFieldIds,
              ptraConfigOnForms
            ),
          },
        };
  },
  [PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isKillLimitRejectActive]]: ({ form }) => {
    const label = PTR_ALERT_TYPE_LABEL[PTRA_ALERT_TYPE.KILL_LIMIT_REJECT];
    const name = PTRA_CONFIGURATION_ENTITY.isKillLimitRejectActive;
    const style = width100;
    const id = PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isKillLimitRejectActive];
    return [Forms.PTRA_EDIT, Forms.PTRA_AGU_EDIT].includes(form)
      ? {
          Type: FormSelect,
          TypeName: "FormSelect",
          props: {
            label,
            name,
            id,
            options: SelectOptions.alertEditOptions,
            style,
            placeholder: EDIT_ALERT_PLACEHOLDER,
          },
        }
      : {
          Type: FormRadioCheckboxButton,
          TypeName: "FormRadioCheckboxButton",
          props: {
            label,
            name,
            id,
            style,
            Type: "checkbox",
            validateOther: getAllFieldValuesEmptyValidateOther(id, allPtraAlertOptionFieldIds),
            validation: getAllFieldValuesEmptyValidation(
              id,
              allPtraAlertOptionFieldIds,
              ptraConfigOnForms
            ),
          },
        };
  },
  [PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isHeldTradeActive]]: ({ form }) => {
    const label = PTR_ALERT_TYPE_LABEL[PTRA_ALERT_TYPE.HELD_TRADE];
    const name = PTRA_CONFIGURATION_ENTITY.isHeldTradeActive;
    const style = width100;
    const id = PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isHeldTradeActive];
    return [Forms.PTRA_EDIT, Forms.PTRA_AGU_EDIT].includes(form)
      ? {
          Type: FormSelect,
          TypeName: "FormSelect",
          props: {
            label,
            name,
            id,
            options: SelectOptions.alertEditOptions,
            style,
            placeholder: EDIT_ALERT_PLACEHOLDER,
          },
        }
      : {
          Type: FormRadioCheckboxButton,
          TypeName: "FormRadioCheckboxButton",
          props: {
            label,
            name,
            id,
            style,
            Type: "checkbox",
            validateOther: getAllFieldValuesEmptyValidateOther(id, allPtraAlertOptionFieldIds),
            validation: getAllFieldValuesEmptyValidation(
              id,
              allPtraAlertOptionFieldIds,
              ptraConfigOnForms
            ),
          },
        };
  },
  [PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isEmailRealTimeActive]]: ({ form }) => {
    const label = "Email Real Time";
    const name = PTRA_CONFIGURATION_ENTITY.isEmailRealTimeActive;
    const style = width100;
    const id = PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isEmailRealTimeActive];
    return [Forms.PTRA_EDIT, Forms.PTRA_AGU_EDIT].includes(form)
      ? {
          Type: FormSelect,
          TypeName: "FormSelect",
          props: {
            label,
            name,
            id,
            options: SelectOptions.alertEditOptions,
            style,
            placeholder: EDIT_ALERT_PLACEHOLDER,
          },
        }
      : {
          Type: FormRadioCheckboxButton,
          TypeName: "FormRadioCheckboxButton",
          props: {
            label,
            name,
            id,
            style,
            Type: "checkbox",
            augmentOnChange: {
              [Forms.PTRA_CONFIG.key]: [AUGMENT_ON_CHANGE.EMPTY_PTRA_EMAILS],
              [Forms.PTRA_AGU_CONFIG.key]: [AUGMENT_ON_CHANGE.EMPTY_PTRA_EMAILS],
            },
            validateOther: [
              ...getAllFieldValuesEmptyValidateOther(id, allPtraDeliveryOptionFieldIds),
              PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.includeWorkXUserRecipient],
              PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.emailId],
            ],
            validation: getAllFieldValuesEmptyValidation(
              id,
              allPtraDeliveryOptionFieldIds,
              ptraConfigOnForms
            ),
          },
        };
  },
  [PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isEmailEndOfDayActive]]: ({ form }) => {
    const label = "Email End of Day";
    const name = PTRA_CONFIGURATION_ENTITY.isEmailEndOfDayActive;
    const style = width100;
    const id = PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isEmailEndOfDayActive];
    return [Forms.PTRA_EDIT, Forms.PTRA_AGU_EDIT].includes(form)
      ? {
          Type: FormSelect,
          TypeName: "FormSelect",
          props: {
            label,
            name,
            id,
            options: SelectOptions.alertEditOptions,
            style,
            placeholder: EDIT_ALERT_PLACEHOLDER,
          },
        }
      : {
          Type: FormRadioCheckboxButton,
          TypeName: "FormRadioCheckboxButton",
          props: {
            label,
            name,
            id,
            style,
            Type: "checkbox",
            validateOther: getAllFieldValuesEmptyValidateOther(id, allPtraDeliveryOptionFieldIds),
            validation: getAllFieldValuesEmptyValidation(
              id,
              allPtraDeliveryOptionFieldIds,
              ptraConfigOnForms
            ),
          },
        };
  },
  [addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.intradayNextDay)]: ({ form }) => ({
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      label: "Intraday / Next Day",
      name: PTRA_CONFIGURATION_ENTITY.intradayNextDay,
      id: addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.intradayNextDay),
      options:
        SelectOptions[
          addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.intradayNextDay)
        ],
      style: width100,
      placeholder: [Forms.PTRA_EDIT, Forms.PTRA_AGU_EDIT].includes(form)
        ? EDIT_ALERT_PLACEHOLDER
        : "<All>",
      isClearable: [Forms.PTRA_EDIT, Forms.PTRA_AGU_EDIT].includes(form),
    },
  }),
  [PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isAggSettingActive]]: ({ form }) => {
    const label = PTR_ALERT_TYPE_LABEL[PTRA_ALERT_TYPE.SETTING_AGGREGATE];
    const name = PTRA_CONFIGURATION_ENTITY.isAggSettingActive;
    const style = width100;
    const id = PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isAggSettingActive];
    return [Forms.PTRA_EDIT, Forms.PTRA_AGU_EDIT].includes(form)
      ? {
          Type: FormSelect,
          TypeName: "FormSelect",
          props: {
            label,
            name,
            id,
            options: SelectOptions.alertEditOptions,
            style,
            placeholder: EDIT_ALERT_PLACEHOLDER,
          },
        }
      : {
          Type: FormRadioCheckboxButton,
          TypeName: "FormRadioCheckboxButton",
          props: {
            label,
            name,
            style,
            id,
            Type: "checkbox",
            validateOther: getAllFieldValuesEmptyValidateOther(id, allPtraAlertOptionFieldIds),
            validation: getAllFieldValuesEmptyValidation(
              id,
              allPtraAlertOptionFieldIds,
              ptraConfigOnForms
            ),
          },
        };
  },
  [PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isPerTradeSettingActive]]: ({ form }) => {
    const label = PTR_ALERT_TYPE_LABEL[PTRA_ALERT_TYPE.SETTING_PER_TRADE];
    const name = PTRA_CONFIGURATION_ENTITY.isPerTradeSettingActive;
    const style = width100;
    const id = PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isPerTradeSettingActive];
    return [Forms.PTRA_EDIT, Forms.PTRA_AGU_EDIT].includes(form)
      ? {
          Type: FormSelect,
          TypeName: "FormSelect",
          props: {
            label,
            name,
            id,
            options: SelectOptions.alertEditOptions,
            style,
            placeholder: EDIT_ALERT_PLACEHOLDER,
          },
        }
      : {
          Type: FormRadioCheckboxButton,
          TypeName: "FormRadioCheckboxButton",
          props: {
            label,
            name,
            style,
            id,
            Type: "checkbox",
            validateOther: getAllFieldValuesEmptyValidateOther(id, allPtraAlertOptionFieldIds),
            validation: getAllFieldValuesEmptyValidation(
              id,
              allPtraAlertOptionFieldIds,
              ptraConfigOnForms
            ),
          },
        };
  },
  [PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isMaxTradeSettingActive]]: ({ form }) => {
    const label = PTR_ALERT_TYPE_LABEL[PTRA_ALERT_TYPE.SETTING_MAX_TRADE];
    const name = PTRA_CONFIGURATION_ENTITY.isMaxTradeSettingActive;
    const style = width100;
    const id = PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isMaxTradeSettingActive];
    return [Forms.PTRA_EDIT, Forms.PTRA_AGU_EDIT].includes(form)
      ? {
          Type: FormSelect,
          TypeName: "FormSelect",
          props: {
            label,
            name,
            id,
            options: SelectOptions.alertEditOptions,
            style,
            placeholder: EDIT_ALERT_PLACEHOLDER,
          },
        }
      : {
          Type: FormRadioCheckboxButton,
          TypeName: "FormRadioCheckboxButton",
          props: {
            label,
            name,
            style,
            id,
            Type: "checkbox",
            validateOther: getAllFieldValuesEmptyValidateOther(id, allPtraAlertOptionFieldIds),
            validation: getAllFieldValuesEmptyValidation(
              id,
              allPtraAlertOptionFieldIds,
              ptraConfigOnForms
            ),
          },
        };
  },
  [PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isDefaultActionSettingActive]]: ({ form }) => {
    const label = PTR_ALERT_TYPE_LABEL[PTRA_ALERT_TYPE.SETTING_DEFAULT_ACTION];
    const name = PTRA_CONFIGURATION_ENTITY.isDefaultActionSettingActive;
    const style = width100;
    const id = PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isDefaultActionSettingActive];
    return [Forms.PTRA_EDIT, Forms.PTRA_AGU_EDIT].includes(form)
      ? {
          Type: FormSelect,
          TypeName: "FormSelect",
          props: {
            label,
            name,
            id,
            options: SelectOptions.alertEditOptions,
            style,
            placeholder: EDIT_ALERT_PLACEHOLDER,
          },
        }
      : {
          Type: FormRadioCheckboxButton,
          TypeName: "FormRadioCheckboxButton",
          props: {
            label,
            name,
            style,
            id,
            Type: "checkbox",
            validateOther: getAllFieldValuesEmptyValidateOther(id, allPtraAlertOptionFieldIds),
            validation: getAllFieldValuesEmptyValidation(
              id,
              allPtraAlertOptionFieldIds,
              ptraConfigOnForms
            ),
          },
        };
  },
  [addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.isEndOfDayAlertActive)]: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      label: "Bulk Summary End of Day",
      name: PTRA_CONFIGURATION_ENTITY.isEndOfDayAlertActive,
      id: addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.isEndOfDayAlertActive),
      options: SelectOptions.alertEditOptions,
      style: width100,
      placeholder: EDIT_ALERT_PLACEHOLDER,
    },
  },
  [PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isAlertLogActive]]: ({ form }) => {
    const label = "Add Alert to the UI Log";
    const name = PTRA_CONFIGURATION_ENTITY.isAlertLogActive;
    const style = width100;
    const id = PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isAlertLogActive];
    const helpText = PTRA_WORKX_USER_FIELD_HELPTEXT;
    return [Forms.PTRA_EDIT, Forms.PTRA_AGU_EDIT].includes(form)
      ? {
          Type: FormSelect,
          TypeName: "FormSelect",
          props: {
            label,
            name,
            id,
            options: SelectOptions.alertEditOptions,
            style,
            placeholder: EDIT_ALERT_PLACEHOLDER,
            helpText,
          },
        }
      : {
          Type: FormRadioCheckboxButton,
          TypeName: "FormRadioCheckboxButton",
          props: {
            label,
            name,
            id,
            style,
            Type: "checkbox",
            helpText,
            getTooltip: ({ disabled }) => {
              switch (disabled) {
                case true:
                  return `Always enabled if '${PTRA_SEND_ALERT_TO_USER_EMAIL_LABEL}' or '${PTRA_POPUPS_LABEL}' is active`;
                case false:
                default:
                  return undefined;
              }
            },
            disabledWhen: [
              {
                field: PTRA_CONFIGURATION_ENTITY.includeWorkXUserRecipient,
                value: true,
              },
              {
                field: PTRA_CONFIGURATION_ENTITY.isAlertPopupActive,
                value: true,
              },
            ],
            validateOther: getAllFieldValuesEmptyValidateOther(id, allPtraDeliveryOptionFieldIds),
            validation: getAllFieldValuesEmptyValidation(
              id,
              allPtraDeliveryOptionFieldIds,
              ptraConfigOnForms
            ),
          },
        };
  },
  [PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isAlertPopupActive]]: ({ form }) => {
    const label = PTRA_POPUPS_LABEL;
    const name = PTRA_CONFIGURATION_ENTITY.isAlertPopupActive;
    const style = width100;
    const id = PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isAlertPopupActive];
    const helpText = PTRA_WORKX_USER_FIELD_HELPTEXT;
    return [Forms.PTRA_EDIT, Forms.PTRA_AGU_EDIT].includes(form)
      ? {
          Type: FormSelect,
          TypeName: "FormSelect",
          props: {
            label,
            name,
            id,
            options: SelectOptions.alertEditOptions,
            style,
            placeholder: EDIT_ALERT_PLACEHOLDER,
            helpText,
          },
        }
      : {
          Type: FormRadioCheckboxButton,
          TypeName: "FormRadioCheckboxButton",
          props: {
            label,
            name,
            id,
            style,
            Type: "checkbox",
            helpText,
            augmentOnChange: {
              [Forms.PTRA_CONFIG.key]: [AUGMENT_ON_CHANGE.SET_ALERT_LOG],
              [Forms.PTRA_AGU_CONFIG.key]: [AUGMENT_ON_CHANGE.SET_ALERT_LOG],
            },
            validateOther: getAllFieldValuesEmptyValidateOther(id, allPtraDeliveryOptionFieldIds),
            validation: getAllFieldValuesEmptyValidation(
              id,
              allPtraDeliveryOptionFieldIds,
              ptraConfigOnForms
            ),
          },
        };
  },
  [PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isAlertSoundActive]]: ({ form }) => {
    const label = "Sound";
    const name = PTRA_CONFIGURATION_ENTITY.isAlertSoundActive;
    const style = width100;
    const id = PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.isAlertSoundActive];
    const helpText = PTRA_WORKX_USER_FIELD_HELPTEXT;
    return [Forms.PTRA_EDIT, Forms.PTRA_AGU_EDIT].includes(form)
      ? {
          Type: FormSelect,
          TypeName: "FormSelect",
          props: {
            label,
            name,
            id,
            options: SelectOptions.alertEditOptions,
            style,
            placeholder: EDIT_ALERT_PLACEHOLDER,
            helpText,
          },
        }
      : {
          Type: FormRadioCheckboxButton,
          TypeName: "FormRadioCheckboxButton",
          props: {
            label,
            name,
            id,
            style,
            Type: "checkbox",
            helpText,
            validateOther: getAllFieldValuesEmptyValidateOther(id, allPtraDeliveryOptionFieldIds),
            validation: getAllFieldValuesEmptyValidation(
              id,
              allPtraDeliveryOptionFieldIds,
              ptraConfigOnForms
            ),
          },
        };
  },
  [PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.includeWorkXUserRecipient]]: {
    Type: FormRadioCheckboxButton,
    TypeName: "FormRadioCheckboxButton",
    props: {
      label: PTRA_SEND_ALERT_TO_USER_EMAIL_LABEL,
      name: PTRA_CONFIGURATION_ENTITY.includeWorkXUserRecipient,
      id: PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.includeWorkXUserRecipient],
      style: width100,
      Type: "checkbox",
      augmentOnChange: {
        [Forms.PTRA_CONFIG.key]: [AUGMENT_ON_CHANGE.SET_ALERT_LOG],
        [Forms.PTRA_AGU_CONFIG.key]: [AUGMENT_ON_CHANGE.SET_ALERT_LOG],
      },
      validateOther: getAllFieldValuesEmptyValidateOther(
        PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.includeWorkXUserRecipient],
        allPtraDeliveryOptionFieldIds
      ),
      validation: getAllFieldValuesEmptyValidation(
        PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.includeWorkXUserRecipient],
        allPtraDeliveryOptionFieldIds,
        ptraConfigOnForms
      ),
    },
  },
  [PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.emailId]]: () => ({
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      label: "Additional Recipient(s)",
      isMulti: true,
      name: PTRA_CONFIGURATION_ENTITY.emailId,
      id: PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.emailId],
      inputId: PTRA_CONFIGURATION_ENTITY.emailId,
      options: SelectOptions[PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.emailId]],
      style: width100,
      required: false,
      validateOther: getAllFieldValuesEmptyValidateOther(
        PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.emailId],
        allPtraDeliveryOptionFieldIds
      ),
      validation: getAllFieldValuesEmptyValidation(
        PTRA_FIELD_ID[PTRA_CONFIGURATION_ENTITY.emailId],
        allPtraDeliveryOptionFieldIds,
        ptraConfigOnForms
      ),
    },
  }),
  [addPrefixToField(PTRA_EMAIL_FIELD_PREFIX, PTRA_EMAIL_ENTITY.recipientEmail)]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Email",
      name: PTRA_EMAIL_ENTITY.recipientEmail,
      showLabel: true,
      style: width100,
      required: true,
      id: addPrefixToField(PTRA_EMAIL_FIELD_PREFIX, PTRA_EMAIL_ENTITY.recipientEmail),
    },
  },
  [FieldNames.CUSIP]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      placeholder: "CUSIP",
      label: "CUSIP",
      name: FieldNames.CUSIP,
      showLabel: false,
      style: width100,
      required: true,
      validation: {
        requiredIfAnyFieldValue: {
          onForms: { [Forms.RD_CUSIP.key]: true },
          otherFields: [
            {
              field: FieldNames.symbolOrCUSIP,
              value: symbolOrCUSIP.CUSIP,
            },
          ],
        },
      },
      visible: {
        field: FieldNames.symbolOrCUSIP,
        value: symbolOrCUSIP.CUSIP,
      },
    },
  },
  [FieldNames.symbolOrCUSIP]: {
    Type: FormRadioCheckboxGroup,
    TypeName: "FormRadioCheckboxGroup",
    props: {
      name: FieldNames.symbolOrCUSIP,
      Type: "radio",
      inputID: "symbolOrCUSIP",
      validateOther: [FieldNames.CUSIP, FieldNames.symbol],
      options: [
        {
          label: "Symbol",
          value: symbolOrCUSIP.SYMBOL,
        },
        {
          label: "CUSIP",
          value: symbolOrCUSIP.CUSIP,
        },
      ],
      validation: {
        required: { onForms: { [Forms.RD_CUSIP.key]: true } },
      },
      style: width100,
      inline: true,
      augmentOnChange: {
        [Forms.RD_CUSIP.key]: AUGMENT_ON_CHANGE.EMPTY_SYMBOL_AND_CUSIP,
      },
    },
  },
  [FieldNames.agreementQueryType]: props => ({
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      label: "Query Type",
      name: FieldNames.agreementQueryType,
      options: SelectOptions[FieldNames.agreementQueryType],
      validation: {
        required: { onForms: { [Forms.RD_AGU.key]: true, [Forms.RD_CLEARING.key]: true } },
      },
      style: width100,
    },
  }),
  [FieldNames.MPIDType]: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      isMulti: false,
      label: "MPID Type",
      name: FieldNames.MPIDType,
      options: SelectOptions[FieldNames.MPIDType],
      validation: {
        required: { onForms: { [Forms.RD_AGU.key]: true, [Forms.RD_CLEARING.key]: true } },
      },
      style: width100,
    },
  },
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.groupId)]: {
    Type: WorkXSelectPersistCreatable,
    TypeName: "WorkXSelectPersistCreatable",
    props: {
      label: "Group ID",
      forceUpperCase: false,
      isMulti: false,
      name: EqrcFields.groupId,
      id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.groupId),
      inputId: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.groupId),
      createValidationMessage: "Please enter only numbers",
      onCreateValidation: option => RegExp(/^[0-9]+$/).test(option) && option <= JAVA_8_BIT_INT,
      style: form => {
        switch (form) {
          case Forms.EQRC_AUDIT_MENU:
          case Forms.EQRC_HISTORY_MENU:
            return { gridColumn: "span 2" };
          default:
            return { gridColumn: "span 6" };
        }
      },
      helpText: formValues => {
        return formValues[EqrcFields.port]?.value || formValues[EqrcFields.port]?.length > 0
          ? EQRC_PORT_AND_EXCHANGE_ERROR_TEXT
          : "";
      },
      required: false,
      persistKey: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.groupId),
      validation: {
        lessThan: {
          onForms: {
            [Forms.EQRC_GROSS_EXPOSURE.key]: true,
            [Forms.EQRC_EDIT_GROSS_EXPOSURE.key]: true,
            [Forms.EQRC_AUDIT_MENU.key]: true,
            [Forms.EQRC_HISTORY_MENU.key]: true,
          },
          orEqualToValue: true,
          valueToCheck: JAVA_8_BIT_INT,
        },
      },
      disabledWhen: [
        {
          field: EqrcFields.groupId,
          value: value => {
            return true;
          },
          onForms: [Forms.EQRC_EDIT_ALERT, Forms.EQRC_ALERT_CONFIG],
        },
        {
          field: EqrcFields.port,
          value: value => {
            return ![undefined, null, ""].includes(value);
          },
        },
      ],
    },
  },
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.ruleType)]: {
    Type: FormSelect,
    TypeName: "FormField",
    props: {
      label: "Rule Type",
      name: EqrcFields.ruleType,
      id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.ruleType),
      style: form => {
        switch (form) {
          case Forms.EQRC_HISTORY_MENU:
            return { gridColumn: "span 2" };
          default:
            return { gridColumn: "span 6" };
        }
      },
      validation: {
        required: {
          onForms: {
            [Forms.EQRC_ALERT_CONFIG.key]: true,
            [Forms.EQRC_EDIT_ALERT.key]: true,
            [Forms.EQRC_NEW_ALERT.key]: true,
          },
        },
      },
      options: SelectOptions[addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.ruleType)],
    },
  },
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.alertType)]: {
    Type: FormSelect,
    TypeName: "FormField",
    props: {
      label: "Alert Type",
      name: EqrcFields.alertType,
      id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.alertType),
      style: form => {
        switch (form) {
          case Forms.EQRC_HISTORY_MENU:
            return { gridColumn: "span 2" };
          default:
            return { gridColumn: "span 6" };
        }
      },
      validation: {
        required: {
          onForms: {
            [Forms.EQRC_ALERT_CONFIG.key]: true,
          },
        },
      },
      options: SelectOptions[addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.alertType)],
    },
  },
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.alertLevel)]: {
    Type: FormSelect,
    TypeName: "FormField",
    props: {
      label: "Alert Level",
      name: EqrcFields.alertLevel,
      id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.alertLevel),
      style: { gridColumn: "span 2" },
      options: SelectOptions[addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.alertLevel)],
    },
  },
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.emailAddresses)]: {
    Type: WorkXSelectPersistCreatable,
    TypeName: "WorkXSelectPersistCreatable",
    props: {
      label: "Email Addresses",
      forceUpperCase: false,
      isMulti: true,
      name: EqrcFields.emailAddresses,
      id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.emailAddresses),
      inputId: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.emailAddresses),
      style: { gridColumn: "span 12" },
      required: true,
      persistKey: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.emailAddresses),
      validation: {
        required: {
          onForms: {
            [Forms.EQRC_ALERT_CONFIG.key]: true,
            [Forms.EQRC_EDIT_ALERT.key]: true,
            [Forms.EQRC_NEW_ALERT.key]: true,
          },
        },
      },
    },
  },
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.active)]: {
    Type: FormRadioCheckboxButton,
    TypeName: "FormRadioCheckboxButton",
    props: {
      label: "Configuration Enabled?",
      name: EqrcFields.active,
      id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.active),
      style: { gridColumn: "span 6" },
      validation: {
        required: {
          onForms: {
            [Forms.EQRC_ALERT_CONFIG.key]: true,
          },
        },
      },
    },
  },
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.mpid)]: props => {
    let Type, TypeName, isMulti;
    switch (props.form) {
      case Forms.EQRC_FAT_FINGER:
      case Forms.EQRC_RESTRICTED_STOCK_LIST:
      case Forms.EQRC_GROSS_EXPOSURE:
      case Forms.EQRC_MARKET_IMPACT_CHECK:
      case Forms.EQRC_ORDER_TYPE:
      case Forms.EQRC_ADV_CHECK:
      case Forms.EQRC_SHORT_SALE:
      case Forms.EQRC_MAX_NOTIONAL_ORDER:
      case Forms.EQRC_MAX_SHARES_PER_ORDER:
      case Forms.EQRC_SHARES_LOCATED_CHECK:
        Type = WorkXDropdown2;
        TypeName = "WorkXDropdown2";

        isMulti = true;
        break;
      case Forms.EQRC_AUDIT_MENU:
      case Forms.EQRC_ALERT_CONFIG:
      case Forms.EQRC_ORDER_RATE:
      case Forms.EQRC_HISTORY_MENU:
      default:
        Type = FormSelect;

        TypeName = "FormSelect";
        isMulti = false;
        break;
    }
    return {
      Type,
      TypeName,
      props: {
        label: "MPID",
        placeholder: props.form === Forms.EQRC_TOP ? "MPID" : "",
        isMulti,
        showLabel: props.form === Forms.EQRC_TOP ? false : true,
        name: EqrcFields.mpid,
        id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.mpid),
        style: form => {
          switch (form) {
            case Forms.EQRC_AUDIT_MENU:
            case Forms.EQRC_HISTORY_MENU:
              return { gridColumn: "span 1" };
            case Forms.EQRC_TOP:
              return { gridColumn: "span 1", marginRight: 0 };
            default:
              return { gridColumn: "span 4" };
          }
        },
        validation: {
          required: {
            onForms: {
              [Forms.EQRC_FAT_FINGER.key]: true,
              [Forms.EQRC_RESTRICTED_STOCK_LIST.key]: true,
              [Forms.EQRC_GROSS_EXPOSURE.key]: true,
              [Forms.EQRC_MARKET_IMPACT_CHECK.key]: true,
              [Forms.EQRC_ORDER_TYPE.key]: true,
              [Forms.EQRC_ADV_CHECK.key]: true,
              [Forms.EQRC_ORDER_RATE.key]: true,
              [Forms.EQRC_SHORT_SALE.key]: true,
              [Forms.EQRC_ALERT_CONFIG.key]: true,
              [Forms.EQRC_NEW_ALERT.key]: true,
              [Forms.EQRC_AUDIT_MENU.key]: true,
              [Forms.EQRC_ACTIONS.key]: true,
              [Forms.EQRC_HISTORY_MENU.key]: true,
              [Forms.EQRC_MAX_NOTIONAL_ORDER.key]: true,
              [Forms.EQRC_MAX_SHARES_PER_ORDER.key]: true,
              [Forms.EQRC_SHARES_LOCATED_CHECK.key]: true,
            },
          },
        },
        optionsNew: true,
        augmentOnChange: {
          [Forms.EQRC_TOP.key]: AUGMENT_ON_CHANGE.EMPTY_PORT,
          [Forms.EQRC_ACTIONS.key]: AUGMENT_ON_CHANGE.EMPTY_PORT,
        },
      },
    };
  },
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.port)]: props => {
    let Type, TypeName, showLabel, placeholder, hasSelectAll, isMulti;
    switch (props.form) {
      case Forms.EQRC_ACTIONS:
      case Forms.EQRC_FAT_FINGER:
      case Forms.EQRC_RESTRICTED_STOCK_LIST:
      case Forms.EQRC_GROSS_EXPOSURE:
      case Forms.EQRC_MARKET_IMPACT_CHECK:
      case Forms.EQRC_ORDER_TYPE:
      case Forms.EQRC_ADV_CHECK:
      case Forms.EQRC_ORDER_RATE:
      case Forms.EQRC_SHORT_SALE:
      case Forms.EQRC_MAX_NOTIONAL_ORDER:
      case Forms.EQRC_MAX_SHARES_PER_ORDER:
      case Forms.EQRC_SHARES_LOCATED_CHECK:
        Type = WorkXDropdown2;
        TypeName = "WorkXDropdown2";
        showLabel = true;
        placeholder = "";
        hasSelectAll = true;
        isMulti = true;
        break;
      case Forms.EQRC_TOP:
        Type = FormDropdown;
        TypeName = "FormDropdown";
        showLabel = false;
        placeholder = "<All Ports>";
        hasSelectAll = false;
        isMulti = true;
        break;
      case Forms.EQRC_ALERT_CONFIG:
      case Forms.EQRC_HISTORY_MENU:
      case Forms.EQRC_AUDIT_MENU:
      default:
        Type = FormSelect;
        TypeName = "FormSelect";
        showLabel = true;
        placeholder = "";
        hasSelectAll = false;
        isMulti = false;
        break;
    }
    return {
      Type,
      TypeName,
      props: {
        isMulti,
        label: "Port",
        placeholder,
        showLabel,
        name: EqrcFields.port,
        id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.port),
        hasSelectAll,
        style: form => {
          switch (form) {
            case Forms.EQRC_AUDIT_MENU:
            case Forms.EQRC_HISTORY_MENU:
              return { gridColumn: "span 1" };
            case Forms.EQRC_TOP:
              return { gridColumn: "span 2", marginRight: 0 };
            default:
              return { gridColumn: "span 4" };
          }
        },
        helpText: formValues => {
          return formValues[EqrcFields.groupId]?.value ? EQRC_PORT_AND_EXCHANGE_ERROR_TEXT : "";
        },
        validation: {
          required: {
            onForms: {
              [Forms.EQRC_ORDER_RATE.key]: true,
              [Forms.EQRC_ACTIONS.key]: true,
            },
          },
        },
        disabledWhen: [
          {
            field: EqrcFields.exchange,
            value: undefined,
            onForms: [Forms.EQRC_ACTIONS],
          },
          {
            field: EqrcFields.mpid,
            value: undefined,
            onForms: [Forms.EQRC_TOP, Forms.EQRC_ACTIONS],
          },
          {
            field: EqrcFields.mpid,
            value: value => value?.length > 1,
            onForms: [
              Forms.EQRC_FAT_FINGER,
              Forms.EQRC_RESTRICTED_STOCK_LIST,
              Forms.EQRC_GROSS_EXPOSURE,
              Forms.EQRC_MARKET_IMPACT_CHECK,
              Forms.EQRC_ORDER_TYPE,
              Forms.EQRC_ADV_CHECK,
              Forms.EQRC_ORDER_RATE,
              Forms.EQRC_SHORT_SALE,
              Forms.EQRC_ALERT_CONFIG,
              Forms.EQRC_AUDIT_MENU,
              Forms.EQRC_ACTIONS,
              Forms.EQRC_HISTORY_MENU,
              Forms.EQRC_MAX_NOTIONAL_ORDER,
              Forms.EQRC_MAX_SHARES_PER_ORDER,
              Forms.EQRC_SHARES_LOCATED_CHECK,
            ],
          },
          {
            field: EqrcFields.groupId,
            value: value => {
              return ![undefined, null, ""].includes(value);
            },
          },
        ],
        optionsNew: true,
      },
    };
  },

  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.exchange)]: props => ({
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      label: "Exchange",
      name: EqrcFields.exchange,
      id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.exchange),
      style: form => {
        switch (form) {
          case Forms.EQRC_AUDIT_MENU:
          case Forms.EQRC_HISTORY_MENU:
            return { gridColumn: "span 2" };
          default:
            return { gridColumn: "span 2" };
        }
      },
      validation: {
        required: {
          onForms: {
            [Forms.EQRC_FAT_FINGER.key]: true,
            [Forms.EQRC_RESTRICTED_STOCK_LIST.key]: true,
            [Forms.EQRC_GROSS_EXPOSURE.key]: true,
            [Forms.EQRC_MARKET_IMPACT_CHECK.key]: true,
            [Forms.EQRC_ORDER_TYPE.key]: true,
            [Forms.EQRC_ADV_CHECK.key]: true,
            [Forms.EQRC_ORDER_RATE.key]: true,
            [Forms.EQRC_SHORT_SALE.key]: true,
            [Forms.EQRC_ALERT_CONFIG.key]: true,
            [Forms.EQRC_NEW_ALERT.key]: true,
            [Forms.EQRC_AUDIT_MENU.key]: true,
            [Forms.EQRC_ACTIONS.key]: true,
            [Forms.EQRC_HISTORY_MENU.key]: true,
            [Forms.EQRC_MAX_NOTIONAL_ORDER.key]: true,
            [Forms.EQRC_MAX_SHARES_PER_ORDER.key]: true,
            [Forms.EQRC_SHARES_LOCATED_CHECK.key]: true,
          },
        },
      },
      optionsNew: true,
      augmentOnChange: {
        [Forms.EQRC_TOP.key]: AUGMENT_ON_CHANGE.EMPTY_PORT,
        [Forms.EQRC_ACTIONS.key]: AUGMENT_ON_CHANGE.EMPTY_PORT,
      },
    },
  }),
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.rangeOneAmt)]: getEqrcRangeAmt(1),
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.rangeTwoAmt)]: getEqrcRangeAmt(2),
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.rangeThreeAmt)]: getEqrcRangeAmt(3),
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.rangeFourAmt)]: getEqrcRangeAmt(4),
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.rangeFiveAmt)]: getEqrcRangeAmt(5),
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.rangeSixAmt)]: getEqrcRangeAmt(6),
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.rangeOnePct)]: getEqrcRangePct(1),
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.rangeTwoPct)]: getEqrcRangePct(2),
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.rangeThreePct)]: getEqrcRangePct(3),
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.rangeFourPct)]: getEqrcRangePct(4),
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.rangeFivePct)]: getEqrcRangePct(5),
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.rangeSixPct)]: getEqrcRangePct(6),
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.symbols)]: ({ form }) => {
    return {
      Type: WorkXSelectPersistCreatable,
      TypeName: "WorkXSelectPersistCreatable",
      props: {
        label: "Symbols",
        placeholder: [Forms.EQRC_EDIT_SHORT_SALE, Forms.EQRC_EDIT_RESTRICTED_STOCK].includes(form)
          ? EDIT_ALERT_PLACEHOLDER_FOR_LISTS
          : undefined,
        forceUpperCase: true,
        isMulti: true,
        name: EqrcFields.symbols,
        id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.symbols),
        inputId: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.symbols),
        style: { gridColumn: "span 12" },
        required: true,
        persistKey: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.symbols),
        hasImport: true,
        validation: {
          required: {
            onForms: {
              [Forms.EQRC_RESTRICTED_STOCK_LIST.key]: true,
              [Forms.EQRC_SHORT_SALE.key]: true,
              [Forms.EQRC_EDIT_RESTRICTED_STOCK.key]: false,
              [Forms.EQRC_EDIT_SHORT_SALE.key]: false,
            },
          },
        },
      },
    };
  },
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.includeIsoOrders)]: ({ form }) => {
    let Type;
    let TypeName;
    switch (form) {
      case Forms.EQRC_EDIT_FAT_FINGER:
        Type = FormSelect;
        TypeName = "FormSelect";
        break;
      default:
        Type = FormRadioCheckboxButton;
        TypeName = "FormRadioCheckboxButton";
        break;
    }
    return {
      Type: Type,
      TypeName: TypeName,
      props: {
        label: "Include ISO Orders",
        name: EqrcFields.includeIsoOrders,
        options: SelectOptions[addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.includeIsoOrders)],

        id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.includeIsoOrders),
        style: { gridColumn: "span 5" },
        validation: {},
      },
    };
  },
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.includeAuctionOrders)]: ({ form }) => {
    let Type;
    let TypeName;
    switch (form) {
      case Forms.EQRC_EDIT_FAT_FINGER:
        Type = FormSelect;
        TypeName = "FormSelect";
        break;
      default:
        Type = FormRadioCheckboxButton;
        TypeName = "FormRadioCheckboxButton";
        break;
    }
    return {
      Type: Type,
      TypeName: TypeName,
      props: {
        label: "Include Auction Orders",
        name: EqrcFields.includeAuctionOrders,
        options:
          SelectOptions[addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.includeAuctionOrders)],
        id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.includeAuctionOrders),
        style: { gridColumn: "span 5" },
        validation: {},
      },
    };
  },

  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.marketImpact)]: ({ form }) => {
    let Type, TypeName;
    switch (form) {
      case Forms.EQRC_EDIT_MARKET_IMPACT:
        Type = FormSelect;
        TypeName = "FormSelect";
        break;
      default:
        Type = FormRadioCheckboxButton;
        TypeName = "FormRadioCheckboxButton";
        break;
    }
    return {
      Type,
      TypeName,
      props: {
        label: "Market Impact Check Enabled?",
        placeholder: form === Forms.EQRC_EDIT_MARKET_IMPACT ? EDIT_ALERT_PLACEHOLDER : undefined,
        name: EqrcFields.marketImpact,
        id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.marketImpact),
        style: { gridColumn: "span 6" },
        options: SelectOptions[addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.marketImpact)],
        validation: {
          required: {
            onForms: {
              [Forms.EQRC_MARKET_IMPACT_CHECK.key]: true,
            },
          },
        },
      },
    };
  },
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.isoOrders)]: ({ form }) => {
    let Type, TypeName;
    switch (form) {
      case Forms.EQRC_EDIT_ORDER_TYPE:
        Type = FormSelect;
        TypeName = "FormSelect";
        break;
      default:
        Type = FormRadioCheckboxButton;
        TypeName = "FormRadioCheckboxButton";
        break;
    }
    return {
      Type: Type,
      TypeName: TypeName,
      props: {
        label: "Prevent ISO Orders",
        name: EqrcFields.isoOrders,
        placeholder: form === Forms.EQRC_EDIT_ORDER_TYPE ? EDIT_ALERT_PLACEHOLDER : undefined,
        id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.isoOrders),
        style: { gridColumn: "span 6" },
        options: SelectOptions[addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.isoOrders)],
        validation: {
          required: {
            onForms: {
              [Forms.EQRC_ORDER_TYPE.key]: true,
            },
          },
        },
      },
    };
  },
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.shortSaleOrders)]: ({ form }) => {
    let Type, TypeName;
    switch (form) {
      case Forms.EQRC_EDIT_ORDER_TYPE:
        Type = FormSelect;
        TypeName = "FormSelect";
        break;
      default:
        Type = FormRadioCheckboxButton;
        TypeName = "FormRadioCheckboxButton";
        break;
    }
    return {
      Type: Type,
      TypeName: TypeName,
      props: {
        label: "Prevent Short Sale Orders",
        name: EqrcFields.shortSaleOrders,
        placeholder: form === Forms.EQRC_EDIT_ORDER_TYPE ? EDIT_ALERT_PLACEHOLDER : undefined,
        options: SelectOptions[addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.shortSaleOrders)],
        id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.shortSaleOrders),
        style: { gridColumn: "span 6" },
        validation: {
          required: {
            onForms: {
              [Forms.EQRC_ORDER_TYPE.key]: true,
            },
          },
        },
      },
    };
  },
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.nonMarketOrders)]: ({ form }) => {
    let Type, TypeName;
    switch (form) {
      case Forms.EQRC_EDIT_ORDER_TYPE:
        Type = FormSelect;
        TypeName = "FormSelect";
        break;
      default:
        Type = FormRadioCheckboxButton;
        TypeName = "FormRadioCheckboxButton";
        break;
    }
    return {
      Type: Type,
      TypeName: TypeName,
      props: {
        label: "Prevent Non Auction Market Orders",
        placeholder: form === Forms.EQRC_EDIT_ORDER_TYPE ? EDIT_ALERT_PLACEHOLDER : undefined,
        name: EqrcFields.nonMarketOrders,
        options: SelectOptions[addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.nonMarketOrders)],
        id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.nonMarketOrders),
        style: { gridColumn: "span 6" },
        validation: {
          required: {
            onForms: {
              [Forms.EQRC_ORDER_TYPE.key]: true,
            },
          },
        },
      },
    };
  },
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.premarketTrading)]: ({ form }) => {
    let Type, TypeName;
    switch (form) {
      case Forms.EQRC_EDIT_ORDER_TYPE:
        Type = FormSelect;
        TypeName = "FormSelect";
        break;
      default:
        Type = FormRadioCheckboxButton;
        TypeName = "FormRadioCheckboxButton";
        break;
    }
    return {
      Type: Type,
      TypeName: TypeName,
      props: {
        label: "Prevent Pre Market Trading",
        placeholder: form === Forms.EQRC_EDIT_ORDER_TYPE ? EDIT_ALERT_PLACEHOLDER : undefined,
        name: EqrcFields.premarketTrading,
        options: SelectOptions[addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.premarketTrading)],
        id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.premarketTrading),
        style: { gridColumn: "span 6" },
        validation: {
          required: {
            onForms: {
              [Forms.EQRC_ORDER_TYPE.key]: true,
            },
          },
        },
      },
    };
  },
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.postMarketTrading)]: ({ form }) => {
    let Type, TypeName;
    switch (form) {
      case Forms.EQRC_EDIT_ORDER_TYPE:
        Type = FormSelect;
        TypeName = "FormSelect";
        break;
      default:
        Type = FormRadioCheckboxButton;
        TypeName = "FormRadioCheckboxButton";
        break;
    }
    return {
      Type: Type,
      TypeName: TypeName,
      props: {
        label: "Prevent Post Market Trading",
        name: EqrcFields.postMarketTrading,
        placeholder: form === Forms.EQRC_EDIT_ORDER_TYPE ? EDIT_ALERT_PLACEHOLDER : undefined,
        options: SelectOptions[addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.postMarketTrading)],
        id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.postMarketTrading),
        style: { gridColumn: "span 6" },
        validation: {
          required: {
            onForms: {
              [Forms.EQRC_ORDER_TYPE.key]: true,
            },
          },
        },
      },
    };
  },
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.shortSaleExempt)]: ({ form }) => {
    let Type, TypeName;
    switch (form) {
      case Forms.EQRC_EDIT_ORDER_TYPE:
        Type = FormSelect;
        TypeName = "FormSelect";
        break;
      default:
        Type = FormRadioCheckboxButton;
        TypeName = "FormRadioCheckboxButton";
        break;
    }
    return {
      Type: Type,
      TypeName: TypeName,
      props: {
        label: "Prevent Short Sale Exempt Orders",
        name: EqrcFields.shortSaleExempt,
        placeholder: form === Forms.EQRC_EDIT_ORDER_TYPE ? EDIT_ALERT_PLACEHOLDER : undefined,
        options: SelectOptions[addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.shortSaleExempt)],
        id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.shortSaleExempt),
        style: { gridColumn: "span 6" },
        validation: {
          required: {
            onForms: {
              [Forms.EQRC_ORDER_TYPE.key]: true,
            },
          },
        },
      },
    };
  },
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.buyMarketIPO)]: ({ form }) => {
    let Type, TypeName;
    switch (form) {
      case Forms.EQRC_EDIT_ORDER_TYPE:
        Type = FormSelect;
        TypeName = "FormSelect";
        break;
      default:
        Type = FormRadioCheckboxButton;
        TypeName = "FormRadioCheckboxButton";
        break;
    }
    return {
      Type: Type,
      TypeName: TypeName,
      props: {
        label: "Prevent IPO Buy Market Orders",
        name: EqrcFields.buyMarketIPO,
        placeholder: form === Forms.EQRC_EDIT_ORDER_TYPE ? EDIT_ALERT_PLACEHOLDER : undefined,
        options: SelectOptions[addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.buyMarketIPO)],
        id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.buyMarketIPO),
        style: { gridColumn: "span 6" },
      },
    };
  },
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.pctAdv)]: ({ form }) => ({
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Percent ADV",
      name: EqrcFields.pctAdv,
      placeholder: form === Forms.EQRC_EDIT_AVG_DAILY_VOLUME ? EDIT_ALERT_PLACEHOLDER : undefined,
      fieldType: "numeric",
      id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.pctAdv),
      style: { gridColumn: "span 4" },
      maxLength: 3,
      validation: {
        required: {
          onForms: {
            [Forms.EQRC_ADV_CHECK.key]: true,
            [Forms.EQRC_EDIT_AVG_DAILY_VOLUME.key]: false,
          },
        },
        greaterThan: {
          onForms: {
            [Forms.EQRC_ADV_CHECK.key]: true,
            [Forms.EQRC_EDIT_AVG_DAILY_VOLUME.key]: true,
          },
          orEqualTo: true,
          valueToCheck: 0,
        },
        lessThan: {
          onForms: {
            [Forms.EQRC_ADV_CHECK.key]: true,
            [Forms.EQRC_EDIT_AVG_DAILY_VOLUME.key]: true,
          },
          orEqualToValue: true,
          valueToCheck: 100,
        },
      },
    },
  }),
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.minVolume)]: ({ form }) => ({
    Type: FormField,
    TypeName: "FormField",
    props: {
      fieldType: "numeric",
      label: "Minimum Volume",
      name: EqrcFields.minVolume,
      placeholder: form === Forms.EQRC_EDIT_AVG_DAILY_VOLUME ? EDIT_ALERT_PLACEHOLDER : undefined,
      id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.minVolume),
      allowDecimal: false,
      style: { gridColumn: "span 4" },
      maxLength: 16,
      validation: {
        required: {
          onForms: {
            [Forms.EQRC_ADV_CHECK.key]: true,
            [Forms.EQRC_EDIT_AVG_DAILY_VOLUME.key]: false,
          },
        },
        greaterThan: {
          onForms: {
            [Forms.EQRC_ADV_CHECK.key]: true,
            [Forms.EQRC_EDIT_AVG_DAILY_VOLUME.key]: true,
          },
          orEqualToValue: true,
          valueToCheck: 0,
        },
        lessThan: {
          onForms: {
            [Forms.EQRC_ADV_CHECK.key]: true,
            [Forms.EQRC_EDIT_AVG_DAILY_VOLUME.key]: true,
          },
          orEqualToValue: true,
          valueToCheck: Number.MAX_SAFE_INTEGER,
        },
      },
    },
  }),
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.duplicateMaxMessages)]: ({ form }) => ({
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Max Duplicate Messages",
      name: EqrcFields.duplicateMaxMessages,
      placeholder: form === Forms.EQRC_EDIT_ORDER_RATE ? EDIT_ALERT_PLACEHOLDER : undefined,
      id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.duplicateMaxMessages),
      style: { gridColumn: "span 4" },
      fieldType: "numeric",
      validation: {
        required: {
          onForms: {
            [Forms.EQRC_ORDER_RATE.key]: true,
            [Forms.EQRC_EDIT_ORDER_RATE.key]: false,
          },
        },
        greaterThan: {
          onForms: {
            [Forms.EQRC_ORDER_RATE.key]: true,
            [Forms.EQRC_EDIT_ORDER_RATE.key]: true,
          },
          orEqualToValue: true,
          valueToCheck: 0,
        },
        lessThan: {
          onForms: {
            [Forms.EQRC_ORDER_RATE.key]: true,
            [Forms.EQRC_EDIT_ORDER_RATE.key]: true,
          },
          orEqualToValue: true,
          valueToCheck: 2147483647,
        },
      },
    },
  }),
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.duplicateTimeWindowSize)]: ({ form }) => ({
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Duplicate Time Window Size",
      placeholder: form === Forms.EQRC_EDIT_ORDER_RATE ? EDIT_ALERT_PLACEHOLDER : undefined,
      name: EqrcFields.duplicateTimeWindowSize,
      fieldType: "numeric",
      id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.duplicateTimeWindowSize),
      style: { gridColumn: "span 4" },
      validation: {
        required: {
          onForms: {
            [Forms.EQRC_ORDER_RATE.key]: true,
            [Forms.EQRC_EDIT_ORDER_RATE.key]: false,
          },
        },
        greaterThan: {
          onForms: {
            [Forms.EQRC_ORDER_RATE.key]: true,
            [Forms.EQRC_EDIT_ORDER_RATE.key]: true,
          },
          orEqualToValue: true,
          valueToCheck: 1,
        },
        lessThan: {
          onForms: {
            [Forms.EQRC_ORDER_RATE.key]: true,
            [Forms.EQRC_EDIT_ORDER_RATE.key]: true,
          },
          orEqualToValue: true,
          valueToCheck: 30,
        },
      },
    },
  }),
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.portMaxMessages)]: ({ form }) => ({
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Max Port Messages",
      placeholder: form === Forms.EQRC_EDIT_ORDER_RATE ? EDIT_ALERT_PLACEHOLDER : undefined,
      name: EqrcFields.portMaxMessages,
      fieldType: "numeric",
      id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.portMaxMessages),
      style: { gridColumn: "span 4" },
      validation: {
        required: {
          onForms: {
            [Forms.EQRC_ORDER_RATE.key]: true,
            [Forms.EQRC_EDIT_ORDER_RATE.key]: false,
          },
        },
        greaterThan: {
          onForms: {
            [Forms.EQRC_ORDER_RATE.key]: true,
            [Forms.EQRC_EDIT_ORDER_RATE.key]: true,
          },
          orEqualToValue: true,
          valueToCheck: 0,
        },
        lessThan: {
          onForms: {
            [Forms.EQRC_ORDER_RATE.key]: true,
            [Forms.EQRC_EDIT_ORDER_RATE.key]: true,
          },
          orEqualToValue: true,
          valueToCheck: 2147483647,
        },
      },
    },
  }),
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.portTimeWindowSize)]: ({ form }) => ({
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Port Time Window Size",
      placeholder: form === Forms.EQRC_EDIT_ORDER_RATE ? EDIT_ALERT_PLACEHOLDER : undefined,
      name: EqrcFields.portTimeWindowSize,
      fieldType: "numeric",
      id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.portTimeWindowSize),
      style: { gridColumn: "span 4" },
      validation: {
        required: {
          onForms: {
            [Forms.EQRC_ORDER_RATE.key]: true,
            [Forms.EQRC_EDIT_ORDER_RATE.key]: false,
          },
        },
        greaterThan: {
          onForms: {
            [Forms.EQRC_ORDER_RATE.key]: true,
            [Forms.EQRC_EDIT_ORDER_RATE.key]: true,
          },
          orEqualToValue: true,
          valueToCheck: 1,
        },
        lessThan: {
          onForms: {
            [Forms.EQRC_ORDER_RATE.key]: true,
            [Forms.EQRC_EDIT_ORDER_RATE.key]: true,
          },
          orEqualToValue: true,
          valueToCheck: 30,
        },
      },
    },
  }),
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.symbolMaxMessages)]: ({ form }) => ({
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Max Symbol Messages",
      placeholder: form === Forms.EQRC_EDIT_ORDER_RATE ? EDIT_ALERT_PLACEHOLDER : undefined,
      name: EqrcFields.symbolMaxMessages,
      fieldType: "numeric",
      id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.symbolMaxMessages),
      style: { gridColumn: "span 4" },
      validation: {
        required: {
          onForms: {
            [Forms.EQRC_ORDER_RATE.key]: true,
            [Forms.EQRC_EDIT_ORDER_RATE.key]: false,
          },
        },
        greaterThan: {
          onForms: {
            [Forms.EQRC_ORDER_RATE.key]: true,
            [Forms.EQRC_EDIT_ORDER_RATE.key]: true,
          },
          orEqualToValue: true,
          valueToCheck: 0,
        },
        lessThan: {
          onForms: {
            [Forms.EQRC_ORDER_RATE.key]: true,
            [Forms.EQRC_EDIT_ORDER_RATE.key]: true,
          },
          orEqualToValue: true,
          valueToCheck: 2147483647,
        },
      },
    },
  }),
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.symbolTimeWindowSize)]: ({ form }) => ({
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Symbol Time Window Size",
      placeholder: form === Forms.EQRC_EDIT_ORDER_RATE ? EDIT_ALERT_PLACEHOLDER : undefined,
      name: EqrcFields.symbolTimeWindowSize,
      fieldType: "numeric",
      id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.symbolTimeWindowSize),
      style: { gridColumn: "span 4" },
      validation: {
        required: {
          onForms: {
            [Forms.EQRC_ORDER_RATE.key]: true,
            [Forms.EQRC_EDIT_ORDER_RATE.key]: false,
          },
        },
        greaterThan: {
          onForms: {
            [Forms.EQRC_ORDER_RATE.key]: true,
            [Forms.EQRC_EDIT_ORDER_RATE.key]: true,
          },
          orEqualToValue: true,
          valueToCheck: 1,
        },
        lessThan: {
          onForms: {
            [Forms.EQRC_ORDER_RATE.key]: true,
            [Forms.EQRC_EDIT_ORDER_RATE.key]: true,
          },
          orEqualToValue: true,
          valueToCheck: 30,
        },
      },
    },
  }),
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.openExposure + EqrcFields.exposureWatch)]: ({
    form,
  }) => ({
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Open Exposure Watch",
      placeholder: form === Forms.EQRC_EDIT_GROSS_EXPOSURE ? EDIT_ALERT_PLACEHOLDER : undefined,
      fieldType: CONTROLLED_INPUT_TYPES.FOUR_DECIMAL_PLACES,
      allowDecimal: true,
      name: EqrcFields.openExposure + EqrcFields.exposureWatch,
      id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.openExposure + EqrcFields.exposureWatch),
      style: { gridColumn: "span 4" },
      validation: {
        required: {
          onForms: {
            [Forms.EQRC_GROSS_EXPOSURE.key]: true,
            [Forms.EQRC_EDIT_GROSS_EXPOSURE.key]: false,
          },
        },
        greaterThan: {
          onForms: {
            [Forms.EQRC_GROSS_EXPOSURE.key]: true,
            [Forms.EQRC_EDIT_GROSS_EXPOSURE.key]: true,
          },
          orEqualToValue: true,
          valueToCheck: 0,
        },
        lessThan: {
          onForms: {
            [Forms.EQRC_GROSS_EXPOSURE.key]: true,
            [Forms.EQRC_EDIT_GROSS_EXPOSURE.key]: true,
          },
          orEqualToValue: true,
          valueToCheck: MAX_DECIMAL,
        },
      },
      validateOther: [
        addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.openExposure + EqrcFields.exposureWarn),
        addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.openExposure + EqrcFields.exposureAction),
      ],
    },
  }),
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.openExposure + EqrcFields.exposureWarn)]: ({
    form,
  }) => ({
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Open Exposure Warn",
      placeholder: form === Forms.EQRC_EDIT_GROSS_EXPOSURE ? EDIT_ALERT_PLACEHOLDER : undefined,
      name: EqrcFields.openExposure + EqrcFields.exposureWarn,
      id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.openExposure + EqrcFields.exposureWarn),
      style: { gridColumn: "span 4" },
      fieldType: CONTROLLED_INPUT_TYPES.FOUR_DECIMAL_PLACES,
      validation: {
        required: {
          onForms: {
            [Forms.EQRC_GROSS_EXPOSURE.key]: true,
            [Forms.EQRC_EDIT_GROSS_EXPOSURE.key]: false,
          },
        },
        greaterThan: {
          onForms: {
            [Forms.EQRC_GROSS_EXPOSURE.key]: true,
            [Forms.EQRC_EDIT_GROSS_EXPOSURE.key]: true,
          },
          orEqualToValue: true,
          orEqualToField: false,
          zeroMeansDisabled: true,
          valueToCheck: 0,
          fields: [
            addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.openExposure + EqrcFields.exposureWatch),
          ],
        },
        lessThan: {
          onForms: {
            [Forms.EQRC_GROSS_EXPOSURE.key]: true,
            [Forms.EQRC_EDIT_GROSS_EXPOSURE.key]: true,
          },
          orEqualToValue: true,
          valueToCheck: MAX_DECIMAL,
        },
      },
      validateOther: [
        addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.openExposure + EqrcFields.exposureWatch),
        addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.openExposure + EqrcFields.exposureAction),
      ],
    },
  }),
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.openExposure + EqrcFields.exposureAction)]: ({
    form,
  }) => ({
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Open Exposure Action",
      placeholder: form === Forms.EQRC_EDIT_GROSS_EXPOSURE ? EDIT_ALERT_PLACEHOLDER : undefined,
      name: EqrcFields.openExposure + EqrcFields.exposureAction,
      id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.openExposure + EqrcFields.exposureAction),
      style: { gridColumn: "span 4" },
      fieldType: CONTROLLED_INPUT_TYPES.FOUR_DECIMAL_PLACES,
      validation: {
        required: {
          onForms: {
            [Forms.EQRC_GROSS_EXPOSURE.key]: true,
            [Forms.EQRC_EDIT_GROSS_EXPOSURE.key]: false,
          },
        },
        greaterThan: {
          onForms: {
            [Forms.EQRC_GROSS_EXPOSURE.key]: true,
            [Forms.EQRC_EDIT_GROSS_EXPOSURE.key]: true,
          },
          orEqualToValue: true,
          orEqualToField: false,
          zeroMeansDisabled: true,
          valueToCheck: 0,
          fields: [
            addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.openExposure + EqrcFields.exposureWarn),
          ],
        },
        lessThan: {
          onForms: {
            [Forms.EQRC_GROSS_EXPOSURE.key]: true,
            [Forms.EQRC_EDIT_GROSS_EXPOSURE.key]: true,
          },
          orEqualToValue: true,
          valueToCheck: MAX_DECIMAL,
        },
      },
      validateOther: [
        addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.openExposure + EqrcFields.exposureWarn),
        addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.openExposure + EqrcFields.exposureWatch),
      ],
    },
  }),
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.executedExposure + EqrcFields.exposureWatch)]: ({
    form,
  }) => ({
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Executed Exposure Watch",
      placeholder: form === Forms.EQRC_EDIT_GROSS_EXPOSURE ? EDIT_ALERT_PLACEHOLDER : undefined,

      name: EqrcFields.executedExposure + EqrcFields.exposureWatch,
      id: addPrefixToField(
        EQRC_FIELD_PREFIX,
        EqrcFields.executedExposure + EqrcFields.exposureWatch
      ),
      style: { gridColumn: "span 4" },
      fieldType: CONTROLLED_INPUT_TYPES.FOUR_DECIMAL_PLACES,
      validation: {
        required: {
          onForms: {
            [Forms.EQRC_GROSS_EXPOSURE.key]: true,
            [Forms.EQRC_EDIT_GROSS_EXPOSURE.key]: false,
          },
        },
        greaterThan: {
          onForms: {
            [Forms.EQRC_GROSS_EXPOSURE.key]: true,
            [Forms.EQRC_EDIT_GROSS_EXPOSURE.key]: true,
          },
          orEqualToValue: true,
          valueToCheck: 0,
        },
        lessThan: {
          onForms: {
            [Forms.EQRC_GROSS_EXPOSURE.key]: true,
            [Forms.EQRC_EDIT_GROSS_EXPOSURE.key]: true,
          },
          orEqualToValue: true,
          valueToCheck: MAX_DECIMAL,
        },
      },
      validateOther: [
        addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.executedExposure + EqrcFields.exposureWarn),
        addPrefixToField(
          EQRC_FIELD_PREFIX,
          EqrcFields.executedExposure + EqrcFields.exposureAction
        ),
      ],
    },
  }),
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.executedExposure + EqrcFields.exposureWarn)]: ({
    form,
  }) => ({
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Executed Exposure Warn",
      placeholder: form === Forms.EQRC_EDIT_GROSS_EXPOSURE ? EDIT_ALERT_PLACEHOLDER : undefined,

      name: EqrcFields.executedExposure + EqrcFields.exposureWarn,
      id: addPrefixToField(
        EQRC_FIELD_PREFIX,
        EqrcFields.executedExposure + EqrcFields.exposureWarn
      ),
      style: { gridColumn: "span 4" },
      fieldType: CONTROLLED_INPUT_TYPES.FOUR_DECIMAL_PLACES,
      validation: {
        required: {
          onForms: {
            [Forms.EQRC_GROSS_EXPOSURE.key]: true,
            [Forms.EQRC_EDIT_GROSS_EXPOSURE.key]: false,
          },
        },
        greaterThan: {
          onForms: {
            [Forms.EQRC_GROSS_EXPOSURE.key]: true,
            [Forms.EQRC_EDIT_GROSS_EXPOSURE.key]: true,
          },
          orEqualToField: false,
          orEqualToValue: true,
          zeroMeansDisabled: true,
          valueToCheck: 0,
          fields: [
            addPrefixToField(
              EQRC_FIELD_PREFIX,
              EqrcFields.executedExposure + EqrcFields.exposureWatch
            ),
          ],
        },
        lessThan: {
          onForms: {
            [Forms.EQRC_GROSS_EXPOSURE.key]: true,
            [Forms.EQRC_EDIT_GROSS_EXPOSURE.key]: true,
          },
          orEqualToValue: true,
          valueToCheck: MAX_DECIMAL,
        },
        validateOther: [
          addPrefixToField(
            EQRC_FIELD_PREFIX,
            EqrcFields.executedExposure + EqrcFields.exposureAction
          ),
          addPrefixToField(
            EQRC_FIELD_PREFIX,
            EqrcFields.executedExposure + EqrcFields.exposureWatch
          ),
        ],
      },
    },
  }),
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.executedExposure + EqrcFields.exposureAction)]: ({
    form,
  }) => ({
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Executed Exposure Action",
      placeholder: form === Forms.EQRC_EDIT_GROSS_EXPOSURE ? EDIT_ALERT_PLACEHOLDER : undefined,

      name: EqrcFields.executedExposure + EqrcFields.exposureAction,
      id: addPrefixToField(
        EQRC_FIELD_PREFIX,
        EqrcFields.executedExposure + EqrcFields.exposureAction
      ),
      style: { gridColumn: "span 4" },
      fieldType: CONTROLLED_INPUT_TYPES.FOUR_DECIMAL_PLACES,
      validation: {
        required: {
          onForms: {
            [Forms.EQRC_GROSS_EXPOSURE.key]: true,
            [Forms.EQRC_EDIT_GROSS_EXPOSURE.key]: false,
          },
        },
        greaterThan: {
          onForms: {
            [Forms.EQRC_GROSS_EXPOSURE.key]: true,
            [Forms.EQRC_EDIT_GROSS_EXPOSURE.key]: true,
          },
          orEqualToValue: true,
          orEqualToField: false,
          zeroMeansDisabled: true,
          valueToCheck: 0,
          fields: [
            addPrefixToField(
              EQRC_FIELD_PREFIX,
              EqrcFields.executedExposure + EqrcFields.exposureWarn
            ),
          ],
        },
        lessThan: {
          onForms: {
            [Forms.EQRC_GROSS_EXPOSURE.key]: true,
            [Forms.EQRC_EDIT_GROSS_EXPOSURE.key]: true,
          },
          orEqualToValue: true,
          valueToCheck: MAX_DECIMAL,
        },
      },
      validateOther: [
        addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.executedExposure + EqrcFields.exposureWarn),
        addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.executedExposure + EqrcFields.exposureWatch),
      ],
    },
  }),
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.notionalExposure + EqrcFields.exposureWatch)]: ({
    form,
  }) => ({
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Notional Exposure Watch",
      placeholder: form === Forms.EQRC_EDIT_GROSS_EXPOSURE ? EDIT_ALERT_PLACEHOLDER : undefined,
      name: EqrcFields.notionalExposure + EqrcFields.exposureWatch,
      id: addPrefixToField(
        EQRC_FIELD_PREFIX,
        EqrcFields.notionalExposure + EqrcFields.exposureWatch
      ),
      style: { gridColumn: "span 4" },
      fieldType: CONTROLLED_INPUT_TYPES.FOUR_DECIMAL_PLACES,
      validation: {
        required: {
          onForms: {
            [Forms.EQRC_GROSS_EXPOSURE.key]: true,
            [Forms.EQRC_EDIT_GROSS_EXPOSURE.key]: false,
          },
        },
        greaterThan: {
          onForms: {
            [Forms.EQRC_GROSS_EXPOSURE.key]: true,
            [Forms.EQRC_EDIT_GROSS_EXPOSURE.key]: true,
          },
          orEqualToValue: true,
          valueToCheck: 0,
        },
        lessThan: {
          onForms: {
            [Forms.EQRC_GROSS_EXPOSURE.key]: true,
            [Forms.EQRC_EDIT_GROSS_EXPOSURE.key]: true,
          },
          orEqualToValue: true,
          valueToCheck: MAX_DECIMAL,
        },
      },
      validateOther: [
        addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.notionalExposure + EqrcFields.exposureWarn),
        addPrefixToField(
          EQRC_FIELD_PREFIX,
          EqrcFields.notionalExposure + EqrcFields.exposureAction
        ),
      ],
    },
  }),
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.notionalExposure + EqrcFields.exposureWarn)]: ({
    form,
  }) => ({
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Notional Exposure Warn",
      placeholder: form === Forms.EQRC_EDIT_GROSS_EXPOSURE ? EDIT_ALERT_PLACEHOLDER : undefined,
      name: EqrcFields.notionalExposure + EqrcFields.exposureWarn,
      id: addPrefixToField(
        EQRC_FIELD_PREFIX,
        EqrcFields.notionalExposure + EqrcFields.exposureWarn
      ),
      style: { gridColumn: "span 4" },
      fieldType: CONTROLLED_INPUT_TYPES.FOUR_DECIMAL_PLACES,
      validation: {
        required: {
          onForms: {
            [Forms.EQRC_GROSS_EXPOSURE.key]: true,
            [Forms.EQRC_EDIT_GROSS_EXPOSURE.key]: false,
          },
        },
        greaterThan: {
          onForms: {
            [Forms.EQRC_GROSS_EXPOSURE.key]: true,
            [Forms.EQRC_EDIT_GROSS_EXPOSURE.key]: true,
          },
          orEqualToValue: true,
          orEqualToField: false,
          zeroMeansDisabled: true,
          valueToCheck: 0,
          fields: [
            addPrefixToField(
              EQRC_FIELD_PREFIX,
              EqrcFields.notionalExposure + EqrcFields.exposureWatch
            ),
          ],
        },
        lessThan: {
          onForms: {
            [Forms.EQRC_GROSS_EXPOSURE.key]: true,
            [Forms.EQRC_EDIT_GROSS_EXPOSURE.key]: true,
          },
          orEqualToValue: true,
          valueToCheck: MAX_DECIMAL,
        },
        validateOther: [
          addPrefixToField(
            EQRC_FIELD_PREFIX,
            EqrcFields.notionalExposure + EqrcFields.exposureWatch
          ),
          addPrefixToField(
            EQRC_FIELD_PREFIX,
            EqrcFields.notionalExposure + EqrcFields.exposureAction
          ),
        ],
      },
    },
  }),
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.notionalExposure + EqrcFields.exposureAction)]: ({
    form,
  }) => ({
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Notional Exposure Action",
      placeholder: form === Forms.EQRC_EDIT_GROSS_EXPOSURE ? EDIT_ALERT_PLACEHOLDER : undefined,
      name: EqrcFields.notionalExposure + EqrcFields.exposureAction,
      id: addPrefixToField(
        EQRC_FIELD_PREFIX,
        EqrcFields.notionalExposure + EqrcFields.exposureAction
      ),
      style: { gridColumn: "span 4" },
      fieldType: CONTROLLED_INPUT_TYPES.FOUR_DECIMAL_PLACES,
      validation: {
        required: {
          onForms: {
            [Forms.EQRC_GROSS_EXPOSURE.key]: true,
            [Forms.EQRC_EDIT_GROSS_EXPOSURE.key]: false,
          },
        },
        greaterThan: {
          onForms: {
            [Forms.EQRC_GROSS_EXPOSURE.key]: true,
            [Forms.EQRC_EDIT_GROSS_EXPOSURE.key]: true,
          },
          orEqualToValue: true,
          orEqualToField: false,
          zeroMeansDisabled: true,
          valueToCheck: 0,
          fields: [
            addPrefixToField(
              EQRC_FIELD_PREFIX,
              EqrcFields.notionalExposure + EqrcFields.exposureWarn
            ),
          ],
        },
        lessThan: {
          onForms: {
            [Forms.EQRC_GROSS_EXPOSURE.key]: true,
            [Forms.EQRC_EDIT_GROSS_EXPOSURE.key]: true,
          },
          orEqualToValue: true,
          valueToCheck: MAX_DECIMAL,
        },
      },
      validateOther: [
        addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.notionalExposure + EqrcFields.exposureWarn),
        addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.notionalExposure + EqrcFields.exposureWatch),
      ],
    },
  }),
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.breachAction)]: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      label: "Breach Action",
      name: EqrcFields.breachAction,
      id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.breachAction),
      style: { gridColumn: "span 4" },
      validation: {
        required: {
          onForms: {
            [Forms.EQRC_GROSS_EXPOSURE.key]: true,
            [Forms.EQRC_EDIT_GROSS_EXPOSURE.key]: false,
          },
        },
      },
      options: SelectOptions[addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.breachAction)],
    },
  },
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.status)]: props => ({
    Type: FormRadioCheckboxGroup,
    TypeName: "FormRadioCheckboxGroup",
    props: {
      inline: props?.form?.key === Forms.EQRC_ACTIVE_OR_CONFIGURED_TABLE.key,
      Type: "radio",
      id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.status),
      name: EqrcFields.status,
      options: [
        {
          label: DAY_TYPES[EQRC_RULE_STATUS.Active],
          value: EQRC_RULE_STATUS.Active,
        },
        {
          label: DAY_TYPES[EQRC_RULE_STATUS.Configured],
          value: EQRC_RULE_STATUS.Configured,
        },
      ],
    },
  }),

  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.dateRange)]: {
    Type: DatePicker,
    TypeName: "DatePicker",
    props: {
      label: "Date Range",
      name: EqrcFields.dateRange,
      id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.dateRange),
      placeholder: "Select...",
      rangeType: "range",
      style: { gridColumn: "span 2" },
      validation: {
        required: {
          onForms: {
            [Forms.EQRC_HISTORY_MENU.key]: true,
          },
        },
      },
      zIndex: 1061,
      augmentOnChange: {
        [Forms.EQRC_AUDIT_MENU.key]: AUGMENT_ON_CHANGE.SET_EQRC_START_END_DATE,
        [Forms.EQRC_HISTORY_MENU.key]: AUGMENT_ON_CHANGE.SET_EQRC_START_END_DATE,
      },
    },
  },
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.beginDate)]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Start Date",
      name: EqrcFields.beginDate,
      id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.beginDate),
    },
  },
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.endDate)]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "End Date",
      name: EqrcFields.endDate,
      id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.endDate),
    },
  },
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.perOrderSharesLimit)]: ({ form }) => ({
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Per Order Shares Limit",
      placeholder:
        Forms.EQRC_EDIT_MAX_SHARES_PER_ORDER === form ? EDIT_ALERT_PLACEHOLDER : undefined,
      style: { gridColumn: "span 4" },
      fieldType: "numeric",
      name: EqrcFields.perOrderSharesLimit,
      id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.perOrderSharesLimit),
      validation: {
        required: {
          onForms: {
            [Forms.EQRC_MAX_SHARES_PER_ORDER.key]: true,
            [Forms.EQRC_EDIT_MAX_SHARES_PER_ORDER.key]: false,
          },
        },
        greaterThan: {
          onForms: {
            [Forms.EQRC_MAX_SHARES_PER_ORDER.key]: true,
            [Forms.EQRC_EDIT_MAX_SHARES_PER_ORDER.key]: true,
          },
          orEqualToValue: true,
          valueToCheck: 0,
        },
        lessThan: {
          onForms: {
            [Forms.EQRC_MAX_SHARES_PER_ORDER.key]: true,
            [Forms.EQRC_EDIT_MAX_SHARES_PER_ORDER.key]: true,
          },
          orEqualToValue: true,
          valueToCheck: 999999,
        },
      },
    },
  }),
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.perOrderNotionalLimit)]: ({ form }) => ({
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Per Order Notional Limit",
      placeholder: Forms.EQRC_EDIT_MAX_NOTIONAL_ORDER === form ? EDIT_ALERT_PLACEHOLDER : undefined,
      style: { gridColumn: "span 4" },
      fieldType: "numeric",
      name: EqrcFields.perOrderNotionalLimit,
      id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.perOrderNotionalLimit),
      maxLength: 15,
      validation: {
        mustPerformMathCheck: {
          onForms: {
            [Forms.EQRC_MAX_NOTIONAL_ORDER.key]: true,
            [Forms.EQRC_EDIT_MAX_NOTIONAL_ORDER.key]: true,
          },
          mathPredicate: value => value % 1000 === 0,
          messageFragment: "a multiple of 1000",
        },
        required: {
          onForms: {
            [Forms.EQRC_MAX_NOTIONAL_ORDER.key]: true,
            [Forms.EQRC_EDIT_MAX_NOTIONAL_ORDER.key]: true,
          },
        },
        greaterThan: {
          onForms: {
            [Forms.EQRC_MAX_NOTIONAL_ORDER.key]: true,
            [Forms.EQRC_EDIT_MAX_NOTIONAL_ORDER.key]: true,
          },
          orEqualToValue: true,
          valueToCheck: 0,
        },
        lessThan: {
          onForms: {
            [Forms.EQRC_MAX_NOTIONAL_ORDER.key]: true,
            [Forms.EQRC_EDIT_MAX_NOTIONAL_ORDER.key]: true,
          },
          orEqualToValue: true,
          valueToCheck: 922337203685000,
        },
      },
    },
  }),
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.sharesLocatedSSE)]: ({ form }) => {
    let Type, TypeName;
    switch (form) {
      case Forms.EQRC_EDIT_SHARES_LOCATED_CHECK:
        Type = FormSelect;
        TypeName = "FormSelect";
        break;
      default:
        Type = FormRadioCheckboxButton;
        TypeName = "FormRadioCheckboxButton";
        break;
    }
    return {
      Type,
      TypeName,
      props: {
        label: "Include Short Sale Exempt",
        placeholder:
          Forms.EQRC_EDIT_SHARES_LOCATED_CHECK === form ? EDIT_ALERT_PLACEHOLDER : undefined,
        style: { gridColumn: "span 5" },
        name: EqrcFields.sharesLocatedSSE,
        id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.sharesLocatedSSE),
        options: SelectOptions[addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.sharesLocatedSSE)],
        disabledWhen: [
          {
            field: EqrcFields.sharesLocatedOption,
            value: value =>
              value === EQRC_SHARES_LOCATED_OPTIONS[EQRC_SHARES_LOCATED_VALUES.DISABLED],
          },
        ],
      },
    };
  },
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.sharesLocatedOption)]: ({ form }) => ({
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      label: "Shares Located Check",
      placeholder:
        Forms.EQRC_EDIT_SHARES_LOCATED_CHECK === form ? EDIT_ALERT_PLACEHOLDER : undefined,
      style: { gridColumn: "span 5" },
      options: SelectOptions[addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.sharesLocatedOption)],
      name: EqrcFields.sharesLocatedOption,
      id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.sharesLocatedOption),
      validation: {
        required: {
          onForms: {
            [Forms.EQRC_EDIT_SHARES_LOCATED_CHECK.key]: false,
            [Forms.EQRC_SHARES_LOCATED_CHECK.key]: true,
          },
        },
      },
    },
  }),
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.sharesLocateRuleWarning)]: ({ form }) => ({
    Type: WorkXText,
    TypeName: "WorkXText",
    props: {
      name: EqrcFields.sharesLocateRuleWarning,
      id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.sharesLocateRuleWarning),
      style: { gridColumn: "span 5", fontSize: "12px", marginTop: "-2rem" },

      children: (
        <p>
          *Note that this rule is only intended for the OUCH 5.0 and INET FIX protocols. Any rule
          set up at the MPID level which contains ports with other protocols will result in the
          rejection of any Short Sale or Short Sale Exempt orders on those ports.
        </p>
      ),
    },
  }),
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.brokersListRule)]: ({ form }) => ({
    Type: WorkXSelectPersistCreatable,
    TypeName: "WorkXSelectPersistCreatable",
    props: {
      label: "Broker List",
      placeholder:
        Forms.EQRC_EDIT_SHARES_LOCATED_CHECK === form
          ? EDIT_ALERT_PLACEHOLDER_FOR_CHANGES_MADE
          : undefined,
      style: { gridColumn: "span 12" },
      isMulti: true,
      name: EqrcFields.brokersListRule,
      id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.brokersListRule),
      persistKey: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.brokersListRule),
      onCreateValidation: value => value.length === 4 && RegExp(/^[a-zA-Z1-9]+$/).test(value),
      forceUpperCase: true,
      helpText: formValues => {
        return formValues[`${OPPOSITE_ROW}${EqrcFields.allowBrokerList}`];
      },
      disabledWhen: [
        {
          field: EqrcFields.sharesLocatedOption,
          value: value => {
            return ![
              EQRC_SHARES_LOCATED_OPTIONS[EQRC_SHARES_LOCATED_VALUES.ENABLED],
              undefined,
            ].includes(value);
          },
        },
        {
          field: `${OPPOSITE_ROW}${EqrcFields.allowBrokerList}`,
          value: value => {
            return !["", true, undefined].includes(value);
          },
        },
        {
          field: EqrcFields.enableBrokerList,
          value: value => {
            return [false, undefined].includes(value);
          },
        },
      ],
    },
  }),
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.enableBrokerList)]: ({ form }) => ({
    Type: FormRadioCheckboxButton,
    TypeName: "FormRadioCheckboxButton",
    props: {
      label: "Enable Broker List",
      style: { gridColumn: "span 12" },
      name: EqrcFields.enableBrokerList,
      id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.enableBrokerList),
    },
  }),
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.portAction)]: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      label: "Port Action",
      id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.portAction),
      name: EqrcFields.portAction,
      options: SelectOptions[addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.portAction)],
      validation: {
        requiredIfAnyFieldValue: {
          otherFields: [
            {
              field: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.cancelAction),
              value: comparators.emptyArrayorString,
            },
          ],
          onForms: { [Forms.EQRC_ACTIONS.key]: true },
        },
      },
    },
  },
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.cancelAction)]: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      label: "Cancel Action",
      name: EqrcFields.cancelAction,
      id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.cancelAction),
      options: SelectOptions[addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.cancelAction)],
      validation: {
        requiredIfAnyFieldValue: {
          otherFields: [
            {
              field: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.portAction),
              value: comparators.falseNullOrUndefined,
            },
          ],
          onForms: { [Forms.EQRC_ACTIONS.key]: true },
        },
      },
    },
  },
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.ruleStatus)]: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      label: "Cancel Action",
      name: EqrcFields.cancelAction,
      id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.cancelAction),
      options: SelectOptions[addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.cancelAction)],
      validation: {
        requiredIfAnyFieldValue: {
          otherFields: [
            {
              field: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.portAction),
              value: comparators.falseNullOrUndefined,
            },
          ],
          onForms: { [Forms.EQRC_ACTIONS.key]: true },
        },
      },
    },
  },
  [FieldNames.workXTheme]: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      label: "WorkX Theme",
      name: FieldNames.workXTheme,
      id: FieldNames.workXTheme,
      options: SelectOptions[FieldNames.workXTheme],
      style: width100,
      isClearable: false,
    },
  },
  [FieldNames.workXNotificationPlacement]: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      label: "Notification Placement",
      name: FieldNames.workXNotificationPlacement,
      id: FieldNames.workXNotificationPlacement,
      options: SelectOptions[FieldNames.workXNotificationPlacement],
      style: width100,
      isClearable: false,
    },
  },
  [FieldNames.clearingScanMPID]: {
    Type: WorkXSelectPersistCreatable,
    TypeName: "WorkXSelectPersistCreatable",
    props: {
      label: "MPID",
      forceUpperCase: true,
      isMulti: true,
      name: FieldNames.clearingScanMPID,
      id: FieldNames.clearingScanMPID,
      inputId: FieldNames.clearingScanMPID,
      style: width100,
      required: true,
      persistKey: FieldNames.clearingScanMPID,
      onCreateValidation: value => value.length === 4 && RegExp(/^[a-zA-Z]+$/).test(value),
      createValidationMessage: "Please enter 4 letters",
      validation: {
        required: {
          onForms: {
            [Forms.RD_CLEARING.key]: true,
          },
        },
        maxSelected: {
          max: 100,
          onForms: { [Forms.RD_CLEARING.key]: true },
        },
      },
    },
  },
  [addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.exchange)]: () => {
    const id = addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.exchange);
    return {
      Type: FormSelect,
      TypeName: "FormSelect",
      props: {
        isMulti: false,
        label: "Exchange",
        name: RASH_ORDER.exchange,
        id: id,
        options: SelectOptions[id],
        style: width114,
        validation: {
          required: {
            onForms: {
              [Forms.ORDER_ENTRY.key]: true,
            },
          },
        },
      },
    };
  },
  [addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.symbol)]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Symbol",
      name: RASH_ORDER.symbol,
      id: addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.symbol),
      style: width100,
      Type: "alpha",
      fieldType: [
        { type: CONTROLLED_INPUT_TYPES.SYMBOL, forms: ALL_FORMS },
        { type: CONTROLLED_INPUT_TYPES.INVERSE_CHAR_CASE, forms: [Forms.ORDER_ENTRY.key] },
      ],
      maxLength: 8,
      helpText: inverseCharacterCaseHelpText,
      validation: {
        required: {
          onForms: {
            [Forms.ORDER_ENTRY.key]: true,
          },
        },
      },
    },
  },
  [addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.pegType)]: () => {
    const id = addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.pegType);
    return {
      Type: FormSelect,
      TypeName: "FormSelect",
      props: {
        isMulti: false,
        label: "Peg Type",
        name: RASH_ORDER.pegType,
        id,
        options: SelectOptions[id],
        style: width100,
        validation: {
          required: {
            [Forms.ORDER_ENTRY.key]: true,
          },
        },
      },
    };
  },
  [addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.display)]: () => {
    const id = addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.display);
    return {
      Type: FormField,
      TypeName: "FormField",
      props: {
        label: "Display",
        name: RASH_ORDER.display,
        id,
        style: width100,
        validation: {
          required: {
            onForms: {
              [Forms.ORDER_ENTRY.key]: true,
            },
          },
        },
      },
    };
  },
  [addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.side)]: () => {
    const id = addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.side);
    return {
      Type: FormSelect,
      TypeName: "FormSelect",
      props: {
        isMulti: false,
        label: "Side",
        name: RASH_ORDER.side,
        id,
        options: SelectOptions[id],
        style: width100,
        validation: {
          required: {
            onForms: {
              [Forms.ORDER_ENTRY.key]: true,
            },
          },
        },
      },
    };
  },
  [addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.price)]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Price",
      name: RASH_ORDER.price,
      id: addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.price),
      style: width100,
      Type: "text",
      fieldType: CONTROLLED_INPUT_TYPES.RASH_PRICE,
      validation: {
        required: {
          onForms: {
            [Forms.ORDER_ENTRY.key]: true,
          },
        },
      },
    },
  },

  [addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.orderQuantity)]: ({ form }) => {
    const helpText = form === Forms.ORDER_CANCEL ? "Specify 0 to cancel entire order" : undefined;
    const label = form === Forms.ORDER_CANCEL ? "Remaining Quantity" : "Total Quantity";
    return {
      Type: FormField,
      TypeName: "FormField",
      props: {
        label,
        name: RASH_ORDER.orderQuantity,
        id: addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.orderQuantity),
        style: form => {
          switch (form) {
            case Forms.ORDER_CANCEL:
              return width100;
            default:
              return width114;
          }
        },
        helpText,
        Type: "text",
        fieldType: "numeric",
        validation: {
          required: {
            onForms: {
              [Forms.ORDER_ENTRY.key]: true,
            },
          },
        },
      },
    };
  },
  [addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.maxFloor)]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Display Quantity",
      name: RASH_ORDER.maxFloor,
      id: addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.maxFloor),
      style: width114,
      Type: "text",
      fieldType: "numeric",
    },
  },
  [addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.route)]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Routing",
      name: RASH_ORDER.route,
      id: addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.route),
      fieldType: [
        { type: CONTROLLED_INPUT_TYPES.INVERSE_CHAR_CASE, forms: [Forms.ORDER_ENTRY.key] },
      ],
      helpText: inverseCharacterCaseHelpText,
      style: width100,
      validation: {
        required: {
          onForms: {
            [Forms.ORDER_ENTRY.key]: true,
          },
        },
      },
    },
  },
  [addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.timeInForce)]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      name: RASH_ORDER.timeInForce,
      id: addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.timeInForce),
      label: "TIF",
      style: width114,
      required: true,
      numberOfInputs: 2,
    },
  },
  [addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.capacity)]: () => {
    const id = addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.capacity);
    return {
      Type: FormSelect,
      TypeName: "FormSelect",
      props: {
        isMulti: false,
        label: "Capacity",
        name: RASH_ORDER.capacity,
        id,
        options: SelectOptions[id],
        style: width100,
        validation: {
          required: {
            onForms: {
              [Forms.ORDER_ENTRY.key]: true,
            },
          },
        },
      },
    };
  },
  [addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.firm)]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Branch SQ#",
      name: RASH_ORDER.firm,
      id: addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.firm),
      Type: "text",
      style: width100,
    },
  },
  [addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.subId)]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Sender Sub ID",
      name: RASH_ORDER.subId,
      id: addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.subId),
      Type: "text",
      style: width100,
    },
  },
  [addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.entryDate)]: {
    Type: DatePicker,
    TypeName: "FormField",
    props: {
      label: "Received Date",
      name: RASH_ORDER.entryDate,
      id: addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.entryDate),
      style: width100,
    },
  },

  [addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.pegType)]: () => {
    const id = addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.pegType);
    return {
      Type: FormSelect,
      TypeName: "FormSelect",
      props: {
        isMulti: false,
        label: "Peg Type",
        name: RASH_ORDER.pegType,
        id,
        options: SelectOptions[id],
        style: width100,
        validation: {
          required: {},
        },
      },
    };
  },
  [addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.pegDifference)]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Peg Offset",
      name: RASH_ORDER.pegDifference,
      id: addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.pegDifference),
      Type: "text",
      style: width100,
    },
  },
  [addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.discretionPegType)]: () => {
    const id = addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.discretionPegType);
    return {
      Type: FormSelect,
      TypeName: "FormSelect",
      props: {
        isMulti: false,
        label: "Discretion Peg Type",
        name: RASH_ORDER.discretionPegType,
        id,
        options: SelectOptions[id],
        style: width100,
        validation: {
          required: {},
        },
      },
    };
  },
  [addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.discretionPrice)]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Discretion Price",
      name: RASH_ORDER.discretionPrice,
      id: addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.discretionPrice),
      Type: "text",
      fieldType: "price",
      style: width100,
    },
  },
  [addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.discretionPegDifference)]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Discretion Offset",
      name: RASH_ORDER.discretionPegDifference,
      id: addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.discretionPegDifference),
      style: width100,
    },
  },
  [addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.minQuantity)]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Min Quantity",
      name: RASH_ORDER.minQuantity,
      id: addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.minQuantity),
      Type: "text",
      fieldType: "numeric",
      style: width100,
    },
  },
  [addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.randomReserve)]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Random Reserve",
      name: RASH_ORDER.randomReserve,
      id: addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.randomReserve),
      Type: "text",
      fieldType: "numeric",
      style: width100,
    },
  },
  [addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.intermarketSweep)]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Intermarket Sweep",
      name: RASH_ORDER.intermarketSweep,
      id: addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.intermarketSweep),
      Type: "text",
      style: width100,
    },
  },
  [addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.crossType)]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Cross Type",
      name: RASH_ORDER.crossType,
      id: addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.crossType),
      Type: "text",
      style: width100,
    },
  },
  [addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.reactiveTradeNow)]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Reactive Trade Now",
      name: RASH_ORDER.reactiveTradeNow,
      id: addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.reactiveTradeNow),
      Type: "text",
      style: width100,
    },
  },
  [addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.customerType)]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Customer Type",
      name: RASH_ORDER.customerType,
      id: addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.customerType),
      Type: "text",
      style: width100,
    },
  },
  [FieldNames.ptrNextDayCheckbox]: {
    Type: FormRadioCheckboxButton,
    TypeName: "FormRadioCheckboxButton",
    props: {
      label: "Save to Next Day",
      name: FieldNames.ptrNextDayCheckbox,
      id: FieldNames.ptrNextDayCheckbox,
      Type: "checkbox",
    },
  },
  [addPrefixToField(PV_REJECT_FIELD_PREFIX, PV_REJECT_TOP.mpid)]: getMpid({
    name: PV_REJECT_TOP.mpid,
    id: addPrefixToField(PV_REJECT_FIELD_PREFIX, PV_REJECT_TOP.mpid),
  }),
  [addPrefixToField(PV_REJECT_FIELD_PREFIX, PV_REJECT_TOP.rejectDate)]: () => {
    const id = addPrefixToField(PV_REJECT_FIELD_PREFIX, PV_REJECT_TOP.rejectDate);
    return {
      Type: FormSelect,
      TypeName: "FormSelect",
      props: {
        isMulti: false,
        placeholder: "Reject Date",
        name: PV_REJECT_TOP.rejectDate,
        id,
        options: SelectOptions[id],
        marginRight: 0,
        isClearable: false,
      },
    };
  },
  [PVR_FIELD_ID[PVR_CONFIGURATION_ENTITY.isAllMpid]]: () => {
    const label = "Subscribe for all MPIDs";
    const name = PVR_CONFIGURATION_ENTITY.isAllMpid;
    const style = width100;
    const id = PVR_FIELD_ID[PVR_CONFIGURATION_ENTITY.isAllMpid];
    return {
      Type: FormRadioCheckboxButton,
      TypeName: "FormRadioCheckboxButton",
      props: {
        label,
        name,
        style,
        id,
        Type: "checkbox",
        validateOther: [PVR_FIELD_ID[PVR_CONFIGURATION_ENTITY.mpid]],
      },
    };
  },
  [PVR_FIELD_ID[PVR_CONFIGURATION_ENTITY.mpid]]: getMpid({
    name: PVR_CONFIGURATION_ENTITY.mpid,
    id: PVR_FIELD_ID[PVR_CONFIGURATION_ENTITY.mpid],
  }),
  [PVR_FIELD_ID[PVR_CONFIGURATION_ENTITY.porStatusAlertActive]]: ({ form }) => {
    const label = PVR_ALERT_TYPE_LABEL[PVR_ALERT_TYPE.POR_STATUS];
    const name = PVR_CONFIGURATION_ENTITY.porStatusAlertActive;
    const style = width100;
    const id = PVR_FIELD_ID[PVR_CONFIGURATION_ENTITY.porStatusAlertActive];
    return [Forms.PVR_EDIT].includes(form)
      ? {
          Type: FormSelect,
          TypeName: "FormSelect",
          props: {
            label,
            name,
            id,
            options: SelectOptions.alertEditOptions,
            style,
            placeholder: EDIT_ALERT_PLACEHOLDER,
          },
        }
      : {
          Type: FormRadioCheckboxButton,
          TypeName: "FormRadioCheckboxButton",
          props: {
            label,
            name,
            style,
            id,
            Type: "checkbox",
            validateOther: getAllFieldValuesEmptyValidateOther(id, allPvrAlertOptionFieldIds),
            validation: getAllFieldValuesEmptyValidation(
              id,
              allPvrAlertOptionFieldIds,
              pvrConfigOnForms
            ),
          },
        };
  },
  [PVR_FIELD_ID[PVR_CONFIGURATION_ENTITY.pvWindowAlertActive]]: ({ form }) => {
    const label = PVR_ALERT_TYPE_LABEL[PVR_ALERT_TYPE.POR_WINDOW];
    const name = PVR_CONFIGURATION_ENTITY.pvWindowAlertActive;
    const style = width100;
    const id = PVR_FIELD_ID[PVR_CONFIGURATION_ENTITY.pvWindowAlertActive];
    return [Forms.PVR_EDIT].includes(form)
      ? {
          Type: FormSelect,
          TypeName: "FormSelect",
          props: {
            label,
            name,
            id,
            options: SelectOptions.alertEditOptions,
            style,
            placeholder: EDIT_ALERT_PLACEHOLDER,
          },
        }
      : {
          Type: FormRadioCheckboxButton,
          TypeName: "FormRadioCheckboxButton",
          props: {
            label,
            name,
            style,
            id,
            Type: "checkbox",
            validateOther: getAllFieldValuesEmptyValidateOther(id, allPvrAlertOptionFieldIds),
            validation: getAllFieldValuesEmptyValidation(
              id,
              allPvrAlertOptionFieldIds,
              pvrConfigOnForms
            ),
          },
        };
  },
  [PVR_FIELD_ID[PVR_CONFIGURATION_ENTITY.pvResubmitAlertActive]]: ({ form }) => {
    const label = PVR_ALERT_TYPE_LABEL[PVR_ALERT_TYPE.PV_RESUBMIT];
    const name = PVR_CONFIGURATION_ENTITY.pvResubmitAlertActive;
    const style = width100;
    const id = PVR_FIELD_ID[PVR_CONFIGURATION_ENTITY.pvResubmitAlertActive];
    return [Forms.PVR_EDIT].includes(form)
      ? {
          Type: FormSelect,
          TypeName: "FormSelect",
          props: {
            label,
            name,
            id,
            options: SelectOptions.alertEditOptions,
            style,
            placeholder: EDIT_ALERT_PLACEHOLDER,
          },
        }
      : {
          Type: FormRadioCheckboxButton,
          TypeName: "FormRadioCheckboxButton",
          props: {
            label,
            name,
            style,
            id,
            Type: "checkbox",
            validateOther: getAllFieldValuesEmptyValidateOther(id, allPvrAlertOptionFieldIds),
            validation: getAllFieldValuesEmptyValidation(
              id,
              allPvrAlertOptionFieldIds,
              pvrConfigOnForms
            ),
          },
        };
  },
  [PVR_FIELD_ID[PVR_CONFIGURATION_ENTITY.includeWorkXUserRecipient]]: {
    Type: FormRadioCheckboxButton,
    TypeName: "FormRadioCheckboxButton",
    props: {
      label: PTRA_SEND_ALERT_TO_USER_EMAIL_LABEL,
      name: PVR_CONFIGURATION_ENTITY.includeWorkXUserRecipient,
      id: PVR_FIELD_ID[PVR_CONFIGURATION_ENTITY.includeWorkXUserRecipient],
      style: width100,
      Type: "checkbox",
      augmentOnChange: {
        [Forms.PVR_CONFIG.key]: [AUGMENT_ON_CHANGE.SET_ALERT_LOG],
      },
      validateOther: getAllFieldValuesEmptyValidateOther(
        PVR_FIELD_ID[PVR_CONFIGURATION_ENTITY.includeWorkXUserRecipient],
        allPvrDeliveryOptionFieldIds
      ),
      validation: getAllFieldValuesEmptyValidation(
        PVR_FIELD_ID[PVR_CONFIGURATION_ENTITY.includeWorkXUserRecipient],
        allPvrDeliveryOptionFieldIds,
        pvrConfigOnForms
      ),
    },
  },
  [PVR_FIELD_ID[PVR_CONFIGURATION_ENTITY.emailId]]: () => ({
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      label: "Additional Recipient(s)",
      isMulti: true,
      name: PVR_CONFIGURATION_ENTITY.emailId,
      id: PVR_FIELD_ID[PVR_CONFIGURATION_ENTITY.emailId],
      inputId: PVR_CONFIGURATION_ENTITY.emailId,
      options: SelectOptions[PVR_FIELD_ID[PVR_CONFIGURATION_ENTITY.emailId]],
      style: width100,
      required: false,
      validateOther: getAllFieldValuesEmptyValidateOther(
        PVR_FIELD_ID[PVR_CONFIGURATION_ENTITY.emailId],
        allPvrDeliveryOptionFieldIds
      ),
      validation: getAllFieldValuesEmptyValidation(
        PVR_FIELD_ID[PVR_CONFIGURATION_ENTITY.emailId],
        allPvrDeliveryOptionFieldIds,
        pvrConfigOnForms
      ),
    },
  }),
  [PVR_FIELD_ID[PVR_CONFIGURATION_ENTITY.emailActive]]: () => {
    const label = "Email Real Time";
    const name = PVR_CONFIGURATION_ENTITY.emailActive;
    const style = width100;
    const id = PVR_FIELD_ID[PVR_CONFIGURATION_ENTITY.emailActive];
    return {
      Type: FormSelect,
      TypeName: "FormSelect",
      props: {
        label,
        name,
        id,
        options: SelectOptions.alertEditOptions,
        style,
        placeholder: EDIT_ALERT_PLACEHOLDER,
      },
    };
  },
  [addPrefixToField(PVR_EMAIL_FIELD_PREFIX, PVR_EMAIL_ENTITY.recipientEmail)]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Email",
      name: PVR_EMAIL_ENTITY.recipientEmail,
      showLabel: true,
      style: width100,
      required: true,
      id: addPrefixToField(PVR_EMAIL_FIELD_PREFIX, PVR_EMAIL_ENTITY.recipientEmail),
    },
  },
  [PVR_FIELD_ID[PVR_CONFIGURATION_ENTITY.logActive]]: ({ form }) => {
    const label = "Add Alert to the UI Log";
    const name = PVR_CONFIGURATION_ENTITY.logActive;
    const style = width100;
    const id = PVR_FIELD_ID[PVR_CONFIGURATION_ENTITY.logActive];
    const helpText = PTRA_WORKX_USER_FIELD_HELPTEXT;
    return [Forms.PVR_EDIT].includes(form)
      ? {
          Type: FormSelect,
          TypeName: "FormSelect",
          props: {
            label,
            name,
            id,
            options: SelectOptions.alertEditOptions,
            style,
            placeholder: EDIT_ALERT_PLACEHOLDER,
            helpText,
          },
        }
      : {
          Type: FormRadioCheckboxButton,
          TypeName: "FormRadioCheckboxButton",
          props: {
            label,
            name,
            id,
            style,
            Type: "checkbox",
            helpText,
            getTooltip: ({ disabled }) => {
              switch (disabled) {
                case true:
                  return `Always enabled if '${PTRA_SEND_ALERT_TO_USER_EMAIL_LABEL}' or '${PTRA_POPUPS_LABEL}' is active`;
                case false:
                default:
                  return undefined;
              }
            },
            disabledWhen: [
              {
                field: PVR_CONFIGURATION_ENTITY.includeWorkXUserRecipient,
                value: true,
              },
              {
                field: PVR_CONFIGURATION_ENTITY.isAlertPopupActive,
                value: true,
              },
            ],
            validateOther: getAllFieldValuesEmptyValidateOther(id, allPvrDeliveryOptionFieldIds),
            validation: getAllFieldValuesEmptyValidation(
              id,
              allPvrDeliveryOptionFieldIds,
              pvrConfigOnForms
            ),
          },
        };
  },
  [PVR_FIELD_ID[PVR_CONFIGURATION_ENTITY.popupActive]]: ({ form }) => {
    const label = PTRA_POPUPS_LABEL;
    const name = PVR_CONFIGURATION_ENTITY.popupActive;
    const style = width100;
    const id = PVR_FIELD_ID[PVR_CONFIGURATION_ENTITY.popupActive];
    const helpText = PTRA_WORKX_USER_FIELD_HELPTEXT;
    return [Forms.PVR_EDIT].includes(form)
      ? {
          Type: FormSelect,
          TypeName: "FormSelect",
          props: {
            label,
            name,
            id,
            options: SelectOptions.alertEditOptions,
            style,
            placeholder: EDIT_ALERT_PLACEHOLDER,
            helpText,
          },
        }
      : {
          Type: FormRadioCheckboxButton,
          TypeName: "FormRadioCheckboxButton",
          props: {
            label,
            name,
            id,
            style,
            Type: "checkbox",
            helpText,
            augmentOnChange: {
              [Forms.PVR_CONFIG.key]: [AUGMENT_ON_CHANGE.SET_ALERT_LOG],
            },
            validateOther: getAllFieldValuesEmptyValidateOther(id, allPvrDeliveryOptionFieldIds),
            validation: getAllFieldValuesEmptyValidation(
              id,
              allPvrDeliveryOptionFieldIds,
              pvrConfigOnForms
            ),
          },
        };
  },
  [addPrefixToField(PVR_FIELD_PREFIX, POR_BYPASS_REJECT_AUDIT_FIELD)]: {
    Type: FormRadioCheckboxButton,
    TypeName: "FormRadioCheckboxButton",
    props: {
      label: "Bypass Reject File Persistence",
      name: POR_BYPASS_REJECT_AUDIT_FIELD,
      id: addPrefixToField(PVR_FIELD_PREFIX, POR_BYPASS_REJECT_AUDIT_FIELD),
      style: { marginBottom: "1rem" },
      Type: "checkbox",
    },
  },
  [addPrefixToField(BANNER_FIELD_PREFIX, BANNER_FORM.IS_ENABLED)]: {
    Type: FormRadioCheckboxButton,
    TypeName: "FormRadioCheckboxButton",
    props: {
      label: "Enabled",
      name: BANNER_FORM.IS_ENABLED,
      id: addPrefixToField(BANNER_FIELD_PREFIX, BANNER_FORM.IS_ENABLED),
      style: { ...width100, marginBottom: "1rem" },
      Type: "checkbox",
      validation: {
        required: {
          onForms: {
            [Forms.SETTINGS_BANNER.key]: true,
          },
        },
      },
    },
  },
  [addPrefixToField(BANNER_FIELD_PREFIX, BANNER_FORM.DATE_RANGE)]: {
    Type: DatePicker,
    TypeName: "DatePicker",
    props: {
      label: "Date Range",
      rangeType: "range",
      name: BANNER_FORM.DATE_RANGE,
      id: addPrefixToField(BANNER_FIELD_PREFIX, BANNER_FORM.DATE_RANGE),
      style: width100,
      validation: {
        required: {
          onForms: {
            [Forms.SETTINGS_BANNER.key]: true,
          },
        },
      },
    },
  },
  [addPrefixToField(BANNER_FIELD_PREFIX, BANNER_FORM.MESSAGE)]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Message",
      name: BANNER_FORM.MESSAGE,
      id: addPrefixToField(BANNER_FIELD_PREFIX, BANNER_FORM.MESSAGE),
      Type: "text",
      maxLength: 255,
      style: width100,
      validation: {
        required: {
          onForms: {
            [Forms.SETTINGS_BANNER.key]: true,
          },
        },
      },
    },
  },
  [addPrefixToField(BANNER_FIELD_PREFIX, BANNER_FORM.THEME)]: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      isMulti: false,
      label: "Theme",
      name: BANNER_FORM.THEME,
      id: addPrefixToField(BANNER_FIELD_PREFIX, BANNER_FORM.THEME),
      options: SelectOptions[addPrefixToField(BANNER_FIELD_PREFIX, BANNER_FORM.THEME)],
      validation: {
        required: {
          onForms: {
            [Forms.SETTINGS_BANNER.key]: true,
          },
        },
      },
    },
  },
  [addPrefixToField(BANNER_FIELD_PREFIX, BANNER_FORM.LINK_LABEL)]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Link Label",
      name: BANNER_FORM.LINK_LABEL,
      id: addPrefixToField(BANNER_FIELD_PREFIX, BANNER_FORM.LINK_LABEL),
      Type: "text",
      maxLength: 50,
      style: width100,
    },
  },
  [addPrefixToField(BANNER_FIELD_PREFIX, BANNER_FORM.LINK_URL)]: {
    Type: FormField,
    TypeName: "FormField",
    props: {
      label: "Link URL",
      name: BANNER_FORM.LINK_URL,
      id: addPrefixToField(BANNER_FIELD_PREFIX, BANNER_FORM.LINK_URL),
      Type: "text",
      maxLength: 2048,
      style: width100,
    },
  },
  [addPrefixToField(POR_AUDIT_FIELD_PREFIX, FieldNames.symbol)]: {
    Type: WorkXSelectPersistCreatable,
    TypeName: "WorkXSelectPersistCreatable",
    props: {
      label: "Symbol",
      forceUpperCase: true,
      isMulti: true,
      name: FieldNames.symbol,
      id: addPrefixToField(POR_AUDIT_FIELD_PREFIX, FieldNames.symbol),
      inputId: addPrefixToField(POR_AUDIT_FIELD_PREFIX, FieldNames.symbol),
      required: true,
      persistKey: addPrefixToField(POR_AUDIT_FIELD_PREFIX, FieldNames.symbol),
      overflow: true,
      validation: {
        required: {
          onForms: {
            [Forms.POR_AUDIT.key]: false,
          },
        },
      },
    },
  },
};

const createMPIDStatSelect = isRight => {
  let MPIDStatSelect = { ..._.pick(final, [FieldNames.trf, FieldNames.mpid]) };
  Object.entries(MPIDStatSelect).forEach(([key, value], i) => {
    final[`${key}_stat`] = props => {
      return execOrReturn(MPIDStatSelect[key], props);
    };
  });
  return MPIDStatSelect;
};

const MPIDStatSelect = createMPIDStatSelect(false);
export { MPIDStatSelect };
export const stats = _.pick(final, ["trfVals_stat", "mpidvals_stat"]);

export const LimitMonitorSelect = _.pick(final, [FieldNames.mpid]);
export const rightLimit = _.pick(final, [FieldNames.mpid]);

export const rightSidePanelFields = {
  space1: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      isMulti: false,
      label: "Space 1",
      name: "space1",

      options: [
        { value: 1, label: "Page 1" },
        { value: 2, label: "TODO" },
      ],
      style: { width: "125px" },
    },
  },
  space2: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      isMulti: false,
      label: "Space 2",
      name: "space2",

      options: [
        { value: 1, label: "Page 2" },
        { value: 2, label: "TODO" },
      ],
      style: { width: "125px" },
    },
  },
  space3: {
    Type: FormSelect,
    TypeName: "FormSelect",
    props: {
      isMulti: false,
      label: "Space 3",
      name: "space3",

      options: [
        { value: 1, label: "Page 3" },
        { value: 2, label: "TODO" },
      ],
      style: { width: "125px" },
    },
  },
  linkToMainPanel: {
    Type: FormRadioCheckboxButton,
    TypeName: "FormRadioCheckboxButton",
    props: {
      label: "Link to Main Panel",

      maxLength: 4,
      style: {},
      Type: "checkbox",
    },
  },
};

export const PVMemberMonitorTop = _.pick(final, [
  addPrefixToField(PV_REJECT_FIELD_PREFIX, PV_REJECT_TOP.rejectDate),
  addPrefixToField(PV_REJECT_FIELD_PREFIX, PV_REJECT_TOP.mpid),
]);
