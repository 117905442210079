import { useCallback, useMemo } from "react";
import { Header } from "@nef/core";
import { StandardTables } from "wksConstants";
import { TableButtons } from "components/standardTable";
import React from "react";
import { TableButtonAction, TableButtonLabel } from "wksConstantsTS";

const DisableHeader = ({ validRows }) => {
  return (
    <>
      <Header size={3}>{`Delete ${validRows.length} Email(s)?`}</Header>
      <span>This will also disable all alert configurations with a matching email</span>
    </>
  );
};

export const PvrEmailTableButtons = ({ onClickNew, onClickEdit, onClickDisable }) => {
  const handleEdit = useCallback(() => {
    if (typeof onClickEdit === "function") {
      onClickEdit();
    }
  }, [onClickEdit]);

  const handleNew = useCallback(() => {
    if (typeof onClickNew === "function") {
      onClickNew();
    }
  }, [onClickNew]);

  const handleDisable = useCallback(() => {
    if (typeof onClickDisable === "function") {
      onClickDisable();
    }
  }, [onClickDisable]);

  const tableButtons = useMemo(() => {
    return [
      {
        icon: "ban",
        text: TableButtonLabel.DELETE,
        actionId: TableButtonAction.PVR_EMAIL_DELETE,
        allowConfirm: true,
        canConfirm: true,
        requireSelect: true,
        allowMultiSelect: true,
        onClick: handleDisable,
        header: DisableHeader,
      },
      {
        icon: "edit",
        text: TableButtonLabel.EDIT,
        actionId: TableButtonAction.PVR_EMAIL_EDIT,
        confirm: false,
        requireSelect: true,
        allowMultiSelect: true,
        onClick: handleEdit,
      },
      {
        icon: "plus",
        text: TableButtonLabel.NEW,
        actionId: TableButtonAction.PVR_EMAIL_NEW,
        confirm: false,
        requireSelect: false,
        allowMultiSelect: true,
        onClick: handleNew,
      },
    ];
  }, [handleDisable, handleEdit, handleNew]);

  return <TableButtons table={StandardTables.PVR_EMAIL_MANAGER} buttons={tableButtons} />;
};
