import dayjs from "dayjs";
import { BANNER_FORM, Banner } from "./constant";
import { getYesOrNo } from "../ptra/columns";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.extend(timezone);

export const BannerManagerColumns = [
  {
    Header: "Enabled",
    accessor: (row: Banner) => getYesOrNo(row[BANNER_FORM.IS_ENABLED]),
    width: 150,
    id: BANNER_FORM.IS_ENABLED,
  },
  {
    Header: "Start Date",
    accessor: (row: Banner) =>
      row[BANNER_FORM.START_DATE] ? dayjs(row[BANNER_FORM.START_DATE]).format("MM/DD/YYYY") : "N/A",
    width: 100,
    id: BANNER_FORM.START_DATE,
  },
  {
    Header: "End Date",
    accessor: (row: Banner) =>
      row[BANNER_FORM.END_DATE] ? dayjs(row[BANNER_FORM.END_DATE]).format("MM/DD/YYYY") : "N/A",
    width: 100,
    id: BANNER_FORM.END_DATE,
  },
  {
    Header: "Message",
    accessor: BANNER_FORM.MESSAGE,
    width: 100,
    id: BANNER_FORM.MESSAGE,
  },
  {
    Header: "Theme",
    accessor: BANNER_FORM.THEME,
    width: 100,
    id: BANNER_FORM.THEME,
  },
  {
    Header: "Link Label",
    accessor: BANNER_FORM.LINK_LABEL,
    width: 100,
    id: BANNER_FORM.LINK_LABEL,
  },
  {
    Header: "Link URL",
    accessor: BANNER_FORM.LINK_URL,
    Cell: ({ row }: { row: { original: Banner } }) => (
      <a href={row.original[BANNER_FORM.LINK_URL]} target="_blank" rel="noreferrer">
        {row.original[BANNER_FORM.LINK_URL]}
      </a>
    ),
    width: 100,
    id: BANNER_FORM.LINK_URL,
  },
];
