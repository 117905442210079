import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.extend(timezone);

export const EST_TIMEZONE = dayjs().tz("America/New_York").format("Z");

export const FROM_DATE_TIME = "00:00:00.000";
export const TO_DATE_TIME = "23:59:59.999";

export const FROM_DATE_SUFFIX = `T${FROM_DATE_TIME}${EST_TIMEZONE}`;
export const TO_DATE_SUFFIX = `T${TO_DATE_TIME}${EST_TIMEZONE}`;
type DateSuffix = typeof FROM_DATE_SUFFIX | typeof TO_DATE_SUFFIX;
const DateFmt = "YYYY-MM-DD";

export const formatDateForRequest = (date: string | dayjs.Dayjs, suffix: DateSuffix) => {
  if (typeof date === "string") {
    return dayjs(date).format(`${DateFmt}${suffix}`);
  }
  if (typeof date === "object") {
    return date.format(`${DateFmt}${suffix}`);
  }
  return undefined;
};

export const formatDateTimeWithoutTz = (date: string | dayjs.Dayjs, time: string) => {
  if (typeof date === "string") {
    return `${dayjs(date).format(`${DateFmt}T${time}`)}Z`;
  }
  if (typeof date === "object") {
    return `${date.format(`${DateFmt}T${time}`)}Z`;
  }
  return undefined;
};
