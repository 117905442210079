import React, { useCallback, useEffect, useRef, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { useAlertDispatch } from "../context";
import { GenericAlerts } from "../genericAlerts";
import styled from "styled-components";
import { FontAwesomeIcon, Header } from "@nef/core";
import { getColor } from "@nef/style-utils";
import dayjs from "dayjs";
import { useAlertCacheState, useAlertCacheDispatch, AlertMap } from "./alertCache";
import { PvrAlertBody } from "./pvrAlertBody";
import { AlertFooter, ALERT_FILTER } from "./alertFooter";
import { getIdentifierFromAlert, PorWindowEvent, PVR_ALERT_MODEL } from "./constants";
import { getPvrAlertLabel, POR_STATUS, PVR_ALERT_TYPE } from "components/settings/pvr/constants";
import { GENERIC_ALERT_ITEM, GenericAlertItem } from "../constant";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.extend(timezone);

const Icon = styled(FontAwesomeIcon)`
  ${props => `
    font-size: 16px;
    color: ${getColor(
      props.color as "success" | "danger" | "warning" | "primary" | "gray",
      300
    )(props)};
  `}
`;

const Title = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
`;

const TitleTextAndIcon = styled.div`
  display: flex;
  grid-gap: 0.5rem;
  align-items: center;
`;

const StyledHeader = styled(Header)`
  margin: 0;
`;

const DateWrapper = styled.span`
  color: ${getColor("gray", 500)};
`;

const sortPvrAlerts = (a: GenericAlertItem, b: GenericAlertItem) => {
  return a.time - b.time;
};

const defaultFilter = ALERT_FILTER.ALL;
const pvrId = "pvr-alerts-id";
export const PvrAlertsContainer = () => {
  const pvrAlertData = useAlertCacheState();
  const pvrAlertDispatch = useAlertCacheDispatch();
  const alertDispatch: any = useAlertDispatch();
  const [activeFilter, setActiveFilter] = useState(defaultFilter);
  const lastSetFilter = useRef(defaultFilter);
  const lastSetAlerts = useRef<{ alerts: AlertMap; itemSet: GenericAlertItem[] }>({
    alerts: {},
    itemSet: [],
  });

  useEffect(() => {
    alertDispatch({
      type: "SET_STATUS",
      payload: pvrAlertData.status,
    });
  }, [alertDispatch, pvrAlertData.status]);

  const getCurrentAlertItems = useCallback(
    (filter: ALERT_FILTER) => {
      let activePvrAlerts;
      switch (filter) {
        case ALERT_FILTER.READ:
          activePvrAlerts = pvrAlertData.readAlerts;
          break;
        case ALERT_FILTER.UNREAD:
          activePvrAlerts = pvrAlertData.unreadAlerts;
          break;
        case ALERT_FILTER.ALL:
        default:
          activePvrAlerts = pvrAlertData.alerts;
          break;
      }
      if (activePvrAlerts !== lastSetAlerts.current.alerts) {
        lastSetAlerts.current.alerts = activePvrAlerts;
        const items = Object.values(activePvrAlerts).reduce((acc, curr: PVR_ALERT_MODEL) => {
          // if configured for both sides, only show action alert
          const identifier = getIdentifierFromAlert(curr);
          const subtitle = <PvrAlertBody alert={curr} includeMarkAsRead={true} />;

          let icon;
          switch (curr.alertType) {
            case PVR_ALERT_TYPE.POR_STATUS:
              switch (curr.status) {
                case POR_STATUS.PENDING:
                  icon = <Icon iconClassName={"fa-clock"} color="primary" />;
                  break;
                case POR_STATUS.APPROVED:
                  icon = <Icon iconClassName={"fa-key"} color="success" />;
                  break;
                case POR_STATUS.DENIED:
                  icon = <Icon iconClassName={"fa-lock"} color="danger" />;
                  break;
                case POR_STATUS.EXPIRED:
                  icon = <Icon iconClassName={"fa-exclamation-triangle"} color="warning" />;
                  break;
                default:
                  icon = <Icon iconClassName={"fa-frown-open"} color="gray" />;
                  break;
              }
              break;
            case PVR_ALERT_TYPE.PVR_RESUBMIT:
              icon = <Icon iconClassName={"fa-check-square"} color="primary" />;
              break;
            case PVR_ALERT_TYPE.POR_WINDOW:
              icon = (
                <Icon
                  iconClassName={"fa-window-maximize"}
                  color={curr.event === PorWindowEvent.OPEN ? "success" : "danger"}
                />
              );
              break;
            default:
              icon = <Icon iconClassName={"fa-frown-open"} color="gray" />;
              break;
          }

          const time = curr.timestamp;
          acc[identifier] = {
            [GENERIC_ALERT_ITEM.ICON]: icon,
            [GENERIC_ALERT_ITEM.TITLE]: (
              <Title>
                <TitleTextAndIcon>
                  <StyledHeader size={4}>{`${getPvrAlertLabel(curr)}`}</StyledHeader>
                  {icon}
                </TitleTextAndIcon>
                <DateWrapper>{dayjs(time).format("hh:mm A")}</DateWrapper>
              </Title>
            ),
            [GENERIC_ALERT_ITEM.SUBTITLE]: subtitle,
            [GENERIC_ALERT_ITEM.ID]: identifier,
            time,
          };
          return acc;
        }, {} as { [id: string]: GenericAlertItem });
        const itemSet = Object.values(items).sort(sortPvrAlerts);
        lastSetAlerts.current.itemSet = itemSet;
        return itemSet;
      }
      return lastSetAlerts.current.itemSet;
    },
    [pvrAlertData.alerts, pvrAlertData.readAlerts, pvrAlertData.unreadAlerts]
  );

  const handleFilterChange = useCallback(
    (value: ALERT_FILTER) => {
      if (lastSetFilter.current === value) {
        alertDispatch({ type: "START_FORCE_REFRESH" });
      } else {
        setActiveFilter(value);
      }
    },
    [alertDispatch]
  );

  const handleNewActiveAlerts = useCallback(
    (activeAlerts: GenericAlertItem[]) => {
      pvrAlertDispatch({
        type: "SET_ACTIVE_ALERTS",
        payload: activeAlerts,
      });
      alertDispatch({
        type: "SET_FOOTER",
        payload: <AlertFooter activeFilter={activeFilter} onFilterChange={handleFilterChange} />,
      });
    },
    [activeFilter, alertDispatch, handleFilterChange, pvrAlertDispatch]
  );

  useEffect(() => {
    const actions = [];
    actions.push({
      type: "SET_ALERTS",
      payload: {
        alerts: getCurrentAlertItems(activeFilter),
        totalNumAlerts: Object.keys(pvrAlertData.alerts).length,
      },
    });
    if (lastSetFilter.current !== activeFilter) {
      actions.push({
        type: "START_FORCE_REFRESH",
      });
      lastSetFilter.current = activeFilter;
    }
    alertDispatch(actions);
  }, [activeFilter, alertDispatch, getCurrentAlertItems, handleFilterChange, pvrAlertData.alerts]);

  useEffect(() => {
    alertDispatch({
      type: "SET_FOOTER",
      payload: <AlertFooter activeFilter={activeFilter} onFilterChange={handleFilterChange} />,
    });
  }, [activeFilter, alertDispatch, handleFilterChange]);

  return (
    <GenericAlerts
      id={pvrId}
      iconClassName="fa-inbox"
      tooltip="Price Reject Override Alerts"
      emptyStateTitle="No Alerts Found"
      emptyStateSubtitle="Today's Price Reject Override (PRO) Alerts matching a configuration for this user will be logged
  here"
      emptyFilterTitle={`No ${activeFilter} Alerts`}
      emptyFilterSubtitle="Adjust the filter to view alerts"
      itemHeight={105}
      onChangeAlertSetShown={handleNewActiveAlerts}
      hasUnread={Object.keys(pvrAlertData.unreadAlerts).length > 0}
    />
  );
};
