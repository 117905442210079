import { Forms } from "components/fields/fieldConstants";
import { Submit, useFormContext, useFormDispatch } from "components/form";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  convertDataToAPI,
  errorCallbackCB,
  formValidationCheck,
  useStandardTableContext,
  useStandardTableDispatch,
} from "components/standardTable";
import { doAction } from "components/standardTable/network";
import { useUserContext } from "components/user";
import { handleCancel, setCellsLoading, setFormLoading } from ".";
import { useViewModelDispatch } from "../../context";
import { actionCallbacks, payload } from "components/standardTable/actionHooks";
import { network } from "components/reportScan/network";
import { TableButtonAction } from "wksConstantsTS";
import { USER_MPIDS_ATTRIBUTE } from "components/user/mpidContext";

const accentName = "secondary";
const buttonText = "Copy";
export const CopySubmit = ({ table, form }) => {
  const [formData] = useFormContext();
  const formDispatch = useFormDispatch();
  const [standardTableData] = useStandardTableContext();
  const standardTableDispatch = useStandardTableDispatch();
  const [userData] = useUserContext();
  const viewDispatch = useViewModelDispatch();
  const history = useHistory();
  const errorCallback = useCallback(
    errorCallbackCB({ formDispatch, standardTableDispatch, table, form }),
    [formDispatch, standardTableDispatch, table, form]
  );

  const onCancel = useCallback(() => {
    handleCancel({ viewDispatch, formDispatch, form, history });
  }, [viewDispatch, formDispatch, form, history]);

  const reverseCallback = useCallback(
    responseJson => {
      actionCallbacks.reverse({
        formDispatch,
        standardTableDispatch,
        form,
        table,
        viewDispatch,
        ack: responseJson,
        history,
      });
    },
    [form, formDispatch, standardTableDispatch, table, viewDispatch, history]
  );

  const copyCallback = useCallback(
    responseJson => {
      actionCallbacks.copy({
        formDispatch,
        standardTableDispatch,
        viewDispatch,
        form,
        table,
        ack: responseJson[0],
        history,
      });
    },
    [form, formDispatch, standardTableDispatch, table, viewDispatch, history]
  );

  const handleSubmit = useCallback(() => {
    const valid = formValidationCheck({
      formData,
      formDispatch,
      form: form,
      userData,
    });
    if (!valid) {
      return;
    }

    const fieldData = [{ ...formData[form.key].fields }];

    if (formData[Forms.TR_COPY.key].fields.isReversal) {
      const reqData = convertDataToAPI(fieldData);
      reqData[0].isReversalTrade = "true";
      doAction(TableButtonAction.REVERSE, reqData, reverseCallback, errorCallback);
    } else {
      const reqData = payload.copy({
        fieldData: fieldData[0],
        username: userData.username,
      });
      network(userData.mpidAttributes[USER_MPIDS_ATTRIBUTE.UI_TRADE_REPORTING_ACTION_MPIDS]).doCopy(
        [reqData],
        copyCallback,
        errorCallback
      );
    }

    setFormLoading(formDispatch, form);
    setCellsLoading(standardTableDispatch, table, fieldData);
  }, [
    formData,
    formDispatch,
    form,
    userData,
    standardTableDispatch,
    table,
    reverseCallback,
    errorCallback,
    copyCallback,
  ]);

  return (
    <Submit
      form={form}
      accentName={accentName}
      buttonText={buttonText}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      isDisabled={standardTableData[table]?.isLoading}
      isLoading={formData[form.key]?.isLoading}
      errors={Object.keys(formData[form.key].totalFormErrors).length}
    />
  );
};
