import { NotificationHub } from "@nef/core";
import { ContextProviderProps, DispatchFn, DispatchFnSwitch } from "components/context/constants";
import ContextFactory from "components/context/factory";
import { getHeaders } from "keycloak";
import { doFetchWrapper } from "network";
import React, { useCallback, useEffect, useReducer } from "react";
import { formatUrl } from "utils/js.utils";
import { Status } from "wksConstantsTS";
import { DATE_FILTER, PvReject, RejectStatus } from "components/pvRejects/constant";

const DELETE_REJECT_STATUS_LIST = [
  RejectStatus.Closed,
  RejectStatus.Repaired,
  RejectStatus.Updated,
  RejectStatus.Deleted,
];

export enum REJECT_CACHE_FILTER_BY {
  NULL,
  ALL,
  MPID,
  SYMBOL,
  MPID_SYMBOL,
  FINRA,
}

export type RejectCacheMpidCritia = { mpids: string[]; rejectText: string; date: DATE_FILTER };
export type RejectCacheSymbolCriteria = {
  symbols: string[];
  rejectText: string;
  date: DATE_FILTER;
};
export type RejectCacheMpidSymbolCriteria = {
  mpidToSymbols: { [mpid: string]: string[] };
  rejectText: string;
  date: DATE_FILTER;
};
export type RejectCacheFinraCriteria = {
  mpidToSymbols: { [mpid: string]: string[] };
  rejectText?: string;
};

export type REJECT_CACHE_FILTER =
  | null
  | {
      filterBy: REJECT_CACHE_FILTER_BY.MPID;
      criteria: RejectCacheMpidCritia;
    }
  | {
      filterBy: REJECT_CACHE_FILTER_BY.SYMBOL;
      criteria: RejectCacheSymbolCriteria;
    }
  | {
      filterBy: REJECT_CACHE_FILTER_BY.MPID_SYMBOL;
      criteria: RejectCacheMpidSymbolCriteria;
    }
  | {
      filterBy: REJECT_CACHE_FILTER_BY.FINRA;
      criteria: RejectCacheFinraCriteria;
    };

export enum FinraOffsetKeyPrefix {
  RANGE = "R",
  OVERRIDE = "O",
}

export const getOffsetKeyForMpidAndSymbol = (mpid: string, symbol: string, prefix?: string) => {
  if (prefix) {
    return `${prefix}|${mpid}|${symbol}`;
  }
  return `${mpid}|${symbol}`;
};

type RejectMap = { [rejectId: number]: PvReject };
type OffsetMap = { [key: string]: number };

enum TIMESTAMP_KEY {
  ALL_REJECTS = "allRejectsTs",
  RANGE_REJECTS = "rangeRejectsTs",
  OVERRIDE_REJECTS = "overrideRejectsTs",
}
type TimestampMap = { [key in TIMESTAMP_KEY]?: number };

export type RejectCacheState = {
  data: RejectMap;
  offsets: OffsetMap;
  filter: REJECT_CACHE_FILTER;
  status: Status;
  makeInitialRequest: boolean;
  requestFailedCount: number;
  hasMadeInitialRequest: boolean;
  isPolling: boolean;
  isRequesting: boolean;
  timeStamps: TimestampMap;
  instId: number;
  isLoading: boolean;
  requestAbort: boolean;
  abort: AbortController;
  requestTimer: NodeJS.Timeout | undefined;
};

export type RejectCacheAction =
  | { type: "START_POLLING"; payload: { filter: REJECT_CACHE_FILTER } }
  | { type: "RESET_CACHE" }
  | { type: "STOP_POLLING" }
  | { type: "HANDLE_INITIAL_REQUEST" }
  | { type: "HANDLE_REQUEST_FAILED"; payload: number }
  | { type: "SET_LOADING"; payload: boolean }
  | { type: "SET_REQUESTING"; payload: boolean }
  | { type: "SET_REQUEST_STATUS"; payload: Status }
  | { type: "SET_ABORT"; payload: AbortController }
  | { type: "SET_REQUEST_TIMER"; payload: NodeJS.Timeout | undefined }
  | { type: "SET_DATA"; payload: { [rejectId: number]: PvReject } }
  | { type: "SET_OFFSETS"; payload: { [key: string]: number } }
  | { type: "SET_TIMESTAMPS"; payload: TimestampMap };

const FACTORY = new ContextFactory<RejectCacheState, RejectCacheAction>();

const DEFAULT_STATE = {
  data: {},
  offsets: {},
  filter: null,
  status: Status.NO_STATUS,
  makeInitialRequest: false,
  requestFailedCount: 0,
  hasMadeInitialRequest: false,
  isPolling: false,
  isRequesting: false,
  timeStamps: {},
  instId: 0,
  isLoading: false,
  requestAbort: false,
  abort: new AbortController(),
  requestTimer: undefined,
};

const [DISPATCH_CONTEXT, STATE_CONTEXT] = FACTORY.createContexts({ ...DEFAULT_STATE });

const DISPATCH_FN_SWITCH: DispatchFnSwitch<RejectCacheState, RejectCacheAction> = (
  prevState: RejectCacheState,
  action: RejectCacheAction
) => {
  switch (action.type) {
    case "START_POLLING": {
      const { filter } = action.payload;
      prevState.abort.abort();
      clearTimeout(prevState.requestTimer);
      return {
        ...prevState,
        filter,
        isPolling: true,
        makeInitialRequest: true, //!prevState.hasMadeInitialRequest,
        hasMadeInitialRequest: false,
        isRequesting: false,
        isLoading: true,
        data: {},
        offsets: {},
        timeStamps: {},
      };
    }
    case "RESET_CACHE": {
      prevState.abort.abort();
      clearTimeout(prevState.requestTimer);
      return {
        ...DEFAULT_STATE,
      };
    }
    case "STOP_POLLING": {
      prevState.abort.abort();
      clearTimeout(prevState.requestTimer);
      return {
        ...prevState,
        isPolling: false,
      };
    }
    case "HANDLE_INITIAL_REQUEST": {
      return {
        ...prevState,
        makeInitialRequest: false,
        hasMadeInitialRequest: true,
      };
    }
    case "HANDLE_REQUEST_FAILED": {
      return {
        ...prevState,
        makeInitialRequest: true,
        hasMadeInitialRequest: false,
        requestFailedCount: action.payload,
      };
    }
    case "SET_LOADING": {
      return { ...prevState, isLoading: action.payload };
    }
    case "SET_REQUESTING": {
      return { ...prevState, isRequesting: action.payload };
    }
    case "SET_REQUEST_STATUS": {
      return { ...prevState, status: action.payload };
    }
    case "SET_ABORT": {
      return { ...prevState, abort: action.payload };
    }
    case "SET_REQUEST_TIMER": {
      return { ...prevState, requestTimer: action.payload };
    }
    case "SET_DATA": {
      return { ...prevState, data: action.payload, isLoading: false };
    }
    case "SET_OFFSETS": {
      return { ...prevState, offsets: action.payload };
    }
    case "SET_TIMESTAMPS": {
      return { ...prevState, timeStamps: action.payload };
    }
    default:
      return { ...prevState };
  }
};

const DISPATCH_FN = FACTORY.createDispatchFn<RejectCacheState, RejectCacheAction>(
  DISPATCH_FN_SWITCH
);

interface RejectCacheProviderProps extends ContextProviderProps {}

const getKeyForReject = (reject: PvReject): number => {
  return reject.id;
};

const updateDataForFinra = ({
  rejectEntries,
  newOffsets,
  newData,
  offsetPrefix = "",
}: {
  rejectEntries: Array<[string, { key: string; reject: PvReject }[]]>;
  newOffsets: OffsetMap;
  newData: RejectMap;
  offsetPrefix?: string;
}) => {
  rejectEntries.forEach(([mpid, rejects]) => {
    rejects.forEach(({ key, reject }) => {
      if (newOffsets[getOffsetKeyForMpidAndSymbol(mpid, key, offsetPrefix)] === undefined) {
        newOffsets[getOffsetKeyForMpidAndSymbol(mpid, key, offsetPrefix)] = 1;
      } else {
        newOffsets[getOffsetKeyForMpidAndSymbol(mpid, key, offsetPrefix)]++;
      }
      if (reject.rejectStatus !== null && DELETE_REJECT_STATUS_LIST.includes(reject.rejectStatus)) {
        delete newData[getKeyForReject(reject)];
      } else {
        newData[getKeyForReject(reject)] = reject;
      }
    });
  });
};

const pollingIntervalMs = 4000;
export const RejectCacheProvider: React.FC<RejectCacheProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer<DispatchFn<RejectCacheState, RejectCacheAction>>(
    DISPATCH_FN,
    DEFAULT_STATE
  );

  const getRejectData = useCallback(
    (isInitialData: boolean) => {
      const getSuccessActions = ({
        isInitialData,
        hasUpdate,
        timer,
        newOffsets,
        newData,
        newTimestamps,
      }: {
        isInitialData: boolean;
        hasUpdate: boolean;
        timer: NodeJS.Timeout;
        newOffsets?: OffsetMap;
        newData?: RejectMap;
        newTimestamps?: TimestampMap;
      }) => {
        const actions: RejectCacheAction[] = [
          {
            type: "SET_LOADING",
            payload: false,
          },
          {
            type: "SET_REQUEST_STATUS",
            payload: Status.SUCCESS,
          },
          {
            type: "SET_REQUEST_TIMER",
            payload: timer,
          },
        ];

        if (isInitialData) {
          actions.push({ type: "HANDLE_INITIAL_REQUEST" });
        }
        if (hasUpdate) {
          if (newOffsets !== undefined) {
            actions.push({
              type: "SET_OFFSETS",
              payload: newOffsets,
            });
          } else {
            console.warn("RejectCache: Missing new offsets");
          }
          if (newData !== undefined) {
            actions.push({
              type: "SET_DATA",
              payload: newData,
            });
          } else {
            console.warn("RejectCache: Missing new data");
          }
          if (newTimestamps !== undefined) {
            actions.push({
              type: "SET_TIMESTAMPS",
              payload: newTimestamps,
            });
          } else {
            console.warn("RejectCache: Missing new timestamps");
          }
        }
        return actions;
      };

      const getRejectsByMpidSuccess = (data: {
        rejects: { key: string; reject: PvReject }[];
        timestamp: number;
      }) => {
        const timer = setTimeout(() => {
          dispatch({ type: "SET_REQUESTING", payload: true });
        }, pollingIntervalMs);

        const hasUpdate = data.rejects.length > 0;
        if (hasUpdate) {
          const newOffsets = { ...state.offsets };
          const newData = { ...state.data };
          data.rejects.forEach(({ key, reject }) => {
            if (newOffsets[key] === undefined) {
              newOffsets[key] = 1;
            } else {
              newOffsets[key]++;
            }
            if (
              reject.rejectStatus !== null &&
              DELETE_REJECT_STATUS_LIST.includes(reject.rejectStatus)
            ) {
              delete newData[getKeyForReject(reject)];
            } else {
              newData[getKeyForReject(reject)] = reject;
            }
          });
          const newTimestamps = {
            [TIMESTAMP_KEY.ALL_REJECTS]: data.timestamp,
          };
          dispatch(
            getSuccessActions({
              isInitialData,
              hasUpdate,
              timer,
              newOffsets,
              newData,
              newTimestamps,
            })
          );
        } else {
          dispatch(getSuccessActions({ isInitialData, hasUpdate, timer }));
        }
      };

      const getRejectsByMpidAndSymbolSuccess = (data: {
        rejects: { [mpid: string]: { key: string; reject: PvReject }[] };
        timestamp: number;
      }) => {
        const timer = setTimeout(() => {
          dispatch({ type: "SET_REQUESTING", payload: true });
        }, pollingIntervalMs);

        const mapEntries = Object.entries(data.rejects);
        const hasUpdate = mapEntries.length > 0;
        if (hasUpdate) {
          const newOffsets = { ...state.offsets };
          const newData = { ...state.data };
          Object.entries(data.rejects).forEach(([mpid, rejects]) => {
            rejects.forEach(({ key, reject }) => {
              if (newOffsets[getOffsetKeyForMpidAndSymbol(mpid, key)] === undefined) {
                newOffsets[getOffsetKeyForMpidAndSymbol(mpid, key)] = 1;
              } else {
                newOffsets[getOffsetKeyForMpidAndSymbol(mpid, key)]++;
              }
              if (
                reject.rejectStatus !== null &&
                DELETE_REJECT_STATUS_LIST.includes(reject.rejectStatus)
              ) {
                delete newData[getKeyForReject(reject)];
              } else {
                newData[getKeyForReject(reject)] = reject;
              }
            });
          });
          const newTimestamps = {
            [TIMESTAMP_KEY.ALL_REJECTS]: data.timestamp,
          };
          dispatch(
            getSuccessActions({
              isInitialData,
              hasUpdate,
              timer,
              newOffsets,
              newData,
              newTimestamps,
            })
          );
        } else {
          dispatch(getSuccessActions({ isInitialData, hasUpdate, timer }));
        }
      };

      const getRejectsForFinraSuccess = (data: {
        rangeRejects: {
          rejects: { [mpid: string]: { key: string; reject: PvReject }[] };
          timestamp: number;
        };
        overrideRejects: {
          rejects: { [mpid: string]: { key: string; reject: PvReject }[] };
          timestamp: number;
        };
      }) => {
        const timer = setTimeout(() => {
          dispatch({ type: "SET_REQUESTING", payload: true });
        }, pollingIntervalMs);

        const rangeRejectMapEntries = Object.entries(data.rangeRejects.rejects);
        const overrideRejectMapEntries = Object.entries(data.overrideRejects.rejects);
        const hasUpdate = rangeRejectMapEntries.length > 0 || overrideRejectMapEntries.length > 0;
        if (hasUpdate) {
          const newOffsets = { ...state.offsets };
          const newData = { ...state.data };
          updateDataForFinra({
            rejectEntries: rangeRejectMapEntries,
            newOffsets,
            newData,
            offsetPrefix: FinraOffsetKeyPrefix.RANGE,
          });
          updateDataForFinra({
            rejectEntries: overrideRejectMapEntries,
            newOffsets,
            newData,
            offsetPrefix: FinraOffsetKeyPrefix.OVERRIDE,
          });
          const newTimestamps = {
            [TIMESTAMP_KEY.RANGE_REJECTS]: data.rangeRejects.timestamp,
            [TIMESTAMP_KEY.OVERRIDE_REJECTS]: data.overrideRejects.timestamp,
          };
          dispatch(
            getSuccessActions({
              isInitialData,
              hasUpdate,
              timer,
              newOffsets,
              newData,
              newTimestamps,
            })
          );
        } else {
          dispatch(getSuccessActions({ isInitialData, hasUpdate, timer }));
        }
      };

      const getRejectsError = () => {
        const actions: RejectCacheAction[] = [
          {
            type: "SET_LOADING",
            payload: false,
          },
          { type: "HANDLE_REQUEST_FAILED", payload: state.requestFailedCount + 1 },
        ];
        actions.push({
          type: "SET_REQUEST_STATUS",
          payload: Status.ERROR,
        });
        if (state.status !== Status.ERROR) {
          NotificationHub.send("danger", "Error getting Price Validation Rejects");
        }
        dispatch(actions);
      };

      const abortController = new AbortController();
      dispatch({ type: "SET_ABORT", payload: abortController });
      if (state.filter !== null) {
        switch (state.filter.filterBy) {
          case REJECT_CACHE_FILTER_BY.MPID:
            const mpids = state.filter.criteria.mpids.reduce((acc, curr) => {
              acc[curr] = state.offsets[curr] || 0;
              return acc;
            }, {} as { [mpid: string]: number });
            doFetchWrapper(
              formatUrl(process.env.REACT_APP_URL_PVR_REJECT_CACHE, "rejectsByMpid"),
              {
                method: "post",
                mode: "cors",
                signal: abortController.signal,
                headers: getHeaders(),
                body: JSON.stringify({
                  mpids,
                  rejectText: state.filter.criteria.rejectText,
                  date: state.filter.criteria.date,
                  timestamp: state.timeStamps[TIMESTAMP_KEY.ALL_REJECTS] || 0,
                }),
              },
              getRejectsByMpidSuccess,
              getRejectsError
            );
            break;
          case REJECT_CACHE_FILTER_BY.SYMBOL:
            const symbols = state.filter.criteria.symbols.reduce((acc, curr) => {
              acc[curr] = state.offsets[curr] || 0;
              return acc;
            }, {} as { [symbol: string]: number });
            doFetchWrapper(
              formatUrl(process.env.REACT_APP_URL_PVR_REJECT_CACHE, "rejectsBySymbol"),
              {
                method: "post",
                mode: "cors",
                signal: abortController.signal,
                headers: getHeaders(),
                body: JSON.stringify({
                  symbols,
                  rejectText: state.filter.criteria.rejectText,
                  date: state.filter.criteria.date,
                  timestamp: state.timeStamps[TIMESTAMP_KEY.ALL_REJECTS] || 0,
                }),
              },
              getRejectsByMpidSuccess,
              getRejectsError
            );
            break;
          case REJECT_CACHE_FILTER_BY.MPID_SYMBOL: {
            const mpidToSymbols = Object.entries(state.filter.criteria.mpidToSymbols).reduce(
              (acc, [mpid, symbols]: [string, string[]]) => {
                symbols.forEach(symbol => {
                  if (!acc[mpid]) {
                    acc[mpid] = {};
                  }
                  acc[mpid][symbol] =
                    state.offsets[getOffsetKeyForMpidAndSymbol(mpid, symbol)] || 0;
                });
                return acc;
              },
              {} as { [mpid: string]: { [symbol: string]: number } }
            );
            doFetchWrapper(
              formatUrl(process.env.REACT_APP_URL_PVR_REJECT_CACHE, "rejectsByMpidAndSymbol"),
              {
                method: "post",
                mode: "cors",
                signal: abortController.signal,
                headers: getHeaders(),
                body: JSON.stringify({
                  mpidToSymbols,
                  rejectText: state.filter.criteria.rejectText,
                  date: state.filter.criteria.date,
                  timestamp: state.timeStamps[TIMESTAMP_KEY.ALL_REJECTS] || 0,
                }),
              },
              getRejectsByMpidAndSymbolSuccess,
              getRejectsError
            );
            break;
          }
          case REJECT_CACHE_FILTER_BY.FINRA: {
            const { rangeMpidToSymbols, overrideMpidToSymbols } = Object.entries(
              state.filter.criteria.mpidToSymbols
            ).reduce(
              (acc, [mpid, symbols]: [string, string[]]) => {
                symbols.forEach(symbol => {
                  if (!acc.rangeMpidToSymbols[mpid]) {
                    acc.rangeMpidToSymbols[mpid] = {};
                  }
                  acc.rangeMpidToSymbols[mpid][symbol] =
                    state.offsets[
                      getOffsetKeyForMpidAndSymbol(mpid, symbol, FinraOffsetKeyPrefix.RANGE)
                    ] || 0;
                  if (!acc.overrideMpidToSymbols[mpid]) {
                    acc.overrideMpidToSymbols[mpid] = {};
                  }
                  acc.overrideMpidToSymbols[mpid][symbol] =
                    state.offsets[
                      getOffsetKeyForMpidAndSymbol(mpid, symbol, FinraOffsetKeyPrefix.OVERRIDE)
                    ] || 0;
                });
                return acc;
              },
              { rangeMpidToSymbols: {}, overrideMpidToSymbols: {} } as {
                rangeMpidToSymbols: { [mpid: string]: { [symbol: string]: number } };
                overrideMpidToSymbols: { [mpid: string]: { [symbol: string]: number } };
              }
            );
            doFetchWrapper(
              formatUrl(process.env.REACT_APP_URL_PVR_REJECT_CACHE, "finraRejects"),
              {
                method: "post",
                mode: "cors",
                signal: abortController.signal,
                headers: getHeaders(),
                body: JSON.stringify({
                  rangeMpidToSymbols,
                  overrideMpidToSymbols,
                  rangeTimestamp: state.timeStamps[TIMESTAMP_KEY.RANGE_REJECTS] || 0,
                  overrideTimestamp: state.timeStamps[TIMESTAMP_KEY.OVERRIDE_REJECTS] || 0,
                }),
              },
              getRejectsForFinraSuccess,
              getRejectsError
            );
            break;
          }
        }
      }
    },
    [
      state.offsets,
      state.data,
      state.filter,
      state.requestFailedCount,
      state.status,
      state.timeStamps,
    ]
  );

  // -- initial request --
  // should only have to run once as long as timeStamp
  // is persisted throughout the entire session
  useEffect(() => {
    if (state.makeInitialRequest && state.isPolling) {
      if (state.requestFailedCount > 0) {
        const timer = setTimeout(() => {
          getRejectData(true);
        }, pollingIntervalMs);
        dispatch({ type: "SET_REQUEST_TIMER", payload: timer });
      } else {
        dispatch({ type: "SET_LOADING", payload: true });
        getRejectData(true);
      }
    }
  }, [state.makeInitialRequest, getRejectData, state.requestFailedCount, state.isPolling]);

  useEffect(() => {
    if (state.isPolling && state.isRequesting && !state.requestAbort) {
      dispatch({ type: "SET_REQUESTING", payload: false });
      getRejectData(false);
    } else if (!state.isPolling) {
      dispatch({ type: "SET_REQUEST_STATUS", payload: Status.NO_STATUS });
    }
  }, [state.isPolling, state.isRequesting, state.requestAbort, getRejectData]);

  return (
    <DISPATCH_CONTEXT.Provider value={dispatch}>
      <STATE_CONTEXT.Provider value={state}>{children}</STATE_CONTEXT.Provider>
    </DISPATCH_CONTEXT.Provider>
  );
};

export const useRejectCacheDispatch =
  FACTORY.createContextHook<React.Dispatch<RejectCacheAction | RejectCacheAction[]>>(
    DISPATCH_CONTEXT
  );
export const useRejectCacheState = FACTORY.createContextHook<RejectCacheState>(STATE_CONTEXT);
