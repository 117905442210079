import { useFormContext } from "components/form";
import { getEntryMPIDsFromHeldRecord } from "components/limitMonitor/constants";
import { useUserContext } from "components/user";
import { USER_MPIDS_ATTRIBUTE } from "components/user/mpidContext";
import {
  getFieldValue,
  hasValidMPIDFromReportingParty,
  getIsClearingOnlyEligible,
} from "utils/js.utils";
import { FirmType, ReportingParty, TradeStatus } from "../../wksConstants";
import { TableButtonAction } from "../../wksConstantsTS";
import { ApiResponseNames, ApiToFieldMap, FieldNames } from "../fields";
import { useHKCacheContext } from "../limitMonitor";
import {
  getClearingMpidFromClearingNum,
  useRefDataContext,
  CLEARING_DATA_MODEL,
  FIRM_DATA_MODEL,
} from "../refData";
import { KeycloakRoles } from "../user";
import { convertDataToAPI } from "./sampleApiData";

const getFirmDataFromClearingData = clearingData => {
  if (Array.isArray(clearingData)) {
    return clearingData.reduce((acc, curr) => {
      if (!acc[curr[CLEARING_DATA_MODEL.clearingNum]]) {
        acc[curr[CLEARING_DATA_MODEL.clearingNum]] = {
          [FIRM_DATA_MODEL.firmMPID]: curr[CLEARING_DATA_MODEL.clearingMPID],
          [FIRM_DATA_MODEL.clearingNum]: curr[CLEARING_DATA_MODEL.clearingNum],
          [FIRM_DATA_MODEL.firmType]: FirmType.CLEARING,
        };
      }
      return acc;
    }, {});
  }
  return null;
};

const validTypes = actionId => {
  switch (actionId) {
    case TableButtonAction.BREAK:
    case TableButtonAction.REVERSE: {
      return r => {
        return getFieldValue(r[ApiResponseNames.tradeStatus]) !== "-"; /* Rejected */
      };
    }
    case TableButtonAction.CLOSE:
    case TableButtonAction.REPAIR: {
      return r => getFieldValue(r[ApiResponseNames.tradeStatus]) === "-"; /* Rejected */
    }
    case TableButtonAction.EDIT_LIMITS:
      return () => true;
    default:
      return r =>
        !["-" /* Rejected */, "A" /* Accepted */].includes(
          getFieldValue(r[ApiResponseNames.tradeStatus])
        );
  }
};

const checkers = (user, refData, hkData) => {
  return {
    [TableButtonAction.ACCEPT]: r => {
      if (user?.entitlements[KeycloakRoles.TRADE_REPORTING_CLEARING_ONLY]) {
        if (!getIsClearingOnlyEligible(r[ApiResponseNames.clearReportRiskVals], true)) {
          return { invalid: r };
        }
      }

      if (r.reportingParty && r.reportingParty.value === "M") {
        if (
          user.mpidAttributes[USER_MPIDS_ATTRIBUTE.UI_TRADE_REPORTING_ACTION_MPIDS].includes(
            getFieldValue(r[ApiResponseNames.contraMPID])
          )
        ) {
          return { valid: r };
        }

        if (user?.entitlements[KeycloakRoles.CLEARER] && Array.isArray(refData?.firm)) {
          const clearingMpid = getClearingMpidFromClearingNum(
            refData.firm,
            r[ApiResponseNames.contraClearingNum]
          );
          if (
            user.mpidAttributes[USER_MPIDS_ATTRIBUTE.UI_TRADE_REPORTING_ACTION_MPIDS].includes(
              clearingMpid
            )
          ) {
            return { valid: r };
          }
        }
      }
      return { invalid: r };
    },
    [TableButtonAction.DECLINE]: r => {
      if (user?.entitlements[KeycloakRoles.TRADE_REPORTING_CLEARING_ONLY]) {
        if (!getIsClearingOnlyEligible(r[ApiResponseNames.clearReportRiskVals], true)) {
          return { invalid: r };
        }
      }

      let computedMPID, computedClearingNum;
      if (r[ApiResponseNames.reportingParty] && r[ApiResponseNames.reportingParty].value === "O") {
        computedMPID = getFieldValue(r[ApiResponseNames.executingMPID]);
        computedClearingNum = r[ApiResponseNames.executingClearingNum];
      } else {
        computedMPID = getFieldValue(r[ApiResponseNames.contraMPID]);
        computedClearingNum = r[ApiResponseNames.contraClearingNum];
      }

      if (
        user.mpidAttributes[USER_MPIDS_ATTRIBUTE.UI_TRADE_REPORTING_ACTION_MPIDS].includes(
          computedMPID
        )
      ) {
        return { valid: r };
      }

      if (user?.entitlements[KeycloakRoles.CLEARER] && Array.isArray(refData?.firm)) {
        const clearingMpid = getClearingMpidFromClearingNum(refData.firm, computedClearingNum);
        if (
          user.mpidAttributes[USER_MPIDS_ATTRIBUTE.UI_TRADE_REPORTING_ACTION_MPIDS].includes(
            clearingMpid
          )
        ) {
          return { valid: r };
        }
      }

      return { invalid: r };
    },
    [TableButtonAction.CANCEL]: r => {
      if (user?.entitlements[KeycloakRoles.TRADE_REPORTING_CLEARING_ONLY]) {
        if (!getIsClearingOnlyEligible(r[ApiResponseNames.clearReportRiskVals], false)) {
          return { invalid: r };
        }
      }

      if (
        hasValidMPIDFromReportingParty(
          getFieldValue(r?.reportingParty),
          r,
          user.mpidAttributes[USER_MPIDS_ATTRIBUTE.UI_TRADE_REPORTING_ACTION_MPIDS]
        )
      ) {
        return { valid: r };
      } else {
        return { invalid: r };
      }
    },
    [TableButtonAction.BREAK]: r => {
      if (user?.entitlements[KeycloakRoles.TRADE_REPORTING_CLEARING_ONLY]) {
        if (!getIsClearingOnlyEligible(r[ApiResponseNames.clearReportRiskVals], false)) {
          return { invalid: r };
        }
      }

      const exec =
        [undefined, null, "0", "2"].includes(r.breakState) &&
        user.mpidAttributes[USER_MPIDS_ATTRIBUTE.UI_TRADE_REPORTING_ACTION_MPIDS].includes(
          getFieldValue(r.executingMPID)
        );

      const contra =
        [undefined, null, "0", "1"].includes(r.breakState) &&
        user.mpidAttributes[USER_MPIDS_ATTRIBUTE.UI_TRADE_REPORTING_ACTION_MPIDS].includes(
          getFieldValue(r.contraMPID)
        );

      const returnv = {
        controlNum: r.controlNum,
        side: r.side,
        ...(exec && {
          exec: getFieldValue(r.executingMPID),
        }),
        ...(contra && { contra: getFieldValue(r.contraMPID) }),
      };

      if (exec || contra) {
        return { valid: returnv };
      } else {
        return { invalid: returnv };
      }
    },
    [TableButtonAction.REVERSE]: r => {
      if (user?.entitlements[KeycloakRoles.TRADE_REPORTING_CLEARING_ONLY]) {
        if (!getIsClearingOnlyEligible(r[ApiResponseNames.clearReportRiskVals], false)) {
          return { invalid: r };
        }
      }

      if (
        hasValidMPIDFromReportingParty(
          getFieldValue(r?.reportingParty),
          r,
          user.mpidAttributes[USER_MPIDS_ATTRIBUTE.UI_TRADE_REPORTING_ACTION_MPIDS]
        )
      ) {
        return { valid: r };
      } else {
        return { invalid: r };
      }
    },
    [TableButtonAction.MODIFY]: r => {
      if (user?.entitlements[KeycloakRoles.TRADE_REPORTING_CLEARING_ONLY]) {
        if (!getIsClearingOnlyEligible(r[ApiResponseNames.clearReportRiskVals], false)) {
          return { invalid: r };
        }
      }
      return { valid: r };
    },
    [TableButtonAction.REPAIR]: r => {
      if (
        hasValidMPIDFromReportingParty(
          getFieldValue(r?.reportingParty),
          r,
          user.mpidAttributes[USER_MPIDS_ATTRIBUTE.UI_TRADE_REPORTING_ACTION_MPIDS]
        )
      ) {
        return { valid: r };
      } else {
        return { invalid: r };
      }
    },
    [TableButtonAction.CLOSE]: r => {
      if (
        hasValidMPIDFromReportingParty(
          getFieldValue(r?.reportingParty),
          r,
          user.mpidAttributes[USER_MPIDS_ATTRIBUTE.UI_TRADE_REPORTING_ACTION_MPIDS]
        )
      ) {
        return { valid: r };
      } else {
        return { invalid: r };
      }
    },
    [TableButtonAction.ALLOW]: r => {
      if (Array.isArray(refData?.firm) && hkData.activeHeldId !== null) {
        const firmData = getFirmDataFromClearingData(refData?.clearing);
        const { btExecFlag, btContraFlag, executingClearingNum, contraClearingNum } = r;
        const validBtFlagValues = ["B", "S"];
        const activeRelationship = refData.clearing.find(
          rel => rel.id.toString() === hkData.activeHeldId.toString()
        );
        const { execEntryMPID, contraEntryMPID } = getEntryMPIDsFromHeldRecord(r);
        if (
          firmData[executingClearingNum] &&
          validBtFlagValues.includes(btExecFlag) &&
          activeRelationship.correspondentMPID === execEntryMPID
        ) {
          return { valid: r };
        }

        if (
          firmData[contraClearingNum] &&
          validBtFlagValues.includes(btContraFlag) &&
          activeRelationship.correspondentMPID === contraEntryMPID
        ) {
          return { valid: r };
        }
      }
      return { invalid: r };
    },
    [TableButtonAction.INHIBIT]: r => {
      if (Array.isArray(refData?.clearing) && hkData.activeHeldId !== null) {
        const firmData = getFirmDataFromClearingData(refData?.clearing);
        const { btExecFlag, btContraFlag, executingClearingNum, contraClearingNum } = r;
        const validBtFlagValues = ["B", "S"];
        const activeRelationship = refData.clearing.find(
          rel => rel.id.toString() === hkData.activeHeldId.toString()
        );
        const { execEntryMPID, contraEntryMPID } = getEntryMPIDsFromHeldRecord(r);
        if (
          firmData[executingClearingNum] &&
          validBtFlagValues.includes(btExecFlag) &&
          activeRelationship.correspondentMPID === execEntryMPID
        ) {
          return { valid: r };
        }

        if (
          firmData[contraClearingNum] &&
          validBtFlagValues.includes(btContraFlag) &&
          activeRelationship.correspondentMPID === contraEntryMPID
        ) {
          return { valid: r };
        }
      }
      return { invalid: r };
    },
    [TableButtonAction.MATCH]: r => {
      let myMPID = getFieldValue(r[ApiResponseNames.contraMPID]);
      if (getFieldValue(r[ApiResponseNames.reportingParty]) === ReportingParty.Contra) {
        myMPID = getFieldValue(r[ApiResponseNames.executingMPID]);
      }
      if (
        [TradeStatus.Open, TradeStatus.Unmatched].includes(
          getFieldValue(r[ApiResponseNames.tradeStatus])
        ) &&
        user.mpidAttributes[USER_MPIDS_ATTRIBUTE.UI_TRADE_REPORTING_ACTION_MPIDS].includes(myMPID)
      ) {
        return { valid: r };
      }
      return { invalid: r };
    },
  };
};

export const preCheck = (r, actionId, user, refData, hkData) => {
  if (!checkers()[actionId]) {
    return { valid: r };
  }

  if (r.tradeStatus && r.tradeStatus.value === "P") {
    return { invalid: r };
  }

  // do not allow actions on in-flight actions
  if (r.status === "loading") {
    return { invalid: r };
  }

  if (validTypes(actionId)(r)) {
    return checkers(user, refData, hkData)[actionId](r);
  }

  return { invalid: r };
};

const useCheckers = () => {
  const [user] = useUserContext();
  const [refData] = useRefDataContext();
  const [hkData] = useHKCacheContext();

  return (r, actionId) => {
    return preCheck(r, actionId, user, refData, hkData);
  };
};

export default useCheckers;

export const useToPayload = () => {
  const [formData] = useFormContext();
  const [user] = useUserContext();
  const [refData] = useRefDataContext();
  const form = formData["STAccept"].fields;

  return makePayload({ form, user, refData });
};

export const makePayload = ({ form, user, refData }) => {
  return (r, actionId) => {
    // build the request object array
    let row = {
      ...r,
      controlNum: r?.controlNum,
      frontEndUserId: user.username,
      capacity: form.capacity && form.capacity.value,
      shortSaleInd:
        r && r.side && r.side.value === "B"
          ? (form.contraShortSaleInd && form.contraShortSaleInd.value) || undefined
          : undefined,
      useNetTrading: r?.riskConfig?.[ApiResponseNames.useNetTrading],
    };

    switch (actionId) {
      case TableButtonAction.ACCEPT: {
        const contraMPID = getFieldValue(r[ApiResponseNames.contraMPID]);
        if (
          user.mpidAttributes[USER_MPIDS_ATTRIBUTE.UI_TRADE_REPORTING_ACTION_MPIDS].includes(
            contraMPID
          )
        ) {
          row = Object.assign(row, {
            mpid: contraMPID,
          });
        } else {
          const clearingMpid = getClearingMpidFromClearingNum(
            refData?.firm,
            r[ApiResponseNames.contraClearingNum]
          );
          row = Object.assign(row, {
            mpid: clearingMpid,
          });
        }
        break;
      }
      case TableButtonAction.DECLINE: {
        let computedMPID, computedClearingNum;
        if (
          r[ApiResponseNames.reportingParty] &&
          r[ApiResponseNames.reportingParty].value === "O"
        ) {
          computedMPID = getFieldValue(r[ApiResponseNames.executingMPID]);
          computedClearingNum = r[ApiResponseNames.executingClearingNum];
        } else {
          computedMPID = getFieldValue(r[ApiResponseNames.contraMPID]);
          computedClearingNum = r[ApiResponseNames.contraClearingNum];
        }
        if (
          user.mpidAttributes[USER_MPIDS_ATTRIBUTE.UI_TRADE_REPORTING_ACTION_MPIDS].includes(
            computedMPID
          )
        ) {
          row = Object.assign(row, {
            mpid: computedMPID,
          });
        } else {
          const clearingMpid = getClearingMpidFromClearingNum(refData?.firm, computedClearingNum);
          row = Object.assign(row, {
            mpid: clearingMpid,
          });
        }

        break;
      }
      case TableButtonAction.CANCEL: {
        const computedMPID =
          r.reportingParty && r.reportingParty.value === "O"
            ? getFieldValue(r.contraMPID)
            : getFieldValue(r.executingMPID);

        row = Object.assign(row, {
          mpid: computedMPID,
        });
        break;
      }
      case TableButtonAction.BREAK: {
        if (r.exec) {
          Object.assign(row, {
            exec: r.exec,
            side: r.side.value,
          });
        }

        if (r.contra) {
          Object.assign(row, {
            contra: r.contra,
          });
        }

        break;
      }
      case TableButtonAction.REVERSE:
        const tempObj = { ...r };

        Object.entries(row).forEach(([key, value]) => {
          let fieldName = ApiToFieldMap[key];
          if (typeof fieldName === "function") {
            fieldName = fieldName(row);
          }
          if (fieldName) {
            row[fieldName] = value;
          }
        });

        const getTradeThrough = tradeThroughExempt => {
          if (tradeThroughExempt === "Y") {
            return true;
          }
          if (tradeThroughExempt === "N") {
            return false;
          }
          return undefined;
        };

        Object.assign(row, tempObj, {
          portUser: user.username,
          tradeThrough: getTradeThrough(r[ApiResponseNames.tradeThrough]),
          sellerDays: r[ApiResponseNames.sellerDays],
          isReversalTrade: "true",
          originalControlDate: r.actDate,
          originalControlNum: r.controlNum,
          referenceReportingVenue: (r.trf.value ? r.trf.value : r.trf) === "1" ? "Q" : "B",
          objVer: r.objver,
          priceOverride: row.priceOverride === "O" ? true : false,
        });

        if (row[FieldNames.contract] === undefined) {
          row[FieldNames.contract] = row[ApiResponseNames.price];
        }
        // why are we converting? table reverse shouldn't need this
        convertDataToAPI([row]);

        // TODO: test from here down
        row.modifier2 = row.entryModifier2;
        row.modifier3 = row.entryModifier3;
        row.modifier4 = row.entryModifier4;
        const executionTimeNs = r[FieldNames.executionTimeNs];
        if (Array.isArray(executionTimeNs)) {
          row[
            FieldNames.executionTime
          ] = `${executionTimeNs[0]}:${executionTimeNs[1]}:${executionTimeNs[2]}.${executionTimeNs[3]}`;
        } else {
          delete r[FieldNames.executionTime];
        }
        const modifier2TimeNs = row[FieldNames.modifier2TimeNs];
        const modifier4TimeNs = row[FieldNames.modifier4TimeNs];
        if (Array.isArray(modifier2TimeNs)) {
          row[
            FieldNames.modifier2Time
          ] = `${modifier2TimeNs[0]}:${modifier2TimeNs[1]}:${modifier2TimeNs[2]}.${modifier2TimeNs[3]}`;
        }
        if (Array.isArray(modifier4TimeNs)) {
          r[
            FieldNames.modifier4Time
          ] = `${modifier4TimeNs[0]}:${modifier4TimeNs[1]}:${modifier4TimeNs[2]}.${modifier4TimeNs[3]}`;
        }
        break;
      case TableButtonAction.CLOSE: {
        Object.assign(row, {
          objVer: r.objver,
          workstationId: r.workstationID,
        });
        break;
      }
      default:
        break;
    }

    return row;
  };
};
