import { Button, CSSGrid, Header, Popover, PopoverBody } from "@nef/core";
import { FieldLoop, tableActions } from "components/fields";

import { Forms } from "components/fields/fieldConstants";
import {
  eqrcActiveOrConfiguredStatus,
  eqrcDateRange,
  eqrcGroupId,
  eqrcRuleTypeAlertLevel,
  exportLimitType,
  intradayBuySellLimits,
  intradayNetLimits,
  porAction,
  pvrResubmit,
  rashCancelFields,
} from "components/fields/fieldSubsetsConstants";
import { EqrcExchangeMpidPort } from "components/settings/eqrc/exchangeMpidPort/exchangeMpidPort";
import { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { ApiResponseNames } from "../fields";
import { useFormContext, useFormDispatch } from "../form";
import { useUserContext } from "../user";
import { getFormValidation } from "./actionHooks";

export const BreakMenu = ({ rows }) => {
  const single = rows.filter(r => (r.exec && !r.contra) || (r.contra && !r.exec));
  const double = rows.filter(r => r.exec && r.contra);

  return (
    <>
      {!!single.length && (
        <>
          <p>{single.length} trade(s) will be broken on one side</p>
          <ul>
            {single.map(r => (
              <li>{r.controlNum}</li>
            ))}
          </ul>
        </>
      )}
      {!!double.length && (
        <>
          <p>{double.length} trade(s) will be broken on both sides</p>
          <ul>
            {double.map(r => (
              <li>{r.controlNum}</li>
            ))}
          </ul>
        </>
      )}
    </>
  );
};
export const AcceptMenu = memo(({ hasBuy, isOpen }) => {
  return (
    <>
      <FieldLoop form={Forms.ST_ACCEPT} fields={tableActions.acceptA} />
      {hasBuy && <FieldLoop form={Forms.ST_ACCEPT} fields={tableActions.acceptB} />}
    </>
  );
});

export const MenuHeader = memo(({ header }) => {
  return <Header size={3}>{header}</Header>;
});

export const AltHeader = memo(({ text }) => {
  return (
    <>
      <Header size={3}>{`Cannot ${text} trade(s)`}</Header>
      <p>All trade(s) ignored due to invalid state </p>
    </>
  );
});

export const EditLimitsMenu = ({ row }) => {
  const formDispatch = useFormDispatch();
  const [userData] = useUserContext();

  useEffect(() => {
    const formActions = [
      {
        type: "SET_FORM_VALUES",
        payload: { form: Forms.PTR_INTRADAY_LIMITS, fields: { ...row.riskConfig } },
      },
      {
        type: "INIT_FORM_VALIDATION",
        payload: { form: Forms.PTR_INTRADAY_LIMITS, entitlements: userData.entitlements },
      },
      {
        type: "ALLOW_SHOW_ERROR",
        payload: {
          form: Forms.PTR_INTRADAY_LIMITS,
          allowShowError: true,
        },
      },
    ];
    formDispatch(formActions);
  }, [formDispatch, row.riskConfig, userData.entitlements]);

  if (row.riskConfig[ApiResponseNames.useNetTrading]) {
    return <FieldLoop form={Forms.PTR_INTRADAY_LIMITS} fields={intradayNetLimits} />;
  } else {
    return (
      <CSSGrid cols="1fr 1fr" gap="0 1rem">
        <FieldLoop
          form={Forms.PTR_INTRADAY_LIMITS}
          fields={intradayBuySellLimits}
          isReactFragment={true}
        />
      </CSSGrid>
    );
  }
};

export const ExportLimitsMenu = () => {
  return (
    <FieldLoop form={Forms.PTR_EXPORT_LIMITS} fields={exportLimitType} isReactFragment={true} />
  );
};

export const EqrcEditMenu = () => {
  return (
    <FieldLoop
      form={Forms.EQRC_EDIT_STATUS_MENU}
      fields={eqrcActiveOrConfiguredStatus}
      isReactFragment={true}
    />
  );
};

const AuditMenuWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const MenuButtonWrapper = styled.div`
  grid-column: span 2;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr;
`;

const StyledPopover = styled(Popover)`
  max-height: none;
`;

export const EqrcHistoryMenu = ({
  rows,
  isOpen,
  target,
  requireSelect,
  validRows,
  onClick,
  toggle,
  text,
  form,
  isAlertHistory,
  children,
}) => {
  const [formData] = useFormContext();
  const formDispatch = useFormDispatch();
  const [userData] = useUserContext();
  const [wasLastResetOpen, setLastResetOpen] = useState(false);

  useEffect(() => {
    if (isOpen && isOpen !== wasLastResetOpen) {
      formDispatch({
        type: "RESET_FORM",
        payload: { form, entitlements: userData.entitlements },
      });
    }
    setLastResetOpen(isOpen);
  }, [userData, formDispatch, isOpen, wasLastResetOpen, form]);

  const handleClick = useCallback(() => {
    const valid = getFormValidation(form, formData, formDispatch);
    if (!valid) {
      return;
    } else {
      onClick();
    }
  }, [form, formData, formDispatch, onClick]);

  return (
    <StyledPopover
      isOpen={isOpen}
      target={target}
      placement={"bottom"}
      width={325}
      maxContent={false}
    >
      <PopoverBody>
        {children}
        <AuditMenuWrapper>
          <EqrcExchangeMpidPort form={form} hideClearButton={true}>
            <FieldLoop form={form} fields={eqrcGroupId} isReactFragment={true} />
            {isAlertHistory ? (
              <FieldLoop form={form} fields={eqrcRuleTypeAlertLevel} isReactFragment={true} />
            ) : (
              <></>
            )}
            <FieldLoop form={form} fields={eqrcDateRange} isReactFragment={true} />
          </EqrcExchangeMpidPort>
          <MenuButtonWrapper>
            <Button disabled={requireSelect && validRows.length <= 0} onClick={handleClick}>
              {text}
            </Button>
            <Button onClick={toggle} outline>
              Discard
            </Button>
          </MenuButtonWrapper>
        </AuditMenuWrapper>
      </PopoverBody>
    </StyledPopover>
  );
};

export const RashCancelMenu = ({
  rows,
  isOpen,
  target,
  requireSelect,
  validRows,
  onClick,
  toggle,
  text,
  children,
  setOpen,
}) => {
  const [formData] = useFormContext();
  const formDispatch = useFormDispatch();
  const [userData] = useUserContext();

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  useEffect(() => {
    if (rows?.length !== 1) {
      setOpen(false);
    }
  }, [setOpen, rows]);

  useEffect(() => {
    if (!isOpen) {
      formDispatch({
        type: "RESET_FORM",
        payload: { form: Forms.ORDER_CANCEL, entitlements: userData.entitlements },
      });
    }
  }, [formDispatch, isOpen, userData.entitlements]);

  const handleClick = useCallback(() => {
    const valid = getFormValidation(Forms.ORDER_CANCEL, formData, formDispatch);
    if (!valid) {
      return;
    } else {
      onClick();
    }
  }, [formData, formDispatch, onClick]);

  return (
    <StyledPopover
      isOpen={isOpen}
      target={target}
      placement={"bottom"}
      width={325}
      maxContent={false}
    >
      <PopoverBody>
        {children}
        <FieldLoop form={Forms.ORDER_CANCEL} fields={rashCancelFields} />
        <MenuButtonWrapper>
          <Button onClick={handleClick}>Send</Button>
          <Button onClick={handleClose} outline>
            Close
          </Button>
        </MenuButtonWrapper>
      </PopoverBody>
    </StyledPopover>
  );
};

export const PVResubmitMenu = memo(({ hasBuy, isOpen }) => {
  return (
    <>
      <FieldLoop form={Forms.PVR_RESUBMIT} fields={pvrResubmit.lateTradeMod} />
    </>
  );
});

export const PorActionMenu = memo(({ form }) => {
  return <FieldLoop form={form} fields={porAction.bypassAuditTrailCheck} />;
});
