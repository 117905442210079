import React, { useMemo } from "react";
import { TableButton } from "components/standardTable";
import { Messages } from "wksConstants";
import { TableButtonAction, TableButtonLabel } from "wksConstantsTS";
import { getIsButtonDisabled } from "./util";
import { getNumSelectedRows, useStandardTableContext } from "../standardTableContext";
import { TradesHeader } from "./header.trades";
import { useIsConfirmTableHotButtons } from "./";

export const DeclineButton = ({ table }) => {
  const [standardTableData] = useStandardTableContext();
  const declineButtonConfig = {
    icon: "ban",
    text: TableButtonLabel.DECLINE,
    actionId: TableButtonAction.DECLINE,
    canConfirm: true,
    allowConfirm: useIsConfirmTableHotButtons(),
    requireSelect: true,
    allowMultiSelect: true,
    disabledMessage: Messages.MULTI_ROW,
    header: TradesHeader,
  };
  const numSelected = useMemo(() => {
    return getNumSelectedRows(standardTableData[table]);
  }, [table, standardTableData]);

  return (
    <TableButton
      table={table}
      {...declineButtonConfig}
      disabled={getIsButtonDisabled(declineButtonConfig, numSelected)}
      key="tableButton_decline"
    />
  );
};
