import { Forms } from "components/fields/fieldConstants";
import { Submit, useFormContext, useFormDispatch } from "components/form";
import React, { useCallback } from "react";
import { useSettingsContext } from "components/settings";
import {
  convertDataToAPI,
  errorCallbackCB,
  formValidationCheck,
  useStandardTableContext,
  useStandardTableDispatch,
} from "components/standardTable";
import { doAction } from "components/standardTable/network";
import { useUserContext } from "components/user";
import { StandardTables } from "wksConstants";
import { TableButtonAction } from "wksConstantsTS";
import { setFormLoading, setTableLoading } from ".";
import { useViewModelDispatch } from "../../context";
import { actionCallbacks, payload } from "components/standardTable/actionHooks";
import { network } from "components/reportScan/network";
import { useHistory } from "react-router-dom";
import { USER_MPIDS_ATTRIBUTE } from "components/user/mpidContext";

const accentName = "primary";
const buttonText = "Send";
const table = StandardTables.TR_REPORT;
const form = Forms.TR_REPORT;
export const ReportSubmit = () => {
  const [formData] = useFormContext();
  const formDispatch = useFormDispatch();
  const [standardTableData] = useStandardTableContext();
  const standardTableDispatch = useStandardTableDispatch();
  const [userData] = useUserContext();
  const [settingsData] = useSettingsContext();
  const viewDispatch = useViewModelDispatch();
  const history = useHistory();
  const errorCallback = useCallback(
    errorCallbackCB({ formDispatch, standardTableDispatch, table, form }),
    [formDispatch, standardTableDispatch]
  );

  const reportCallback = useCallback(
    responseJson => {
      actionCallbacks.report({
        formDispatch,
        standardTableDispatch,
        settingsData,
        userData,
        ack: responseJson[0],
      });
    },
    [formDispatch, settingsData, standardTableDispatch, userData]
  );

  const reverseCallback = useCallback(
    responseJson => {
      actionCallbacks.reverse({
        formDispatch,
        standardTableDispatch,
        form,
        table,
        viewDispatch,
        ack: responseJson,
        history,
      });
    },
    [formDispatch, standardTableDispatch, viewDispatch, history]
  );

  const handleSubmit = useCallback(() => {
    const valid = formValidationCheck({
      formData,
      formDispatch,
      form: form,
      userData,
    });
    if (!valid) {
      return;
    }

    const fieldData = [{ ...formData[form.key].fields }];

    if (formData[Forms.TR_REPORT.key].fields.isReversal) {
      const reqData = convertDataToAPI(fieldData);
      reqData[0].isReversalTrade = "true";
      doAction(TableButtonAction.REVERSE, reqData, reverseCallback, errorCallback);
    } else {
      const reqData = payload.report({
        fieldData: fieldData[0],
        username: userData.username,
      });
      network(userData.mpidAttributes[USER_MPIDS_ATTRIBUTE.WORKX_TRADE_REPORTING_MPIDS]).doReport(
        [reqData],
        reportCallback,
        errorCallback
      );
    }

    setFormLoading(formDispatch, form);
    setTableLoading(standardTableDispatch, table);
  }, [
    formData,
    formDispatch,
    userData,
    standardTableDispatch,
    reverseCallback,
    errorCallback,
    reportCallback,
  ]);

  return (
    <Submit
      form={form}
      accentName={accentName}
      buttonText={buttonText}
      onSubmit={handleSubmit}
      isDisabled={standardTableData[table]?.isLoading}
      isLoading={formData[form.key]?.isLoading}
      errors={Object.keys(formData[form.key].totalFormErrors).length}
    />
  );
};
