import dayjs from "dayjs";

import { TableColumn } from "./columnDefinitions";
import { BREACHES, BREACHES_LABELS } from "./eqrc.types";

enum BreachDetailFields {
  ALERT_TYPE = "alertType",
  EXCHANGE = "exchange",
  MPID = "mpid",
  PORT = "port",
  GROUP_ID = "groupId",
  SYMBOL = "symbol",
  QUANTITY = "quantity",
  ORDER_REF_ID = "orderRefId",
  SIDE = "side",
  TIME_STAMP = "timeStamp",
}

export const columns: { [columnName: string]: TableColumn } = {
  alertType: {
    accessor: BreachDetailFields.ALERT_TYPE,
    id: BreachDetailFields.ALERT_TYPE,
    Header: "Breach Type",
    width: 160,
    Cell: ({ row }: { row: any }) => {
      return BREACHES_LABELS[row.original.alertType as BREACHES];
    },
  },
  exchange: {
    accessor: BreachDetailFields.EXCHANGE,
    id: BreachDetailFields.EXCHANGE,
    Header: "Exchange",
    width: 120,
  },
  mpid: {
    accessor: BreachDetailFields.MPID,
    id: BreachDetailFields.MPID,
    Header: "MPID",
    width: 80,
  },
  port: {
    accessor: BreachDetailFields.PORT,
    id: BreachDetailFields.PORT,
    Header: "Port",
    width: 80,
  },
  groupId: {
    accessor: BreachDetailFields.GROUP_ID,
    id: BreachDetailFields.GROUP_ID,
    Header: "Group ID",
    width: 80,
  },
  symbol: {
    accessor: BreachDetailFields.SYMBOL,
    id: BreachDetailFields.SYMBOL,
    Header: "Symbol",
    width: 80,
  },
  quantity: {
    accessor: BreachDetailFields.QUANTITY,
    id: BreachDetailFields.QUANTITY,
    Header: "Quantity",
    width: 80,
  },
  tradeRefId: {
    accessor: BreachDetailFields.ORDER_REF_ID,
    id: BreachDetailFields.ORDER_REF_ID,
    Header: "Order ID",
    width: 80,
  },
  side: {
    accessor: BreachDetailFields.SIDE,
    id: BreachDetailFields.SIDE,
    Header: "Side",
    width: 80,
  },
  timeStamp: {
    accessor: BreachDetailFields.TIME_STAMP,
    id: BreachDetailFields.TIME_STAMP,
    Header: "Breach Time",
    Cell: ({ row }: { row: any }) => {
      return dayjs(row.original.timestamp).format("YYYY:MM:DD hh:mm:ss");
    },
  },
};

export const columns2 = [
  columns.exchange,
  columns.mpid,
  columns.port,
  columns.groupId,
  columns.alertType,
  columns.symbol,
  columns.side,
  columns.quantity,
  columns.tradeRefId,
  columns.timeStamp,
];
