import { Forms } from "components/fields/fieldConstants";
import { useFormContext, useFormDispatch } from "components/form";
import { SettingsWrapper } from "components/settings";
import { formValidationCheck, useStandardTableDispatch } from "components/standardTable";
import { useCallback, useEffect } from "react";
import { Button, NotificationHub } from "@nef/core";
import { FieldNames, porAuditFields } from "components/fields";
import FieldLoop from "components/fields/fieldLoop";
import { getHeaders } from "keycloak";
import { doFetchWrapper } from "network";
import styled from "styled-components";
import { SelectOption } from "types";
import { formatUrl } from "utils/js.utils";
import { StandardTables } from "wksConstants";
import { PorAuditTable } from "./porAuditTable";
import { useUserContext } from "components/user";
import { FROM_DATE_TIME, TO_DATE_TIME, formatDateTimeWithoutTz } from "utils/dateUtils";
import { PORModel } from "components/pvRejects/constant";

const StyledButton = styled(Button)`
  margin-top: 21px !important;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr auto;
  align-items: start;
  height: 75px;
  grid-gap: 1rem;
`;

type PorAuditTrailBody = {
  startDateTime: string;
  endDateTime: string;
  symbols: string[];
  mpids: string[];
};

export const PorAudit = () => {
  const tableDispatch = useStandardTableDispatch();
  const [formData] = useFormContext();
  const formDispatch = useFormDispatch();
  const [userData] = useUserContext();

  const successCb = useCallback(
    (data: PORModel[]) => {
      tableDispatch([
        {
          type: "SET_TABLE_DATA",
          payload: { table: StandardTables.POR_AUDIT, data },
        },
        {
          type: "SET_IS_LOADING",
          payload: { table: StandardTables.POR_AUDIT, isLoading: false },
        },
        {
          type: "SET_LAST_QUERY_DATE",
          payload: { table: StandardTables.POR_AUDIT },
        },
      ]);
      NotificationHub.send("success", `Retrieved ${data.length} audit trail result(s)`);
    },
    [tableDispatch]
  );

  const errorCb = useCallback(
    (err: { message?: string }) => {
      NotificationHub.send(
        "danger",
        err?.message ? err.message : "Error retrieving audit trail results"
      );
      tableDispatch(
        {
          type: "SET_TABLE_DATA",
          payload: { table: StandardTables.POR_AUDIT, data: [] },
        },
        {
          type: "SET_IS_LOADING",
          payload: { table: StandardTables.POR_AUDIT, isLoading: false },
        },
        {
          type: "SET_LAST_QUERY_DATE",
          payload: { table: StandardTables.POR_AUDIT },
        }
      );
    },
    [tableDispatch]
  );

  const handleAudit = useCallback(() => {
    const valid = formValidationCheck({
      formData,
      formDispatch,
      form: Forms.POR_AUDIT,
      userData,
    });
    if (!valid) {
      return;
    }
    tableDispatch({
      type: "SET_IS_LOADING",
      payload: { table: StandardTables.POR_AUDIT, isLoading: true },
    });
    const formValues = formData[Forms.POR_AUDIT.key].fields;
    const body: PorAuditTrailBody = {
      startDateTime: formatDateTimeWithoutTz(
        formValues[FieldNames.dateRange]?.[0],
        FROM_DATE_TIME
      ) as string,
      endDateTime: formatDateTimeWithoutTz(
        formValues[FieldNames.dateRange]?.[1],
        TO_DATE_TIME
      ) as string,
      symbols: formValues[FieldNames.symbol]?.map((opt: SelectOption) => opt.value) ?? [],
      mpids: formValues[FieldNames.mpid]?.map((opt: SelectOption) => opt.value) ?? [],
    };
    doFetchWrapper(
      formatUrl(
        process.env.REACT_APP_URL_PVR_POR_SERVICE,
        "/por/searchByPendingDateTimeBetweenAndSymbolAndMpid"
      ),
      {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(body),
      },
      successCb,
      errorCb
    );
  }, [errorCb, formData, formDispatch, successCb, tableDispatch, userData]);

  useEffect(() => {
    formDispatch({
      type: "INIT_FORM_VALIDATION",
      payload: { form: Forms.POR_AUDIT },
    });
  }, [formDispatch]);

  return (
    <>
      <SettingsWrapper
        header="POR Audit Trail"
        subheader={
          <Grid>
            <FieldLoop
              isReactFragment={true}
              form={Forms.POR_AUDIT}
              fields={porAuditFields}
              classNames={undefined}
              augmentOnChange={undefined}
              augmentOnCreate={undefined}
              portalRef={undefined}
              isDisabled={undefined}
              containerRef={undefined}
              showLabel={undefined}
            />
            <StyledButton onClick={handleAudit} size="sm">
              Scan
            </StyledButton>
          </Grid>
        }
        errorMessage={formData[Forms.POR_AUDIT.key].globalErrorMessage}
        hasSave={false}
        disableFormWrapperClass={true}
        headerMenu={undefined}
        RightSide={undefined}
        tabs={undefined}
      >
        <PorAuditTable />
      </SettingsWrapper>
    </>
  );
};
