import React from "react";
import { NotificationHub } from "@nef/core";
import { ApiToFieldMap, ErrorCode, Forms } from "components/fields/fieldConstants";
import { ExportNotificationButton } from ".";
import { StandardTables, TradeStatus } from "../../wksConstants";
import { TableButtonAction } from "../../wksConstantsTS";
import { convertSampleAPIData } from "./sampleApiData";
import { ApiResponseNames, FieldNames } from "../fields";
import { getFieldLabel, getFieldValue } from "utils/js.utils";
import { NotificationParagraph } from "components/styled";

export const getFormValidation = (form, formData, formDispatch, user) => {
  formDispatch({
    type: "ALLOW_SHOW_ERROR",
    payload: {
      form: form,
      allowShowError: true,
    },
  });

  // we have errors, prevent submission of data
  if (formData[form.key].totalFormErrors > 0) {
    formDispatch({
      type: "SET_GLOBAL_ERROR",
      payload: {
        message: "Validation errors detected",
        form: form,
      },
    });

    const scrollElement = window.document.querySelectorAll(".heightOverride")[0];
    if (scrollElement) {
      scrollElement.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }

    return false;
  } else {
    formDispatch({
      type: "SET_GLOBAL_ERROR",
      payload: {
        message: "",
        form: form,
      },
    });
    return true;
  }
};

export const formValidationCheck = ({ formData, formDispatch, form, userData }) => {
  return getFormValidation(form, formData, formDispatch, userData);
};

export const errorCallbackCB =
  ({ formDispatch, standardTableDispatch, table, form }) =>
  (resp, id, controlNum) => {
    if (resp?.[ApiResponseNames.errorMessages]?.length > 0) {
      resp[ApiResponseNames.errorMessages].forEach(errorMsg => {
        NotificationHub.send("danger", errorMsg);
      });
    } else {
      const errorText = resp?.message;
      NotificationHub.send("danger", errorText?.length ? errorText : "Server Error", {
        subtitle: "Please try your request again",
      });
    }

    standardTableDispatch({
      type: "SET_IS_LOADING",
      payload: { table, isLoading: false },
    });

    formDispatch({
      type: "SET_NOT_IS_LOADING",
      payload: { form },
    });

    let message = "";
    if (resp?.errorType === "MissingRequiredField") {
      message = resp.message;
      const field = resp?.subErrors?.[0].field;
      if (field === ApiResponseNames.sellerDays) {
        message += " Settlement Other";
      } else {
        message += ` ${ApiToFieldMap[resp?.subErrors?.[0].field] || resp?.subErrors?.[0].field}`;
      }
    }
    if (resp?.errorType === "General") {
      message = resp.message;
    }
    if (resp?.[ApiResponseNames.errorCode] === ErrorCode.BAD_REQUEST) {
      message = resp?.[ApiResponseNames.errorMessages]?.[0];
    }
    formDispatch({
      type: "SET_GLOBAL_ERROR",
      payload: {
        message: message,
        form,
      },
    });

    const values = [
      { key: "status", value: "danger" },
      {
        key: "statusText",
        value: {
          statusText: message || "Network Error",
          ackType: "failure",
          tradeActionType: form?.label,
        },
      },
    ];

    let realControlNum;
    if (controlNum) {
      realControlNum = controlNum;
    } else {
      realControlNum = "";
    }
    const key = realControlNum
      ? { value: realControlNum, key: "controlNum" }
      : { value: id, key: "id" };
    standardTableDispatch({
      type: "UPDATE_CELLS",
      payload: {
        table,
        key,
        values: values,
      },
    });
  };

// might be able to be a generic interval for table and truncated
const setTruncatedConversionInterval = (
  standardTableDispatch,
  convertedTableData,
  truncated,
  table
) => {
  const increment = 2500;
  let startTruncated = 0;
  let convertedTruncation = [];
  const convertTruncatedInterval = setInterval(() => {
    if (startTruncated >= truncated.length) {
      standardTableDispatch([
        {
          type: "SET_ALL_THE_DATA",
          payload: {
            table,
            data: convertedTableData.concat(convertedTruncation),
          },
        },
        {
          type: "SET_IS_EXPORT_DISABLED",
          payload: { table, isDisabled: false },
        },
      ]);
      window.clearInterval(convertTruncatedInterval);
    }
    const end = startTruncated + increment;
    const conversion = convertSampleAPIData(truncated.slice(startTruncated, end));
    convertedTruncation = convertedTruncation.concat(conversion);
    startTruncated += increment;
  }, 500);
};
const subtitleGenerator = ({ size, table, length }) => (
  <>
    <p>{`${length} matches found. Query result set larger than maximum table rows -
          ${size} of ${length} records
          loaded into table`}</p>
    <ExportNotificationButton table={table} />
  </>
);

const reportActionCb = ({
  formDispatch,
  standardTableDispatch,
  viewDispatch,
  form,
  table,
  ack,
  history,
}) => {
  const formActions = [];
  if (getFieldValue(ack?.tradeStatus) === TradeStatus.Rejected) {
    if (ack.rejectText) {
      formActions.push({
        type: "SET_GLOBAL_ERROR",
        payload: {
          message: ack.rejectText,
          form,
        },
      });

      // don't add row to table if reject source is not A
      if (ack.rejectSource !== "A") {
        return;
      }
      standardTableDispatch({
        type: "SET_SOMEONE_ASKED_THAT_WE_REFRESH",
        payload: { ask: true, table },
      });

      NotificationHub.send("danger", `${form.label} not complete`);
    }
  } else {
    const { contraMPID, executingMPID, symbol, side, quantity, controlNum } = ack;
    viewDispatch({
      type: "CANCEL_FORM",
      payload: { history },
    });
    NotificationHub.send("success", `${form.label} complete`, {
      subtitle: `MPID: ${getFieldLabel(executingMPID)}, Contra: ${getFieldLabel(
        contraMPID
      )}, Symbol: ${symbol}, Side: ${side?.label}, Quantity: ${quantity}, Control#: ${controlNum}`,
    });
  }

  standardTableDispatch([
    {
      type: "SET_NOT_LOADING",
      payload: { table },
    },
    {
      type: "ADD_TABLE_DATA_FIRST",
      payload: {
        data: ack,
        table,
      },
    },
    {
      type: "DESELECT_ALL_ROWS",
      payload: {
        table,
      },
    },
  ]);
  formActions.push({
    type: "SET_NOT_IS_LOADING",
    payload: { form },
  });
  formDispatch(formActions);
};

const getReportSubtitle = ({ executingMPID, contraMPID, symbol, side, quantity, controlNum }) =>
  `MPID: ${getFieldLabel(executingMPID)}, Contra: ${getFieldLabel(
    contraMPID
  )}, Symbol: ${symbol}, Side: ${
    side?.label ? side.label : "N/A"
  }, Quantity: ${quantity}, Control#: ${controlNum ? controlNum : "N/A"}`;

export const actionCallbacks = {
  report: ({ formDispatch, standardTableDispatch, settingsData, userData, ack }) => {
    const dispatches = [];
    const formActions = [];
    if (ack?.tradeStatus?.value === "-") {
      if (ack.rejectText) {
        const { contraMPID, executingMPID, symbol, side, quantity, controlNum } = ack;

        formActions.push({
          type: "SET_GLOBAL_ERROR",
          payload: {
            message: ack.rejectText,
            form: Forms.TR_REPORT,
          },
        });

        NotificationHub.send("danger", "Report did not complete", {
          subtitle: getReportSubtitle({
            executingMPID,
            contraMPID,
            symbol,
            side,
            quantity,
            controlNum,
          }),
        });

        dispatches.push({
          type: "SET_SOMEONE_ASKED_THAT_WE_REFRESH",
          payload: { table: StandardTables.TR_REPORT, ask: true },
        });
      }
    } else {
      const { contraMPID, executingMPID, symbol, side, quantity, controlNum } = ack;

      NotificationHub.send("success", "Report complete", {
        subtitle: getReportSubtitle({
          executingMPID,
          contraMPID,
          symbol,
          side,
          quantity,
          controlNum,
        }),
      });
    }

    const dispatches2 = [
      {
        type: "SET_NOT_LOADING",
        payload: { table: StandardTables.TR_REPORT },
      },

      {
        type: "DESELECT_ALL_ROWS",
        payload: {
          table: StandardTables.TR_REPORT,
        },
      },
      { type: "SET_LAST_QUERY_DATE", payload: { table: StandardTables.TR_REPORT } },
    ];

    // don't add row to table if reject source is not A
    if (ack.rejectSource !== "A") {
      dispatches2.push({
        type: "ADD_TABLE_DATA_FIRST",
        payload: {
          data: ack,
          table: StandardTables.TR_REPORT,
        },
      });
    }

    standardTableDispatch(dispatches2.concat(dispatches));

    if (!settingsData.stickyFields_submit && !ack.rejectText) {
      formActions.push({
        type: "RESET_FORM",
        payload: { form: Forms.TR_REPORT, entitlements: userData.entitlements },
      });
    }
    formActions.push({
      type: "SET_NOT_IS_LOADING",
      payload: { form: Forms.TR_REPORT },
    });
    formDispatch(formActions);
  },
  genericScan: (
    formDispatch,
    maxTableSize,
    standardTableDispatch,
    tableData,
    truncated,
    table,
    form,
    userMpids
  ) => {
    const increment = 1000;
    let convertedTableData = [];
    let startTableData = 0;

    const convertTableDataInterval = setInterval(() => {
      if (startTableData >= tableData.length) {
        window.clearInterval(convertTableDataInterval);
        const allData = convertedTableData.concat(truncated);
        const subtitle =
          allData.length > maxTableSize
            ? subtitleGenerator({
                size: maxTableSize,
                length: allData.length,
                table,
              })
            : `${allData.length || "No"} matches found`;

        NotificationHub.send("success", "Scan complete", {
          subtitle,
        });

        let tableDispatches = [];
        if (Array.isArray(truncated) && truncated.length > 0) {
          tableDispatches.push({
            type: "SET_IS_EXPORT_DISABLED",
            payload: { table: table, isDisabled: true },
          });

          setTruncatedConversionInterval(
            standardTableDispatch,
            convertedTableData,
            truncated,
            table
          );
        }

        tableDispatches = tableDispatches.concat([
          {
            type: "SET_TABLE_DATA",
            payload: {
              table: table,
              data: convertedTableData.concat(truncated),
            },
          },
          {
            type: "SET_NOT_LOADING",
            payload: { table: table, isLoading: false },
          },
          {
            type: "DESELECT_ALL_ROWS",
            payload: {
              table: table,
            },
          },
          { type: "SET_LAST_QUERY_DATE", payload: { table } },
        ]);

        standardTableDispatch(tableDispatches);

        if (form) {
          formDispatch({
            type: "SET_NOT_IS_LOADING",
            payload: { form: form },
          });
        }
      }

      const end = startTableData + increment;
      const conversion = convertSampleAPIData(tableData.slice(startTableData, end), userMpids);
      convertedTableData = convertedTableData.concat(conversion);
      startTableData += increment;
    }, 300);
  },
  repair: ({
    row,
    viewDispatch,
    formDispatch,
    userData,
    standardTableDispatch,
    id,
    form,
    table,
    history,
  }) => {
    const formActions = [];
    if (row.rejectText) {
      formActions.push({
        type: "SET_GLOBAL_ERROR",
        payload: {
          message: row.rejectText,
          form,
        },
      });
    }
    const standardTableDispatches = [
      {
        type: "DESELECT_ALL_ROWS",
        payload: { table },
      },
    ];
    const statusText = row.rejectText ? row.rejectText : undefined;
    Object.assign(row, {
      status: row.tradeStatus?.value === "-" ? "danger" : "success",
      statusText: [
        {
          statusText,
          ackType: row.tradeStatus?.value === "-" ? "failure" : "success",
          tradeActionType: "Repair",
        },
      ],
    });

    standardTableDispatches.push({
      type: "SWAP_IN_ROW",
      payload: { table, row, id },
    });

    formActions.push({ type: "SET_NOT_IS_LOADING", payload: { form } });
    if (row.tradeStatus.value !== "-") {
      formActions.push({
        type: "RESET_FORM",
        payload: { form, entitlements: userData.entitlements },
      });
      viewDispatch({
        type: "CANCEL_FORM",
        payload: { history },
      });

      const { executingMPID, contraMPID, symbol, side, quantity, controlNum } = row;
      NotificationHub.send("success", "Repair complete", {
        subtitle: getReportSubtitle({
          executingMPID,
          contraMPID,
          symbol,
          side,
          quantity,
          controlNum,
        }),
      });
    } else {
      NotificationHub.send("danger", "Repair did not complete");
    }
    if (!row.rejectText) {
      standardTableDispatches.push({
        type: "DESELECT_ALL_ROWS",
        payload: {
          table,
        },
      });
    }
    formDispatch(formActions);
    standardTableDispatch(standardTableDispatches);
  },
  modify: ({
    formDispatch,
    table,
    form,
    viewDispatch,
    standardTableDispatch,
    responseJson,
    originalWksID,
    originalControlNum,
    history,
  }) => {
    const [fresh, original] = responseJson;
    const formActions = [];
    // new trade failed
    if (fresh.rejectText?.length && original.rejectText?.length) {
      const values = [
        { key: "status", value: "danger" },
        {
          key: "statusText",
          value: [
            {
              statusText: fresh.rejectText,
              ackType: "failure",
              tradeActionType: TableButtonAction.MODIFY,
            },
          ],
        },
      ];
      formActions.push({
        type: "SET_GLOBAL_ERROR",
        payload: { form, message: fresh.rejectText },
      });

      const key = originalControlNum
        ? { value: originalControlNum, key: "controlNum" }
        : { value: originalWksID, key: "workstationID" };
      standardTableDispatch({
        type: "UPDATE_CELLS",
        payload: {
          table,
          key,
          values: values,
        },
      });

      NotificationHub.send("danger", "Modify Not Complete", {
        subtitle: (
          <>
            <NotificationParagraph>As-of Correction Trade did not succeed.</NotificationParagraph>
            <NotificationParagraph>
              Reversal for the Original Trade was not processed.
            </NotificationParagraph>
            <NotificationParagraph>
              Please correct and re-submit the as-of trade error in the modify form.
            </NotificationParagraph>
          </>
        ),
      });
      formActions.push({ type: "SET_NOT_IS_LOADING", payload: { form } });
    } else if (!fresh.rejectText?.length && original.rejectText?.length) {
      // reversal trade failed
      const key = originalControlNum
        ? { value: originalControlNum, key: "controlNum" }
        : { value: originalWksID, key: "workstationID" };
      Object.assign(fresh, {
        status: "success",
        statusText: [
          {
            statusText: undefined,
            ackType: "success",
            tradeActionType: TableButtonAction.MODIFY,
          },
        ],
      });

      const originalStatusUpdate = [
        { key: "status", value: "danger" },
        {
          key: "statusText",
          value: [
            {
              statusText: `Trade reversal failure, ${original.rejectText}`,
              ackType: "failure",
              tradeActionType: TableButtonAction.MODIFY,
            },
          ],
        },
      ];

      if (table === StandardTables.TR_REPORT) {
        standardTableDispatch({
          type: "SET_SOMEONE_ASKED_THAT_WE_REFRESH",
          payload: { ask: true, table },
        });
      } else {
        standardTableDispatch({
          type: "ADD_ROW_BEFORE_GENERIC",
          payload: {
            table,
            key: key.key,
            value: key.value,
            row: fresh,
            originalWksID,
          },
        });
      }

      viewDispatch({
        type: "CANCEL_FORM",
        payload: { history },
      });
      standardTableDispatch({
        type: "UPDATE_CELLS",
        payload: {
          table,
          key,
          values: originalStatusUpdate,
        },
      });

      const { controlNum } = fresh;
      const { controlNum: ogControlNum } = original;
      NotificationHub.send("danger", "Modify Not Complete", {
        subtitle: (
          <>
            <NotificationParagraph>
              Control#: {controlNum} (As-of Correction Trade)
            </NotificationParagraph>
            <NotificationParagraph>
              Orig Control#: {ogControlNum} (Reversal did not succeed)
            </NotificationParagraph>
            <NotificationParagraph>
              Please go to My Reported Trade or Reject Scan to repair the failed reversal.
            </NotificationParagraph>
          </>
        ),
      });
    } else {
      // success
      Object.assign(fresh, {
        status: fresh.rejectText ? "danger" : "success",
        statusText: [
          {
            statusText: fresh.rejectText ? fresh.rejectText : undefined,
            ackType: fresh.rejectText ? "failure" : "success",
            tradeActionType: TableButtonAction.MODIFY,
          },
        ],
      });

      Object.assign(original, {
        status: original.rejectText ? "danger" : "success",
        statusText: [
          {
            statusText: original.rejectText ? original.rejectText : undefined,
            ackType: original.rejectText ? "failure" : "success",
            tradeActionType: TableButtonAction.MODIFY,
          },
        ],
      });

      const originalStatus = [
        { key: "status", value: original.rejectText ? "danger" : "success" },
        {
          key: "statusText",
          value: [
            {
              statusText: original.rejectText ? original.rejectText : undefined,
              ackType: original.rejectText ? "failure" : "success",
              tradeActionType: TableButtonAction.MODIFY,
            },
          ],
        },
      ];
      const { controlNum } = fresh;
      const { controlNum: reversalControlNum } = original;
      NotificationHub.send("success", "Modify Complete", {
        subtitle: (
          <>
            <NotificationParagraph>
              Control#: {controlNum} (As-Of Correction Trade)
            </NotificationParagraph>
            <NotificationParagraph>Control#: {reversalControlNum} (Reversal)</NotificationParagraph>
          </>
        ),
      });
      const key = originalControlNum
        ? { value: originalControlNum, key: "controlNum" }
        : { value: originalWksID, key: "workstationID" };
      //must 'add before' before we replace the row we would be looking for...
      standardTableDispatch({
        type: "ADD_ROW_BEFORE_GENERIC",
        payload: {
          table,
          key: key.key,
          value: key.value,
          row: original,
        },
      });
      standardTableDispatch({
        type: "ADD_ROW_BEFORE_GENERIC",
        payload: {
          table,
          key: key.key,
          value: key.value,
          row: fresh,
        },
      });

      standardTableDispatch({
        type: "UPDATE_CELLS",
        payload: { table, key, values: originalStatus },
      });

      viewDispatch({
        type: "CANCEL_FORM",
        payload: { history },
      });
    }
    formActions.push({
      type: "SET_NOT_IS_LOADING",
      payload: { form },
    });
    formDispatch(formActions);
  },
  copy: ({ formDispatch, standardTableDispatch, viewDispatch, form, table, ack, history }) => {
    reportActionCb({
      formDispatch,
      standardTableDispatch,
      viewDispatch,
      form,
      table,
      ack,
      history,
    });
  },
  reverse: ({ formDispatch, standardTableDispatch, form, table, viewDispatch, ack, history }) => {
    const formActions = [];
    formActions.push({
      type: "SET_NOT_IS_LOADING",
      payload: { form },
    });
    standardTableDispatch({
      type: "SET_IS_LOADING",
      payload: { table, isLoading: false },
    });

    if (ack?.json?.errorType) {
      formActions.push({
        type: "SET_GLOBAL_ERROR",
        payload: { form, message: ack?.json?.message },
      });
      NotificationHub.send("danger", "Reverse failed", {
        subtitle: ack?.json?.message,
      });
    } else {
      const r = convertSampleAPIData([ack.json])[0];
      if (r.rejectText?.length) {
        formActions.push({
          type: "SET_GLOBAL_ERROR",
          payload: { form, message: r.rejectText },
        });
        NotificationHub.send("danger", "Reverse rejected", {
          subtitle: r.rejectText,
        });
      } else {
        standardTableDispatch({
          type: "ADD_TABLE_DATA_FIRST",
          payload: { table, data: r },
        });
        viewDispatch({
          type: "CANCEL_FORM",
          payload: { history },
        });
        NotificationHub.send("success", "Reverse complete", {
          subtitle: `Control#: ${
            r[ApiResponseNames.controlNum] ? r[ApiResponseNames.controlNum] : "N/A"
          }`,
        });
      }
    }
    formDispatch(formActions);
  },
  match: ({
    formDispatch,
    standardTableDispatch,
    viewDispatch,
    form,
    table,
    ack,
    history,
    userData,
  }) => {
    const formActions = [];
    if (getFieldValue(ack?.tradeStatus) === TradeStatus.Rejected) {
      if (ack.rejectText) {
        formActions.push({
          type: "SET_GLOBAL_ERROR",
          payload: {
            message: ack.rejectText,
            form,
          },
        });
        if (ack.rejectSource !== "A") {
          return;
        }
        standardTableDispatch({
          type: "SET_SOMEONE_ASKED_THAT_WE_REFRESH",
          payload: { table, ask: true },
        });
        NotificationHub.send("danger", `${form.label} not complete`);
      }
    } else {
      const { contraMPID, executingMPID, symbol, side, quantity, controlNum, tradeStatus } = ack;
      viewDispatch({
        type: "CANCEL_FORM",
        payload: { history },
      });
      if (tradeStatus?.value === TradeStatus.Matched) {
        NotificationHub.send("success", `Match complete`, {
          subtitle: getReportSubtitle({
            executingMPID,
            contraMPID,
            symbol,
            side,
            quantity,
            controlNum,
          }),
        });
      } else {
        NotificationHub.send("warning", `Report complete`, {
          subtitle: `Control#: ${controlNum} could not be verified with a Match Control# or Matched status. Please verify Control#: ${controlNum} submission.`,
        });
      }
    }

    standardTableDispatch([
      {
        type: "SET_NOT_LOADING",
        payload: { table },
      },
      {
        type: "ADD_TABLE_DATA_FIRST",
        payload: {
          data: ack,
          table,
        },
      },
      {
        type: "DESELECT_ALL_ROWS",
        payload: {
          table,
        },
      },
    ]);
    formActions.push({
      type: "SET_NOT_IS_LOADING",
      payload: { form },
    });
    formDispatch(formActions);
  },
};

export const payload = {
  report: ({ fieldData, username }) => {
    fieldData["portUser"] = username;
    return fieldData;
  },
  repair: ({ userData, form, formDispatch, selectedRow, fieldData }) => {
    // have to stuff workstationID into form object so it persists
    if (!fieldData.workstationId) {
      formDispatch({
        type: "WRITE_FORM_VALUE",
        payload: {
          fields: { workstationId: selectedRow.workstationID },
          form,
        },
      });
    }
    // then add extra keys not on form that are needed for post
    Object.assign(fieldData, {
      tradeStatus: selectedRow.tradeStatus || "",
      rejectText: selectedRow.rejectText || "",
      workstationId: selectedRow.workstationID,
      portUser: userData.user,
    });

    return fieldData;
  },
  // modify sends original and edit records
  modify: ({ formData, formDispatch, userData, form, fieldData, selectedRow }) => {
    if (!fieldData[0].workstationId) {
      formDispatch({
        type: "WRITE_FORM_VALUE",
        payload: {
          fields: { workstationId: selectedRow.workstationID },
          form,
        },
      });
    }

    Object.assign(fieldData[0], {
      portUser: userData.username,
      workstationId: selectedRow.workstationID,
    });

    fieldData[1] = formData[form.key].valuesAsSet;
    Object.assign(fieldData[1], {
      portUser: userData.username,
      tradeThrough: selectedRow.tradeThrough === "y" ? true : undefined,
      [FieldNames.tradeThroughExempt]: selectedRow[ApiResponseNames.tradeThroughExempt],
      [FieldNames.sellerDays]: selectedRow[ApiResponseNames.sellerDays]
        ? selectedRow[ApiResponseNames.sellerDays]
        : selectedRow[ApiResponseNames.settlement].value,
      isReversalTrade: "true",
      originalControlDate: selectedRow.actDate,
      originalControlNum: selectedRow.controlNum,
      referenceReportingVenue:
        (selectedRow.trf.value ? selectedRow.trf.value : selectedRow.trf) === "1" ? "Q" : "B",
      workstationId: selectedRow.workstationID,
      clearingFlag: selectedRow.clearingFlag,
    });

    return fieldData;
  },
  copy: ({ fieldData, username }) => {
    fieldData["portUser"] = username;
    return fieldData;
  },
};
