import { NotificationHub } from "@nef/core";
import { SelectOptions } from "components/fields";
import { getHeaders } from "keycloak";
import { doFetchWrapper } from "network";
import { createContext, useCallback, useContext, useEffect, useReducer } from "react";
import { addPrefixToField, formatUrl } from "utils/js.utils";
import { Views } from "viewConstants";
import { useUserContext } from "../../../user";
import { PTRA_CONFIGURATION_ENTITY, PTRA_FIELD_PREFIX } from "../fields";
import { PTRA_EMAIL_ENTITY, PTRA_EMAIL_STATUS } from "./fields";

const ptraEmailDispatch = createContext();
ptraEmailDispatch.displayName = "PtraEmailDispatch";
export const usePtraEmailDispatch = () => {
  return useContext(ptraEmailDispatch);
};

const ptraEmailContext = createContext();
ptraEmailContext.displayName = "EmailContext";
export const usePtraEmailContext = () => {
  return useContext(ptraEmailContext);
};

const defaultState = {
  emails: [],
  isLoading: true,
  userId: null,
  refreshCount: 0,
};

const DispatchFn = (state, actions) => {
  if (!Array.isArray(actions)) {
    return DispatchFnSwitch(state, actions);
  }
  return actions.reduce((acc, curr) => DispatchFnSwitch(acc, curr), { ...state });
};
const DispatchFnSwitch = (state, action) => {
  switch (action.type) {
    case "START_REFRESH": {
      return { ...state, isLoading: true };
    }
    case "FINISH_REFRESH": {
      const emails = action.payload;
      const emailOptions = emails.reduce((acc, curr) => {
        if (curr[PTRA_EMAIL_ENTITY.status] === PTRA_EMAIL_STATUS.Enabled) {
          acc.push({
            value: curr[PTRA_EMAIL_ENTITY.id],
            label: curr[PTRA_EMAIL_ENTITY.recipientEmail],
          });
        }
        return acc;
      }, []);
      SelectOptions[addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.emailId)] = () =>
        emailOptions;
      return { ...state, emails, isLoading: false, refreshCount: state.refreshCount + 1 };
    }
    case "REFRESH_ERROR": {
      NotificationHub.send(
        "danger",
        "An error occurred getting emails for Post - Trade Risk Alerts"
      );
      return { ...state, isLoading: false };
    }
    case "SET_USER_ID": {
      return { ...state, userId: action.payload };
    }
    default:
      return { ...state };
  }
};

const PtraEmailContext = ({ children, defaultData, userId }) => {
  const [state, dispatchF] = useReducer(DispatchFn, Object.assign({}, defaultState, defaultData));
  const [user] = useUserContext();

  useEffect(() => {
    dispatchF({
      type: "SET_USER_ID",
      payload: userId,
    });
  }, [userId]);

  const getEmailsCallback = useCallback(data => {
    dispatchF({
      type: "FINISH_REFRESH",
      payload: data,
    });
  }, []);

  const getEmailsError = useCallback(() => {
    dispatchF({
      type: "REFRESH_ERROR",
    });
  }, []);

  useEffect(() => {
    if (state.isLoading && user?.allowed.views[Views.PTR_EMAILS]) {
      doFetchWrapper(
        formatUrl(process.env.REACT_APP_URL_PTRA_SUB_API, "/alert/email"),
        {
          method: "GET",
          mode: "cors",
          headers: getHeaders(),
        },
        getEmailsCallback,
        getEmailsError
      );
    }
  }, [getEmailsCallback, getEmailsError, state.isLoading, user]);

  return (
    <ptraEmailDispatch.Provider value={dispatchF}>
      <ptraEmailContext.Provider value={[state, dispatchF]}>{children}</ptraEmailContext.Provider>
    </ptraEmailDispatch.Provider>
  );
};

export default PtraEmailContext;
