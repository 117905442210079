import { Box, FormSelectCreatable, NotificationHub } from "@nef/core";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { execOrReturn } from "utils/js.utils";
import { final } from "..";
import { useUserContext } from "../../user/context";
import styled from "styled-components";
import { WorkXSelectPersistCreatableManageButton } from "./manageButton";
import { getPersistedCreatableName } from "./constants";
import { handlePersistCreatableOptions } from "./network";
import { Importer } from "./importButton";
import _ from "lodash";
import { FieldBox } from "components/styled";

const Wrapper = styled.div`
  ${props => `
    display: grid;
    align-items: center;
    grid-template-columns: 1fr repeat(${props.hasImport ? 2 : 1}, auto);     
  `}
`;

const defaultValidationMessage = "Did not pass validation";
const WorkXSelectPersistCreatable = props => {
  const [user, userDispatch] = useUserContext();
  const [options, setOptions] = useState([]);
  const {
    value,
    style,
    isRequired,
    form,
    name,
    portalRef,
    handleInputChange: onInputChange,
    id,
    persistKey,
    isMulti,
    onCreateValidation,
    createValidationMessage,
    hasImport = false,
  } = props;
  const fieldData = useMemo(
    () =>
      execOrReturn(final[id || name], {
        form,
        entitlements: user.entitlements,
      }),
    [form, id, name, user.entitlements]
  );

  const forceUpperCase = useMemo(() => fieldData && fieldData.props.forceUpperCase, [fieldData]);

  // build persisted options only when persisted data changes
  useEffect(() => {
    const persistedItem = user?.userData?.find(
      item => item.name === getPersistedCreatableName(persistKey)
    );

    if (persistedItem) {
      let parsedPersistedItems = JSON.parse(persistedItem.data);

      let optionsToReturn = parsedPersistedItems.reduce((acc, option) => {
        let preppedValue = { ...option };
        if (onCreateValidation) {
          if (!onCreateValidation(option.value)) {
            return acc;
          }
        }

        if (forceUpperCase) {
          preppedValue = {
            label: option.label.toUpperCase(),
            value: option.value.toUpperCase(),
          };
        }

        acc.push(preppedValue);
        return acc;
      }, []);

      setOptions(optionsToReturn);
    }
  }, [forceUpperCase, onCreateValidation, persistKey, user?.userData]);

  // build imported options only when an import happens
  const onImportSet = useCallback(
    data => {
      if (!data) {
        return;
      }

      const createdOptions = [];
      data.split(",").forEach(option => {
        let trimmedOption = option.trim();

        if (forceUpperCase) {
          trimmedOption = trimmedOption.toUpperCase();
        }

        const optionToPush = { label: trimmedOption, value: trimmedOption };
        options.push(optionToPush);
        createdOptions.push(optionToPush);
      });

      const allOptions = _.uniqBy(options, "value");
      const newOptionsString = JSON.stringify(allOptions);
      handlePersistCreatableOptions({ persistKey, user, userDispatch, newOptionsString });

      // what should we set the select's selected options to, after import
      let newValue;
      if (value === undefined) {
        newValue = createdOptions;
      } else {
        newValue = value.concat(createdOptions);
      }

      // set the select's selected options
      if (onInputChange) {
        onInputChange({
          id,
          name,
          value: _.uniqBy(newValue, "value"),
          fieldAugment: fieldData?.props?.augmentOnChange?.[form.key],
        });
      }
    },
    [
      fieldData?.props?.augmentOnChange,
      forceUpperCase,
      form.key,
      id,
      name,
      onInputChange,
      options,
      persistKey,
      user,
      userDispatch,
      value,
    ]
  );

  const handleChange = useCallback(
    (e, other) => {
      if (typeof e === "object") {
        let { value } = e;
        if (
          (other && other.action === "clear") ||
          !e ||
          e.clearSelection ||
          e.value?.clearSelection
        ) {
          value = null;
        }

        if (onInputChange) {
          onInputChange({
            id,
            name,
            value,
            fieldAugment: fieldData?.props?.augmentOnChange?.[form.key],
          });
        }
      }
    },
    [onInputChange, id, name, fieldData?.props?.augmentOnChange, form.key]
  );

  const onCreateOption = useCallback(
    inputValue => {
      if (typeof onCreateValidation === "function") {
        if (!onCreateValidation(inputValue)) {
          NotificationHub.send(
            "danger",
            `Unable to create an option with the provided value: '${inputValue}'`,
            {
              subtitle: createValidationMessage
                ? createValidationMessage
                : defaultValidationMessage,
            }
          );
          return;
        }
      }

      let option;
      if (forceUpperCase) {
        option = {
          label: inputValue.toUpperCase(),
          value: inputValue.toUpperCase(),
        };
      } else {
        option = { value: inputValue, label: inputValue };
      }

      if (isMulti) {
        let newValue = [];
        if (Array.isArray(value)) {
          newValue = Array.from(value);
        }
        newValue.push(option);
        handleChange({ value: newValue });
      } else {
        handleChange({ value: option });
      }

      const newOptions = Array.from(options);
      newOptions.push(option);
      const newOptionsString = JSON.stringify(newOptions);
      handlePersistCreatableOptions({ persistKey, user, userDispatch, newOptionsString });
    },
    [
      onCreateValidation,
      forceUpperCase,
      isMulti,
      options,
      persistKey,
      user,
      userDispatch,
      createValidationMessage,
      value,
      handleChange,
    ]
  );

  const manageButtonId = useMemo(() => {
    return `PERSIST_CREATABLE_DELETE_BUTTON_${id ? id : name}`;
  }, [id, name]);

  const placeholderText = useMemo(() => {
    if (props.placeholder) {
      return props.placeholder;
    } else if (props.isMulti) {
      return "Select or create options";
    } else {
      return "Select or create an option";
    }
  }, [props.isMulti, props.placeholder]);

  return (
    <Wrapper style={style} hasImport={true}>
      <FieldBox $isRequired={isRequired} paddingBottom={3}>
        <FormSelectCreatable
          key={JSON.stringify(options)}
          {...props}
          placeholder={placeholderText}
          menuPortalTarget={portalRef?.current}
          value={value || ""}
          onChange={handleChange}
          onCreateOption={onCreateOption}
          options={options}
        />
      </FieldBox>
      {hasImport ? <Importer onImportSet={onImportSet} /> : <></>}
      <WorkXSelectPersistCreatableManageButton
        key={JSON.stringify(options)}
        id={manageButtonId}
        form={form}
        options={options}
        persistKey={persistKey}
      />
    </Wrapper>
  );
};

export default memo(WorkXSelectPersistCreatable);
