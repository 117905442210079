import dayjs from "dayjs";
import React, { useEffect, createContext, useContext, useReducer } from "react";
import { Forms, ScanDateType } from "components/fields/fieldConstants";
import { FieldNames } from "../fields/fieldConstants";
import { safeParseJSON } from "utils/js.utils";
import { RequestResult } from "../../wksConstants";
import { useUserContext, INITIAL_DATA_MODEL } from "components/user";
import { DEFAULT_SCAN_DATE_TYPE } from "./formContext";

const formLayoutDispatch = createContext();
formLayoutDispatch.displayName = "FormLayoutDispatch";
export const useFormLayoutDispatch = () => {
  return useContext(formLayoutDispatch);
};

const formLayoutContext = createContext();
formLayoutContext.displayName = "FormLayoutContext";
export const useFormLayoutContext = () => {
  return useContext(formLayoutContext);
};

let constructState = {
  isDefaultsSavingDisabled: false,
};
Object.keys(Forms).forEach(form => {
  constructState[Forms[form].key] = {
    layouts: {},
    savingList: [],
    activeLayout: undefined,
    maxNumLayout: 10,
  };
});

const defaultState = constructState;

const DispatchFn = (state, actions) => {
  if (!Array.isArray(actions)) {
    return DispatchFnSwitch(state, actions);
  }
  return actions.reduce((acc, curr) => DispatchFnSwitch(acc, curr), { ...state });
};
const DispatchFnSwitch = (state, action) => {
  switch (action.type) {
    case "SET_LAYOUT_SAVING": {
      const { form, layout, isSaving } = action.payload;
      const savingList = [...state[form.key].savingList];
      const index = savingList.indexOf(layout);
      if (isSaving && index === -1) {
        savingList.push(layout);
      } else if (!isSaving && index !== -1) {
        savingList.splice(index, 1);
      }
      const newState = { ...state, [form.key]: { ...state[form.key], savingList } };
      return newState;
    }
    case "SET_ACTIVE_FORM_LAYOUT": {
      const { form, key } = action.payload;
      const newState = { ...state };
      newState[form.key] = { ...newState[form.key], activeLayout: key };
      return newState;
    }
    case "SAVE_LAYOUT": {
      const { form, fields, key, isActive } = action.payload;
      const layouts = { ...state[form.key].layouts };
      layouts[key] = fields;
      return {
        ...state,
        [form.key]: {
          ...state[form.key],
          layouts,
          activeLayout: isActive ? key : state.activeLayout,
        },
      };
    }
    case "REMOVE_FORM_LAYOUT": {
      const { form, key } = action.payload;
      let activeLayout = state[form.key].activeLayout;
      if (activeLayout === key) {
        activeLayout = undefined;
      }
      const layouts = { ...state[form.key].layouts };
      delete layouts[key];
      return {
        ...state,
        [form.key]: {
          ...state[form.key],
          activeLayout,
          layouts,
        },
      };
    }
    case "DISABLE_DEFAULTS_SAVING": {
      return {
        ...state,
        isDefaultsSavingDisabled: action.payload,
      };
    }
    case "LOAD_FORM_LAYOUTS": {
      const { form, layouts } = action.payload;

      const dates = [
        FieldNames.actDate,
        FieldNames.originalControlDate,
        FieldNames.reversalOriginalControlDate,
      ];
      const dateRanges = [FieldNames.dateRange, FieldNames.reportDateRange];
      const times = [
        FieldNames.actTime,
        FieldNames.modifier2Time,
        FieldNames.modifier4Time,
        FieldNames.executionTime,
      ];
      Object.keys(layouts).forEach(layout => {
        // don't load times saved as NEF time picker array that has too view items
        times.forEach(time => {
          if (layouts[layout][time]?.length < 4) {
            layouts[layout][time] = undefined;
          }
        });

        dates.forEach(dateField => {
          if (layouts[layout][dateField] !== undefined) {
            layouts[layout][dateField] = dayjs(layouts[layout][dateField]);
          }
        });

        dateRanges.forEach(dateRangeField => {
          if (layouts[layout][dateRangeField] !== undefined) {
            layouts[layout][dateRangeField] = [
              dayjs(layouts[layout][dateRangeField][0]),
              dayjs(layouts[layout][dateRangeField][1]),
            ];
          }
        });

        // SCAN has single select trf field, while others don't, so apply there only:
        if (form === Forms.TR_SCAN) {
          // if the user has a (legacy) saved layout that has multiple trfs selected,
          //   populate the form with blank trf => multiple TRFs are now represented by
          //   blank TRF rather than all TRF
          // else they have just one selected, so add just that one to the form
          if (layouts[layout][FieldNames.trf]?.length > 1) {
            layouts[layout][FieldNames.trf] = [];
          } else if (layouts[layout][FieldNames.trf]?.length === 1) {
            layouts[layout][FieldNames.trf] = layouts[layout][FieldNames.trf][0];
          }

          const tradeDateFields = [FieldNames.dateRange, FieldNames.tradeTimeRange];
          const reportDateFields = [FieldNames.reportDateRange, FieldNames.reportTimeRange];
          const [defaultDateRangeFields, otherDateRangeFields] =
            DEFAULT_SCAN_DATE_TYPE === ScanDateType.TRADE_DATE
              ? [tradeDateFields, reportDateFields]
              : [reportDateFields, tradeDateFields];
          if (
            [DEFAULT_SCAN_DATE_TYPE, undefined].includes(layouts[layout][FieldNames.scanDateType])
          ) {
            otherDateRangeFields.forEach(field => delete layouts[layout][field]);
          } else {
            defaultDateRangeFields.forEach(field => delete layouts[layout][field]);
          }
        }
      });

      const newState = {
        ...state,
        [form.key]: {
          ...state[form.key],
          layouts: Object.assign({}, { ...state[form.key].layouts }, layouts),
        },
      };
      return newState;
    }
    default:
      return { ...state };
  }
};

const FormLayoutProvider = ({ children, defaultData }) => {
  const [state, dispatchF] = useReducer(DispatchFn, Object.assign({}, defaultState, defaultData));
  const [user] = useUserContext();

  useEffect(() => {
    if (
      user[INITIAL_DATA_MODEL.userDataResult] === RequestResult.success &&
      Array.isArray(user[INITIAL_DATA_MODEL.userData])
    ) {
      let allLayouts = user[INITIAL_DATA_MODEL.userData].filter(
        item => item.type === "form_fields"
      );
      let formLayouts = [];
      if (allLayouts.length > 0) {
        allLayouts.forEach(layout => {
          const formKey = Object.entries(Forms).find(([, v]) => v.key === layout.name)?.[0];
          if (formKey) {
            const form = Forms[formKey];
            if (form) {
              const layouts = safeParseJSON(layout?.data);
              formLayouts.push({ form, layouts });
            }
          }
        });
      }
      const actions = formLayouts.map(setting => {
        return {
          type: "LOAD_FORM_LAYOUTS",
          payload: setting,
        };
      });

      actions.push({ type: "DISABLE_DEFAULTS_SAVING", payload: false });
      dispatchF(actions);
    } else if (user[INITIAL_DATA_MODEL.userDataResult] === RequestResult.error) {
      dispatchF({ type: "DISABLE_DEFAULTS_SAVING", payload: true });
    }
  }, [user]);

  return (
    <formLayoutDispatch.Provider value={dispatchF}>
      <formLayoutContext.Provider value={[state, dispatchF]}>{children}</formLayoutContext.Provider>
    </formLayoutDispatch.Provider>
  );
};

export default FormLayoutProvider;
