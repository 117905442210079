import { getHeaders } from "keycloak";
import { doFetch as globalDoFetch } from "network";
import { formatUrl } from "../../utils/js.utils";
import { TableButtonAction } from "wksConstantsTS";

const urls = {
  [TableButtonAction.ACCEPT]: formatUrl(process.env.REACT_APP_URL_ACT_WS, "accept"),
  [TableButtonAction.DECLINE]: formatUrl(process.env.REACT_APP_URL_ACT_WS, "decline"),
  [TableButtonAction.CANCEL]: formatUrl(process.env.REACT_APP_URL_ACT_WS, "cancel"),
  [TableButtonAction.BREAK]: formatUrl(process.env.REACT_APP_URL_ACT_WS, "break"),
  [TableButtonAction.REVERSE]: formatUrl(process.env.REACT_APP_URL_ACT_WS, "reverse"),
  [TableButtonAction.CLOSE]: formatUrl(process.env.REACT_APP_URL_ACT_WS, "reject/close"),
  [TableButtonAction.ALLOW]: formatUrl(process.env.REACT_APP_URL_ACT_WS, "allow"),
  [TableButtonAction.INHIBIT]: formatUrl(process.env.REACT_APP_URL_ACT_WS, "inhibit"),
};

export const doFetch = async (r, action, callback, errorCallback) => {
  const id = r.id;
  const controlNum = r.controlNum;
  try {
    const { json, response } = await globalDoFetch(urls[action], {
      method: "post",
      mode: "cors",
      headers: getHeaders(),
      body: JSON.stringify(r),
    });

    // only consider 'request errors' if !ok and errorType is not present
    // treat all other requests as valid responses and update rows
    if (response?.ok === false && !json.errorType) {
      errorCallback(json, action, id, controlNum);
      return { response, json };
    } else {
      if (action === TableButtonAction.BREAK) {
        json.brokenFrom = r.willBreakFrom;
      }
      callback({ response, json }, id, controlNum);
    }
    return { response, json };
  } catch (e) {
    errorCallback(e, action, id, controlNum);
  }
};
