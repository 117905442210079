import { StandardHeader, StandardTable } from "components/standardTable";
import { useCallback } from "react";
import { StandardTables } from "wksConstants";

export const PorAuditTable = () => {
  const Header = useCallback(() => <StandardHeader title="Audit Trail" />, []);
  return (
    <StandardTable
      header={Header}
      table={StandardTables.POR_AUDIT}
      enableLayoutExport={true}
      isSingleSelect={false}
      isColumnsVirtualized={false}
      isFilterable={true}
      subHeader={undefined}
      hideRowCount={false}
      hideSelectedCount={true}
      hideQueryDate={false}
      headerMenu={undefined}
      exportCallback={undefined}
      exportFileName={undefined}
      additionalRowClick={undefined}
      isEditableTableEnabled={undefined}
      style={undefined}
      isRowSelectEnabled={false}
      Header={undefined}
    />
  );
};
